/*
 * @Author: Ajay 
 * @Date: 2019-03-28 09:01:57 
 * @Project: Ezyprocure
 * @Filename: ezyap.ezycompany.service.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-03-28 09:11:12
 */

(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('EzyCompanyService', EzyCompanyService);

  EzyCompanyService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function EzyCompanyService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {
    var EzyCompanyService = {
      updateIdentifier: updateIdentifier,
      getIdentifier: getIdentifier,
      createIdentifier: createIdentifier
    }
    return EzyCompanyService;

    /**
     * [Update the EzyCompany details]
     * @param {Object} ezyCompanyDetails [details for ezyCompany]
     */
    function updateIdentifier(ezyCompanyDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-company-details',
        method: "POST",
        data: ezyCompanyDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    /**
     * [get EzyCompany Details]
     * @param {Integer} companyId [Id of EzyCompany]
     * @param {String} userRole [Role of the user]
     */
    function getIdentifier(companyId, userRole) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-company-details/' + companyId + '/' + userRole,
        method: "GET"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    
    /**
     * [Add the details for EzyCompany]
     * @param {Object} ezyCompanyDetails [details for ezyCompany]
     */
    function createIdentifier(ezyCompanyDetails) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.itemImageBulkUploadServiceBase + RESOURCE_URL_CONSTANTS.PEPPOL_SERVICE + 'api/ezy-company-details',
        method: "PUT",
        data: ezyCompanyDetails
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
