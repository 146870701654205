/*
 * @Author: Lovin Chacko
 * @Date: 2020-08-10 12:39:42
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-03-26 21:55:29
 */

(function () {
    "use strict";

    angular
        .module("ezyprocureApp")
        .controller("AdhocPriceUpdateController", AdhocPriceUpdateController);

    AdhocPriceUpdateController.$inject = [
        "$state",
        "$mdDialog",
        "BuyerDataService",
        "AdhocItemPriceUpdateService",
        "BuyerSupplierMappingService",
        "BrandManagementService",
        "BuyerBusinessService",
        "GLOBAL_CONSTANTS",
        "paginationConstants",
        "NotificationService",
    ];

    function AdhocPriceUpdateController(
        $state,
        $mdDialog,
        BuyerDataService,
        AdhocItemPriceUpdateService,
        BuyerSupplierMappingService,
        BrandManagementService,
        BuyerBusinessService,
        GLOBAL_CONSTANTS,
        paginationConstants,
        NotificationService
    ) {
        var vm = this;
        vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
        vm.getAllBuyerMappedAdhocSuppliers = getAllBuyerMappedAdhocSuppliers;
        vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
        vm.priceUpdateFilter = {};
        vm.priceUpdateFilter.approvalRequiredFromBuyer = false;
        vm.proceedToPriceUpdate = proceedToPriceUpdate;
        vm.priceUpdateList = false;
        vm.recordError = {};
        vm.selectAllRows = false;
        vm.checkAllRows = checkAllRows;
        vm.deleteSelected = deleteSelected;
        vm.limitOptions = paginationConstants.limitOptions;
        vm.query = {
            limit: paginationConstants.itemsPerPage,
            page: 1,
        };
        vm.showPopup = showPopup;
        vm.confirmNo = confirmNo;
        vm.confirmYes = confirmYes;
        vm.recordError.responseList = [];
        vm.deletePriceUpdate = deletePriceUpdate;
        vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
        vm.getAllBrandMappedsBusinessOutlets =
            getAllBrandMappedsBusinessOutlets;
        vm.selectAll = selectAll;
        vm.deselectAll = deselectAll;
        vm.clearSearch = clearSearch;
        vm.itemDTOObj = {
            brandList: [],
            businessList: [],
        };
        vm.showPanel = false;
        vm.editPrice = editPrice;
        vm.itemSearch = itemSearch;
        vm.proceedToPriceUpdateNew = proceedToPriceUpdateNew;
        vm.isSingle = false;

        /**
         * [getAllBuyerMappedAdhocSuppliers Get all adhoc suppliers mapped buyer]
         * @param {Array} buyerIdList
         */
        function getAllBuyerMappedAdhocSuppliers() {
            if (vm.suppliers) return false;
            AdhocItemPriceUpdateService.getAllBuyerMappedAdhocSuppliers(
                vm.buyerId,
                {
                    page: 0,
                    size: paginationConstants.maxLimit,
                    sort: vm.supplierSort,
                }
            ).then(
                function (response) {
                    vm.suppliers = response.data;
                },
                function (error) {}
            );
        }

        function clearSearch() {
            vm.itemDTOObj = {
                brandList: [],
                businessList: [],
            };
            vm.showPanel = false;
        }

        /**
         * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
         * @param {Array} buyerIdList
         */
        function getAllBrandsOfBuyerList() {
            vm.itemDTOObj.businessList = [];
            if (vm.brands) return false;
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
                query: "",
                buyerId: BuyerDataService.getBuyerProfile().buyerUser.buyer.id,
            };
            BrandManagementService.getAllBrandsOfBuyerList(params).then(
                function (response) {
                    vm.brands = response;
                },
                function (error) {
                    NotificationService.error({
                        title: "Brand Listing",
                        error: error,
                    });
                }
            );
        }

        function deleteSelected() {
            var deleteList = [];
            angular.forEach(vm.priceLists, function (invoice) {
                if (invoice.selected) {
                    deleteList.push(invoice.id);
                }
            });
            AdhocItemPriceUpdateService.deletePriceUpdateBulk({
                ids: deleteList,
            }).then(
                function (response) {
                    NotificationService.success({
                        title: "Price Update",
                        message: "alertMessage.DELETED_SUCCESSFULLY",
                    });
                    vm.proceedToPriceUpdate();
                },
                function (error) {
                    NotificationService.error({
                        title: "Price Update",
                        error: error,
                    });
                }
            );
        }

        /**
         * cancel allows close the pop-up
         */
        function confirmNo() {
            $mdDialog.cancel();
        }

        function confirmYes() {
            if(vm.isSingle){
                vm.deletePriceUpdate(vm.isSingle);
            } else {
                vm.deleteSelected();
            }
            $mdDialog.cancel();
        }

        function showPopup(val) {
            vm.isSingle = val.id;
            console.log(val);
            vm.confirmTitle = "Delete Confirmation";
            vm.confirmBody =
                "You are going to delete an existing price update. This will revert the price to previous price. Do you want to continue?";
            $mdDialog.show({
                templateUrl:
                    "app/core/common-templates/confirm-dialog.tmpl.html",
                controller: function () {
                    return vm;
                },
                controllerAs: "vm",
                targetEvent: event,
                clickOutsideToClose: true,
            });
        }
        function checkAllRows() {
            vm.selectAllRows
                ? (vm.selectAllRows = true)
                : (vm.selectAllRows = false);
            angular.forEach(vm.priceLists, function (invoice) {
                invoice.selected = vm.selectAllRows;
            });
        }

        /**
         * [selectAll Selects all from the list]
         * @param  {[type]} dropdown [Active dropdown - outlets or brands]
         */
        function selectAll(dropdown) {
            switch (dropdown) {
                case "BRANDS":
                    vm.itemDTOObj.brandList = _.map(vm.brands, function (obj) {
                        return obj;
                    });
                    break;
                case "OUTLETS":
                    vm.itemDTOObj.businessList = _.map(
                        vm.brandMappedBusinessOutlets,
                        function (obj) {
                            return obj;
                        }
                    );
                    break;
            }
        }

        /**
         * [deselectAll Deselect all from the list]
         * @param  {[type]} dropdown [Active dropdown - outlets or brands]
         */
        function deselectAll(dropdown) {
            switch (dropdown) {
                case "BRANDS":
                    vm.itemDTOObj.brandList = [];
                    break;
                case "OUTLETS":
                    vm.itemDTOObj.businessList = [];
                    break;
            }
        }

        /**
         * [getAllBrandMappedsBusinessOutlets  return business outlets based on brand]
         */
        function getAllBrandMappedsBusinessOutlets(brandList) {
            var brandIdList = _.map(brandList, _.property("id"));
            var itemTerminationDTO = {
                brandIdList: brandIdList,
                buyerIdList: [vm.buyerId],
            };
            var params = {
                page: 0,
                size: paginationConstants.maxLimit,
            };
            BuyerBusinessService.getAllBrandMappedsBusinessOutlets(
                itemTerminationDTO,
                params
            ).then(
                function (response) {
                    vm.brandMappedBusinessOutlets = response.data;
                },
                function (error) {}
            );
        }

        /**
         * [proceedToPriceUpdate Proceed to item selection page]
         */
        function proceedToPriceUpdate() {
            var businessList = _.map(
                vm.itemDTOObj.businessList,
                _.property("id")
            );
            var supplierId = vm.itemDTOObj.supplier.id;
            AdhocItemPriceUpdateService.getAllAdhocItemPrices({
                supplierId: supplierId,
                itemId: vm.itemDTOObj.itemId ? vm.itemDTOObj.itemId : "",
                businessIdList: businessList,
                page: vm.query.page - 1,
                size: vm.query.limit,
            }).then(
                function (response) {
                    vm.showPanel = true;
                    AdhocItemPriceUpdateService.setPriceObject(vm.itemDTOObj);
                    vm.priceList = [];
                    vm.priceLists = response.data;
                    vm.totalItems = response.headers("X-Total-Count");
                },
                function (error) {
                    NotificationService.error({
                        title: "Price Update",
                        error: error,
                    });
                }
            );
        }

        function proceedToPriceUpdateNew() {
            var businessList = _.map(
                vm.itemDTOObj.businessList,
                _.property("id")
            );
            var supplierId = vm.itemDTOObj.supplier.id;
            AdhocItemPriceUpdateService.getAllAdhocItemPrices({
                supplierId: supplierId,
                itemId: vm.itemDTOObj.itemId ? vm.itemDTOObj.itemId : "",
                businessIdList: businessList,
                page: vm.query.page - 1,
                size: vm.query.limit,
            }).then(
                function (response) {
                    vm.showPanel = true;
                    AdhocItemPriceUpdateService.setPriceObject(vm.itemDTOObj);
                    vm.priceList = [];
                    vm.priceLists = response.data;
                    vm.totalItems = response.headers("X-Total-Count");
                    $state.go("buyer.adhoc-tools.price-update.add");
                },
                function (error) {
                    NotificationService.error({
                        title: "Price Update",
                        error: error,
                    });
                }
            );
        }

        function itemSearch() {
            AdhocItemPriceUpdateService.searchAllItems({
                businessIdList: _.map(
                    vm.itemDTOObj.businessList,
                    _.property("id")
                ),
                brandIdList: _.map(vm.itemDTOObj.brandList, _.property("id")),
                supplierId: vm.itemDTOObj.supplier.id,
                queryField: "",
                queryText: "",
            }).then(
                function (response) {
                    vm.items = response.data;
                },
                function (error) {}
            );
        }

        /**
         * [editPrice] setprice object to service ]
         */
        function editPrice(priceList) {
            var editObj = priceList;
            if (!editObj.effectiveEndDate) editObj.perpetual = true;
            AdhocItemPriceUpdateService.setEditPriceObject(editObj);
            $state.go("buyer.adhoc-tools.price-update.edit");
        }

        /**
         * [deletePriceUpdate] delete price update]
         */
        function deletePriceUpdate(id) {
            AdhocItemPriceUpdateService.deletePriceUpdate(id).then(
                function (response) {
                    NotificationService.success({
                        title: "Price Update",
                        message: "alertMessage.DELETED_SUCCESSFULLY",
                    });
                    vm.proceedToPriceUpdate();
                },
                function (error) {
                    NotificationService.error({
                        title: "Price Update Request",
                        error: error,
                    });
                }
            );
        }
    }
})();
