/**
* @Author: Shibi
* @Date:   18-May-2020 18:10 +05:30
* @Project: Ezyprocure
* @Filename: stock-take.controller.js
*/

(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .controller('StockTakeController', StockTakeController);
  StockTakeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$q', '$mdDialog', '$filter', 'StockTakeService', 'NotificationService','pagingParams', 'paginationConstants', 'Auth', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS'];
  function StockTakeController($scope, $rootScope, $state, $stateParams, $q, $mdDialog, $filter, StockTakeService,NotificationService, pagingParams, paginationConstants, Auth, GLOBAL_CONSTANTS, EXPORT_CONSTANTS) {
    var vm = this;
    vm.init = init;  
    vm.todaysDate = new Date();
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getAllStockTake = getAllStockTake;    
    vm.getAllStockTakeCategory = getAllStockTakeCategory;    
    vm.getAllStockTakeLocations = getAllStockTakeLocations;
    vm.getAllStockTakeItemName  = getAllStockTakeItemName;
    vm.getAllStockTakeSubCategory = getAllStockTakeSubCategory;
    vm.getAllStockTakeWarehouse = getAllStockTakeWarehouse;
    vm.variationStock = variationStock; 
    vm.createStockTake = createStockTake;
    vm.stockTakeFilterClear = stockTakeFilterClear;
    vm.itemListQuery = {};
    vm.resetPaginationQuery = resetPaginationQuery;    
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.itemUoMList = [];
    vm.selectedIndex = undefined;
    vm.selectedItemName = undefined;
    vm.totalInvStock = undefined;
    vm.totalInvStockValue = undefined;
    vm.openDialog = openDialog;
    vm.closeDialog = closeDialog;
    vm.calculateInvStock = calculateInvStock;
    vm.calculateCurrentStockValue = calculateCurrentStockValue;
    vm.calculateCurrentStockValueInListView = calculateCurrentStockValueInListView;
    vm.getValidStockEntries = getValidStockEntries;

    function getValidStockEntries() {
      var validStockEntries = [];
      if (vm.items && vm.items.length) {
        validStockEntries = vm.items.filter(function (item) {
          return (item.currentstock != "" && item.currentstock != undefined) && item.rate;
        });
      }
      return validStockEntries;
    }

    function calculateCurrentStockValueInListView(index) {
      var item = vm.items[index];
      var currentStock = item.currentstock || 0;
      var cost = item.rate || 0;
      item.currentStockValue = (cost * currentStock).toFixed(2);
      // item.invCurrentStockValue = item.currentStockValue;
    }

    function calculateCurrentStockValue(index) {
      var item = vm.itemUoMList[index];
      var currentStock = item.currentStock || 0;
      var cost = item.rate || 0;
      item.currentStockValue = (cost * currentStock).toFixed(3);
      item.invCurrentStockValue = item.currentStockValue;
      calculateTotalInvStock();
    }

    function resetPaginationQuery() {
      vm.query = {
        limit: 20,
        page: 1
      };
    }
    vm.resetPaginationQuery();
    function init() {  
      vm.getAllStockTake();
      vm.getAllStockTakeCategory();
      vm.getAllStockTakeLocations();
      vm.getAllStockTakeItemName();        
    }
    // init();
    //Get all items
    function getAllStockTake() {             
      StockTakeService.getAllStockTakeItems(vm.itemListQuery,{
          page: vm.query.page - 1,
          size: vm.query.limit,
          categoryId : vm.itemListQuery.categoryId ? vm.itemListQuery.categoryId:'',
          subcategoryId : vm.itemListQuery.subcategoryId ? vm.itemListQuery.subcategoryId: '',
          locationId : vm.itemListQuery.locationId ? vm.itemListQuery.locationId: '',
          warehouseId : vm.itemListQuery.warehouseId ? vm.itemListQuery.warehouseId : '',
          itemId : vm.itemListQuery.itemId ? vm.itemListQuery.itemId : '',      
        }).then(function(response){          
          vm.totalItems = response.headers('X-Total-Count');
          vm.recordError = [];                    
          vm.items = response.data; 
          createUoMList();         
          vm.itemListLoaded = true;            
        },function(error){
          NotificationService.error({
            title: 'Stock Take - Listing Items',
            error: error
          });
        });      
    }

    function createUoMList() {
      vm.items.forEach(function (item) {
        var inventoryUoM = { "id": item.uomId, "name": item.uom, "rate": item.rate };
        item.selectedUoM = inventoryUoM.id;
        item.itemUoMList = [];
        if (item.alternateUoms && item.alternateUoms.length > 0) {
          item.itemUoMList = item.alternateUoms.map( function (altUoM) {
            var _altUoM = { "id": altUoM.id, "name": altUoM.alternateUomName, "rate": item.rate };
            return _altUoM;
          });
        }
        item.itemUoMList.unshift(inventoryUoM);
      });
    }

    function openDialog(index) {
      vm.itemUoMList = vm.items[index].itemUoMList;
      vm.selectedIndex = index;
      vm.selectedItemName = vm.items[index].itemName;
      calculateTotalInvStock();
      // show dialog
      $mdDialog.show({
        templateUrl: 'app/common-modules/stock-take/stock-take-main/templates/stock-update.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function closeDialog() {
      $mdDialog.hide();
    }

    function calculateStockValue(currentStock, baseConversionFactor, alternateUnits) {
      var invStock = (parseFloat(isNaN(currentStock) ? 0 : currentStock) / parseFloat(alternateUnits)) * parseFloat(baseConversionFactor);
      return invStock.toFixed(3);
    }

    /**
     * calculate inventory stock for alternate uoms,
     * skip calculation for base uom,
     * update current stock value
     * @param {number} uomIndex 
     */
    function calculateInvStock(uomIndex) {
      calculateTotalInvStock();
      if (uomIndex) {
        var selectedItem = vm.items[vm.selectedIndex];
        var chosenAltUoM = selectedItem.alternateUoms.find(function (altUoM) {
          return altUoM.id == selectedItem.itemUoMList[uomIndex].id;
        });
        var invStock = calculateStockValue(selectedItem.itemUoMList[uomIndex].currentStock, chosenAltUoM.baseUomFactor, chosenAltUoM.alternateConversionFactor);
        selectedItem.itemUoMList[uomIndex].invCurrentStock = invStock;
      }
      else {
        vm.items[vm.selectedIndex].itemUoMList[uomIndex].invCurrentStock = (+vm.items[vm.selectedIndex].itemUoMList[uomIndex].currentStock).toFixed(3);
      }
      calculateTotalInvStock();
    }

    function calculateTotalInvStock() {
      var selectedItem = vm.items[vm.selectedIndex];
      var _totalInvStock = 0;
      var _totalInvStockValue = 0;
      selectedItem.itemUoMList.forEach(function (uom) {
        if(uom.invCurrentStock && !isNaN(parseFloat(uom.invCurrentStock))) {
          _totalInvStock += parseFloat(uom.invCurrentStock);
        }
      });
      vm.totalInvStock = _totalInvStock.toFixed(3);
      selectedItem.currentstock = vm.totalInvStock;
      calculateCurrentStockValueInListView(vm.selectedIndex);
      variationStock(selectedItem.inStock, selectedItem.currentstock, vm.selectedIndex);
    }

    //Get all Category
    function getAllStockTakeCategory() {        
      StockTakeService.getAllStockTakeCategoryItems().then(function(response){   
        vm.stockCategory = response.data; 
        },function(error){
          NotificationService.error({
            title: 'Stock Take - category Items',
            error: error
          });
        });      
        vm.getAllStockTake();  
    }
    //Get all Locations
    function getAllStockTakeLocations() {        
      StockTakeService.getAllStockTakeLocationsItems().then(function(response){   
        vm.stockLocations = response.data; 
        },function(error){
          NotificationService.error({
            title: 'Stock Take - Locations',
            error: error
          });
        }); 
        vm.getAllStockTake();  
    }
    //Get all ItemName
    function getAllStockTakeItemName() {        
      StockTakeService.getAllStockTakeItemNames().then(function(response){   
        vm.stockItemName = response.data; 
        },function(error){
          NotificationService.error({
            title: 'Stock Take - Locations',
            error: error
          });
        });      
        vm.getAllStockTake();  
    }

    //Get all subcategories
    function getAllStockTakeSubCategory() {          
      StockTakeService.getAllStockTakeSubCategoryItems(vm.itemListQuery.categoryId).then(function(response){   
        vm.stockSubCategory = response.data; 
        },function(error){
          NotificationService.error({
            title: 'Stock Take - SubCategory',
            error: error
          });
        });  
        vm.getAllStockTake();  
    }

    //Get all warehouse
    function getAllStockTakeWarehouse() {          
      StockTakeService.getAllStockTakeWarehouseItems(vm.itemListQuery.locationId).then(function(response){   
        vm.stockWareHouse = response.data; 
        },function(error){
          NotificationService.error({
            title: 'Stock Take - Warehouse',
            error: error
          });
        });     
        vm.getAllStockTake();  
    }

    function variationStock(instock, currentstock, index) {   
      vm.selectedIndexVariation = index;   
      var selectedItem = vm.items[index];
      if(selectedItem.inStock > 0) {
        selectedItem.variancePercent = (((selectedItem.currentstock - selectedItem.inStock) * 100)/selectedItem.inStock).toFixed(2);
      }
      else {
        selectedItem.variancePercent = selectedItem.inStock;
      }
      selectedItem.variance = Math.sign(selectedItem.currentstock - selectedItem.inStock);
      selectedItem.varianceQty = selectedItem.variance * ((selectedItem.currentstock - selectedItem.inStock).toFixed(3));
      // calculate stock value
      calculateCurrentStockValueInListView(index);       
    } 

    function createStockTake() {
      var validEntries = getValidStockEntries();
      var selectedStockTake = _.map(validEntries, function (item) {
        return {
          "stockTakeId": item.id,
          "itemName": item.itemName,
          "batch": item.batch,
          "bin": item.bin,
          'inStock': item.inStock,
          'currentStock': item.currentstock,
          'amount': item.currentStockValue,
          'cost': item.rate,
          'variation': item.varianceQty,
          'expiryDate': item.expiryDate,
          'warehouse': item.warehouse,
          'itemCode':item.itemCode,
          'location': item.location,
          'subcategory': item.subcategory,
          'uom': item.uom,
          'remarks': vm.remarksOnStockTake,
          'createdDate': vm.todaysDate
        }
      });
      var selectedStockTakeItems = _.compact(selectedStockTake);
      if (selectedStockTakeItems.length > 0) {
        StockTakeService.setSelectedStockTake(selectedStockTakeItems);
        NotificationService.success({
          title: 'Confirm Stock Take',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
        if ($state.current.parent == 'buyer') {
          $state.go('buyer.confirm-stocktake', {
            reload: false
          });
        }
        else {
          $state.go('supplier.confirm-stocktake', {
            reload: false
          });
        }
      }
    }

    function stockTakeFilterClear(){
      vm.itemListQuery.categoryId = '';
      vm.itemListQuery.subcategoryId = '';
      vm.itemListQuery.locationId = '';
      vm.itemListQuery.warehouseId = '';
      vm.itemListQuery.itemId = '';
      vm.getAllStockTake();
    }

    }
})();
