/**
* @Author: sneha
* @Date:   2018-09-07T14:42:15+05:30
* @Project: Ezyprocure
* @Filename: ezypayment.cards.add.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-09-27T16:29:07+05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAddCardController', EzypaymentAddCardController);

  EzypaymentAddCardController.$inject = ['$stateParams', '$state', 'NotificationService', 'toastr', 'CardsService', 'GLOBAL_CONSTANTS', '$mdDialog'];

  function EzypaymentAddCardController($stateParams, $state, NotificationService, toastr, CardsService, GLOBAL_CONSTANTS, $mdDialog) {

    var vm = this;
    vm.init = init;
    vm.getSessionFromMPGS = getSessionFromMPGS;
    vm.getBrandsAndCardType = getBrandsAndCardType;
    vm.addCreditCard = addCreditCard;
    vm.months = GLOBAL_CONSTANTS.CREDIT_CARD.MONTHS;
    vm.years = {};
    vm.errorMessage = '';
    vm.showMPGSErrorNotification = showMPGSErrorNotification;
    vm.errorList = [];
    vm.configurePaymentSession = configurePaymentSession;
    vm.getMPGSErrorList = getMPGSErrorList;
    vm.userRole = $stateParams.userRole;
    vm.addSupplierCreditCard = addSupplierCreditCard;
    vm.cancel = cancel;
    vm.generateYears = generateYears;
    vm.generateYears();
    vm.updateMPGS = false;
    vm.randomIdMPGSRef = '';
    vm.randomIdMPGSTrans = '';
    vm.getPaymentResponse = getPaymentResponse;
    vm.checkPaymentResponse = '';    
    vm.creditCard = CardsService.getBrandsAndCardType();

    function init(){
      vm.getBrandsAndCardType();
    }

    // year generator
    function generateYears() {
      var currentYear = new Date().getFullYear();
      var limit = currentYear + 20;
      while (limit >= currentYear) {
        vm.years[currentYear] = currentYear % 100;
        ++currentYear;
      }
    }

    /**
    * [configurePaymentSession configure Payment session object for adding card]
    * @return {[type]} [description]
    */
    function configurePaymentSession(){
      vm.MPGSScriptURL = CardsService.getMPGSScriptNewVersion(vm.creditCard.merchantId);  //get url for MPGS script to enable hosted session

      //set script for MPGS hosted session for saving card details
      $.getScript(vm.MPGSScriptURL, function() {
        if (self === top) {
          var antiClickjack = document.getElementById("antiClickjack");
          antiClickjack.parentNode.removeChild(antiClickjack);
        } else {
          top.location = self.location;
        }
            //configuring PaymentSession object
            PaymentSession.configure({
              session: vm.creditCard.sessionId,
              fields: {
                // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
                card: {
                  number: "#card-number",
                  securityCode: "#security-code",
                  expiryMonth: "#expiry-month",
                  expiryYear: "#expiry-year"
                }
              },
              //SPECIFY YOUR MITIGATION OPTION HERE
              frameEmbeddingMitigation: ["javascript"],
              callbacks: {
                initialized: function(response) {
                  // HANDLE INITIALIZATION RESPONSE

                },
                formSessionUpdate: function(response) {
                  vm.errorList = [];
                  // HANDLE RESPONSE FOR UPDATE SESSION
                  if (response.status) {
                    if (response.status === "ok") {
                      vm.creditCard.sessionId = response.session.id;
                    //   if(vm.userRole === 'supplier'){
                    //     vm.addSupplierCreditCard();
                    //   }else{
                    //     vm.addCreditCard();
                    //   }
                    } else if (response.status === "fields_in_error")  {
                      NotificationService.simpleErrorToast({
                        title: 'Add Credit Card',
                        message: 'alertMessage.MPGS.SESSION_UPDATE_FAILED_WITH_FIELD_ERRORS'
                      });
                      vm.getMPGSErrorList(response.errors);
                    } else if (response.status === "request_timeout")  {
                      toastr.error('Session update failed with request timeout!', response.errors.message);

                    } else if (response.status === "system_error")  {
                      toastr.error('Session update failed with system error!', response.errors.message);
                    }
                  } else {
                    toastr.error('Session update failed!', response);
                  }
                  if(vm.errorMessage !== ""){
                    vm.showMPGSErrorNotification(vm.errorMessage)
                  }
                }
              }
            });
        // })
      });
    }

    /**
    * [showMPGSErrorNotification description]
    * @param  {[type]} errorMessage [display error messages for MPGS validation messages]
    * @return {[type]}              [description]
    */
    function showMPGSErrorNotification(errorMessage){
      NotificationService.simpleErrorToast({
        title: 'Add Credit Card',
        message: errorMessage
      });
    }

    /**
    * [getBrandsAndCardType retrieve brands and card type from CardsService]
    * @return {[type]} [description]
    */
    function getBrandsAndCardType(){
    if(vm.creditCard == undefined)return;
      var payload = {
        brandIds:vm.creditCard.brandIds,
        cardType:vm.creditCard.cardType,
        cardCycle:vm.creditCard.cardCycle
    }
    if(vm.creditCard.supplierIds.length>0){
      payload.supplierId = vm.creditCard.supplierIds[0];
    }
    CardsService.createMPGSSession(payload).then(function (res){
      vm.randomIdMPGSRef = (Math.random() + 1).toString(36).substring(7) + (Math.random() + '').substring(2,10);
      vm.randomIdMPGSTrans = (Math.random() + 1).toString(36).substring(7) + (Math.random() + '').substring(2,10);

      vm.creditCard.sessionId = res.data.message;
      payload.sessionId =  vm.creditCard.sessionId;
          CardsService.updateMPGSSession(payload).then(function (res){
            vm.creditCard.sessionId = res.data.message;
            if(!vm.creditCard){
              $state.go('ezypayment.buyer.manage-cards.choose-brands',{});
            }
            else{
              vm.configurePaymentSession();
            }
          });
    })
    }

    /**
    * [getSessionFromMPGS get sessionID from MPGS]
    * @return {[type]} [description]
    */
    function getSessionFromMPGS() {
      var user ;
      vm.userRole === 'buyer' ? user = vm.creditCard.brandIds : user = vm.creditCard.supplierIds;
      if(vm.creditCard && user.length>0){
        // UPDATE THE SESSION WITH THE INPUT FROM HOSTED FIELDS
        PaymentSession.updateSessionFromForm('card');
        var payloadInit = {
            sessionId:vm.creditCard.sessionId,
            brandIds:vm.creditCard.brandIds,
            cardType:vm.creditCard.cardType,
            cardCycle:vm.creditCard.cardCycle,
            referenceNumber: vm.randomIdMPGSRef,
            transactionUuid:vm.randomIdMPGSTrans
        }
        var statusInit = {
          transactioUUID: vm.randomIdMPGSTrans,
          referenceNumber: vm.randomIdMPGSRef          
        }
        if(vm.creditCard.supplierIds.length>0){
          payloadInit.supplierId = vm.creditCard.supplierIds[0];
        }
        CardsService.initiateMPGSAuth(payloadInit).then(function (res){
            vm.popupUiCode = res.data.authentication.redirect.html;
            var id = $(vm.popupUiCode).attr('id')
            $(vm.popupUiCode).ready(function() {
              $('#'+id).css("height", "55vh");
              $('#'+id).css("overflow-y", "clip");
              $('iframe').css("height", "414px");
              $('iframe').css("width", "800px");
            });
            $mdDialog.show({
              template: vm.popupUiCode,
              controller: function() {
                  return vm;
              },
              controllerAs: 'vm',
              clickOutsideToClose: false,
              parent: angular.element(document.body)
          });
           vm.checkPaymentResponse = setInterval(function(){
              vm.getPaymentResponse(statusInit);
              }, 3000);
        })
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'Add Credit Card',
          message: error
        });
      }
    }

    /**
    * [addSupplierCreditCard pass sessionID to backend with supplier id]
    */
   function addSupplierCreditCard() {
    if(vm.creditCard.sessionId!==" " && vm.creditCard.supplierIds.length>0){
      vm.creditCardDetails = {
        'sessionId' : vm.creditCard.sessionId,
        'supplierId' : vm.creditCard.supplierIds[0],
        'brandIds' : vm.creditCard.brandIds,
        'cardType' : 'NA',
        'cardCycle': vm.creditCard.cardCycle ? vm.creditCard.cardCycle : ''
      }
      CardsService.createSupplierCreditCards(vm.creditCardDetails).then(function(response){
        NotificationService.success({
          title: 'Cards',
          message: 'Added successfully'
        });
        $state.go('ezypayment.buyer.manage-cards', {initiallyActiveTab : true});
      },function(error) {
        NotificationService.error({
          title: 'Add Credit Card',
          error: error
        });
      });
    }
  }

    /**
    * [addCreditCard pass sessionID to backend]
    */
    function addCreditCard() {
      if(vm.creditCard.sessionId!==" " && vm.creditCard.brandIds.length>0){
        vm.creditCardDetails = {
          'sessionId' : vm.creditCard.sessionId,
          'brandIds' : vm.creditCard.brandIds,
          'cardType' : 'NA',
          'cardCycle': vm.creditCard.cardCycle ? vm.creditCard.cardCycle : ''
        }
        CardsService.addCreditCard(vm.creditCardDetails).then(function(response){
          NotificationService.success({
            title: 'Cards',
            message: 'Added successfully'
          });
          $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false});
        },function(error) {
          NotificationService.error({
            title: 'Add Credit Card',
            error: error
          });
        });
      }
    }

/**
 * [getMPGSErrorList description]
 * @param  {[type]} sessionError [get errors generated by MPGS script ]
 * @return {[type]}              [description]
 */
    function getMPGSErrorList(sessionError){
      if (sessionError.cardNumber) {
        vm.errorList.push('alertMessage.MPGS.CARD_NUMBER_INVALID_OR_MISSING')
      }
      if (sessionError.expiryYear) {
        vm.errorList.push('alertMessage.MPGS.CARD_EXPIRY_YEAR_INVALID')
      }
      if (sessionError.expiryMonth) {
        vm.errorList.push('alertMessage.MPGS.CARD_EXPIRY_MONTH_INVALID')
      }
      if (sessionError.securityCode) {
        vm.errorList.push('alertMessage.MPGS.CARD_SECURITY_CODE_INVALID')
      }
    }

    /**
     * [cancel Depends on the User it will redirects to the corresponding tab]
     */
    function cancel(){
      vm.userRole === 'buyer' ? $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false}) : $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : true})
    }

    /**
     * [To check status of the card adding]
     */
    function getPaymentResponse(statusInit){      
      CardsService.getCardOnTransactionUUID(statusInit).then(function (res){
        if(res.data.status == 'ACCEPT'){
          $mdDialog.cancel();
          clearInterval(vm.checkPaymentResponse);
          vm.creditCard.user === 'buyer' ? $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false}) : $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : true});
        }else{
            
        }        
      });
    }
  }
})();
