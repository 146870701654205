/**
* @Author: Ajay
* @Date:   10-May-2017 19:03 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-pending.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-20T10:03:29+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierInvoicePendingDetailsController', SupplierInvoicePendingDetailsController);

  SupplierInvoicePendingDetailsController.$inject = ['$scope', '$state', '$stateParams', 'NotificationService', 'SupplierDataService', 'BuyerReceivingOrderService', 'InvoiceService', 'UTCDateService', 'PurchaseOrderService', 'Auth', 'GLOBAL_CONFIG_CONSTANTS', 'GLOBAL_CONSTANTS'];

  function SupplierInvoicePendingDetailsController($scope, $state, $stateParams, NotificationService, SupplierDataService, BuyerReceivingOrderService, InvoiceService, UTCDateService, PurchaseOrderService, Auth, GLOBAL_CONFIG_CONSTANTS, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.checkInvoiceAlreadyGenerated = checkInvoiceAlreadyGenerated;
    vm.calculateInvoiceTotal = calculateInvoiceTotal;
    vm.generateInvoice = generateInvoice;
    vm.individualTotalPrice = individualTotalPrice;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.editablePendInv = SupplierDataService.getSupplierProfile().accessDTO.accessForEditingPendingInvoice;
    vm.mandatoryHardcopyInvoice = Auth.hasPermission('accessForMandatoryHardCopyInvoiceNumber');
    vm.calculateDiscount = calculateDiscount;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.calculateOtherCharges = calculateOtherCharges;
    vm.otherChargesAmount = vm.otherChargesPerCent = 0;
    //negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');

    /**
     * [init loads the function initially]
     */
    function init(){
      loadAll();
    }

    // Redirect back to invoice pending listing page if invoice already generated*
    function checkInvoiceAlreadyGenerated(purchaseOrderDetails) {
      if (purchaseOrderDetails.invoice !== null) {
        $state.go('supplier.invoice-pending', {}, { reload: false});
      }
    }

    /**
     * [loadAll Gets GRN item Details by PO number]
     */
    function loadAll() {
      BuyerReceivingOrderService.getGRNItemDetails(vm.purchaseOrderNumber, {
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
      }).then(function(response){
        vm.checkInvoiceAlreadyGenerated(response.data);
        vm.purchaseOrderDetails = response.data;
        orderItemsByPurchaseOrderId(vm.purchaseOrderDetails.items);
        vm.purchaseOrderDetails.invoice = {
          "hardcopyInvoiceNumber": null,
          "deliveryCharge": vm.purchaseOrderDetails.acceptTotalAmount >= vm.purchaseOrderDetails.supplier.minimumOrderForFreeDelivery ? 0 : vm.purchaseOrderDetails.deliveryCharges,
          "discount": vm.purchaseOrderDetails.discount?vm.purchaseOrderDetails.discount:0,
          "invoiceComment": "",
          "invoiceDate": "",
          "invoiceNumber": null,
          "invoiceSubTotal":0,
          "invoiceTotal":0,
          "addedGst":0,
          "grandTotal":0,
          "otherCharges":{}
        };
        vm.purchaseOrderDetails.supplier.deliveryCharge =  vm.purchaseOrderDetails.deliveryCharges;
        vm.maxDeliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
        vm.tempPercentDiscount = 0;
        vm.tempAbsoluteDiscount = vm.purchaseOrderDetails.discount?vm.purchaseOrderDetails.discount:0;
        vm.calculateDiscount('ABSOLUTE');
        vm.calculateInvoiceTotal();
        if (vm.purchaseOrderDetails.otherCharges){
          vm.purchaseOrderDetails.otherCharges = JSON.parse(JSON.stringify(vm.purchaseOrderDetails.otherCharges));
          var keys = Object.keys(vm.purchaseOrderDetails.otherCharges);
          vm.otherChargesAmount = vm.purchaseOrderDetails.otherCharges[keys[0]];
          vm.otherChargesKey = keys[0];
        }
        vm.calculateOtherCharges('AMOUNT')
      },function(error){
      });
    }

    function calculateInvoiceTotal() {
      var deliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
      var discount = vm.tempAbsoluteDiscount ? vm.purchaseOrderDetails.invoice.discount : 0;
      vm.purchaseOrderDetails.invoice.invoiceSubTotal = ((vm.purchaseOrderDetails.purchaseOrderStatus === 'PROCESSED') || (vm.purchaseOrderDetails.purchaseOrderStatus == 'PENDING' &&  vm.purchaseOrderDetails.acceptTotalAmount))? (vm.purchaseOrderDetails.acceptTotalAmount + deliveryCharge): (vm.purchaseOrderDetails.poTotalAmount + deliveryCharge) ;
      if(vm.otherChargesAmount){
        vm.purchaseOrderDetails.invoice.invoiceSubTotal = vm.purchaseOrderDetails.invoice.invoiceSubTotal + vm.otherChargesAmount;
      }
      vm.purchaseOrderDetails.invoice.invoiceTotal = vm.purchaseOrderDetails.invoice.invoiceSubTotal - discount;
      if (vm.purchaseOrderDetails.supplier.tax === 'Active' && vm.purchaseOrderDetails.supplier.gst !== null) {
        vm.purchaseOrderDetails.invoice.addedGst = (vm.purchaseOrderDetails.gst / 100) * vm.purchaseOrderDetails.invoice.invoiceTotal;
      }
      if(vm.purchaseOrderDetails.adhocPOGstEnabled == false){
        vm.purchaseOrderDetails.invoice.addedGst = 0;
      }
      vm.purchaseOrderDetails.invoice.grandTotal = (Math.round(+(vm.purchaseOrderDetails.invoice.invoiceTotal*100).toFixed(2))/100) + (Math.round(+(vm.purchaseOrderDetails.invoice.addedGst*100).toFixed(2))/100) - (Math.round(+(vm.purchaseOrderDetails.creditNoteAmountDTO.totalAmount*100).toFixed(2))/100);
  }

    function generateInvoice() {
      vm.purchaseOrderDetails.invoice.deliveryCharge = vm.purchaseOrderDetails.deliveryCharges ? vm.purchaseOrderDetails.deliveryCharges : 0;
      vm.purchaseOrderDetails.invoice.discount = vm.purchaseOrderDetails.invoice.discount ? vm.purchaseOrderDetails.invoice.discount : 0;
      vm.purchaseOrderDetails.invoice.hardcopyInvoiceNumber = vm.purchaseOrderDetails.invoice.hardcopyInvoiceNumber ?vm.purchaseOrderDetails.invoice.hardcopyInvoiceNumber : 'NA';
      if(vm.otherChargesKey){
        vm.purchaseOrderDetails.invoice.otherCharges[vm.otherChargesKey] = !vm.otherChargesAmount?0:vm.otherChargesAmount;
      }else{
        vm.purchaseOrderDetails.invoice.otherCharges[vm.otherChargesKey] = !vm.otherChargesAmount?0:vm.otherChargesAmount;
      }      
      vm.purchaseOrderDetails.invoice.otherCharges = JSON.stringify(vm.purchaseOrderDetails.invoice.otherCharges);
      InvoiceService.createInvoice(vm.purchaseOrderDetails).then(function(response) {
        $scope.$emit('ezyprocureApp:invoiceUpdate', response);
        NotificationService.success({
          title: 'global.menu.entities.invoice',
          message: 'alertMessage.GENERATED_SUCCESSFULLY'
        });
        $state.go('supplier.reports.invoice-report.preview', {purchaseOrderNumber: vm.purchaseOrderNumber, deliveryDate: vm.deliveryDate});
      }, function(error) {
        vm.purchaseOrderDetails.invoice.otherCharges={}
        NotificationService.error({
          title: 'Invoice Generation',
          error: error
        });
      });
    }

    function individualTotalPrice(p_index) {
      var calculatedPOItem = PurchaseOrderService.getCalculatedPOItem(vm.purchaseOrderDetails.items[p_index]);
      vm.purchaseOrderDetails.items[p_index] = calculatedPOItem;
      vm.purchaseOrderDetails.poTotalAmount = _.sumBy(vm.purchaseOrderDetails.items, function(item) {
        return (Math.round(+(item.poAmount*100).toFixed(2))/100);
      });
      vm.purchaseOrderDetails.acceptTotalAmount =  _.sumBy(vm.purchaseOrderDetails.items, function(item) {
        return (Math.round(+(item.acceptAmount*100).toFixed(2))/100);
      });
      vm.calculateInvoiceTotal();
      vm.calculateDiscount('PERCENTAGE');
    }

    /**
     * calculateDiscount calculates the % of discount when type is 'absolute' and calculates the discount's absolute value when type is 'percentage'
     * @param {string} type
     */
    function calculateDiscount(inputType){
      if(vm.purchaseOrderDetails.invoice.invoiceSubTotal===0){
        vm.calculateInvoiceTotal();
      }
      switch(inputType) {
       case 'PERCENTAGE':
        vm.purchaseOrderDetails.invoice.discount = (vm.tempPercentDiscount/100)*vm.purchaseOrderDetails.invoice.invoiceSubTotal;
        vm.tempAbsoluteDiscount = +(angular.copy(vm.purchaseOrderDetails.invoice.discount).toFixed(2));
        calculateInvoiceTotal();
        break;
       case 'ABSOLUTE':
        vm.tempPercentDiscount = +(angular.copy((vm.tempAbsoluteDiscount*100)/(vm.purchaseOrderDetails.invoice.invoiceSubTotal)).toFixed(2));        //setting value for *vm.purchaseOrderDetails.invoice.discount* in order to use in calculateInvoiceTotal();
        vm.purchaseOrderDetails.invoice.discount = vm.tempAbsoluteDiscount;
        // vm.purchaseOrderDetails.invoice.discount = ((vm.tempPercentDiscount/100)*vm.purchaseOrderDetails.invoice.invoiceSubTotal).toFixed(fractionSize);
        calculateInvoiceTotal();
        break;
      }
    }

    /**
     * calculateOtherCharges
     */
    function calculateOtherCharges(type){
      switch(type){
        case 'AMOUNT':
          vm.otherChargesPerCent = +(angular.copy((vm.otherChargesAmount*100)/(vm.purchaseOrderDetails.acceptTotalAmount)).toFixed(2));;
          vm.calculateInvoiceTotal();
          break;
        case 'PERCENT':
          var amount = (vm.otherChargesPerCent/100)*vm.purchaseOrderDetails.acceptTotalAmount;;
          vm.otherChargesAmount = +(angular.copy(amount).toFixed(2));
          vm.calculateInvoiceTotal();
          break;
      }
    }

    // change items order from template to controller
    function orderItemsByPurchaseOrderId(itemsList) {
        if(Array.isArray(itemsList)) {
          return itemsList.sort(function(a, b) {
            return a.purchaseOrderId - b.purchaseOrderId;
          });
        }
        else {
          return [];
        }
      }
  }
})();
