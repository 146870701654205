/**
* @Author: Ajay
* @Date:   14-Dec-2016 12:54 +05:30
* @Project: Ezyprocure
* @Filename: login.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 18-Apr-2018 11:29 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider.state('activating', {
      parent: 'account',
      url: '/activate/:key?origin',
      data: {
      },
      views: {
        'content@': {
          templateUrl: 'app/account/login/landing.html',
          controller: 'ActivateController',
          controllerAs: 'vm'
        }
      }
    });
  }
})();
