/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: supplier.items-bulk-delete.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Dec-2017 10:40 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('AdhocItemsBulkDeleteController', AdhocItemsBulkDeleteController);

  AdhocItemsBulkDeleteController.$inject = ['$scope', '$rootScope', '$state', 'toastr', '$mdDialog', 'pagingParams', 'BuyerItemService', 'paginationConstants', 'NotificationService'];

  function AdhocItemsBulkDeleteController($scope, $rootScope, $state, toastr, $mdDialog, pagingParams, BuyerItemService, paginationConstants, NotificationService) {
    var vm = this;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;

    vm.loadAll = loadAll;
    vm.bulkDeleteBuyerItems = bulkDeleteBuyerItems;
    vm.checkAll = checkAll;
    vm.showConfirm = showConfirm;
    vm.itemIdList = [];
    vm.selected = 'itemName';
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }


    function loadAll (search) {
      if (!search) {
        vm.searchText = '';
      }
      vm.selectAll = false;
      BuyerItemService.getAllBuyerItems({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        supplierId: '',
        query: vm.searchText,
        searchBy: vm.selected
      })
      .then(function(response){
        vm.items = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
      });
    }

    vm.loadAll();

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.items, function(item) {
        item.selected = vm.selectAll;
      });
    }

    function getSelectedItemIdList(items) {
      var itemIds = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      var selectedItems = _.filter(items, {
        'selected': true
      });
      itemIds = _.map(selectedItems, _.property('id'));
      return itemIds;
    }

    function showConfirm (ev) {
      var itemIdList = getSelectedItemIdList(vm.items);
      if (_.isEmpty(itemIdList)) {
        toastr.error('No items selected', 'Bulk Delete Items');
        return false;
      }
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Adhoc-Supplier Items?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Proceed')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        vm.bulkDeleteBuyerItems(itemIdList);
      }, function() {});
    }

    function bulkDeleteBuyerItems(itemIdList) {
      BuyerItemService.bulkDeleteBuyerItems(itemIdList).
      then(function(responseData) {
        NotificationService.success({
          title: 'Adhoc Items',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Adhoc Items Deletion',
          error: error
        });
      });
    }


  }
})();
