/**
 * @Author: Ajay
 * @Date:   15-Oct-2018 13:08 +05:30
 * @Project: Ezyprocure
 * @Filename: block-ui.config.js
 * @Last modified by:   Ajay
 * @Last modified time: 15-Oct-2018 15:04 +05:30
 */



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .config(blockUIServiceConfig);

  blockUIServiceConfig.$inject = ['blockUIConfig'];

  function blockUIServiceConfig(blockUIConfig) {

    // Change the default overlay message
    blockUIConfig.message = 'Loading..';

    // Change the default delay to 100ms before the blocking is visible
    //blockUIConfig.delay = 1000;

    blockUIConfig.requestFilter = requestFilter;

    function requestFilter(config) {
      // To ignore item search from blockUI service
      if (config.url.match(/.*\/items\/search.*/)) {
        return false;
      }
      // To ignore dashboard apis from blockUI service
      if (config.url.match(/.*\/dashboard.*/)) {
        return false;
      }
      // To ignore buyer dashboard api from blockUI service
      if (config.url.match(/.*\/item-approval-requests\/counter.*/)) {
        return false;
      }
      // To ignore item search from blockUI service
      if (config.url.match(/.*\/buyers\/items\/adhoc.*/)) {
        return false;
      }
      // To ignore peppol api from blockUI service
      if (config.url.match(/.*\/ezyprocure\/invoices.*/)) {
        return false;
      }
      
      // To ignore peppol api from blockUI service
      if (config.method === 'GET' && config.url.match(/.*\/getCardOnTransactionUUID.*/)) {
        return false;
      }

      // To ignore cross border api from blockUI service
      var isCbApi = config.url.match(/cb/gi)
      if (isCbApi) {

        if (config.url.match(/.*\/health.*/)) {
          isCbApi = '';
          return false;
        }
        if (config.url.match(/.*\/buyers\/profile.*/)) {
          isCbApi = '';
          return false;
        }

        if (config.url.match(/.*\/suppliers\/profile.*/)) {
          isCbApi = '';
          return false;
        }
      }


    }
  }
})();
