/*
 * @Author: Jayalekshmi 
 * @Date: 2019-06-18 14:53:08 
 * Project: Ezyprocure
 * @Last Modified by:   Jayalekshmi 
 * @Last Modified time: 2019-06-18 14:53:08 
 */



(function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider.state('supplier.reports.purchase-request.preview', {
        parent: 'supplier.reports.purchase-request',
        url: '/preview/:requirementNumber/:predicate/:reverse',
        data: {
          authorities: ['ROLE_SUPPLIER'],
          pageTitle: 'Purchase Request Preview'
        },
        views: {
          'content@': {
            templateUrl: 'app/common-modules/reports-preview/purchase-request-report.preview.html',
            controller: 'SupplierPurchaseRequestIndividualDetailsController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('invoice');
            $translatePartialLoader.addPart('items');
            $translatePartialLoader.addPart('buyerBusiness');
            $translatePartialLoader.addPart('processOrder');
            return $translate.refresh();
          }]
        }
      });
    }
  })();
  