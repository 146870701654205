/*
 * @Author: Lovin 
 * @Date: 2019-03-25 14:59:51 
 * @Filename: user-redirect.service.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-04-08 15:37:22
 */

 (function() {
  'use strict';

  angular.module('ezyprocureApp').factory('UserRedirectService', UserRedirectService);

  UserRedirectService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function UserRedirectService($http, $q, API_CONSTANTS) {

    var UserRedirectService = {
        redirectToEzysourcing: redirectToEzysourcing,
        getEzysourcingTempToken: getEzysourcingTempToken,
        sync: sync
    };
    return UserRedirectService;

    /* --  redirection urls are selected according to the hosts  -- */
    function redirectToEzysourcing(tempToken){
      window.open(API_CONSTANTS.ezysourcingRedirectUrl + tempToken,'_blank');
    }

    function sync(){
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'sync/domestic/ezypayment',
        method:'POST'
      }).then(function(){
        deferred.resolve();
      }).catch(function(message){
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * getEzysourcingTempToken gets the temporary token for esySourcing redirection
     * @param {integer} userId
     */
    function getEzysourcingTempToken(userId){
      var deferred = $q.defer();
      $http({
        url:API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'ezysource-access/token',
        method:'POST',
        data:userId
      }).then(function(data){
        deferred.resolve(data.data);
      }).catch(function(message){
        deferred.reject(message);
      });
      return deferred.promise;
    }
  }
})();
