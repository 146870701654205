/**
 * @Author: Ajay
 * @Date:   29-Aug-2018 11:56 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.payment-approval-request.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 13-Sep-2018 10:34 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPaymentApprovalRequestController', BuyerPaymentApprovalRequestController);

  BuyerPaymentApprovalRequestController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout', 'NotificationService', '$mdDialog', 'toastr', 'BuyerPaymentApprovalRequestService', 'BuyerDataService', 'paginationConstants', 'pagingParams', 'GLOBAL_CONSTANTS'];

  function BuyerPaymentApprovalRequestController($scope, $rootScope, $state, $stateParams, $timeout, NotificationService, $mdDialog, toastr, BuyerPaymentApprovalRequestService, BuyerDataService, paginationConstants, pagingParams, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    vm.countSelected = countSelected;
    vm.paymentApprovalStatus = $stateParams.paymentApprovalStatus;
    vm.paymentApprovalStatusCap=vm.paymentApprovalStatus.toUpperCase();
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.checkAllInvoices=checkAllInvoices;
    vm.approvalIdList = [];
    vm.getSelectedApprovalIdList = getSelectedApprovalIdList;
    vm.approveOrReject=approveOrReject;
    vm.rejectPaymentApprovalRequest = rejectPaymentApprovalRequest;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.id;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.viewDetails=viewDetails;
    vm.accessForPaymentApproval = BuyerDataService.getBuyerProfile().accessDTO.accessForPaymentApproval;
    vm.showRejectModal = showRejectModal;
    // 2FA changes
    vm.otp = undefined;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.selectAllPaymentItems = selectAllPaymentItems;
    vm.deSelectAllPaymentItems = deSelectAllPaymentItems;

    function init() {
      vm.loadAll();
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function selectAllPaymentItems() {
      vm.selectAllInvoices = true;
      checkAllInvoices();
    }

    function deSelectAllPaymentItems() {
      vm.selectAllInvoices = false;
      checkAllInvoices();
    }

    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };

    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      approvePaymentApprovalRequest(vm.otp);
    }

    function resendOTP() {
      approvePaymentApprovalRequest();
    }

    function countSelected() {
        var count = 0;
        angular.forEach(vm.paymentApprovalRequests, function(paymentApprovalRequest) {
            if(paymentApprovalRequest.selected) {
              count = count + 1;
            }
          });

        return count;
      }

    function checkAllInvoices() {
      vm.selectAllInvoices ? vm.selectAllInvoices = true : vm.selectAllInvoices = false;
      angular.forEach(vm.paymentApprovalRequests, function (paymentApprovalRequest) {
        if (paymentApprovalRequest.approver) {
          paymentApprovalRequest.selected = vm.selectAllInvoices;
        }
      });
    }

    function showRejectModal() {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/payment-approval-request/buyer.payment-approval-request-reject-tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        clickOutsideToClose: true
      });
    }




        function approveOrReject(action){
         vm.approvalIdList = vm.getSelectedApprovalIdList(vm.paymentApprovalRequests);
         if(vm.approvalIdList.length<1){
            NotificationService.simpleErrorToast({
               title: 'global.sipInvoiceApproval',
               message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
            });
            return false;
         }
            action=='APPROVE'?approvePaymentApprovalRequest(): showRejectModal();
        }
    function getSelectedApprovalIdList(items) {
      var itemIds = [];
      //Fetch all ids of supplierItem in vm.supplierItems where supplierItem.selected = true
      var filteredRequests = filterRequestByApprover(items);
      var selectedItems = [];
      if (filteredRequests && filteredRequests.length > 0) {
        selectedItems = _.filter(filteredRequests, {
          'selected': true
        });
      }
      itemIds = _.map(selectedItems, _.property('paymentApprovalRequests.paymentApprovalRequestNumber'));
      return itemIds;
    }

    function filterRequestByApprover(paymentApprovals) {
      var approvalNumberList = [];
      _.forEach(paymentApprovals, function (prRequest) {
        if (prRequest.approver) {
          approvalNumberList.push(prRequest);
        } else {
          prRequest.selected = false;
        }
      });
      return approvalNumberList;
    }

    function approvePaymentApprovalRequest(otp) {
      BuyerPaymentApprovalRequestService.approvePaymentApprovalRequest(vm.approvalIdList, otp).then(function (response) {
        if (response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
          showOtpPrompt();
        }
        else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
          toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
        }
        else {
          NotificationService.success({
            title: 'global.menu.entities.paymentApprovalRequest',
            message: 'alertMessage.APPROVED_SUCCESSFULLY'
          });
          $mdDialog.hide();
          $state.go('buyer.payment-approval-request', { reload: false });
          vm.loadAll();
        }

      }, function (error) {
        NotificationService.error({
          title: 'Payment Approval Request Approving',
          error: error
        });
      });
    }

         function rejectPaymentApprovalRequest() {
               BuyerPaymentApprovalRequestService.rejectPaymentApprovalRequest(vm.approvalIdList, vm.prRemark).then(function(response) {
                 NotificationService.success({
                   title: 'global.menu.entities.paymentApprovalRequest',
                   message: 'alertMessage.REJECTED_SUCCESSFULLY'
                 });
                 vm.cancel();
                 $state.go('buyer.payment-approval-request', {reload: false});
                 vm.loadAll();
               }, function(error) {
                 NotificationService.error({
                   title: 'Payment Approval Request Rejection',
                   error: error
                 });
               });
             }


    //Get all item approval requests of the logged in buyer
    function loadAll() {
      BuyerPaymentApprovalRequestService.getAllPaymentApprovalRequest({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        paymentApprovalStatus: vm.paymentApprovalStatus
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.paymentApprovalRequests = response.data.content;
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Requests Listing',
          error: error
        });
      });
    }

    //Delete Item Approval Request details by approval Request Number
    function deletePaymentApprovalRequest(paymentApprovalRequestNumber) {
      BuyerPaymentApprovalRequestService.deletePaymentApprovalRequest(paymentApprovalRequestNumber).then(function(response) {
        NotificationService.success({
          title: 'Payment Approval Request',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Payment Approval Request Deletion',
          error: error
        });
      });
    }

    function viewDetails(paymentApprovalRequestNumber,paymentApprovalStatus) {
      $state.go('buyer.payment-approval-request.details',{'paymentApprovalRequestNumber':paymentApprovalRequestNumber,'paymentApprovalStatus':paymentApprovalStatus}, {
         reload: false
      });
    }


    vm.showConfirm = function(ev, paymentApprovalRequestNumber) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to dismiss Request - ' + paymentApprovalRequestNumber + ' ? ')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deletePaymentApprovalRequest(paymentApprovalRequestNumber);
      }, function() {});
    };

    vm.cancel = function() {
      $mdDialog.cancel();
    };
  }
})();
