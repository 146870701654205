/**
* @Author: MidhunMohan
* @Date:   06-Apr-2017 17:24 +05:30
* @Project: Ezyprocure
* @Filename: staff-interface.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-12-26T15:35:36+05:30
*/

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('StaffInterfaceIndividualDetailsController', StaffInterfaceIndividualDetailsController);

  StaffInterfaceIndividualDetailsController.$inject = ['$scope', '$rootScope', 'pagingParams', 'paginationConstants', '$filter', '$state', '$q', '$stateParams', '$timeout', 'toastr', 'BuyerUserManagementService', 'UserAccessService', 'NotificationService', 'BuyerItemService', '$mdDialog', 'BuyerOCRService', 'OCRImageUploadService', 'BuyerDataService', 'UTCDateService', 'GLOBAL_CONSTANTS', 'EzyImageZoomService', '$mdMedia', 'Auth'];

  function StaffInterfaceIndividualDetailsController($scope, $rootScope, pagingParams, paginationConstants, $filter, $state, $q, $stateParams, $timeout, toastr, BuyerUserManagementService, UserAccessService, NotificationService, BuyerItemService, $mdDialog, BuyerOCRService, OCRImageUploadService, BuyerDataService, UTCDateService, GLOBAL_CONSTANTS, EzyImageZoomService, $mdMedia, Auth) {
    var vm = this;

    vm.ocrDetailsPredicate = pagingParams.ocrDetailsPredicate;
    vm.ocrDetailsReverse = pagingParams.ocrDetailsAscending;

    vm.init = init;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate).toISOString();
    vm.todaysDate = new Date();
    vm.searchForItems = searchForItems;
    vm.itemCodeChanged = itemCodeChanged;
    vm.itemSearchQuery = {};
    vm.uploadImgModal = showModal;
    vm.testImage = testImage;
    vm.showOCRAcceptModal = showOCRAcceptModal;
    vm.acceptInvoice = acceptInvoice;
    vm.updateInvoice = updateInvoice;
    vm.rejectInvoice = rejectInvoice;
    vm.rejectInvoiceModal = rejectInvoiceModal;
    vm.closeModal = closeModal;
    vm.invoiceArray = {};
    vm.grnData = {};
    vm.ocrResponse = {};
    vm.calculateGst = calculateGst;
    vm.calculateItemPrice = calculateItemPrice;
    vm.calculateCreditTaxAmount = calculateCreditTaxAmount;
    vm.ocrResponseReasons = GLOBAL_CONSTANTS.OCR_RESPONSE_REASON_STATUS;
    vm.imageZoom = imageZoom;
    vm.isZoom = false;
    vm.closeZoom = closeZoom;
    vm.isMobile = $mdMedia('xs');
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForOcrGrnEdit = Auth.hasPermission("accessForOcrGrnEdit");
    vm.accessForOcrPoQtyPrice = Auth.hasPermission("accessForOcrPoQtyPrice");
    vm.updatePrice = updatePrice;
    vm.enableUpdateButton = false;

    vm.changeData = changeData;
    vm.showOCRUpdateModal = showOCRUpdateModal;
    vm.ocrEditCheck = false;
    vm.isDuplicate = false;
    vm.newSelectedItems = [];
    vm.deleteItemByIndex = deleteItemByIndex;
    vm.saveOCRNewItems = saveOCRNewItems;
    vm.addNewGRN = addNewGRN;
    vm.isNotExported = isNotExported;
    vm.newGRNItemDTO = {
      items: []
    };
    vm.isDetailsZoom = false;
    vm.ocrDetailsImageZoom = ocrDetailsImageZoom;
    vm.grnData.ocrVerified = false;
    vm.grnData.ocrVerifiedBy = '';
    vm.accessForOcrVerification = Auth.hasPermission("accessForOcrVerification");
    vm.isAdminBuyer = BuyerDataService.isAdminBuyer();
    vm.hasBuyerAdminAccessForOcrVerification = vm.isAdminBuyer && vm.accessForOcrVerification;
    vm.buyerProfile = BuyerDataService.getBuyerProfile();
    vm.handleVerifyChange = handleVerifyChange;
    vm.verifyTrueMessage = 'You will be verifying that the items in the order are correct and has correct amounts and prices.';
    vm.verifyFalseMessage = 'You are removing verified status from the order.';
    vm.sortItemTable = sortItemTable;
    vm.reverse = false;
    vm.sortKey = 'deliveryDate';

    /**
    * [init loads initilally]
    */
    function init() {
      vm.newSelectedItems = [];
      vm.newGRNItemDTO = {
        items: []
      };
      vm.enableUpdateButton = false;
      loadAll();
      if(!vm.isAdminBuyer) {
        getAdminBuyerProfile(vm.buyerProfile.buyerUser.buyer.id);
      }
    }

    function sortItemTable(keyname) {
      vm.reverse = vm.sortKey == keyname ? !vm.reverse : true;
      vm.sortKey = keyname;
    }

    function getAdminBuyerProfile(companyId) {
      BuyerUserManagementService.getBuyerUserByCompanyId(companyId).then(function (response) {
        if (response) {
          vm.hasBuyerAdminAccessForOcrVerification = response.userAccess.accessForOcrVerification;
        }
      },
        function (error) {

        });
    }

    function handleVerifyChange(ev) {
      var message = vm.grnData.ocrVerified ? vm.verifyTrueMessage : vm.verifyFalseMessage;
      showConfirmDialog(message, ev);
    }

    function showConfirmDialog(message, ev) {
      var confirm = $mdDialog.confirm()
        .title(message)
        .textContent('Do you want to continue ?')
        .ariaLabel('Confirm action')
        .targetEvent(ev)
        .ok('Yes')
        .cancel('No');

      $mdDialog.show(confirm).then(function () {
        updateOcrVerificationStatus();
      }, function () {
        vm.grnData.ocrVerified = !vm.grnData.ocrVerified;
      });
    }

    function updateOcrVerificationStatus() {
      var params = {
        isVerify: vm.grnData.ocrVerified,
        purchaseOrderNumber: vm.poDetails.purchaseOrderNumber,
        poIds: getPurchaseOrderIds(vm.grnData.items)
      }
      BuyerOCRService.updateOcrVerifyStatus(params).then(function(response) {
        if (vm.grnData.ocrVerified) {
          vm.grnData.ocrVerifiedBy = BuyerDataService.getBuyerProfile().buyerUser.user;
        }
        toastr.success('Updated successfully', 'OCR verify')
      }, function(error) {
        vm.grnData.ocrVerified = !vm.grnData.ocrVerified;
        toastr.error(error.data.message, 'Update failed');
      });
    }

    function getPurchaseOrderIds(items) {
      return items.map(function (item) {
        return item.purchaseOrderId;
      });
    }

    //OCR add new row
    function addNewGRN() {
      var itemDTO = {
        id: 0,
        itemCode: "",
        itemName: "",
        uom: {
          name: "",
          weightRequired: true
        },
        weight: 0,
        quantity: 0,
        poUnitPrice: 0,
        grnUnitPrice: 0,
        currency: "",
        acceptedWeight: 0,
        acceptedQuantity: 0,
        receivedDate: new Date(),
        buyerRemarks: ""
      };
      vm.newGRNItemDTO.items.push(itemDTO);
    }

    // search items by business outlet and supplier id
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if (!queryText || JSON.stringify(vm.grnData) === JSON.stringify({})) {
        return;
      }
      else {
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        vm.itemSearchQuery.businessIdList = [vm.grnData.buyerBusiness.id];
        vm.itemSearchQuery.buyerIdList = null;
        vm.itemSearchQuery.supplierId = vm.grnData.supplier.id;
        BuyerItemService.searchAllItems(vm.itemSearchQuery)
          .then(function (response) {
            deferred.resolve($filter('orderBy')(response.data, 'itemName'));
          }, function (error) {
            deferred.reject(error);
          });
      }
      return deferred.promise;
    }

    //adds uom, currency etc. when new item is selected
    function itemCodeChanged(selectedItem, index) {
      var temp = angular.copy(selectedItem);
      vm.newSelectedItems.push(temp);
      vm.newGRNItemDTO.items[index].currency = selectedItem.currency;
      vm.newGRNItemDTO.items[index].uom.name = selectedItem.uom.name;
      vm.newGRNItemDTO.items[index].uom.weightRequired = selectedItem.uom.weightRequired;
      vm.newGRNItemDTO.items[index].uom.moqPrice = selectedItem.uom.moqPrice;
      vm.newGRNItemDTO.items[index].price = selectedItem.price;
      vm.newGRNItemDTO.items[index].moq = selectedItem.moq;
      vm.newGRNItemDTO.items[index].poUnitPrice = selectedItem.price;
      vm.newGRNItemDTO.items[index].grnUnitPrice = selectedItem.price;
      vm.newGRNItemDTO.items[index].id = selectedItem.id;
      vm.newGRNItemDTO.items[index].itemCode = selectedItem.itemCode;
      validateItem(selectedItem);
      if (vm.isDuplicate) {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.ITEM_EXISTS',
          message: 'error.FIELD_CONTAINS_INVALID_DATA'
        });
        vm.newGRNItemDTO.items.splice(index, 1);
        vm.newSelectedItems.splice(index, 1);
        vm.isDuplicate = false;
      }
    }

    // validation for duplicate items
    function validateItem(selectedItem) {
      vm.isDuplicate = false;
      if (vm.newGRNItemDTO.items.length > 1) {
        for (var index = 0; index < (vm.newGRNItemDTO.items.length - 1); ++index) {
          if (selectedItem.id == vm.newGRNItemDTO.items[index].id)
            vm.isDuplicate = true;
        }
      }
    }

    // save newly added items
    function saveOCRNewItems(enableUpdateButton) {
       if(vm.grnData.items.length > 0 && enableUpdateButton==true){
        vm.updatePrice(vm.grnData.items)
       }
       if(vm.newGRNItemDTO.items.length > 0){
      if (vm.isDuplicate) {
        NotificationService.simpleErrorToast({
          title: 'alertMessage.ITEM_EXISTS',
          message: 'error.FIELD_CONTAINS_INVALID_DATA'
        });
        return;
      }
      var newItemJSON = {
        "poNumber": vm.poNumber,
        "deliveryDate": vm.deliveryDate,
        "purchaseOrderTypeId": vm.poDetails.items[0].glCodeMappings ? vm.poDetails.items[0].glCodeMappings.purchaseOrderTypes.id : null,
        "items": []
      };
      angular.forEach(vm.newGRNItemDTO.items, function (value, key) {
        var item = {};
        item.itemId = value.id;
        item.price = value.grnUnitPrice;
        item.quantity = value.uom.weightRequired ? null : value.quantity;
        item.weight = value.uom.weightRequired ? value.weight : null;
        item.buyerRemarks = value.buyerRemarks;
        newItemJSON.items.push(item);
      });
      BuyerOCRService.saveNewItemsOCR(newItemJSON).then(function (response) {
        NotificationService.success({
          title: 'global.itemDetails',
          message: 'alertMessage.ADDED_SUCCESSFULLY'
        });
        BuyerOCRService.grnByPONumber(vm.poNumber, vm.deliveryDate).then(function (res) {
          vm.grnData = res.data;
          invoiceToMicroService(vm.invoiceArray.invoiceUrl);
        }, function(error) {});
      }, function (error) {
        NotificationService.error({
          title: 'global.userAccessTypes.ocrStaffInterface',
          error: error
        });
      });
    }
    }

    // Delete new item
    function deleteItemByIndex(index) {
      vm.newGRNItemDTO.items.splice(index, 1);
      vm.newSelectedItems.splice(index, 1);
    }

    //Add Image to PO Starts Here

    function showModal(event) {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/buyer.ocr.uploadInvoice.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function testImage(file) {
      OCRImageUploadService.getImageBase64(file[0].lfFile, function (base64Img) {
        BuyerOCRService.invoiceImgUpload(base64Img).then(function (response) {
          closeModal();
          invoiceToMicroService(response.data);
        }, function (error) {
        });
      });
    }

    function invoiceToMicroService(invoiceImage) {
      var item = {
        "poNumber": vm.poDetails.purchaseOrderNumber,
        "invoiceUrl": invoiceImage,
        "invoiceAmount": vm.grnData.grnamount.toFixed(2),
        "user": BuyerDataService.getBuyerProfile().buyerUser.user.login,
        "deliveryDate":vm.deliveryDate
      };
      BuyerOCRService.invoiceMicroService(item).then(function (response) {
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        init();
      }, function (error) {
      });
    }
    /**
    * [closeModal description]
    * @return {[type]} [description]
    */
    function closeModal() {
      vm.isZoom = false;
      $mdDialog.hide();
    }


    /**
     * [calculateGst description]
     * If invoice generated, then gst should autopopulate on changing amount
     * gstAmount = amount - (amount / (1 + gstValue))
     */
    function calculateGst() {
      if (vm.ocrResponse.invoiceStatus !== 'PROCESSED' && vm.poDetails.supplier.gst && vm.poDetails.supplier.tax !== 'Inactive') {
        var amount = vm.ocrResponse.ocrInvoiceAmount ? parseFloat(angular.copy(vm.ocrResponse.ocrInvoiceAmount)) : 0;
        var gstValue = vm.poDetails.gst / 100;
        var gstAmount = amount - (amount / (1 + gstValue));
        vm.ocrResponse.ocrGst = $filter('decimalNumber')(gstAmount, 2);
        return vm.ocrResponse.ocrGst;
      }
    }

    /**
    * [calculateCreditTaxAmount description]
    * calculate credit note tax amount if subtotal changed
    */
    function calculateCreditTaxAmount(){
      if ( vm.poDetails.supplier.gst) {
        var amount = vm.poDetails.creditNoteAmountDTO.subTotal ? parseFloat(angular.copy( vm.poDetails.creditNoteAmountDTO.subTotal )): 0
        var gstValue = vm.poDetails.supplier.gst / 100;
        var gstAmount = gstValue * amount;
        vm.poDetails.creditNoteAmountDTO.creditNoteTaxAmount = $filter('decimalNumber')(gstAmount, 2);
      }
    }

    /**
     * [showOCRAcceptModal]
     * editing hardCopyInvoiceNumber, hardCopyInvoiceAmount, GST and hardCopyInvoiceDate(delivery date)
     */
    function showOCRAcceptModal(event) {
      if (vm.hasBuyerAdminAccessForOcrVerification && !vm.grnData.ocrVerified) {
        toastr.error('Please verify the order before accept', 'Order verification is enabled and not verified');
        return;
      }
      vm.ocrResponse.invoiceStatus = vm.poDetails.invoiceStatus;
      vm.ocrResponse.ocrInvoiceStatus = vm.poDetails.items[0].ocrInvoiceStatus;
      vm.ocrResponse.ocrInvoiceDate = vm.todaysDate;
      vm.ocrResponse.supplierTax = vm.poDetails.supplier.tax;

      if (vm.poDetails.invoiceStatus === 'PROCESSED') {
        vm.ocrResponse.ocrInvoiceNumber = (vm.poDetails.invoice.hardcopyInvoiceNumber == 'NA' ||  vm.poDetails.invoice.hardcopyInvoiceNumber == '') ? vm.poDetails.ocrInvoiceNumber ? vm.poDetails.ocrInvoiceNumber : 'NA'  : vm.poDetails.invoice.hardcopyInvoiceNumber ;
        vm.ocrResponse.ocrInvoiceAmount = vm.poDetails.invoice.grandTotal ? vm.poDetails.invoice.grandTotal : vm.grnData.grnamount;
        vm.ocrResponse.ocrGst = vm.poDetails.invoice.addedGst;
        // vm.ocrResponse.ocrInvoiceDate = vm.poDetails.invoice.invoiceDate;
        // vm.ocrResponse.ocrInvoiceDate = vm.grnData ? vm.grnData.recievedDate : vm.deliveryDate;
        vm.ocrResponse.ocrInvoiceDate = vm.deliveryDate;
      }
      else if (vm.poDetails.invoiceStatus === 'PENDING') {
        vm.ocrResponse.ocrInvoiceNumber = vm.poDetails.ocrInvoiceNumber ? vm.poDetails.ocrInvoiceNumber : vm.ocrResponse.hardcopyInvoiceNumber;
        vm.ocrResponse.ocrInvoiceAmount = vm.poDetails.items[0].hardCopyInvoiceStatus == 'AMOUNT_MATCH' ? vm.invoiceArray.invoiceAmount : vm.poDetails.ocrInvoiceAmount;
        vm.ocrResponse.ocrGst = vm.poDetails.items[0].hardCopyInvoiceStatus == 'AMOUNT_MATCH' ? vm.calculateGst() : vm.poDetails.ocrGst;
        vm.ocrResponse.ocrInvoiceDate = vm.poDetails.ocrInvoiceDate ? vm.poDetails.ocrInvoiceDate : vm.deliveryDate;
      }
      else {
        if (vm.poDetails.items[0].hardCopyInvoiceStatus == 'AMOUNT_MATCH') {
          vm.ocrResponse.ocrInvoiceAmount = vm.invoiceArray.invoiceAmount;
          vm.calculateGst();
        }
        // vm.ocrResponse.ocrInvoiceDate = vm.grnData ? vm.grnData.recievedDate : vm.deliveryDate;
        vm.ocrResponse.ocrInvoiceDate = vm.deliveryDate;
      }
      if(vm.ocrResponse.supplierTax == 'Inactive'){
        vm.ocrResponse.ocrGst = 0;
      }
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/individual-details/staff-interface.ocr.accept.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    /**
     * [showOCRUpdateModal]
     * editing hardCopyInvoiceNumber, hardCopyInvoiceAmount, GST and hardCopyInvoiceDate(delivery date)
     */
    function showOCRUpdateModal() {
      vm.ocrEditCheck = true;
      vm.ocrResponse.invoiceStatus = vm.poDetails.invoiceStatus;
      vm.ocrResponse.ocrInvoiceStatus = vm.poDetails.items[0].ocrInvoiceStatus;
      vm.ocrResponse.ocrInvoiceNumber = vm.poDetails.ocrInvoiceNumber;
      vm.ocrResponse.ocrInvoiceAmount = vm.poDetails.ocrInvoiceAmount;
      vm.ocrResponse.ocrGst = vm.poDetails.ocrGst;
      vm.ocrResponse.ocrInvoiceDate = vm.poDetails.ocrInvoiceDate;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/staff-interface/individual-details/staff-interface.ocr.accept.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    /**
    * [acceptInvoice description]
    * @return {[type]} [description]
    */
    function acceptInvoice() {
      var ocrResponseDTO = {
        poNumber: vm.poNumber,
        reason: vm.ocrResponse.reason,
        deliveryDate: BuyerOCRService.toOCRDateString($stateParams.deliveryDate),
        comment: vm.ocrResponse.reason === 'OTHER' ? vm.ocrResponse.comments : '',
        ocrInvoiceNumber: vm.ocrResponse.ocrInvoiceNumber,
        ocrInvoiceAmount: vm.ocrResponse.ocrInvoiceAmount,
        ocrInvoiceDate: vm.ocrResponse.ocrInvoiceDate,
        ocrGst: vm.ocrResponse.ocrGst,
        creditNoteAmount: vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0] ?  vm.poDetails.creditNoteAmountDTO.subTotal : '',
        creditNoteNumber: vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0] ? vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0].creditNoteNumber :'' ,
        creditNoteTaxAmount: vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0] ? vm.poDetails.creditNoteAmountDTO.creditNoteTaxAmount : '',
        creditNoteDate: vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0] ? vm.poDetails.creditNoteAmountDTO.creditNoteAcceptDTOS[0].creditNoteDate : ''
      };
      BuyerOCRService.acceptInvoice(ocrResponseDTO).then(function (response) {
        vm.responseBody = response.data;
        vm.closeModal();
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
        });
        $state.go('buyer.ocr.staff-interface');
      }, function (error) {
        NotificationService.error({
          title: 'global.ocrInvoice',
          error: error
        });
      });
    }
    /**
    * [updatetInvoice description]
    * @return {[type]} [description]
    */
    function updateInvoice() {
      var ocrResponseDTO = {
        poNumber: vm.poNumber,
        reason: vm.ocrResponse.reason,
        deliveryDate: vm.deliveryDate,
        comment: vm.ocrResponse.reason === 'OTHER' ? vm.ocrResponse.comments : '',
        ocrInvoiceNumber: vm.ocrResponse.ocrInvoiceNumber,
        ocrInvoiceAmount: vm.ocrResponse.ocrInvoiceAmount,
        ocrInvoiceDate: vm.ocrResponse.ocrInvoiceDate,
        ocrGst: vm.ocrResponse.ocrGst
      };
      BuyerOCRService.updateInvoice(ocrResponseDTO).then(function(response) {
        vm.responseBody = response.data;
        vm.closeModal();
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go('buyer.ocr.staff-interface');
      }, function(error) {
        NotificationService.error({
          title: 'global.ocrInvoice',
          error: error
        });
      });
    }

    /**
    * [rejectInvoice description]
    * @return {[type]} [description]
    */
    function rejectInvoice() {
      var ocrResponseDTO = {
        poNumber: vm.poNumber,
        reason: vm.ocrResponse.reason,
        deliveryDate: BuyerOCRService.toOCRDateString($stateParams.deliveryDate),
        comment: vm.ocrResponse.reason === 'OTHER' ? vm.ocrResponse.comments : ''
      };
      BuyerOCRService.rejectInvoice(ocrResponseDTO).then(function (response) {
        vm.responseBody = response.data;
        vm.closeModal();
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        $state.go('buyer.ocr.staff-interface');
      }, function (error) {
      });
    }

    function calculateItemPrice(item, itemType) {
      var qty;
      if (itemType != "NEW") {
        qty = item.uom.weightRequired ? item.acceptedWeight : item.acceptedQuantity;
      }
      else {
        qty = item.uom.weightRequired ? item.weight : item.quantity;
      }
      var totalPrice = 0;
      if( item.uom.moqPrice === true)
        totalPrice = (qty/item.moq) * item.price;
      else
        totalPrice = qty * item.price;
      item.poAmount = _.round(totalPrice,2);
    }

    /**
    * [loadAll Gets Purchase Order Details by PO number]
    */
    function loadAll() {
      var deliveryOrder = {};
      deliveryOrder.poNumber = vm.poNumber;
      deliveryOrder.deliveryDate = BuyerOCRService.toOCRDateString($stateParams.deliveryDate);
      BuyerOCRService.getPurchaseOrderDetails(vm.poNumber, vm.deliveryDate).then(function (resp) {
        vm.poDetails = resp.data;
        vm.poDetails.items[0].ocrInvoiceStatus = !vm.poDetails.items[0].ocrInvoiceStatus ? '' : vm.poDetails.items[0].ocrInvoiceStatus.replace(/_/g, ' ');
        BuyerOCRService.grnByPONumber(vm.poNumber, vm.deliveryDate).then(function (res) {
          vm.grnData = res.data;
          orderItemsByPurchaseOrderId(vm.grnData.items)
          vm.grnData.grnamount = vm.grnData.grnamount + vm.grnData.otherCharges[Object.keys(vm.grnData.otherCharges)];
          BuyerOCRService.getInvoiceStatus({
            'deliveryOrders': [deliveryOrder]
          }).then(function (response) {
            if (response.data.data) {
              if (!response.data.data[0]) {
                vm.invoiceArray.invoiceStatus = '';
                vm.invoiceArray.invoiceOCRStatus = '';
              } else {
                vm.invoiceArray = response.data.data[0];
                vm.invoiceArray.invoiceStatus = vm.invoiceArray.invoiceStatus.replace(/_/g, ' ');
                vm.invoiceArray.invoiceOCRStatus = vm.invoiceArray.invoiceOCRStatus.replace(/_/g, ' ');
              }
            }
          }, function (error) { });
        }, function (error) { });
      }, function (error) {
      });
    }

    function imageZoom() {
      vm.isZoom = true;
      setTimeout(function () { EzyImageZoomService.imageZoom('ocrImage', 'ocrZoomImage') }, 500);
    }

    function ocrDetailsImageZoom() {
      vm.isDetailsZoom = !vm.isDetailsZoom;
      if (vm.isDetailsZoom) {
        $timeout(function () {
          EzyImageZoomService.imageZoom('ocr-details-image', 'ocr-details-zoom-image')
        }, 500);
      }
      else {
        EzyImageZoomService.clearLens();
      }
    }

    function closeZoom() {
      vm.isZoom = false;
    }

    //enable or disable the update button
    function changeData(item) {
      vm.enableUpdateButton = true;
      calculateItemPrice(item);
    }

    /**
     * [Update the price and quantity]
     * @param {List} itemDetails
     */
    function updatePrice(itemDetails) {
      var goodsReceivingDTO = [];
      _.filter(itemDetails, function (item) {
        goodsReceivingDTO.push({
          'purchaseOrderId': item.purchaseOrderId,
          'quantity': !item.uom.weightRequired ? item.acceptedQuantity : null,
          'weight': item.uom.weightRequired ? item.acceptedWeight : null,
          'price': item.price,
          'buyerRemark': item.remark
        })
      });
      BuyerOCRService.updatePrice({ goodsReceivingDTO: goodsReceivingDTO }).then(function (response) {
        BuyerOCRService.grnByPONumber(vm.poNumber, vm.deliveryDate).then(function (res) {
          vm.grnData = res.data;
          invoiceToMicroService(vm.invoiceArray.invoiceUrl);
        });
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      }, function (error) {
        NotificationService.error({
          title: 'Updating OCR',
          error: error
        });
      });
    }

    /**
     * isNotExported Returns true if not exported
     */
    function isNotExported(){
        var notExported = true;
        if(vm.poDetails.purchaseJurnalExported || vm.poDetails.salesJurnalExported ||
             vm.poDetails.supplierQuickBooksExported || vm.poDetails.buyerQuickBooksExported ||
              vm.poDetails.apExported || vm.poDetails.millionExported ||
               vm.poDetails.ociExported || vm.poDetails.geoExported ||
               vm.poDetails.xeroExported || vm.poDetails.axExported ||
               vm.poDetails.myobExported || vm.poDetails.accpacExported ||
               vm.poDetails.grnExported || vm.poDetails.xeroInvExported){
            notExported = false;
        }
        return notExported;
    }

    // change items order from template to controller
    function orderItemsByPurchaseOrderId(itemsList) {
        if(Array.isArray(itemsList)) {
          return itemsList.sort(function(a, b) {
            return a.purchaseOrderId - b.purchaseOrderId;
          });
        }
        else {
          return [];
        }
      }

    //function to show reject modal pop up
    function rejectInvoiceModal(event){
        $mdDialog.show({
            templateUrl: 'app/buyer-modules/ocr/staff-interface/individual-details/staff-interface.ocr.reject.tmpl.html',
            controller: function () {
                return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: true
            });
    }
  }
})();
