/**
 * @Author: Ajay
 * @Date:   21-Apr-2017 17:54 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.supplier-mapping.state.js
 * @Last modified by:   Ajay
 * @Last modified time: 01-Jun-2017 12:56 +05:30
 */



 (function() {
    'use strict';
  
    angular
    .module('ezyprocureApp')
    .config(stateConfig);
  
    stateConfig.$inject = ['$stateProvider'];
  
    function stateConfig($stateProvider) {
      $stateProvider
      .state('buyer.supplier-delivery-conditions', {
        parent: 'buyer',
        url: '/buyer-supplier-delivery-conditions?page&sort&search',
        data: {
          authorities: ['ROLE_BUYER'],
          pageTitle: 'ezyprocureApp.buyerSupplierMapping.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/buyer-modules/tools/specific-delivery-conditions/specific-supplier-delivery.html',
            controller: 'SupplierSpecificController',
            controllerAs: 'vm'
          }
        },
          params: {
                page: {
                  value: '1',
                  squash: true
                },
                sort: {
                  value: 'id,asc',
                  squash: true
                },
                search: null
              },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                  return {
                    page: PaginationUtil.parsePage($stateParams.page),
                    sort: $stateParams.sort,
                    predicate: PaginationUtil.parsePredicate($stateParams.sort),
                    ascending: PaginationUtil.parseAscending($stateParams.sort),
                    search: $stateParams.search
                  };
                }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            // $translatePartialLoader.addPart('buyerSupplierMapping');
            // $translatePartialLoader.addPart('global');
            // $translatePartialLoader.addPart('user-management');
            return $translate.refresh();
          }]
        }
      });
    }
  
  })();
  