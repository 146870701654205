/**
* @author: Abhay
* @date:   21-Jan-2021 15:05 +05:30
* @Project: Ezyprocure
* @Filename: admin.release-notes-master.controller.js
* @Last modified by:   Abhay
* @Last modified time: 21-Jan-2021 15:05 +05:30
*/



(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('ReleaseNotesMasterController', ReleaseNotesMasterController);

  ReleaseNotesMasterController.$inject = ['$scope', '$rootScope', '$state', '$mdDialog', 'GLOBAL_CONSTANTS', 'ReleaseNotesMasterService', 'pagingParams', 'paginationConstants', 'NotificationService'];

  function ReleaseNotesMasterController($scope, $rootScope, $state, $mdDialog, GLOBAL_CONSTANTS, ReleaseNotesMasterService, pagingParams, paginationConstants, NotificationService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;

    vm.releaseNotesList = [];
    vm.releaseNotesForm = {
      releaseVersion: undefined,
      imageURL: undefined,
      pdfURL: undefined,
      date: undefined,
      noticeFor: undefined
    }
    vm.releaseNoteType = ["RELEASE_NOTE", "ANNOUNCEMENT"];
    vm.getAllReleaseNotes = getAllReleaseNotes;
    vm.submitReleaseNotesForm = submitReleaseNotesForm;
    vm.showEditDialog = showEditDialog;
    vm.confirmDelete = confirmDelete;
    vm.popReleaseNoteDialog = popReleaseNoteDialog;
    vm.hideDialog = hideDialog;
    vm.cancelDialog = cancelDialog;
    vm.setAsActive = setAsActive;
    vm.releaseNotesForm.noticeFor = "FOR_ALL";
    getAllReleaseNotes();

    function popReleaseNoteDialog() {
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/admin-modules/release-notes-master/admin.release-notes-master-form.tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: false
      }).then(function () {
        //   WhatsNewService.updateHasReadValue(true);
      }, function () {
        //   WhatsNewService.updateHasReadValue(false);
      });
    }

    function resetReleaseNotesForm() {
      vm.releaseNotesForm = {};
      vm.releaseNotesForm.noticeFor = "FOR_ALL";
    }

    function hideDialog() {
      vm.releaseNotesForm = {};
      $mdDialog.hide();
    }

    function cancelDialog() {
      resetReleaseNotesForm();
      $mdDialog.cancel();
    }


    function showEditDialog(ev, releaseNote) {
      vm.releaseNotesForm = JSON.parse(JSON.stringify(releaseNote));
      popReleaseNoteDialog(ev);
    }

    function confirmDelete(ev, releaseNote) {
      ReleaseNotesMasterService.deleteReleaseNotes(releaseNote.id).then(function (response) {
        getAllReleaseNotes();
      },
        function (error) {

        });
    }

    function setAsActive(releaseId,isActive) {
      ReleaseNotesMasterService.submitNewReleaseNote(releaseId,isActive).then(function (response) {
        getAllReleaseNotes();
      },
        function (error) {

        });
    }


    function sort() {
      var result = ['name' + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function getAllReleaseNotes() {
      ReleaseNotesMasterService.getAllReleaseNotes().then(function (response) {
        vm.releaseNotesList = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },
        function (error) {

        });
    }

    function submitReleaseNotesForm() {
      ReleaseNotesMasterService.addReleaseNotes(vm.releaseNotesForm).then(function (response) {
        hideDialog();
        getAllReleaseNotes();
      },
        function (error) {

        });
    }

  }


})();
