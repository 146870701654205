/**
* @Author: Ajay
* @Date:   06-Apr-2017 10:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.purchase-order.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-21T10:47:00+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseOrderIndividualDetailsController', BuyerPurchaseOrderIndividualDetailsController);

  BuyerPurchaseOrderIndividualDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'BuyerDataService', 'PurchaseOrderService', 'BuyerCancelPORequestService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'UTCDateService', 'NotificationService', 'Auth', 'GLOBAL_CONSTANTS'];

  function BuyerPurchaseOrderIndividualDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, BuyerDataService, PurchaseOrderService, BuyerCancelPORequestService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, UTCDateService, NotificationService, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.poNumber = $stateParams.poNumber;
   // vm.deliveryDate = $stateParams.deliveryDate;
   // vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.deliveryDate = $stateParams.deliveryDate ? $stateParams.deliveryDate : '';
    vm.previewType = $stateParams.type;
    vm.todaysDate = $filter('date')(new Date(), 'dd/MM/yyyy');
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.showCancelPOModal = showCancelPOModal;
    vm.cancel = cancel;
    vm.cancelPORequest = cancelPORequest;
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.showOthers = showOthers;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "PURCHASE-ORDER";
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.isSupplierRemarkNotEmpty = isSupplierRemarkNotEmpty;
    vm.getItemSupplierRemark = getItemSupplierRemark;
    vm.groupPODetailsByDate = groupPODetailsByDate;
    vm.purchaseOrderPreview = purchaseOrderPreview;
    vm.userProfile = BuyerDataService.getBuyerProfile();
    vm.inclusiveOfTax = GLOBAL_CONSTANTS.inclusiveOfTax;
    vm.specialRequestList = specialRequestList;
    vm.formatPrice = formatPrice;
    vm.roundOfPrice = roundOfPrice;
     /**
     * [init loads inititally]
     */
    function init(){
      loadAll();
    if(vm.accessForPriceDisplay)
      vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
    else {
      vm.purchaseOrderHeader = _.without(EXPORT_CONSTANTS.CSV.purchaseOrderHeader, 'unit_price' , 'amount_required', 'amount_supplier');
    }
    }
    /**
     * [loadAll Gets Purchase Order Details by PO number]
     */
     function loadAll() {
        if(vm.deliveryDate){
            vm.deliveryDate = vm.deliveryDate.split(',');
          }
          PurchaseOrderService.getPurchaseOrderByPurchaseOrderNumber(vm.poNumber, {
            deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          }).then(function(response){
         vm.poDetails = response.data;
         vm.purchaseOrderNumber = vm.poDetails.purchaseOrderNumber;
         vm.requirementNumber = vm.poDetails.items[0].buyerRequirement.requirementNumber;
         vm.selectedDeliveryDate = vm.poDetails.items[0].supplierResponses ? vm.poDetails.items[0].supplierResponses.deliveryDate : vm.poDetails.items[0].buyerRequirement.deliveryDate;
         vm.invoiceStatus = vm.poDetails.items[0].invoiceStatus;
         //vm.poDetailGroup=[];  // copying into an object similar to poDetails in supplierPO to display in purchase-order-report.preview.html
         //vm.poDetailGroup.push(vm.poDetails.items);
        // vm.totalAmountRequired = sumRequired(vm.poDetails.items);
        // vm.totalAmountSupplier = sumSupplier(vm.poDetails.items);
         vm.poDetailGroup = vm.groupPODetailsByDate(vm.poDetails.items);
         vm.specialRequest = vm.specialRequestList(vm.poDetails.items);
       },function(error){
         NotificationService.error({
           title: 'Purchase Order',
           error: error
         });
       });
     }

     function purchaseOrderPreview(poNumber,type,poDetailItems){
        var deliveryDate = _.map(poDetailItems, function(each){
          if(each.supplierResponses){
            return each.supplierResponses.deliveryDate;
          }
          return each.buyerRequirement.deliveryDate;
        });
      deliveryDate = _.uniq(deliveryDate);
        $state.go('buyer.reports.purchase-order.preview',{
            poNumber: poNumber, type: type, deliveryDate: deliveryDate
        });
     }

     /**
     * [groupPODetailsByDate description]
     * @param  {[objectArray]} itemsData [PODetails to be grouped according to delivery date]
     * @return {[objectArray]}           [description]
     */
    function groupPODetailsByDate(itemsData){
        var poDetailsGroup= _.groupBy(itemsData, function(purchaseOrder){
          if(purchaseOrder.supplierResponses)
          var deliveryDate = moment(purchaseOrder.supplierResponses.deliveryDate).format('DD-MM-YYYY');
          else
          var deliveryDate = moment(purchaseOrder.buyerRequirement.deliveryDate).format('DD-MM-YYYY');
          return deliveryDate;
        });
        var orderDetailsGroup = _.map(poDetailsGroup, function(purchaseOrder, key){
          purchaseOrder['sumRequired'] = vm.sumRequired(purchaseOrder);
          purchaseOrder['sumSupplier'] = vm.sumSupplier(purchaseOrder);
          purchaseOrder['deliveryDate'] = purchaseOrder[0].supplierResponses? purchaseOrder[0].supplierResponses.deliveryDate : purchaseOrder[0].buyerRequirement.deliveryDate;
          return purchaseOrder;
        });
        var purchaseOrders = _.values(orderDetailsGroup);
        return purchaseOrders;
      }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
          var totalAmount = 0;
          _.forEach(poItems, function(item) {
            if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
             
                totalAmount += item.amount
            }
          });
          return _.round(totalAmount, 2);
        }
    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
                  if(item.buyerRequirement.items.uom.weightRequired === false) {
                    if(item.supplierResponses === null) {                  
                        totalAmount += item.amount;
                    }
                    else {
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount +=_.round(((item.supplierResponses.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price),2);
                      }
                      else
                      {
                        totalAmount +=_.round((item.supplierResponses.quantity * item.buyerRequirement.price),2);
                      }
                    }
                  }
                  else {
                    if(item.supplierResponses === null) {
                        totalAmount += item.amount
                    }
                    else {
                      //totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
                      if(item.buyerRequirement.items.uom.moqPrice === true)
                      {
                        totalAmount += _.round(((item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price),2);
                      }
                      else
                      {
                        totalAmount += _.round((item.supplierResponses.weight * item.buyerRequirement.price),2);
                      }
                    }
                  }
                }
              });
      return _.round(totalAmount,2);
    }
    /**
     * [cancel to close the modal]
     */
    function cancel () {
      $mdDialog.cancel();
    }

    /**
     * [showAccessModal shows modal to Cancel PO]
     */
    function showCancelPOModal (event) {
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/reports/purchase-order/individual-details/buyer.purchase-order.individual-details.cancel-po-dialog.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

 //round off price to two decimal places
 function formatPrice(amount){
  return (Math.round((amount)*1000)/1000);
}
    function cancelPORequest() {
      cancel();
      BuyerCancelPORequestService.cancelPORequest(vm.poNumber, {
        buyerCancelpoReason : vm.cancelPOReason
      }).then(function(response){
        NotificationService.success({
          title: 'Cancel PO',
          message: 'Requested successfully'
        });
        $state.go('buyer.reports.purchase-order', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Cancel PO Request',
          error: error
        });
      });
    }

    function getPurchaseOrderCSVData(poDetailItems) {
      return CSVExportService.getPurchaseOrderCSV(vm.poDetails, poDetailItems);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualPurchaseOrderPDF(deliveryDate) {
      PDFExportService.getPurchaseOrderPDF(vm.poNumber, {
        deliveryDate: deliveryDate
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Purchase Order PDF Export',
          error: error
        });
      });
    }

    function showOthers(){
      this.others = false;
      var checkOthers = vm.cancelPO.reason;
      if(checkOthers == 'Others')
      {
        vm.cancelPOReason="";
        this.others = true
      }else if(checkOthers=='Supplier_cannot_deliver'){
        vm.cancelPOReason="Supplier cannot deliver";
        this.others = false;
      }else if(checkOthers=='Approver_cancelled'){
        vm.cancelPOReason="Approver cancelled";
        this.others = false;
      }else{
        vm.cancelPOReason="Customer cancelled the event";
      }
    }

    //To handle large decimal values round of to 4 and then to 2
    function roundOfPrice(amount){
      var totalAmount;
      totalAmount = $filter('totalRoundOfDecimal')(amount);
      return totalAmount;
    }

    function isSupplierRemarkNotEmpty(poDetail) {
      var remark = vm.getItemSupplierRemark(poDetail);
      return remark && remark.length > 0;
    }

    // get supplier remarks from supplier responses if not found get from buyer requirement supplier response
    function getItemSupplierRemark(poDetail) {
      return poDetail.supplierResponses ? poDetail.supplierResponses.supplierRemark : poDetail.buyerRequirement.supplierResponse
    }

    function specialRequestList(poDetailItems){
        vm.specialrequestArray = [];
        poDetailItems.forEach(function (item) {
            if(item.buyerRequirement.specialRequest){
                    vm.specialrequestArray.push({
                        "itemCode": item.buyerRequirement.items.itemCode,
                        "itemName": item.buyerRequirement.items.itemName,
                        "specialRequest" : item.buyerRequirement.specialRequest
                    })
            }
        })
    }

  }
})();
