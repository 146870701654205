/*
 * @Author: lovin
 * @Date: 2019-10-10 19:15:49
 * @Filename: ezypayment.admin.account-details.add.controller.js
 * @Last Modified by: Lovin
 * @Last Modified time: 2019-12-05 15:25:05
 */


(function() {
    'use strict';

    angular
    .module('ezyprocureApp')
    .controller('EzyPaymentAccountDetailsAddController', EzyPaymentAccountDetailsAddController);

    EzyPaymentAccountDetailsAddController.$inject = ['$state', '$stateParams','Auth', 'EzypaymentAccountDetailsService','BuyerDataService','SupplierDataService', 'GLOBAL_CONSTANTS','BuyerSupplierMappingService', 'NotificationService', 'BankService','paginationConstants','BrandManagementService','ManageBuyerService','BrandSupplierMapService'];

    function EzyPaymentAccountDetailsAddController($state, $stateParams,Auth, EzypaymentAccountDetailsService,BuyerDataService,SupplierDataService, GLOBAL_CONSTANTS,BuyerSupplierMappingService, NotificationService, BankService, paginationConstants, BrandManagementService, ManageBuyerService, BrandSupplierMapService ) {
      var vm = this;
      vm.userRole = Auth.getUserRole();
      vm.brandId = $stateParams.brandId;
      vm.addBankAccountDetails = addBankAccountDetails;
      vm.gatewayTypes = GLOBAL_CONSTANTS.GATEWAY_TYPES;
      vm.getAllBanks = getAllBanks;
      vm.selectedBankChanged = selectedBankChanged;
      vm.selectedBank = {};
      vm.query = {
        limit: paginationConstants.itemsPerPage,
        page: 1
      };
      vm.getAllBuyersMappedToPayment = getAllBuyersMappedToPayment;
      vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
      vm.loadDropdowns = loadDropdowns;
      vm.paymentTermsDTO = {
        accountName: undefined,
        accountNumber: undefined,
        bank: {},
        brandList:[],
        supplier: {}
      };
      vm.deselectAll = deselectAll;
      vm.selectAll = selectAll;
      vm.getAllBuyerMappedSuppliers = getAllBuyerMappedSuppliers;
      vm.filter = {
        buyerIdList: null,
        brandIdList: [],
        supplierIdList: null
      };


    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId: !buyerIdList ? BuyerDataService.getBuyerProfile().buyerUser.buyer.id: buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
        vm.filter.supplierIdList=[];
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
    * [loadDropdowns The function executes when the dropdown closes]
    */
    function loadDropdowns(){
     vm.getAllBrandsOfBuyerList(vm.filter.buyerIdList)
    }

    /**
     * [addBankAccountDetails Creates new Account details]
     */
    function addBankAccountDetails(){
      vm.selectedBrand = _.filter(vm.brands, function(brands) {
        return brands.id == vm.filter.brandIdList;
      });
      vm.selectedSupplier = _.filter(vm.suppliers, (function(suppliers){
        return suppliers.id == vm.filter.supplierIdList;
      }));
      var supplier = !vm.selectedSupplier[0] ? SupplierDataService.getSupplierProfile().supplier.id: vm.selectedSupplier[0]
      vm.paymentTermsDTO = {
        'accountName': vm.accountName,
        'accountNumber': vm.accountNumber,
        'paymentType':"BANK_TRANSFER",
        'active':true,
        'bank': {
          id: vm.selectedBank.id,
          bankCode: vm.selectedBank.bankCode,
          bankName: vm.selectedBank.bankName,
          swiftCode: vm.selectedBank.swiftCode
        },
        'brandList':vm.filter.brandIdList,
        'supplier': !vm.selectedSupplier[0] ? SupplierDataService.getSupplierProfile().supplier : vm.selectedSupplier[0]
      }
      EzypaymentAccountDetailsService.createAccountDetail(vm.paymentTermsDTO).then(function(response) {
        NotificationService.success({
          title: ' Account Detail',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
        $state.go('ezypayment.'+vm.userRole.pretty+'.account-details',{reload: false, initialActive: true})
      }, function(error) {
        vm.translatedError = error.data;
        NotificationService.error({
          title: ' Account Detail',
          error: error
        });
      });
    }

    /**
     * [getAllBanks Gets all banks]
     */
    function getAllBanks() {
      BankService.getAllBanks({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.banks = response.data;
      }, function(error) {
      });
    }

    /**
    * [selectedBankChanged Get all the details of selected bank]
    */
    function selectedBankChanged(bankId) {
      var banks = angular.copy(vm.banks);
      vm.selectedBank = BankService.getSelectedBank(banks, bankId);
    }

    /**
     * [getAllBuyers Gets all buyers mapped with the payment model]
     */
    function getAllBuyersMappedToPayment(){
      vm.filter.brandIdList = '';
      vm.filter.supplierIdList = '';
      if(vm.userRole.pretty == 'supplier'){
        vm.mappedItemSearch = '';
        BuyerSupplierMappingService.getAllMappedSupplierBuyers({
          page: vm.query.page - 1,
          size: paginationConstants.maxLimit,
          query: vm.mappedItemSearch,
        }).then(function(response){
          vm.totalItems = response.headers('X-Total-Count');
          vm.buyers = response.data;
        },function(error){
        });
      }
      else {
        ManageBuyerService.getAllAdminBuyers({
          page: 0,
          size: paginationConstants.maxLimit,
          search: ''
        }).then(function(response) {
          vm.buyers = response.data;
        }, function(error) {
          NotificationService.error({
            title: 'Buyer Listing',
            error: error
          });
        });
      }
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
      case 'BRANDS':
        vm.filter.brandIdList = _.map(vm.brands, _.property('id'));
        break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets, suppliers, buyers or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
      case 'BRANDS':
        vm.filter.brandIdList = [];
        break;
      }
    }
      /**
   * [getAllBrandMappedSuppliers Get list of mapped suppliers to the selected brand]
   */
    function getAllBuyerMappedSuppliers(buyerIdList) {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        brandIds: !vm.filter.brandIdList ? '': vm.filter.brandIdList,
      }
      vm.buyerId = !buyerIdList ?BuyerDataService.getBuyerProfile().buyerUser.buyer.id: buyerIdList;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(vm.buyerId,params)
      .then(function(response){
        vm.suppliers = response.data;
      });
    }

  }
})();
