/**
* @Author: Midhun Mohan <MidhunMohan>
* @Date:   07-Apr-2017 19:16 +05:30
* @Project: Ezyprocure
* @Filename: collection-staff-interface-individual-details.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 10:58 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('CSInterfaceIndividualDetailsController', CSInterfaceIndividualDetailsController);

  CSInterfaceIndividualDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'OCRImageUploadService', 'BuyerOCRService', 'BuyerDataService','UTCDateService', 'GLOBAL_CONSTANTS'];

  function CSInterfaceIndividualDetailsController($scope, $rootScope, $filter, $state, $stateParams, NotificationService, $mdDialog, OCRImageUploadService, BuyerOCRService, BuyerDataService, UTCDateService, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.poNumber = $stateParams.poNumber;
    vm.deliveryDate=new Date($stateParams.deliveryDate).toISOString();
    vm.uploadImgModal = showModal;
    vm.testImage = testImage;
    vm.closeModal = closeModal;
    vm.invoiceArray = {};
    vm.grnData={};
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    /**
    * [init loads initilally]
    */
    function init() {
      loadAll();
    }
    //Add Image to PO Starts Here

    function showModal(event) {
      vm.newUserRole = {};
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/ocr/buyer.ocr.uploadInvoice.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }
    /**
    * [testImage description]
    * @param  {[type]} file [description]
    * @return {[type]}      [description]
    */
    function testImage(file) {
      OCRImageUploadService.getImageBase64(file[0].lfFile, function(base64Img) {
        BuyerOCRService.invoiceImgUpload(base64Img).then(function(response) {
          closeModal();
          invoiceToMicroService(response.data);
        }, function(error) {
        });
      });
    }

    function invoiceToMicroService(invoiceImage) {
      var item = {
        "poNumber": vm.poDetails.purchaseOrderNumber,
        "invoiceUrl": invoiceImage,
        "invoiceAmount": vm.grnData.grnamount.toFixed(2),
        "user": BuyerDataService.getBuyerProfile().buyerUser.user.login,
        "deliveryDate":vm.deliveryDate
      };
      BuyerOCRService.invoiceMicroService(item).then(function(response) {
        NotificationService.success({
          title: 'OCR Invoice',
          message: 'alertMessage.UPLOADED_SUCCESSFULLY'
        });
        init();
      }, function(error) {
      });
    }

    function closeModal() {
      $mdDialog.hide();
    }

    /**
    * [loadAll Gets Purchase Order Details by PO number]
    */
    function loadAll() {
      var deliveryOrder={};
      deliveryOrder.poNumber=vm.poNumber;
      deliveryOrder.deliveryDate=BuyerOCRService.toOCRDateString($stateParams.deliveryDate);
      BuyerOCRService.getPurchaseOrderDetails(vm.poNumber,vm.deliveryDate).then(function(response) {
        vm.poDetails = response.data;
        vm.poDetails.items[0].ocrAcceptanceStatus=!vm.poDetails.items[0].ocrAcceptanceStatus?'':vm.poDetails.items[0].ocrAcceptanceStatus.replace(/_/g, ' ');
        BuyerOCRService.grnByPONumber(vm.poNumber,vm.deliveryDate).then(function(response) {
          var poDetails = response.data;
          vm.grnData=poDetails;
          BuyerOCRService.getInvoiceStatus({
            'deliveryOrders': [deliveryOrder]
          }).then(function(response) {
            if (!response.data.data[0])
            vm.invoiceArray.invoiceStatus = false;
            else {
              vm.invoiceArray = response.data.data[0];
              vm.invoiceArray.invoiceStatus = vm.invoiceArray.invoiceStatus.replace(/_/g, ' ');
            }
          }, function(error) {});
        }, function(error) {});
      }, function(error) {
      });
    }

  }
})();
