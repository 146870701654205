/**
* @Author: Abhay
* @Date:   28-April-2021 16:03 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.axs-pay-bills-fines.controller.js
 * @Last modified by:   Abhay
 * @Last modified time: 28-April-2021 16:03 +05:30
*/

(function () {
  'use strict';

  angular
    .module('ezyprocureApp')
    .controller('AxsPayBillsFinesController', AxsPayBillsFinesController);

  AxsPayBillsFinesController.$inject = ['$stateParams', '$state', 'paginationConstants', '$sce', '$filter', 'toastr', 'Auth', 'CardsService', 'BuyerDataService', 'BuyerSupplierMappingService', 'BrandManagementService', 'AxsPayBillsFinesService', 'GLOBAL_CONSTANTS', 'NotificationService', '$timeout'];

  function AxsPayBillsFinesController($stateParams, $state, paginationConstants, $sce, $filter, toastr, Auth, CardsService, BuyerDataService, BuyerSupplierMappingService, BrandManagementService, AxsPayBillsFinesService, GLOBAL_CONSTANTS, NotificationService, $timeout) {
    var vm = this;
    vm.suppliers = [];
    vm.creditCard = {
      brandId: undefined,
      cardToken: undefined
    };
    vm.brands = [];
    vm.cardTypes = [];
    vm.axsPostUrl = trustAsResourceUrl('https://axsuat1.axs.com.sg/wSDK/v1_2/merchantRedirection.php');
    vm.postQueryParams = {
      merchantId: "",
      merchantKeyId: "",
      sessionIdEnc: "",
      userId: "",
      callback: "",
      hashValue: "",
      AXSKeyId: "",
      name: "",
      payment: "",
      landingCode: "",
      contactMask: "",
      contactEnc: "",
      emailMask: "",
      emailEnc: ""
    };
    vm.getBrands = getBrands;
    vm.cardTokenByBrands = cardTokenByBrands;
    vm.userRole = Auth.getUserRole().pretty;
    vm.getAxsRedirectDetails = getAxsRedirectDetails;
    vm.redirectToTransactionDetail = redirectToTransactionDetail;
    vm.axsBrandValidate = true;
    vm.payBillsFines = payBillsFines;
    vm.axsBrandValidation = axsBrandValidation;

    function payBillsFines(){
      getAxsRedirectDetails();
    }


    function cardTokenByBrands() {
      CardsService.fetchAllAvailableCards({
        brandId: vm.creditCard.brandId
      }).then(function (response) {
        vm.tokenCards = response.data.tokenRepos;
      }, function (error) {
        NotificationService.error({
          title: 'Fetching Token By Cards',
          error: error
        });
      });
    }

    function redirectToTransactionDetail() {
      $state.go("ezypayment.buyer.axs-transaction-details");
    }

    function getBrands() {
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        supplierId: vm.creditCard.supplierIds,
        buyerId: buyerId
      }).then(function (response) {
        vm.brands = response.data;
        vm.tokenCards  = '';
        vm.creditCard.cardToken = null;
      });
    }

    function getAxsRedirectDetails() {
        //axsBrandValidation();
      var params = {
        tokenRepoId: vm.creditCard.cardToken
      }
      AxsPayBillsFinesService.axsRedirect(params).then(function (response) {
        setPostRequestData(response.data);
      },
        function (error) {
          toastr.error("Error", "AXS POST Data");
        })
    }

    function setPostRequestData(data) {
      // because key send from BE is different
      data.AXSKeyId = data.axskeyId;
      var paramKeys = Object.keys(vm.postQueryParams);
      paramKeys.forEach(function (key) {
        vm.postQueryParams[key] = data[key] || "";
      });
      $timeout(function() {
        vm.axsRedirect.commit();
      }, 1000);
    }

    function trustAsResourceUrl(url) {
      return $sce.trustAsResourceUrl(url);
    }

    function axsBrandValidation(){
        var brandValidateParams = {
            brandId : vm.creditCard.brandId,
            tokenRepoId: vm.creditCard.cardToken
         }
         AxsPayBillsFinesService.axsBrandValidation(brandValidateParams).then(function (response) {
             if(response){
                vm.axsBrandValidate = false;
             }
        },
          function (error) {
                vm.axsBrandValidate = true;
                toastr.error("", error.data.message);
          })
    }

  }
})();
