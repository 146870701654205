/**
* @Author: Ajay
* @Date:   20-Mar-2017 11:56 +05:30
* @Project: Ezyprocure
* @Filename: buyer.order-history.details.controller.js
 * @Last modified by:   Lovin
 * @Last modified time: 2020-05-18T14:17:20+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerOrderHistoryDetailsController', BuyerOrderHistoryDetailsController);

  BuyerOrderHistoryDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog', 'SupplierProcessOrderService', 'CartService', 'Auth', 'NotificationService'];

  function BuyerOrderHistoryDetailsController($scope, $rootScope, $filter, $state, $stateParams, toastr, $mdDialog, SupplierProcessOrderService, CartService, Auth, NotificationService) {
    var vm = this;
    vm.init = init;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.processOrderStatus = $stateParams.processOrderStatus;
    vm.getProcessOrder = getProcessOrder;
    vm.addToCart = addToCart;
    vm.checkDeliveryQuantity = checkDeliveryQuantity;
    vm.removeFromCart = removeFromCart;
    vm.checkItemInCart = checkItemInCart;
    vm.setDeliveryCondition = setDeliveryCondition;

    function init() {
      getProcessOrder();
    }

    /**
    * [getProcessOrder gets the previous purchase by requirement number]
    */
    function getProcessOrder() {
      SupplierProcessOrderService.getProcessOrderByRequirementNumber(vm.requirementNumber).
      then(function(response) {
        vm.items = response;
        var buyerDeliveryConditions = vm.items.buyerDeliveryConditions;
        _.each(response.processOrders, function(element, index) {

         vm.items.buyerDeliveryConditions == null ? _.extend(element,
            {
                minDate: ( element.items.supplier.noSameDayDelivery)? (angular.copy(new Date(new Date().getTime() + ((element.items.supplier.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
            }) : _.extend(element,
                {
                    deliveryDate: (vm.items.buyerDeliveryConditions.deliveryConditions.noSameDayDelivery && vm.items.buyerDeliveryConditions.deliveryConditions.leadTime >= 0)? (angular.copy(new Date(new Date().getTime() + ((vm.items.buyerDeliveryConditions.deliveryConditions.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                    minDate: ( vm.items.buyerDeliveryConditions.deliveryConditions.noSameDayDelivery)? (angular.copy(new Date(new Date().getTime() + ((vm.items.buyerDeliveryConditions.deliveryConditions.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                })
          });
          vm.items = response;
        }, function(error) {
          NotificationService.error({
            title: 'Order History - Fetching details',
            error: error
          });
        });
      }

    /**
    * [checkDeliveryQuantity validation for quantity exceeding moq]
    * @param  {[object]} itemToBuy [details of selected item]
    * @return {[boolean]}           [quantity exceede or not]
    */
    function checkDeliveryQuantity(itemToBuy) {
      if (itemToBuy.quantity && itemToBuy.quantity >= itemToBuy.items.moq) {
        return true;
      } else {
        toastr.error('Item quantity should be greater than or equal to MOQ.', 'Error');
        return false;
      }
    }

    // set default delivery condition
    function setDeliveryCondition(req){
      req.buyerDeliveryConditions = vm.items.buyerDeliveryConditions;
      req.items.supplier.deliveryCondition = {
        orderTimeForToday: req.items.supplier.orderTimeForToday,
        orderTimeForFriday: req.items.supplier.orderTimeForFriday,
        orderTimeForSaturday: req.items.supplier.orderTimeForSaturday,
        noDeliveryOnHolidays: req.items.supplier.noDeliveryOnHolidays,
        noDeliveryOnSaturday: req.items.supplier.noDeliveryOnSaturday,
        noDeliveryOnSunday: req.items.supplier.noDeliveryOnSunday,
        noDeliveryOnMonday: req.items.supplier.noDeliveryOnMonday,
        minimumOrderForFreeDelivery: req.items.supplier.minimumOrderForFreeDelivery,
        deliveryCharge: req.items.supplier.deliveryCharge,
        belowMinimumPurchaseCondition: req.items.supplier.belowMinimumPurchaseCondition,
        noSameDayDelivery: req.items.supplier.noSameDayDelivery,
        leadTime: req.items.supplier.leadTime,
        noDeliveryOnTuesday: req.items.supplier.noDeliveryOnTuesday,
        noDeliveryOnWednesday: req.items.supplier.noDeliveryOnWednesday,
        noDeliveryOnThursday: req.items.supplier.noDeliveryOnThursday,
        noDeliveryOnFriday: req.items.supplier.noDeliveryOnFriday
      }
    }

    /**
    * [addToCart aads the items to cart]
    * @param {[object]} itemToBuy [details of selected item]
    */
    function addToCart(itemToBuy) {
      setDeliveryCondition(itemToBuy);
      var cartItem = {};
      if (CartService.checkLeadTime(itemToBuy)){
        if (CartService.checkOrderedTime(itemToBuy) && vm.checkDeliveryQuantity(itemToBuy)) {
          cartItem.deliveryDate = itemToBuy.deliveryDate;
          cartItem.quantity = itemToBuy.quantity;
          cartItem.supplierType = itemToBuy.items.supplier.visibilityType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated';
          cartItem.specialRequest = itemToBuy.specialRequest ? itemToBuy.specialRequest : null;
          cartItem.items = {};
          cartItem.buyerBusiness = {};
          cartItem.buyerBusiness.id = itemToBuy.buyerBusiness.id;
          cartItem.items.id = itemToBuy.items.id;
          if (Auth.hasPermission('accessForGlModule')) {
            cartItem.glCodeMappings = itemToBuy.glCodeMappings;
          }
          CartService.addToCart(cartItem).
          then(function(responseData) {
            NotificationService.success({
              title: 'Order History',
              message: 'Added to cart'
            });
            $rootScope.$broadcast('refresh-cart');
          }, function(error) {
            NotificationService.error({
              title: 'Order History - Updating Cart',
              error: error
            });
          });
        }
      }
    }

    /**
    * [checkItemInCart checks whether the selected item is already inthe cart]
    * @param  {[object]} item [item details]
    * @return {[boolean]}      [whether it exists or not]
    */
    function checkItemInCart(item) {
      item.itemId = item.id;
      var index = CartService.checkItemInCart(item);
      if (index !== false) {
        item.inCartId = index;
      }
      return index;
    }

    /**
    * [removeFromCart selected item is removed from the cart]
    * @param  {[number]} cartId [id of the item in cart]
    */
    function removeFromCart(cartId) {
      CartService.removeFromCart(cartId).
      then(function(responseData) {
        NotificationService.success({
          title: 'Order History',
          message: 'Removed from cart'
        });
        $rootScope.$broadcast('refresh-cart');
      }, function(error) {
        NotificationService.error({
          title: 'Order History - Removing from cart',
          error: error
        });
      });
    }


  }
})();
