/**
* @Author: Sneha
* @Date:   2018-09-18T17:44:39+05:30
* @Project: Ezyprocure
* @Filename: ezypyament.manage-cards.choose-brands.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-10-03T15:39:47+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentChooseBrandsController', EzypaymentChooseBrandsController);

  EzypaymentChooseBrandsController.$inject = ['$state', 'paginationConstants', '$stateParams', '$q','CardsService', 'BrandManagementService', 'BuyerDataService', 'GLOBAL_CONSTANTS', 'NotificationService', 'BuyerSupplierMappingService', 'EzypaymentAdminManageMerchantDetailsService'];

  function EzypaymentChooseBrandsController($state, paginationConstants, $stateParams, $q, CardsService, BrandManagementService, BuyerDataService, GLOBAL_CONSTANTS, NotificationService, BuyerSupplierMappingService, EzypaymentAdminManageMerchantDetailsService) {

    var vm = this;
    vm.getBrands = getBrands;
    vm.getCardTypes = getCardTypes;
    vm.creditCard = {};
    vm.proceedToHostedMPGSSession = proceedToHostedMPGSSession;
    vm.getSelectedBrands = getSelectedBrands;
    vm.creditCard.brandIds = [];
    vm.creditCard.supplierIds = [];
    vm.getMerchantIdAndProceed = getMerchantIdAndProceed;
    vm.userRole = $stateParams.userRole;
    vm.loadAllBuyerMappedSuppliers = loadAllBuyerMappedSuppliers;
    vm.getSupplierMerchantIdAndProceed = getSupplierMerchantIdAndProceed;
    vm.getBuyerMerchantIdAndProceed = getBuyerMerchantIdAndProceed;
    vm.proceedToHostedMPGSBuyerSession = proceedToHostedMPGSBuyerSession;
    vm.proceedToHostedMPGSSupplierSession = proceedToHostedMPGSSupplierSession;
    vm.cancel = cancel;
    vm.creditCard.cardType = 'VPS_ACCOUNT';

    /**
     * [loadAllBuyerMappedSuppliers gets all country currencies]
     */
    function loadAllBuyerMappedSuppliers() {
      var params = {
        page: 0,
        size: paginationConstants.maxLimit
      };
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BuyerSupplierMappingService.getAllBuyerMappedSuppliers(buyerId, params).then(function(response){
        vm.suppliers = response.data;
        vm.creditCard.brandIds[0]=[];
      },function(error){
      });
    }

    /**
    * [getBrands get all brands mapped to buyer]
    * @return {[type]} [description]
    */
    function getBrands(){
      var buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      BrandManagementService.getAllBrandsByBuyerId({
        page: 0,
        size: paginationConstants.maxLimit,
        query: '',
        supplierId: vm.creditCard.supplierIds,
        buyerId : buyerId
      }).then(function(response){
        vm.brands = response.data;
      });
    }

     /**
     * Get the Card Cycles
     * @return {[type]} [description]
     */
    function getCardTypes() {
      CardsService.getCardType({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response){
         vm.cardTypes = response.data;
       });
      }

    /**
    * [getSelectedBrands get brandName and id from array of selected brandIds]
    * @return {[type]} [description]
    */
    function getSelectedBrands(){
      //get brand objects from the selected brandIds
      vm.selected =  _.flatMap(vm.creditCard.brandIds, function(brandId){
        return _.filter(vm.brands, ['id', brandId]);
      });

      // create new array of brands with only brandNames and properties
      var selectedList = _.map(vm.selected,  function(brand){
        return {
          'id' : brand.id,
          'name': brand.name
        }
      });
      return selectedList;
    }

    /**
    * [proceedToHostedMPGSSession navigates to MPGS hosted session after checking for initial details required for saving card details]
    * @return {[type]} [description]
    */
    function proceedToHostedMPGSSession() {
      vm.creditCard.user = vm.userRole;
      if(vm.userRole === 'supplier'){
        vm.proceedToHostedMPGSSupplierSession();
      }else if(vm.userRole === 'buyer'){
        vm.proceedToHostedMPGSBuyerSession();
      }
    }

    /**
     * proceedToHostedMPGSSupplierSession Proceeds to the card adding view and calls the session api with merchant id
     */
    function proceedToHostedMPGSBuyerSession() {
      if(vm.creditCard && vm.creditCard.brandIds.length>0){
        vm.creditCard.brandList = BrandManagementService.getSelectedBrands(vm.creditCard.brandIds, vm.brands);
        vm.creditCard.cardType==='ALL_CARD'? vm.creditCard.cardCycle='' : '';
        CardsService.setBrandsAndCardType(vm.creditCard);
        $state.go('ezypayment.buyer.manage-cards.add-card',{});
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'Add Credit Card',
          message: 'alertMessage.MPGS.SELECT_BRANDS_AND_CARD_TYPE'
        });
      }
    }

    /**
     * proceedToHostedMPGSSupplierSession Proceeds to the card adding view and calls the session api with merchant id
     */
    function proceedToHostedMPGSSupplierSession() {
      if(vm.creditCard && vm.creditCard.supplierIds.length>0){
        vm.creditCard.supplierList = _.filter(vm.suppliers, {'id' : vm.creditCard.supplierIds[0]});
        vm.creditCard.cardType==='ALL_CARD'? vm.creditCard.cardCycle='' : '';
        CardsService.setBrandsAndCardType(vm.creditCard);
        $state.go('ezypayment.buyer.manage-cards.add-card',{});
      }
      else{
        NotificationService.simpleErrorToast({
          title: 'Add Credit Card',
          message: 'alertMessage.MPGS.SELECT_BRANDS_AND_CARD_TYPE'
        });
      }
    }

    // getMerchantIdAndProceed Decides the function according to the logged in user is supplier or buyer.
    function getMerchantIdAndProceed(){
      if(vm.userRole === 'supplier'){
        vm.getSupplierMerchantIdAndProceed();
      }else if(vm.userRole === 'buyer'){
        vm.getBuyerMerchantIdAndProceed();
      }
    }

    /**
     * [getBuyerMerchantIdAndProceed Gets buyer merchant id details]
     */
    function getBuyerMerchantIdAndProceed(){
      var merchantIdRequestDTO = {
        'brandId': vm.creditCard.brandIds,
        'gateWayType': 'MPGS'
      }
      CardsService.getMerchantId(merchantIdRequestDTO).then(function(response){
          vm.creditCard.merchantId = response.data[0].merchantId;
          vm.proceedToHostedMPGSSession();
      },function(error) {
        NotificationService.error({
          title: 'MPGS Error',
          error: error
        });
      });
    }

    /**
     * [getSupplierMerchantIdAndProceed Gets supplier merchant id details]
     */
    function getSupplierMerchantIdAndProceed(){
      var merchantIdRequestDTO = {
        'supplierId': vm.creditCard.supplierIds,
        'gateWayType': 'MPGS',
        'brandId': vm.creditCard.brandIds
      }
      EzypaymentAdminManageMerchantDetailsService.getSupplierMerchantIdDetail(merchantIdRequestDTO).then(function(response){
          vm.creditCard.merchantId = response.data[0].merchantId;
          vm.proceedToHostedMPGSSession();
      },function(error) {
        NotificationService.error({
          title: 'MPGS Error',
          error: error
        });
      });
    }

    /**
     * [cancel Depends on the tab it will redirects to the corresponding tab]
     */
    function cancel(){
      vm.userRole === 'buyer' ? $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false}) : $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : true})
    }

  }
})();
