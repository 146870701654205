/*
 * @Author: Lovin Chacko
 * @Date: 2021-04-29 15:57:31
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-05-05 23:18:55
 */




(function () {
    'use strict';

    angular.module('ezyprocureApp').factory('BlanketOrderService', BlanketOrderService);

    BlanketOrderService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

    function BlanketOrderService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

        var BlanketOrderService = {
            createNewBlanketOrder: createNewBlanketOrder,
            getBlanketOrderById: getBlanketOrderById,
            getAllBlanketOrders: getAllBlanketOrders,
            deleteBlanketOrderById: deleteBlanketOrderById,
            updateBlanketOrder: updateBlanketOrder,
            approveBlanketOrder: approveBlanketOrder,
            getAllBlanketOrderBuyNow: getAllBlanketOrderBuyNow
        };

        return BlanketOrderService;


        function createNewBlanketOrder(blanketOrder) {
            var deferred = $q.defer();
            $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-order', blanketOrder).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }


        function getBlanketOrderById(id, params) {
            var deferred = $q.defer();
            $http({
                url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-order/' + id,
                method: "GET",
                params: params,
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function getAllBlanketOrders(filterObj) {
            var deferred = $q.defer();
            $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-orders/', filterObj).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function deleteBlanketOrderById(id) {
            var deferred = $q.defer();
            $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-order/' + id).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function updateBlanketOrder(blanketOrder) {
            var deferred = $q.defer();
            $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-order', blanketOrder).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }


        function getAllBlanketOrderBuyNow(params) {
            var deferred = $q.defer();
            $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-order/buy-now', params).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

        function approveBlanketOrder(id) {
            var deferred = $q.defer();
            $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'blanket-orders/approve/' + id).then(function (data) {
                deferred.resolve(data.data);
            }).catch(function (message) {
                deferred.reject(message);
            });
            return deferred.promise;
        }

    }
})();
