/**
* @Author: Shibi
* @Date:   01-june-2020 18:10 +05:30
* @Project: Ezyprocure
* @Filename: confirm stock-take.controller.js
*/

(function() {
    'use strict';
    angular
    .module('ezyprocureApp')
    .controller('ConfirmStockTakeController', ConfirmStockTakeController);
    ConfirmStockTakeController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$q', '$mdDialog', '$filter', 'StockTakeService', 'NotificationService','pagingParams', 'paginationConstants', 'Auth', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS'];
    function ConfirmStockTakeController($scope, $rootScope, $state, $stateParams, $q, $mdDialog, $filter, StockTakeService,NotificationService, pagingParams, paginationConstants, Auth, GLOBAL_CONSTANTS, EXPORT_CONSTANTS) {
      var vm = this;
      vm.init = init; 
      vm.selectedStockTakeItems=[];
      vm.getSelectedStockTake = getSelectedStockTake;   
      vm.confirmStockTake = confirmStockTake;
      vm.variationDifference = variationDifference;
      vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
      function init() { 
        vm.getSelectedStockTake();
      }
      init();      

      function getValidStockEntries() {
        var validStockEntries = [];
        if (vm.selectedStockTakeItems && vm.selectedStockTakeItems.length) {
          validStockEntries = vm.selectedStockTakeItems.filter(function (item) {
            return (item.currentStock != "" && item.currentStock != undefined);
          });
        }
        return validStockEntries;
      }

      function getSelectedStockTake() {             
        vm.selectedStockTakeItems = StockTakeService.getSelectedStockTake(); 
      }
      function confirmStockTake() {
        var validEntries = getValidStockEntries();
        var selectedStockTake = _.map(validEntries, function (item) {
          return {
            "stockId": item.stockTakeId,
            'currentStock': item.currentStock,
            'cost': item.cost,
            'amount': item.amount,
            'remarks': item.remarks,
            'createdDate': item.createdDate,
            'warehouse':item.warehouse,
            'itemCode':item.itemCode
          }
        });
        var selectedStockTakeItems = _.compact(selectedStockTake);
        if (selectedStockTakeItems.length > 0) {
          StockTakeService.submitSelectedStockTake(selectedStockTakeItems).then(function(response) {
            NotificationService.success({
              title: 'Stock Take Submitted',
              message: 'alertMessage.COMPLETED_SUCCESSFULLY'
            });
            if ($state.current.parent == 'buyer') {
              $state.go('buyer.stock-take', {
                reload: false
              });
            }
            else {
              $state.go('supplier.stock-take', {
                reload: false
              });
            }
          }, function(error) {
            
          });
        }
      }
      function variationDifference(instock,currentstock){
        return Math.abs(instock-currentstock).toFixed(3);      
      }
      }
  })();
  