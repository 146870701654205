/**
* @Author: sneha
* @Date:   2018-09-07T14:42:15+05:30
* @Project: Ezyprocure
* @Filename: ezypayment.cards.add.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-09-27T16:29:07+05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentAuthController', EzypaymentAuthController);

  EzypaymentAuthController.$inject = ['$stateParams', '$state', 'NotificationService', 'CardsService', 'GLOBAL_CONSTANTS'];

  function EzypaymentAuthController($stateParams, $state, NotificationService, CardsService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.init = init;
    vm.addCreditCard = addCreditCard;
    vm.years = {};
    vm.errorMessage = '';
    vm.showMPGSErrorNotification = showMPGSErrorNotification;
    vm.errorList = [];
    vm.getMPGSErrorList = getMPGSErrorList;
    vm.userRole = $stateParams.userRole;
    vm.addSupplierCreditCard = addSupplierCreditCard;
    vm.updateMPGS = false;
    vm.randomIdMPGS = '';
    vm.popupUiCode = localStorage.getItem("popupUiCode");
    /* if(document.getElementById('challengeFrame') === null) {
        window.location.reload();
    } */
        if(location.href.split('/')[location.href.split('/').length-1] === 'auth') {
          var iframeCode = document.getElementById('challengeFrame');
          iframeCode.addEventListener('load',function (){
           /*  iframeCode.contentDocument.body.getElementsByTagName('form')[0].addEventListener('submit', function () {               
                setTimeout(function (){
                    localStorage.removeItem('popupUiCode');
                    $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false});
                }, 2500);
            }) */
          })
        }


    function init(){
      if(location.href.split('/')[location.href.split('/').length-1] === 'auth') {
        var iframeCodeI = document.getElementById('challengeFrame');
        iframeCodeI.addEventListener('load',function (){
/*             iframeCodeI.contentDocument.body.getElementsByTagName('form')[0].addEventListener('submit', function () {
            setTimeout(function (){
                localStorage.removeItem('popupUiCode');
                $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false});
            }, 2500);
          }) */
        })
      }
    }

    /**
    * [showMPGSErrorNotification description]
    * @param  {[type]} errorMessage [display error messages for MPGS validation messages]
    * @return {[type]}              [description]
    */
    function showMPGSErrorNotification(errorMessage){
      NotificationService.simpleErrorToast({
        title: 'Add Credit Card',
        message: errorMessage
      });
    }

    /**
    * [addSupplierCreditCard pass sessionID to backend with supplier id]
    */
   function addSupplierCreditCard() {
    if(vm.creditCard.sessionId!==" " && vm.creditCard.supplierIds.length>0){
      vm.creditCardDetails = {
        'sessionId' : vm.creditCard.sessionId,
        'supplierId' : vm.creditCard.supplierIds[0],
        'brandIds' : vm.creditCard.brandIds,
        'cardType' : 'NA',
        'cardCycle': vm.creditCard.cardCycle ? vm.creditCard.cardCycle : ''
      }
      CardsService.createSupplierCreditCards(vm.creditCardDetails).then(function(response){
        NotificationService.success({
          title: 'Cards',
          message: 'Added successfully'
        });
        localStorage.removeItem('popupUiCode');
        $state.go('ezypayment.buyer.manage-cards', {initiallyActiveTab : true});
      },function(error) {
        NotificationService.error({
          title: 'Add Credit Card',
          error: error
        });
      });
    }
  }

    /**
    * [addCreditCard pass sessionID to backend]
    */
    function addCreditCard() {
      vm.creditCard = JSON.parse(localStorage.getItem('creditCard'));
      if(vm.creditCard.sessionId!==" " && vm.creditCard.brandIds.length>0){
        vm.creditCardDetails = {
          'sessionId' : vm.creditCard.sessionId,
          'brandIds' : vm.creditCard.brandIds,
          'cardType' : 'NA',
          'cardCycle': vm.creditCard.cardCycle ? vm.creditCard.cardCycle : ''
        }
        CardsService.addCreditCard(vm.creditCardDetails).then(function(response){
          NotificationService.success({
            title: 'Cards',
            message: 'Added successfully'
          });
          localStorage.removeItem('popupUiCode');
          $state.go('ezypayment.buyer.manage-cards',{initiallyActiveTab : false});
        },function(error) {
          NotificationService.error({
            title: 'Add Credit Card',
            error: error
          });
        });
      }
    }

/**
 * [getMPGSErrorList description]
 * @param  {[type]} sessionError [get errors generated by MPGS script ]
 * @return {[type]}              [description]
 */
    function getMPGSErrorList(sessionError){
      if (sessionError.cardNumber) {
        vm.errorList.push('alertMessage.MPGS.CARD_NUMBER_INVALID_OR_MISSING')
      }
      if (sessionError.expiryYear) {
        vm.errorList.push('alertMessage.MPGS.CARD_EXPIRY_YEAR_INVALID')
      }
      if (sessionError.expiryMonth) {
        vm.errorList.push('alertMessage.MPGS.CARD_EXPIRY_MONTH_INVALID')
      }
      if (sessionError.securityCode) {
        vm.errorList.push('alertMessage.MPGS.CARD_SECURITY_CODE_INVALID')
      }
    }

  }
})();
