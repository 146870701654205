/**
 * @Author: Ajay
 * @Date:   09-Mar-2017 14:58 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.item.price-update.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 24-Apr-2018 12:16 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPriceUpdateController', SupplierPriceUpdateController);

  SupplierPriceUpdateController.$inject = ['$scope', '$state', '$element', 'toastr', 'SupplierDataService','Auth', 'BuyerSupplierMappingService', 'BrandManagementService', 'BuyerBusinessService', 'SupplierItemPriceUpdateService', 'CsvUploadService', 'GLOBAL_CONSTANTS','paginationConstants', 'NotificationService', 'UtilService', 'S3_URL_CONSTANTS', 'ManageSupplierService', '$q'];

  function SupplierPriceUpdateController($scope, $state, $element, toastr, SupplierDataService, Auth, BuyerSupplierMappingService, BrandManagementService, BuyerBusinessService, SupplierItemPriceUpdateService, CsvUploadService, GLOBAL_CONSTANTS,paginationConstants, NotificationService, UtilService, S3_URL_CONSTANTS, ManageSupplierService, $q) {
    var vm = this;
    vm.getAllSupplierMappedBuyers = getAllSupplierMappedBuyers;
    vm.getAllBuyersBusinessOutlets = getAllBuyersBusinessOutlets;
    vm.priceUpdateFilter = {};
    vm.priceUpdateFilter.approvalRequiredFromBuyer = false;
    vm.proceedToPriceUpdate = proceedToPriceUpdate;
    vm.priceUpdateCSVUpload = priceUpdateCSVUpload;
    vm.itemTypes = GLOBAL_CONSTANTS.ITEM_TYPES;
    vm.hasAccessForPriceUpdateApproval = Auth.hasPermission('accessForPriceUpdateApproval');
    vm.previewPriceUpdateCSVUpload=previewPriceUpdateCSVUpload;
    vm.clearCSVUpload=clearCSVUpload;
    vm.SUPPLIER_PRICE_UPDATE_CSV = S3_URL_CONSTANTS.SUPPLIER_PRICE_UPDATE_CSV;
    vm.isContracted=false;
    vm.buyerList=[];
    vm.contractedBuyers=[];
    vm.checkForContractedSupplier=checkForContractedSupplier;
    vm.priceUpdateList=false;
    vm.isCSV=false;
    vm.recordError = {}
    vm.recordError.responseList = [];
    vm.getAllBrandsOfBuyerList = getAllBrandsOfBuyerList;
    vm.getAllBrandMappedsBusinessOutlets = getAllBrandMappedsBusinessOutlets;
    vm.selectAll = selectAll;
    vm.deselectAll = deselectAll;

    //Get all buyers mapped to the supplier
    function getAllSupplierMappedBuyers() {
      vm.priceUpdateFilter.selectedBuyer = [];
      vm.priceUpdateFilter.brandList = [];
      vm.priceUpdateFilter.businessList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
      };
      if(vm.buyers)
        return false;
      var supplierId = SupplierDataService.getSupplierProfile().supplier.id;
      BuyerSupplierMappingService.getAllSupplierMappedBuyers(supplierId, params).then(function(response) {
        vm.buyers = response.data;
        vm.priceUpdateFilter.selectedBuyer = {};
      }, function(error) {
      });
    }

    /**
     * [getAllBrandsOfBuyerList Gets all brands of selected buyers]
     * @param {Array} buyerIdList
     */
    function getAllBrandsOfBuyerList(buyerIdList){
      vm.priceUpdateFilter.brandList = [];
      vm.priceUpdateFilter.businessList = [];
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        query:'',
        buyerId : buyerIdList
      };
      BrandManagementService.getAllBrandsOfBuyerList(params)
      .then(function(response){
        vm.brands = response;
      },function(error){
        NotificationService.error({
          title: 'Brand Listing',
          error: error
        });
      });
    }

    /**
     * [selectAll Selects all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function selectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.priceUpdateFilter.brandList = _.map(vm.brands, function(obj){
              return obj;
          });
          break;
        case 'OUTLETS':
          vm.priceUpdateFilter.businessList = _.map(vm.brandMappedBusinessOutlets, function(obj){
            return obj;
          });
           break;
      }
    }

    /**
     * [deselectAll Deselect all from the list]
     * @param  {[type]} dropdown [Active dropdown - outlets or brands]
     */
    function deselectAll(dropdown) {
      switch (dropdown) {
        case 'BRANDS':
          vm.priceUpdateFilter.brandList = [];
          break;
        case 'OUTLETS':
          vm.priceUpdateFilter.businessList = [];
          break;
      }
    }

    function getAllBrandMappedsBusinessOutlets(brandList) {
      var brandIdList = _.map(brandList, _.property('id'));
      var buyerIdList = [vm.priceUpdateFilter.selectedBuyer.id];
      if(vm.brandMappedBusinessOutlets)
        return false;
      var itemTerminationDTO = {
        'brandIdList': brandIdList,
        'buyerIdList': buyerIdList
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        supplierId: SupplierDataService.getSupplierProfile().supplier.id
      }
      BuyerBusinessService.getAllBrandMappedsBusinessOutlets(itemTerminationDTO, params).then(function(response) {
        vm.brandMappedBusinessOutlets = response.data;
      }, function(error) {
      });
    }


    //get all business outlets of the selected buyer
    function getAllBuyersBusinessOutlets() {
      vm.priceUpdateFilter.buyerIdList = [];
      if (!vm.priceUpdateFilter.selectedBuyer) {
        return false;
      }
      var params = {
        page: 0,
        size: paginationConstants.maxLimit,
        groupBy: 'buyer'
      };
      vm.priceUpdateFilter.buyerIdList[0] = vm.priceUpdateFilter.selectedBuyer.id;
      BuyerBusinessService.getAllBuyersBusinessOutlets(vm.priceUpdateFilter.buyerIdList, params).then(function(response) {
        vm.buyerBusinessOutlets = response.data;
        vm.priceUpdateFilter.businessList = [];
      }, function(error) {
      });
    }

    /**
     * [proceedToPriceUpdate Proceed to item selection page]
     */
    function proceedToPriceUpdate() {
      if(!vm.priceUpdateList){
        vm.buyerList.push(vm.priceUpdateFilter.selectedBuyer.companyName);
        vm.checkForContractedSupplier(vm.buyerList,'FORM');
      }else{
        //Save price update filter
        SupplierItemPriceUpdateService.setPriceUpdateFilter(vm.priceUpdateFilter);
        $state.go('supplier.items.price-update.item-selection');
      }
    }

    /**
     * [priceUpdateCSVUpload Price update using CSV]
     */
    function priceUpdateCSVUpload() {
      vm.errorList = null;
      var csvFile = vm.files[0];
      //vm.recordError.responseList = [];
      //If no CSV selected show error
      if(!csvFile){
        toastr.error('Please upload a .csv file!', 'CSV File Required');
        return false;
      }

      //If CSV selected convert that to base64
      CsvUploadService.getFileBase64(csvFile.lfFile, function(base64CSV) {
        //Upload price update CSV
        SupplierItemPriceUpdateService.priceUpdateCSVUpload(base64CSV.split(',')[1])
        .then(function(response) {
          vm.files = [];
          vm.priceUpdateItems = [];
          NotificationService.success({
            title: 'Price Update Request',
            message: 'alertMessage.SENT_SUCCESSFULLY'
          });
        }, function(error) {
          vm.files = [];
          vm.recordError = error.data;
          NotificationService.error({
            title: 'Price Update Request',
            error: error
          });
        });
      });

    }

    /**
     * [previewPriceUpdateCSVUpload Preview of Price Update using CSV]

     */
    function previewPriceUpdateCSVUpload(){
      var csvFile = vm.files[0];
      vm.isCsvFile = CsvUploadService.checkFileType(csvFile.lfFile);
      if (vm.isCsvFile) {
        UtilService.csvToJson(csvFile.lfDataUrl)
        .then(function (response) {
          vm.priceUpdateItems = response;
          var buyerList=_.map(vm.priceUpdateItems,_ .property('buyerCompanyName'));
          vm.checkForContractedSupplier(buyerList,'CSV');          
        });
      }
    }

    /**
     * [clearCSVUpload resets the file upload and the preview table
     */
    function clearCSVUpload(){
      vm.files=[];
      vm.priceUpdateItems=[];
      vm.recordError=null;
      vm.contractedBuyers=[];
      vm.priceUpdateList=false;
    }

    /**
     * checkForContractedSupplier
     */
    function checkForContractedSupplier(buyerList,choice){
      vm.isContractedCSV=false;
      vm.contractedBuyers=[];
      ManageSupplierService.isContractedSupplierForBuyer(buyerList,choice).then(function(response){
        vm.contractedBuyers=response.data;
          if(choice==='CSV'){
            vm.isCSV=true;
          }else if(choice==='FORM' && vm.contractedBuyers.data.length>0){
            vm.isContracted=true;
          }
          else if(choice==='FORM'){
            vm.priceUpdateList=true;
            if(vm.priceUpdateFilter.itemType === 'BUYER') {
                 vm.priceUpdateFilter.buyerIdList = [vm.priceUpdateFilter.selectedBuyer.id];
                vm.priceUpdateFilter.businessIdList = _.map(vm.priceUpdateFilter.businessList, 'id');
            }
            else {
              vm.priceUpdateFilter.approvalRequiredFromBuyer = false;
            }
            vm.proceedToPriceUpdate();
          }
      });
    }
  }
})();

