/**
 * @Author: Ajay
 * @Date:   03-Mar-2017 15:12 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.dashboard.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 10-Jan-2019 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerDashboardController', BuyerDashboardController);

  BuyerDashboardController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$filter', '$mdDialog', 'BuyerDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService', '$mdMedia', 'Auth', 'SatsManagementService','BuyerDataService'];

  function BuyerDashboardController($rootScope, $scope, $state, $stateParams, $filter, $mdDialog, BuyerDashboardService, GLOBAL_CONSTANTS, NotificationService, $mdMedia, Auth, SatsManagementService, BuyerDataService) {

    var vm = this;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.getBuyerDashboardCount = getBuyerDashboardCount;
    vm.getBuyerDashboardApprovals = getBuyerDashboardApprovals;
    vm.uobBizPlusUrl = GLOBAL_CONSTANTS.UOB_BIZPLUS_URL;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.isMobile = $mdMedia('xs');
    vm.accessForPromotion = Auth.hasPermission('accessForPromotion');
    vm.accessForSATS = Auth.hasPermission('accessForSATS');
    vm.accessForStockTransfer = Auth.hasPermission('accessForStockTransfer');
    vm.getBuyerDashboardPendingJobsCount = getBuyerDashboardPendingJobsCount;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.getDashboardCount = getDashboardCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;
    vm.accessForEzyinventory = BuyerDataService.getBuyerProfile().accessDTO.accessForEzyinventory;
   vm.initialiseCarousal = initialiseCarousal;
    /**
     * [getBuyerDashboardPendingJobsCount gets the count of Processed jobs count]
     */
    function getBuyerDashboardPendingJobsCount(){
      SatsManagementService.getPendingJobsDashboardCount()
      .then(function(response){
        vm.buyerPendingJobsCount = response.data.count;
      },function(error){
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initialiseCarousal(){
      var myCarousel = document.querySelector('#myCarousel');
      var carousel = $(myCarousel).carousel();
    }
    /** force refresh counts */
    function refreshDashboardCounts() {
      getDashboardCount();
      initializeIntervalCount();
      initialiseCarousal();
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    /** get dashboard count */
    function getDashboardCount() {
      if(eval(vm.tabIndex)) {
        getBuyerDashboardApprovals();
      }
      else {
        getBuyerDashboardCount();
      }
      getCarousalImg();
    }

    function getCarousalImg(){
      BuyerDashboardService.getCarousalDetails()
      .then(function(response){
        vm.carousalImgList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }
    function getBuyerDashboardCount() {
      if(vm.tabIndex != 0)
        return true;
      BuyerDashboardService.getBuyerDashboardCount().then(function(response) {
        vm.buyerDashboardCount = response.data;
        $rootScope.$broadcast('refresh-cart-count',response.data.cartCount);
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Dashboard',
          error: error
        });
      });
    }

    function getBuyerDashboardApprovals() {
      if(vm.tabIndex != 1)
        return true;
      BuyerDashboardService.getBuyerDashboardApprovals().then(function(response) {
        vm.buyerDashboardCount = {
          pendingApprovalsCount: response.data.pendingApprovalsCount
        };
        vm.buyerApprovals = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Buyer Dashboard Approvals',
          error: error
        });
      });
    }

    getDashboardCount();

    initializeIntervalCount();
    initialiseCarousal();
    
    $rootScope.$on('language-update', function(event, key) {
      vm.currentLangKey = key;
    });
    $rootScope.$on('buyer-profile-update', function(event, key) {
      vm.currentLangKey = key.buyerUser.user.langKey;
    });

  }
})();
