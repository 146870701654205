/**
 * @Author: Ajay
 * @Date:   28-Jul-2017 15:53 +05:30
 * @Project: Ezyprocure
 * @Filename: login.controller.js
 * @Last modified by:   Allan
 * @Last modified time: 07-Aug-2017 15:19 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ActivateController', ActivateController);

  ActivateController.$inject = ['Auth', '$stateParams','$state','$window','API_CONSTANTS'];

  function ActivateController (Auth, $stateParams,  $state,$window,API_CONSTANTS) {
    var vm = this;
    vm.initLanding = initLanding;

    function initLanding() {
     Auth.activateAccount($stateParams.key);
      if($stateParams.origin == "EZY_SOURCE"){
        $window.location.href = API_CONSTANTS.ezySourceBase + '#/login' ;
      }
      else if($stateParams.origin == "EZY_CROSSBORDER"){
        Auth.activateEzyprocureAccount($stateParams.key);
        $window.location.href = API_CONSTANTS.cbBase + '#/login' ;
      }
      else{
        Auth.activateEzyprocureAccount($stateParams.key);
        $window.location.href = API_CONSTANTS.ezyProcureBase + '#/login' ;
      }
    }

  }
})();
