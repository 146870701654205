/**
 * @Author: Ajay
 * @Date:   05-Apr-2017 14:41 +05:30
 * @Filename: resource-url.constants.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-02-19T15:41:42+05:30
 */



/**
* Please add in aplhabetic order of keys
*/

"use strict";
angular.module('ezyprocureApp')
.constant('RESOURCE_URL_CONSTANTS', {
  "IMPLEMENTATION_NOTE": "implementation-note/",
  "RELEASE_NOTES": "release-notes/",
  "AUDIT_LOGS_ALL": "auditlogs-all/",
  "ADHOC_PO": "adhoc-po/",
  "BANK_RESOURCE": "banks/",
  "BRANDS": "brands/",
  "BRAND_SUPPLIER_MAPPING_RESOURCE": "brand-supplier-mappings/",
  "BRAND_SUPPLIER_PAYMENT_MODEL_MAPPING_RESOURCE": "brand-supplier-payment-model-mappings/",
  "BRAND_BUYER_USER_MAPPINGS": "brand-buyer-user-mappings/",
  "BUSINESS_OUTLETS_RESOURCE": "business-outlets/",
  "BUSINESS_SUPPLIER_MAPPING_RESOURCE": "business-supplier-mappings/",
  "BUSINESS_REPORTS_RESOURCE": "business-reports/",
  "BUYER_SUPPLIER_MAPPINGS_RESOURCE": "buyer-supplier-mappings/",
  "BUSINESS_USER_MAPPING_RESOURCE": "business-user-mappings/",
  "BUYER_BUSINESS_RESOURCE": "buyer-business/",
  "BUYER_USER_RESOURCE": "buyer-users/",
  "CALENDAR_RESOURCE": "supplier-calendars/",
  "EPD_APPROVAL_REQUESTS": "epd-approval-requests/",
  "EXTERNAL_PAYMENT":"external-payment/",
  "GL_CODE_MAPPING_RESOURCE": "gl-code-mappings/",
  "GL_CODE_RESOURCE": "gl-codes/",
  "GL_SPECIFIC_ITEMGROUP_RESOURCE": "gl-specific-item-groups/",
  "INVOICE_RESOURCE": "invoices/",
  "INVITEBUYERSUPPLIER":"invites",
  "ITEMGROUP_RESOURCE": "item-groups/",
  "ITEM_BUSINESS_MAPPING":"item-business-mapping",
  "ITEM_BUYER_MAPPINGS": "item-buyer-mappings/",
  "LABEL_PRINTING": "label-printing/",
  "MAIN_ITEMGROUP_RESOURCE": "main-item-groups/",
  "MANAGE_EMAILS": "sgebiz-mail-ids/",
  "MPGS_CARDS": "mpgs/cards/",
  "MPGS_CUSTOM_CARDS": "customs/cards/brand",
  "MERCHANT_DETAILS":"merchant-details/",
  "PAYMENT": "payment/",
  "PRICE_UPDATES_RESOURCE": "price-updates/",
  "PROCESS_ORDER_RESOURCE": "process-orders/",
  "PROMOTION_RESOURCE": "promotions/",
  "PURCHASE_APPROVAL_REQUESTS_RESOURCE": "item-approval-requests/",
  "PAYMENT_APPROVAL_REQUESTS_RESOURCE": "payment-approval-requests/",
  "PURCHASE_ORDER_RESOURCE": "purchase-orders/",
  "PURCHASE_ORDER_TYPES_RESOURCE": "purchase-order-types/",
  "SATS":"sats/",
  "SFTP_SERVERS": "sftp-servers/",
  "SUPPLIER_RESOURCE": "suppliers/",
  "SUPPLIER_USER_BUSINESS_MAPPING_RESOURCE": "supplier-user-business-mappings/",
  "SUPPLIER_USER_RESOURCE": "supplier-users/",
  "TRANSFER_REQUEST":"transfer-request/",
  "UOM_RESOURCE": "uoms/",
  "USER_ACCESS_RESOURCE": "user-access/",
  "XERO_RESOURCE" : "redirect/",
  "ORDER_TEMPLATE_USER_MAPPING_RESOURCE":"order-template-user-mappings",
  "ORDER_TEMPLATES":'order-templates',
  "PEPPOL_SERVICE":"peppolService/",
  "JOB_RESOURCE":"job/",
  "EZY_COMPANY_DETAILS_RESOURCE":"/ezy-company-details",
  "BUSINESS_KEY_MAPPING_RESOURCE":"/business-key-mappings",
  "DELIVERY_CONDITIONS_RESOURCE":"delivery-conditions/",
  "BUYER_DELIVERY_CONDITIONS":"buyer-delivery-conditions/",
  "FETCH_PLANS":"plans/country/v2/",
  "FETCH_USER_PLANS":"user-plans/user/",
  "SELECT_PLANS": "user-plans/multiple",
  "PAYMENT_INFOS":"payment-infos",
  "LSBS_VALIDATE_USER": "lsbs/alexworld/validate",
  "APPLY_PROMO": "promo-codes/new/code/",
  "GETADHOCSUPPLIERUSER":"buyer-supplier-mappings/supplier/invite/",
  "LISTCARDS": "user-plans/default-and-saved/userId/",
  "DEFAULTCARDS": "user-plans/update-default-card",
  "DELETECARDS": "user-plans/delete-card/tokenId",
  "GET_MERCHANTID": "master-merchant?countryCode=",
  "POST_CARD_DETAILS": "mpgs/cards/tokenize/user/",
  "MY_PAST_PLANS":"user-plans/pastplans",
  "FUTURECURRENTPLANS":"user-plans/user_plan_listing?",
  "MAPSUPPLIER":"buyer-supplier-mappings/",
  "PLANS": "plans",
  "CURRENCYLIST": "buyer-supplier-currencies/list-all-currencies",
  "CURRENCYMAPPED": "buyer-supplier-currencies/buyer/",
  "INVITEDUSERS":"invites/invitedlist",
  "SUPPLIER": "/supplier/",
  "BUYERCOMPANY":"users/validate-buyerSignup/company/",
  "SUPPLIERCOMPANY":"users/validate-supplierSignup/company/",
  "TRANSACTIONAL_CURRENCIES": "transactional-currencies",
  "TRANSACTIONAL_CURRENCIES_UPDATE": "transactional-currencies/update/",
  "TRANSACTIONAL_CURRENCIES_DELETE": "transactional-currencies/delete/",
  "RESENDEMAIL":"invites/resendinvite",
  "MAPPEDUSERS":"buyer-supplier-mappings/buyer/",
  "EXISTINGUSERS":"buyer-supplier-mappings/allocatedlist",
  "EXISTINGMAPPEDUSERS":"buyer-supplier-mappings/getNotAllocatedList",
  "EXISTINGMAPPEDSUPPLIERS":"buyer-supplier-mappings/supplier",
  "VERIFYLOGIN": "users/verify-first-login",
  "UPDATELOGIN": "users/update-first-login",
  "CHECK_LIMIT_REACHED": "invoice/checkLimitReached",
  "TRANSANCTION_PAYMENT": "invoice/transactionPlanPayment",
  "PROMOCODES": "promo-codes",
  "FETCHPROMOCODES": "filter/promocodes",
  "FILTER": "filter",
  "SUBSCRIPTIONPLANS": "/subscriptionplans",
  "USER_KYC_STATUS": "user-kycs/send-email/",
  "USER_KYC_UPLOAD_STATUS": "user-kycs/update-kyc-status",
  "BUYER_GETBUYER":"buyers/buyer/",
  "BUYER_GETBUYERDETAILS":"buyers/",
  "ENABLEDDISABLEDBUYERSUPPLIER":"buyer-supplier-mappings/buyer/",
  "OUTLET_COUNT": 'buyers/outlet/count/',
  "OUTLETS_AMOUNT":"user-plans/update/outlets",
  "UPDATE_OUTLETS":"user-plans/update/plan/outlets",
  "JSON_DATA":"content/json-data/",
  "NOTIFICATION_SETTINGS": "user_notification_settings/"
});
