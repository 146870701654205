/**
* @Author: Ajay
* @Date:   21-Mar-2017 19:15 +05:30
* @Project: Ezyprocure
* @Filename: manage-buyers.list.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:16 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageBuyerListController', ManageBuyerListController);

  ManageBuyerListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'Auth', 'ManageBuyerService', 'pagingParams', 'paginationConstants'];

  function ManageBuyerListController($scope, $rootScope, $state, NotificationService, $mdDialog, Auth, ManageBuyerService, pagingParams, paginationConstants) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.loadAll = loadAll;
    vm.userRole = Auth.getUserRole();

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      ManageBuyerService.getAllAdminBuyersWithUserDetails({
        page: vm.query.page - 1,
        size: vm.query.limit,
        search: vm.searchText,
        sort: sort(),
        isAdminDashboard:vm.userRole.ugly==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.buyers = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'Admin Buyer Listing',
          error: error
        });
      });
    }

    vm.loadAll();

    function deleteAdminBuyer(buyerId) {
      ManageBuyerService.deleteAdminBuyer(buyerId).then(function(response) {
        NotificationService.success({
          title: 'Admin Buyer',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Admin Buyer Deletion',
          error: error
        });
      });
    }

    vm.showConfirm = function(ev, buyer) {
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to delete Admin Buyer?')
      .textContent('This action could not be reverted back.')
      .targetEvent(ev)
      .ok('Delete')
      .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAdminBuyer(buyer.id);
      }, function() {});
    };

  }
})();
