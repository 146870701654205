/**
* @Author: Ajay
* @Date:   19-Apr-2017 16:02 +05:30
* @Project: Ezyprocure
* @Filename: invoice.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-25T10:28:34+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('InvoiceService', InvoiceService);

  InvoiceService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];
  function InvoiceService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var invoiceService = {
      getAllInvoices: getAllInvoices,
      getAllInvoicesForAdmin: getAllInvoicesForAdmin,
      getInvoice: getInvoice,
      createInvoice: createInvoice,
      updateInvoice: updateInvoice,
      deleteInvoice: deleteInvoice,
      getConsolidatedInvoiceFilters: getConsolidatedInvoiceFilters,
      setConsolidatedInvoiceFilters: setConsolidatedInvoiceFilters,
      getConsolidatedInvoiceDetails: getConsolidatedInvoiceDetails,
      getConsolidatedInvoiceMetaData: getConsolidatedInvoiceMetaData,
      massInvoiceGeneration:massInvoiceGeneration,
      getSupplierInvoiceQuickBooksExportData:getSupplierInvoiceQuickBooksExportData,
      updateExportedInvoiceStatus: updateExportedInvoiceStatus,
      getInvoiceIds: getInvoiceIds,
      updateReportExportedFromList: updateReportExportedFromList,
      getExportedPOIds: getExportedPOIds,
      updateExportedPOStatus: updateExportedPOStatus,
      exportToEzyfinance: exportToEzyfinance
    };

    var consolidatedInvoiceFilters = null;

    return invoiceService;

   // Export to ezy2finance
    function exportToEzyfinance(poDto){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.ezyfinanceUrl + 'api/' + 'push/inv',
        method: "POST",
        headers:{
          'externalApi':'EZPSG'
        },
        data:poDto,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /* --  Invoice CRUD operations  -- */

    //Get All  invoices created by currently logged in user
    function getAllInvoices(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE,
        method: "GET",
        params:urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get All  invoices created for admin
    function getAllInvoicesForAdmin(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE +'all',
        method: "GET",
        params:urlParams,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get invoice details using invoice id
    function getInvoice(invoiceId) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE + invoiceId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Create a new  invoice
    function createInvoice(invoice) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE, invoice).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Update  invoice
    function updateInvoice(invoice) {
      var deferred = $q.defer();
      $http.put(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE, invoice).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Soft Delete  invoice using invoiceId
    function deleteInvoice(invoiceId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE + invoiceId).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getConsolidatedInvoiceDetails gets consolidated PO Details]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getConsolidatedInvoiceDetails(data) {
      var params = {
          page: data.page,
          size: data.size
      }
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE +'consolidated',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getConsolidatedInvoiceMetaData gets consolidated Invoice Details]
     * @param  {[type]} params [filter params]
     * @return {[object]}        [success/error message]
     */
    function getConsolidatedInvoiceMetaData(data) {
      var params = {
          page: data.page,
          size: data.size
      }
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE +'consolidated/metadata',
        method: "POST",
        data: data,
        params: params
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [massInvoiceGeneration - Generates mass invoices]
     * @param {*} acceptOrderDTO
     */
    function massInvoiceGeneration(acceptOrderDTO){
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE +'bulk', acceptOrderDTO)
        .then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Return consolidated Invoice Filters
    function getConsolidatedInvoiceFilters() {
      return consolidatedInvoiceFilters ? consolidatedInvoiceFilters : null;
    }

    // store consolidated Invoice Filters
    function setConsolidatedInvoiceFilters(filter) {
      consolidatedInvoiceFilters = filter;
    }

    /**
     * [getSupplierInvoiceQuickBooksExportData export supplier invoice]
     * @param  {[type]} data [invoice id list]
     * @return {[List]}      [data to be exported]
     */
    function getSupplierInvoiceQuickBooksExportData(data) {
      var deferred = $q.defer();
      $http({
        url: 'api/' + RESOURCE_URL_CONSTANTS.INVOICE_RESOURCE + 'export/quick-books',
        method: "POST",
        data: data
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
/**
 * [updateExportedInvoiceStatus update exported invoice statuses]
 * @param  {[type]} params        [report type]
 * @param  {[type]} invoiceIdList [invoice id list from exported data obtained from API]
 * @return {[type]}               [description]
 */
    function updateExportedInvoiceStatus(params,invoiceIdList){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'export-invoices',
        method: "POST",
        params: params,
        data: invoiceIdList,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

/**
 * [updateExportedPOStatus description]
 * @param  {[type]} params        [export type]
 * @param  {[type]} poIdList      [po id list for flag update]
 * @return {[type]}               [description]
 */
    function updateExportedPOStatus(params,poIdList){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'purchase-exports',
        method: "POST",
        params: params,
        data: poIdList,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
    * [getInvoiceIds get invoice id list from invoice export data]
    * @param  {[type]} exportedPOs [exportedPOs for export]
    * @return {[type]}             [description]
    */
    function getInvoiceIds(exportedPOs) {
      var invoiceIdList = _.uniq(_.map(exportedPOs, _.property('purchaseOrder.invoice.id')));
      return invoiceIdList;
    }

    /**
    * [getExportedPOIds get po id list from invoice export data]
    * @param  {[type]} exportedPOs [exportedPOs list]
    * @return {[type]}             [description]
    */
    function getExportedPOIds(exportedPOs) {
      var poIdList = _.uniq(_.map(exportedPOs, _.property('purchaseOrderId')));
      return poIdList;
    }

    /**
    * [updateReportExportedFromList Update the status in po item list using invoice id list]
    * @param  {[type]} invoiceIdList [List of invoice order ids]
    * @return {[type]}                     [description]
    */
    function updateReportExportedFromList(poList, idList, reportType, exportType) {
      switch(exportType){
        case 'JURNAL':
        _.forEach(poList, function(po, key) {
          if(_.includes(idList, po.purchaseOrder.id)){
            if(reportType === 'PURCHASE'){
              poList[key].purchaseJurnalExported = true;
            }
            if(reportType === 'SALES'){
              poList[key].salesJurnalExported = true;
            }
          }
        });
        break;
        case 'OTHERS':
        _.forEach(poList, function(po, key) {
          if(po.purchaseOrder.invoice){
          if(_.includes(idList, po.purchaseOrder.invoice.id)){
            if(reportType === 'SALES'){
              poList[key].supplierQuickBooksExported = true;
            }
          }
          }
        });
        break;
      }

      return poList;
    }

  }
})();
