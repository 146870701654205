/**
* @Author: Ajay
* @Date:   27-Mar-2017 13:16 +05:30
* @Project: Ezyprocure
* @Filename: manage-buyers.edit.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 06-Nov-2017 14:54 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageBuyerEditController', ManageBuyerEditController);

  ManageBuyerEditController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Auth', 'ManageBuyerService', 'ImageUploadService', 'GLOBAL_CONSTANTS', 'NotificationService', 'EzyCompanyService', '$mdDialog'];

  function ManageBuyerEditController($scope, $rootScope, $state, $stateParams, Auth, ManageBuyerService, ImageUploadService, GLOBAL_CONSTANTS, NotificationService, EzyCompanyService, $mdDialog) {
    var vm = this;

    vm.buyerId = $stateParams.buyerId;
    vm.save = save;
    vm.updateBuyerActivatedKey = updateBuyerActivatedKey;
    vm.getBuyerData = getBuyerData;
    vm.setCompanyLogo = setCompanyLogo;
    vm.uploadLogo = uploadLogo;
    vm.userRole = Auth.getUserRole();
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.updateIdentifier=updateIdentifier;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getIdentifier=getIdentifier;
    vm.createIdentifier=createIdentifier;
    vm.isEzyapActivate = false;
    vm.init = init;
    vm.deleteIsoCodeAndIdentifier = deleteIsoCodeAndIdentifier;
    vm.initiateSMPRegistration = initiateSMPRegistration;
    vm.showPopup = showPopup;
    vm.cancel = cancel;
    vm.checkStatus = checkStatus;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;
    vm.buyerAccountTypes = GLOBAL_CONSTANTS.BUYER_VISIBILITY_TYPES;
    vm.init();

    function save() {
      if((vm.buyer.accessForPEPPOL || vm.userRole.pretty == 'admin') && vm.ezyCompanyDetails){
        if(vm.ezyCompanyDetailsId){
          vm.ezyCompanyDetails.isoCode == '' && vm.ezyCompanyDetails.identifier == '' ? vm.deleteIsoCodeAndIdentifier() : vm.updateIdentifier();
        }
        else{
          vm.createIdentifier();
        }
     }
      // Call Admin supplier service with supplier object as parameter to update
      ManageBuyerService.updateAdminBuyer(vm.buyer).then(function(response) {
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Buyer',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        $state.go(vm.userRole.pretty + '.buyers', {
          reload: false
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Buyer Updation',
          error: error
        });
      });
    }

    function updateBuyerActivatedKey(buyerId, isActivate) {
      ManageBuyerService.updateBuyerActivatedKey(buyerId, isActivate).then(function(response) {
        vm.buyer.user.activated = response.data.user.activated;
        $scope.$emit('ezyprocureApp:supplierUpdate', response);
        NotificationService.success({
          title: 'Admin Buyer',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      }, function(error) {
        NotificationService.error({
          title: 'Admin Buyer Updation',
          error: error
        });
      });
    }

    /**
     * [getIdentifier Gets the identifier details]
     */
    function getIdentifier() {
      EzyCompanyService.getIdentifier(vm.buyerId,'ROLE_BUYER').then(function(response) {
       vm.ezyCompanyDetails=response.data;
       vm.ezyCompanyDetailsId=vm.ezyCompanyDetails.id;
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    /**
     * [updateIdentifier Updates the identifier details]
     */
    function updateIdentifier() {
      EzyCompanyService.updateIdentifier(
        {ezpCompanyId: vm.ezyCompanyDetails.ezpCompanyId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         grBuffer:vm.ezyCompanyDetails.grBuffer,
         userRole:'ROLE_BUYER',
         id:vm.ezyCompanyDetails.id}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    //delete iso code and identifier
    function deleteIsoCodeAndIdentifier(){
      EzyCompanyService.deleteIsoCodeAndIdentfier(vm.ezyCompanyDetailsId).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){
      });
    }

    /**
     * [createIdentifier Creates the Identifier details]
     */
    function createIdentifier() {
      EzyCompanyService.createIdentifier(
        {ezpCompanyId:vm.buyerId,
         identifier:vm.ezyCompanyDetails.identifier,
         isoCode:vm.ezyCompanyDetails.isoCode,
         grBuffer:vm.ezyCompanyDetails.grBuffer,
         userRole:'ROLE_BUYER',
         id:''}).then(function(response) {
      }, function(error) {
        NotificationService.simpleErrorToast({
          title: 'Admin Buyer Updation',
          message: error.data.message
        });
      });
    }

    function getBuyerData() {
      ManageBuyerService.getAdminBuyer(vm.buyerId).
      then(function(response) {
        if (response) {
          vm.buyer = response;
          vm.companyCodeExists = vm.buyer.companyCode ? true : false;
          if(vm.buyer.accessForPEPPOL || vm.userRole.pretty == 'admin'){
            vm.getIdentifier();
           }
        }
      }, function(error) {
      });
    }

    function init(){
      vm.getBuyerData();
    }

    /**IMAGE UPLOAD**/

    function setCompanyLogo(flowFile) {
      ImageUploadService.getImageBase64(flowFile.file, function(base64Img) {
        vm.uploadLogo(base64Img);
      });
    }

    function uploadLogo(base64Img) {
      vm.uploadingInProgress = true;
      ImageUploadService.uploadImage(base64Img).
      then(function(responseData) {
        vm.buyer.companyLogo = responseData.url;
        $scope.editBuyerForm.$pristine = false;
        NotificationService.success({
          title: 'Buyer Logo uploaded successfully',
          message: 'Please update to save changes!'
        });
        vm.uploadingInProgress = false;
      }, function(error) {
        vm.uploadingInProgress = false;
        NotificationService.error({
          title: 'Buyer Logo Updation',
          error: error
        });
      });
    }

    function showPopup(event) {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/smp-participant-edit.popup.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function cancel(){
      $mdDialog.cancel();
    }

    function initiateSMPRegistration(){
      vm.cancel();
      var data = {
        identifierValue: vm.buyer.companyRegistrationNumber,
        additionalInformation: vm.additionalInformation ? vm.additionalInformation : '',
        contactEmail: vm.buyer.user.email,
        contactName: vm.buyer.contactPersonName ? vm.buyer.contactPersonName : '',
        phoneNumber: vm.buyer.phone ? vm.buyer.phone : '',
        contactType: 'Primary',
        countryCode: vm.buyer.user.userCountry,
        geographicalInformation: vm.geographicalInformation ? vm.geographicalInformation :'',
        language: vm.buyer.user.langKey,
        userName: vm.buyer.user.firstName + ' ' + vm.buyer.user.lastName,
        websiteUri: vm.websiteUri ? vm.websiteUri : '',
        pdActivation: true,
        smlActivation: true,
        userId:vm.buyer.user.id ,
        role: 'ROLE_BUYER',
        companyName: vm.buyer.companyName,
        companyId: vm.buyer.id
      }
      EzyCompanyService.initiateSMPRegistration(data).then(function(response){
        NotificationService.success({
          title: 'Initiate SMP Registration',
          message: 'Success'
        });
        vm.ezyCompanyDetails.status = 'Success';
        vm.checkStatus();
      },
      function(error){
        NotificationService.error({
          title: 'Initiate SMP Registration',
          error: error
        });
      })
    }

    function checkStatus(){
      var status = ['FAILED',null];
      return _.includes(status,vm.ezyCompanyDetails.status);
    }
  }
})();
