/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.due-payment-summary.state.js
* @Last modified by:   Allan
* @Last modified time: 27-August-2018 19:03 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
    .state('ezypayment.buyer.due-payment-summary', {
      parent: 'ezypayment.buyer',
      url: '/due-payment-summary',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/due-payment-summary/ezypayment.due-payment-summary.html',
          controller: 'EzypaymentDuePaymentSummaryController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          $translatePartialLoader.addPart('purchaseOrder');
          $translatePartialLoader.addPart('invoiceOCR');
          $translatePartialLoader.addPart('externalPayment');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.due-payment-summary-cust', {
        parent: 'ezypayment.buyer',
        url: '/due-payment-summary/:isCustom',
        data: {
          authorities: ['ROLE_BUYER']
        },
        views: {
          'content@': {
            templateUrl: 'app/ezypayment-modules/buyer-modules/due-payment-summary/ezypayment.due-payment-summary.html',
            controller: 'EzypaymentDuePaymentSummaryController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
            return true;
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('purchaseOrder');
            $translatePartialLoader.addPart('invoiceOCR');
            $translatePartialLoader.addPart('externalPayment');
            return $translate.refresh();
          }]
        }
      })
    .state('ezypayment.buyer.due-payment-summary.details', {
      parent: 'ezypayment.buyer.due-payment-summary',
      url: '/details',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/due-payment-summary/details/ezypayment.due-payment-summary.details.html',
          controller: 'EzypaymentDuePaymentSummaryDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
    .state('ezypayment.buyer.due-payment-summary.details.transaction-report', {
      parent: 'ezypayment.buyer.due-payment-summary',
      url: '/details/transaction-report',
      data: {
        authorities: ['ROLE_BUYER']
      },
      views: {
        'content@': {
          templateUrl: 'app/ezypayment-modules/buyer-modules/due-payment-summary/details/ezypayment.due-payment-summary.details.transaction-report.html',
          controller: 'EzypaymentDuePaymentSummaryDetailsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
          return true;
        }],
        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
          $translatePartialLoader.addPart('global');
          return $translate.refresh();
        }]
      }
    })
  }
})();
