/**
* @Author: Ajay
* @Date:   27-May-2017 11:05 +05:30
* @Project: Ezyprocure
* @Filename: buyer.purchase-order.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T10:50:41+05:30
*/


(function() {

  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPurchaseOrderController', BuyerPurchaseOrderController);

  BuyerPurchaseOrderController.$inject = ['$window','$scope','$translate', '$location','$rootScope', '$q', '$filter', '$state', '$stateParams', 'toastr', '$mdDialog','pagingParams', 'BuyerDataService', 'NotificationService', 'UTCDateService', 'BuyerBusinessService','PurchaseOrderService', 'BuyerSupplierMappingService', 'ItemGroupService', 'GLOBAL_CONSTANTS', 'paginationConstants', 'BuyerBusinessUserMapService', 'BuyerBuyNowService', 'CSVExportService', 'EXPORT_CONSTANTS','CallbackResourceService','XeroResourceService', 'GLOBAL_CONFIG_CONSTANTS', 'Auth'];

  function BuyerPurchaseOrderController($window,$scope,$translate, $location,$rootScope,$q ,$filter, $state, $stateParams, toastr, $mdDialog, pagingParams, BuyerDataService, NotificationService, UTCDateService, BuyerBusinessService, PurchaseOrderService, BuyerSupplierMappingService, ItemGroupService, GLOBAL_CONSTANTS, paginationConstants, BuyerBusinessUserMapService, BuyerBuyNowService, CSVExportService, EXPORT_CONSTANTS,CallbackResourceService,XeroResourceService, GLOBAL_CONFIG_CONSTANTS, Auth) {
    var vm = this;
    vm.predicate =  JSON.parse(localStorage.getItem("purchase-order-predicate")) || pagingParams.predicate;
    vm.reverse =  JSON.parse(localStorage.getItem("purchase-order-reverse")) || pagingParams.ascending;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.prevSelectedOutletId= 0;
    vm.loadItemGroups = loadItemGroups;
    vm.loadAll = loadAll;
    vm.todaysDate = new Date();
    vm.poStatuses = GLOBAL_CONSTANTS.PO_STATUSES;
    vm.listViews = GLOBAL_CONSTANTS.LIST_VIEWS;
    vm.selectAlll = selectAlll;
    vm.deselectAll = deselectAll;
    vm.supplierVisibilityTypes = GLOBAL_CONSTANTS.SUPPLIER_VISIBILITY_TYPES_BUYER;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.query = JSON.parse(localStorage.getItem("purchase-order-paginate")) || {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };

    vm.limitOptions = paginationConstants.limitOptions;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    //CSV Header
    vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderListingHeader;

    // XERO
    vm.connectToXero = connectToXero;
    vm.disconnectToXero = disconnectToXero;
    vm.exportToXero = exportToXero;
    vm.getTokenExpiry = getTokenExpiry;
    vm.getOraganization = getOraganization;
    vm.closeModal = closeModal;
    vm.fetchExportToXeroList = fetchExportToXeroList;
    vm.checkAll = checkAll;
    vm.getSelectedPOs = getSelectedPOs;
    vm.getSelectedPOIds = getSelectedPOIds;
    vm.validateExportXero = validateExportXero;
    vm.updateXeroExportFlag = updateXeroExportFlag;
    vm.selectAll = false;
    vm.getAllExportPurchaseOrderDetails = getAllExportPurchaseOrderDetails;
    vm.navigateToConsolidatedView = navigateToConsolidatedView;
    //CSV Function Declaration
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getSelectedPONumbers = getSelectedPONumbers;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT
    //filter reset
    vm.resetFilters = resetFilters;
    vm.exportToEzyfinance = exportToEzyfinance;
    vm.filter =  JSON.parse(localStorage.getItem("purchase-order-filter")) || {
        poFromDate: vm.todaysDate,
        poToDate: vm.todaysDate,
        listView: vm.listViews[0],
        supplierVisibilityType: vm.supplierVisibilityTypes[0],
        poStatus: vm.poStatuses[0]
    };
    vm.init = init();

    function init() {
        if(vm.filter.businessOutletId) {
            vm.getAllBuyerBusinessOutlets();
          }
          if(vm.filter.supplierId) {
            vm.getAllAllocatedSuppliersForBusiness();
          }

          if(vm.filter.itemGroupId) {
            vm.loadItemGroups();
          }
          vm.loadAll();
    }
    function selectAlll(dropdown) {
        switch (dropdown) {
            case 'OUTLETS':
              vm.filter.businessOutletId = _.map(vm.businessOutlets, _.property('id'));
              break;
        }
    }

    function deselectAll(dropdown) {
        switch (dropdown) {
        case 'OUTLETS':
          vm.filter.businessOutletId = [];
          break;
        }
    }
    function exportToEzyfinance(){
      var selectedPos = [];
      var checkSelectedPos = [];
      var poNumList = [];
      var data = {};
      vm.errorList = [];
      selectedPos = getSelectedPOs(vm.poItems);
      if(!selectedPos || selectedPos.length == 0){
        toastr.error($translate.instant('alertMessage.PLEASE_SELECT_ATLEAST_ONE_RECORD'), 'Export To Ezy2Finance - Failed!');
        return false;
      }
      checkSelectedPos = _.filter(selectedPos, function(po){
        return po.purchaseOrder.purchaseOrderStatus != 'PROCESSED' && po.purchaseOrder.purchaseOrderStatus != 'PENDING';
      });
      if(checkSelectedPos.length != 0){
        toastr.error($translate.instant('alertMessage.PURCHASE_ORDER_STATUS_SHOULD_BE_PENDING_OR_PROCESSED'), 'Please Check Selected PO Status!');
        return false;
      }
      _.forEach(selectedPos, function(po){
        data = {
          "key":po.purchaseOrder.purchaseOrderNumber,
          "value":po.purchaseOrder.processOrder.deliveryDate
        }
        poNumList.push(data);
      });
      PurchaseOrderService.exportToEzyfinance(poNumList).then(function(response){
        vm.errorList = _.filter(response.data, function(each){
          return each.status == 'FAILED';
        })
        if(!vm.errorList){
          NotificationService.success({
            title: 'Export To Ezy2Finance',
            message: 'success'
          });
        }
        else if(vm.errorList.length == 1){
          toastr.error(vm.errorList[0].message, 'Export To Ezy2Finance - Failed');
        }
      },
      function(error){
        NotificationService.simpleErrorToast({
          title: 'Export To Ezy2Finance',
          message: error
        });
      });
    }

    //Export To Purchase Order CSV
    function getPurchaseOrderCSVData() {
      if(vm.accessForPriceDisplay)
        vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderListingHeader;
      else {
        vm.purchaseOrderHeader = _.without(EXPORT_CONSTANTS.CSV.purchaseOrderListingHeader, 'unitPrice' , 'totalAmount', 'currency');
      }
      var deferred = $q.defer();
      vm.getAllExportPurchaseOrderDetails().then(function(response){
        if(_.isEmpty(response)){
          NotificationService.simpleErrorToast({
            title: 'global.exportPurchaseOrders',
            message: 'global.table.NO_RECORDS_FOUND'
          });
          return deferred.promise;
        }else {
          var exportPO = CSVExportService.getPurchaseOrderListingCSV(response);
          deferred.resolve(exportPO);
        }
      },function(error){
        NotificationService.simpleErrorToast({
          title: 'global.exportPurchaseOrders',
          message: error
        });
        deferred.reject(error);
      });
      return deferred.promise;
    }

    /**
    * [sort to sort by PO date]
    */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
    * [getAllBuyerBusinessOutlets Gets all business outlets of buyer]
    */
    function getAllBuyerBusinessOutlets() {
      if(vm.businessOutlets)
        return false;
      vm.businessOutlets = null;
      BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
      then(function(response){
        vm.businessOutlets = response;
      },function(error){
      });
    }


    /**
    * [getAllAllocatedSuppliersForBusiness Get all allocated suppliers of buyer business]
    */
    function getAllAllocatedSuppliersForBusiness() {
      if(vm.supplierList && vm.prevSelectedOutletId === vm.filter.businessOutletId)
        return false;
      vm.prevSelectedOutletId = vm.filter.businessOutletId;
      var params = {'businessId': !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId};
      BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
        vm.supplierList = $filter('orderBy')(response.data, 'companyName');
      },function(error){
      });
    }

    function loadItemGroups() {
      if(vm.itemGroups)
        return false;
      ItemGroupService.getAllItemGroups({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
      }).then(function(response) {
        vm.itemGroups = response.data;
      }, function(error) {
      });
    }

    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
        localStorage.setItem("purchase-order-filter", JSON.stringify(vm.filter));
        localStorage.setItem("purchase-order-predicate", JSON.stringify(vm.predicate));
        localStorage.setItem("purchase-order-reverse", JSON.stringify(vm.reverse));
        localStorage.setItem("purchase-order-paginate", JSON.stringify(vm.query));
      if(vm.filter.listView === "Individual") {
        getIndividualPODetails();
      }
    }

    /**
    * [getIndividualPODetails gets PO details for each PO Number]
    */
    function getIndividualPODetails() {
      PurchaseOrderService.getAllPurchaseOrders({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: vm.filter.poStatus ,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
        businessOutletName: '' ,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
        grnFromDate : '',
        grnToDate : '',
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        approvalRequestNumber: !vm.filter.approvalRequestNumber ? '' :vm.filter.approvalRequestNumber,
        buyerIdList: '',
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
        module : 0
      }).then(function(response){
        vm.poItems = response.data;
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Purchase Order Details',
          error: error
        });
      });
    }

    //creates an object vm.purchaseOrderRequestDTO to use as a request in consolidated view
    function navigateToConsolidatedView(){
      vm.purchaseOrderRequestDTO={
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: vm.filter.poStatus ,
        invoiceStatus: '',
        invoiceNumber: '',
        businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
        businessOutletName: '' ,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
        query: !vm.filter.itemName ? '' : vm.filter.itemName,
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
        module : 0
      };
      var canRedirect = PurchaseOrderService.setPurchaseOrderRequestParams(vm.purchaseOrderRequestDTO);
      if(canRedirect == true){
      $state.go('buyer.reports.purchase-order.consolidated-details', {
        reload: false
      });
    }
    }

    function connectToXero() {
      CallbackResourceService.redirectUrl({
        module : 0,
      }).then(function(response){
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    function disconnectToXero() {
      vm.organisationName = "";
      CallbackResourceService.disconnectToXero().then(function(response){
        vm.xero = response.xero;
        NotificationService.success({
          title: response.data.status,
          message: response.data.message
        });

      },function(error){
        NotificationService.error({
          title: 'global.buttons.disconnectXERO',
          error: error
        });
      });

    }

    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      angular.forEach(vm.poItems, function(poItem) {
        poItem.purchaseOrder.selected = vm.selectAll;
      });
    }

    function validateExportXero(poItems) {
      var validSelection = true;
      var validStatus = ['PENDING','PROCESSED'];
      _.forEach(poItems, function(po, key) {
        if(validSelection){
          if(validStatus.indexOf(po.purchaseOrder.purchaseOrderStatus) === -1){
            validSelection = false;
            NotificationService.simpleErrorToast({
              title: 'global.buttons.exportXERO',
              message: 'Only Pending and Processed POs can be exported to XERO'
            });
          }
        }
      });
      return validSelection;
    }

    function getSelectedPOs(poItems) {
      var selectedPOs = _.filter(poItems, function(po) {
        return po.purchaseOrder.selected === true;
      });
      return selectedPOs;
    }

    function getSelectedPOIds(selectedPOs) {
      var purchaseOrderIdList = _.map(selectedPOs, _.property('purchaseOrder.id'));
      return purchaseOrderIdList;
    }

    /**
    * [getSelectedPONumbers get PO Numbers of selected POs]
    * @param  {[type]} selectedPOs [list of selected POs]
    * @return {[type]}             [purchaseOrderNumberList]
    */
    function getSelectedPONumbers(selectedPOs) {
      var purchaseOrderNumberList = _.map(selectedPOs, _.property('purchaseOrder.purchaseOrderNumber'));
      return purchaseOrderNumberList;
    }

    function fetchExportToXeroList() {
      var purchaseOrderIdList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      if(vm.validateExportXero(selectedPOs)){
        purchaseOrderIdList = vm.getSelectedPOIds(selectedPOs);
      }
      return purchaseOrderIdList;
    }

    function updateXeroExportFlag(purchaseOrderIdList) {
      _.forEach(vm.poItems, function(po, key) {
        if(_.includes(purchaseOrderIdList, po.purchaseOrder.id)){
          vm.poItems[key].purchaseOrder.axExported = undefined;
        }
      });
    }

    function exportToXero(){
      var purchaseOrderIdList = fetchExportToXeroList();

      if(angular.isDefined(vm.poItems) && purchaseOrderIdList.length > 0){

        XeroResourceService.exportToXero({
          purchaseOrderStatus : vm.filter.poStatus ,
          poNumber : !vm.filter.poNumber ? '' : vm.filter.poNumber,
          fromDate : !vm.filter.poFromDate ? !vm.filter.deliveryFromDate ? (!vm.filter.grnFromDate ? '' :UTCDateService.utcFromDate(new Date(vm.filter.grnFromDate))) : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)) : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          toDate : !vm.filter.poToDate ? !vm.filter.deliveryToDate ? (!vm.filter.grnToDate ? '' :UTCDateService.utcToDate(new Date(vm.filter.grnToDate))) : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)) : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          businessOutletName : '',
          itemName : '',
          supplierId : !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId : !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          buyerName : '',
          poExported : '',
          page : 0,
          query : '',
          size : 20,
          sort : 'id,desc',
          purchaseOrderIdList : (purchaseOrderIdList.length === 0) ? [''] : purchaseOrderIdList,
          supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
          invoiceStart: '',
          invoiceEnd: '',
          invoiceNumber : '',
          module : 0,
        }).then(function(response){
          vm.message = response.headers('X-ezyprocureApp-alert')
          NotificationService.success({
            title: 'global.buttons.exportXERO',
            message: vm.message
          });
          vm.updateXeroExportFlag(purchaseOrderIdList);
          vm.selectAll = false;
          setTimeout(function(){
            vm.loadAll();
          },60000);
        },function(error){
          NotificationService.error({
            title: 'global.buttons.exportXERO',
            error: error
          });
          getTokenExpiry();
          disableConnectToXeroBtn();
        });
      } else {
        NotificationService.simpleErrorToast({
          title: 'global.buttons.exportXERO',
          message: 'alertMessage.SEARCH_AND_SELECT'
        });
      }
    }

    function getTokenExpiry() {

      CallbackResourceService.getTokenExpiry(
      ).then(function(response){
        vm.xero = response;
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });

    }

    function disableConnectToXeroBtn() {
      CallbackResourceService.getAuthTimeout(
      ).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.errors;
        if(window.location.href.indexOf("?") > -1) {
          var code = window.location.href.split('code=')[1].split('&scope=')[0];
          var scope = window.location.href.split('scope=')[1].split('&session_state=')[0];
          var session_state = window.location.href.split('session_state=')[1].split('&state')[0];
          var state = window.location.href.split('state=')[1];
          CallbackResourceService.xeroCallback({ code: code, scope: scope, sessionState: session_state,state: state })
          .then(function(response){
            vm.xero = response;
            vm.organisationdata = response.data.data;
            if(vm.organisationdata.length == 1){
              vm.tenantId = vm.organisationdata[0].tenantId;
              vm.getOraganization();
            } else {
              $mdDialog.show({
                templateUrl: 'app/core/common-templates/xero-multiple-organization.html',
                controller: function() {
                  return vm;
                },
                controllerAs: 'vm',
                targetEvent: event,
                clickOutsideToClose: true
              });
            }
            if(!vm.organisationName){
              vm.organisationName = vm.xero.errors;
              }
            code = null;
            scope = null;
            session_state=null;
            state=null;
          }, function(error){
            vm.xero = CallbackResourceService.getXeroConnectionParams();
            NotificationService.error({
              title: 'global.buttons.connectXERO',
              error: error
            });
          });
        }
      },function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
    }

    disableConnectToXeroBtn();

    function getOraganization(){
      closeModal();
      var params ={
        tenantId: vm.tenantId
      };
      CallbackResourceService.xeroConnect(params).then(function(response){
        vm.xero = response;
        vm.organisationName = vm.xero.data.responseList[0].reason;
        NotificationService.success({
          title: 'global.buttons.connectXERO',
          message: 'alertMessage.XERO.SUCCESS'
        });
      }, function(error){
        NotificationService.error({
          title: 'global.buttons.connectXERO',
          error: error
        });
      });
     }

    function closeModal() {
      $mdDialog.cancel();
    }

/**
 * [getAllExportPurchaseOrderDetails get Purchase order lists for CSV export]
 * @return {[type]} [description]
 */
    function getAllExportPurchaseOrderDetails(){
      var deferred = $q.defer();
      vm.purchaseOrderNumberList = [];
      var selectedPOs = vm.getSelectedPOs(vm.poItems);
      vm.purchaseOrderNumberList = vm.getSelectedPONumbers(selectedPOs);
      vm.exportPORequestDTO = {
          poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
          purchaseOrderStatus: vm.filter.poStatus ,
          invoiceStatus: '',
          invoiceNumber: '',
          businessOutletId: !vm.filter.businessOutletId ? '' : vm.filter.businessOutletId ,
          businessOutletName: '' ,
          //If dates are selected, they are converted to UTC format
          poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.poFromDate)),
          poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.poToDate)),
          deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(new Date(vm.filter.deliveryFromDate)),
          deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(new Date(vm.filter.deliveryToDate)),
          grnFromDate : '',
          grnToDate : '',
          query: !vm.filter.itemName ? '' : vm.filter.itemName,
          supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
          itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
          debitNoteStatus: '',
          grnNumber: '',
          buyerName: '',
          creditNoteStatus: '',
          supplierVisibilityType: vm.filter.supplierVisibilityType ? vm.filter.supplierVisibilityType : '',
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
          xeroExported: vm.filter.xeroExported ? vm.filter.xeroExported : '',
          module : 0
      };
      /**
      * [if selection is made getExportGeoDetails for selected records]
      */
      if(vm.purchaseOrderNumberList.length > 0) {
        PurchaseOrderService.getPOExportListing(vm.exportPORequestDTO, vm.purchaseOrderNumberList, 'batch').then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.simpleErrorToast({
            title: 'global.fetchingCSVExportDetails',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      /**
      * [if no selection is made getAllExportGeoDetails based on search query]
      */
      else if (_.isEmpty(vm.purchaseOrderNumberList) && selectedPOs.length === 0) {
        PurchaseOrderService.getPOExportListing(vm.exportPORequestDTO, [],'all').then(function(response){
          deferred.resolve(response.data);
        },function(error){
          NotificationService.simpleErrorToast({
            title: 'global.fetchingCSVExportDetails',
            error: error
          });
          deferred.reject(error);
        });
        return deferred.promise;
      }
      else {
        return deferred.promise;
      }
    }

     // filter reset
     function resetFilters() {
        vm.filter.poStatus = vm.poStatuses[0]
        vm.filter.poNumber = '';
        vm.filter.poFromDate = vm.todaysDate;
        vm.filter.poToDate = vm.todaysDate;
        vm.filter.deliveryFromDate = null;
        vm.filter.deliveryToDate = null;
        vm.filter.itemGroupId = '';
        vm.filter.itemName = '';
        vm.filter.approvalRequestNumber = '';
        vm.filter.businessOutletId = '';
        vm.filter.supplierId = '';
        vm.filter.listView = vm.listViews[0];
        vm.filter.supplierVisibilityType = vm.supplierVisibilityTypes[0];
        vm.filter.xeroExported = '';
        vm.poItems = [];
        localStorage.setItem("purchase-order-filter", JSON.stringify(vm.filter));
    }
  }
})();
