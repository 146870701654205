/*
 * @Author: Ajay 
 * @Date: 2019-04-16 10:01:09 
 * @Project: Ezyprocure
 * @Filename: ezypayment.admin.visa-payment.controller.js
 * @Last Modified by: Ajay
 * @Last Modified time: 2019-05-10 13:22:42
 */

(function () {
  'use strict';
  angular
    .module('ezyprocureApp')
    .controller('EzypaymentAdminVISAPaymentController', EzypaymentAdminVISAPaymentController);

  EzypaymentAdminVISAPaymentController.$inject = ['$scope', '$state', 'NotificationService', '$mdDialog', 'GLOBAL_CONSTANTS', 'EzypaymentAdminVISAPaymentService']

  function EzypaymentAdminVISAPaymentController($scope, $state, NotificationService, $mdDialog, GLOBAL_CONSTANTS, EzypaymentAdminVISAPaymentService) {

    var vm = this;
    vm.confirmPasswordModal = confirmPasswordModal;
    vm.closeConfirmPasswordModal = closeConfirmPasswordModal;
    vm.checkPassword = checkPassword;
    vm.passcode = '';
    vm.visaPaymentPassword = GLOBAL_CONSTANTS.VISA_PAYMENT_PASSWORD;
    vm.addPayee = addPayee;
    vm.addPayeeKYC = addPayeeKYC;
    vm.payment = payment;
    vm.taxConsolidatedInvoice = taxConsolidatedInvoice;
    vm.remittanceAdvise = remittanceAdvise;
    vm.confirmPasswordModal();
    // tax invoice month and year
    vm.taxInvoiceMonth = "";
    vm.taxInvoiceYear = "";
    vm.months = [];
    vm.years = [];
    vm.showMonthYearSelectionModal = showMonthYearSelectionModal;
    vm.closeModal = closeModal;
    vm.generateYears = generateYears;
    vm.generateMonths = generateMonths;
    vm.generateYears();
    vm.triggerNFriendsJob = triggerNFriendsJob;
    vm.triggerNfriendsPayee = triggerNfriendsPayee;
    vm.triggerJobNfriendsPayment = triggerJobNfriendsPayment;
    vm.triggerJobNfriendsKyc = triggerJobNfriendsKyc;
    vm.externalPaymentFlg = true;
    function triggerNfriendsPayee() {
      EzypaymentAdminVISAPaymentService.triggerJobNfriendsPayee()
      .then(function (response) {
        NotificationService.success({
          title: 'global.form.nFriendsPayee',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
      }, function (error) {
        NotificationService.error({
          title: 'global.form.nFriendsPayee',
          error: error
        });
      })
    }

    function triggerJobNfriendsPayment() {
      EzypaymentAdminVISAPaymentService.triggerJobNfriendsPayment()
      .then(function (response) {
        NotificationService.success({
          title: 'global.form.nFriendsPayment',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
      }, function (error) {
        NotificationService.error({
          title: 'global.form.nFriendsPayment',
          error: error
        });
      })
    }

    function triggerJobNfriendsKyc() {
      EzypaymentAdminVISAPaymentService.triggerJobNfriendsKyc()
      .then(function (response) {
        NotificationService.success({
          title: 'global.form.nFriendsKYC',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
      }, function (error) {
        NotificationService.error({
          title: 'global.form.nFriendsKYC',
          error: error
        });
      })
    }
    

    //Manual trigger Nfriends job
    function triggerNFriendsJob() {
      EzypaymentAdminVISAPaymentService.triggerNFriendsJob()
        .then(function (response) {
          NotificationService.success({
            title: 'global.form.nFriends',
            message: 'alertMessage.PROCESSED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.form.nFriends',
            error: error
          });
        })
    }

    /**
    * [addPayee Adds Payee]
    */
    function addPayee() {
      EzypaymentAdminVISAPaymentService.addPayee()
        .then(function (response) {
          NotificationService.success({
            title: 'global.buttons.addPayee',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Add Payee',
            error: error
          });
        })
    }
    
    /**
     * [addPayeeKYC Adds Payee KYC]
     */
    function addPayeeKYC() {
      EzypaymentAdminVISAPaymentService.addPayeeKYC()
        .then(function (response) {
          NotificationService.success({
            title: 'global.buttons.addPayeeKYC',
            message: 'alertMessage.ADDED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'Add Payee KYC',
            error: error
          });
        })
    }

    /**
     * [payment generates payment]
     */
    function payment() {
      EzypaymentAdminVISAPaymentService.payment()
        .then(function (response) {
          NotificationService.success({
            title: 'global.visaPayment',
            message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.visaPayment',
            error: error
          });
        })
    }

    /**
     * [checkPassword check whether the entered visa payment password is correct]
     * @param {Integer} passcode [VISA Payment Password]
     */
    function checkPassword(passcode) {
      if (vm.visaPaymentPassword === passcode) {
        vm.closeConfirmPasswordModal();
      } else {
        NotificationService.simpleErrorToast({
          title: "global.menu.entities.externalPayment",
          message: 'alertMessage.INCORRECT_PASSWORD'
        });
      }

    }

    function confirmPasswordModal(event) {
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/admin-modules/visa-payment/ezypayment.admin.visa-payment-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function closeConfirmPasswordModal() {
      vm.passcode = '';
      $mdDialog.cancel();
    }

    // year generator
    function generateYears() {
      var currentYear = new Date().getFullYear();
      var limit = 2017;
      while (currentYear >= limit) {
        vm.years.push(currentYear);
        --currentYear;
      }
    }

    function generateMonths() {
      if(vm.taxInvoiceYear != new Date().getFullYear()) {
        vm.months = GLOBAL_CONSTANTS.MONTHS;
      }
      else {
        var currentMonth = new Date().getMonth();
        vm.months = GLOBAL_CONSTANTS.MONTHS.slice(0, currentMonth);
      }
    }

    // modal close
    function closeModal() {
      $mdDialog.hide();
    }

    // month selection modal for tax invoice 
    function showMonthYearSelectionModal() {
      $mdDialog.show({
        templateUrl: 'app/ezypayment-modules/admin-modules/visa-payment/ezypayment.admin.visa-payment-tax-invoice-modal.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }


    /**
     * [taxConsolidatedInvoice generates Invoice]
     */
    function taxConsolidatedInvoice() {
      var month = vm.months.indexOf(vm.taxInvoiceMonth) + 1;
      var year = parseInt(vm.taxInvoiceYear);
      EzypaymentAdminVISAPaymentService.taxConsolidatedInvoice(month, year)
        .then(function (response) {
          closeModal();
          NotificationService.success({
            title: 'global.buttons.taxConsolidatedInvoice',
            message: 'alertMessage.GENERATED_SUCCESSFULLY'
          });
        }, function (error) {
          closeModal();
          NotificationService.error({
            title: 'global.buttons.taxConsolidatedInvoice',
            error: error
          });
        })
    }

     /**
     * [remittanceAdvise generates Invoice]
     */
    function remittanceAdvise() {
      EzypaymentAdminVISAPaymentService.remittanceAdvise()
        .then(function (response) {
          NotificationService.success({
            title: 'global.buttons.remittanceAdvise',
            message: response.data.message
          });
        }, function (error) {
          NotificationService.error({
            title: 'global.buttons.remittanceAdvise',
            error: error
          });
        })
    }
  }
})();
