/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.cards.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-09-27T16:29:41+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('CardsService', CardsService);

  CardsService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function CardsService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var CardsService = {
      addCreditCard: addCreditCard,
      deleteCard: deleteCard,
      getAllCards: getAllCards,
      fetchAllAvailableCards: fetchAllAvailableCards,
      getOdbcCards: getOdbcCards,
      getCardType: getCardType,
      getMPGSScript: getMPGSScript,
      setBrandsAndCardType: setBrandsAndCardType,
      getBrandsAndCardType: getBrandsAndCardType,
      getMerchantId: getMerchantId,
      getAllSupplierCards : getAllSupplierCards,
      createSupplierCreditCards : createSupplierCreditCards,
      deleteSupplierCard : deleteSupplierCard,
      getCybersourceCardsByType: getCybersourceCardsByType,
      getWorldPayCardsByRole: getWorldPayCardsByRole,
      saveTransactionUuid: saveTransactionUuid,
      getCybersourceTransactionResponse: getCybersourceTransactionResponse,
      deleteCybersourceCardById: deleteCybersourceCardById,
      deleteWorldPayCardById: deleteWorldPayCardById,
      createMPGSSession: createMPGSSession,
      initiateMPGSAuth:initiateMPGSAuth,
      updateMPGSSession: updateMPGSSession,
      MPGSUpdatePayer: MPGSUpdatePayer,
      getCardOnTransactionUUID: getCardOnTransactionUUID,
      getMPGSScriptNewVersion:getMPGSScriptNewVersion
    };

    return CardsService;

    var creditCard = [];

    function deleteCybersourceCardById(id) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/token/repo/' + id).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function deleteWorldPayCardById(id) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'custom/card/' + id).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    function getCybersourceTransactionResponse() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/card/response',
        method: "GET",
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function saveTransactionUuid(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/card/token',
        method: "POST",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getCybersourceCardsByType(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/cards/all',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getWorldPayCardsByRole(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'customs/cards/all',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    /**
     * [getAllSupplierCards Get all supplier cards]
     */
    function getAllSupplierCards(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS + 'suppliers',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    //get card type service

    function getCardType(params) {
      var deferred = $q.defer();
      $http({
        url:  API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion +'cardCycles',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }
    /**
     * [createSupplierCreditCards Get all supplier cards]
     */
    function createSupplierCreditCards(creditCard){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS + 'supplier',
        method: "POST",
        data: creditCard,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function addCreditCard(creditCard) {
      var deferred = $q.defer();
      $http.post(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS, creditCard).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function deleteCard(brandId, creditCardId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS + brandId + "/" + creditCardId).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });

      return deferred.promise;
    }

    // Deletes supplier credit card
    function deleteSupplierCard(supplierId, creditCardId) {
      var deferred = $q.defer();
      $http.delete(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS+ 'suppliers/' + supplierId + "/" + creditCardId).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllCards(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CARDS,
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllCustomCards(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.MPGS_CUSTOM_CARDS,
          method: "GET",
          params: params,
        }).then(function(data) {
          deferred.resolve(data);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    function fetchAllAvailableCards(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/all/cards/listing',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getOdbcCards(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'cybersource/cards/brand',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  function  getMerchantId(params){
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'merchant-details/brand',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getMPGSScript(merchantId){
      var MPGSScriptURL =API_CONSTANTS.MPGS.BASE_URL + "form/version/" + API_CONSTANTS.MPGS.SANDBOX_MODULE_VERSION + "/merchant/" + merchantId + "/session.js?debug=true"
      return MPGSScriptURL;
    }

    function createMPGSSession(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/cards/createSessionId',
          method: "POST",
          data: data,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      function initiateMPGSAuth(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/cards/initiateAuthentication',
          method: "POST",
          data: data,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      function updateMPGSSession(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/cards/updateSessionId',
          method: "POST",
          data: data,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

      function MPGSUpdatePayer(data) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/mpgs/cards/authenticatePayer',
          method: "POST",
          data: data,
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    // set brands and card type
    function setBrandsAndCardType(creditCardDetails){
      creditCard = creditCardDetails;
    }

    // get brands and card type
    function getBrandsAndCardType(){
      return creditCard;
    }

    function getCardOnTransactionUUID(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'mpgs/cards/getCardOnTransactionUUID',
        method: "GET",
        params: {'transactioUUID':params.transactioUUID, 'referenceNumber':params.referenceNumber},
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getMPGSScriptNewVersion(merchantId){
      var MPGSScriptURL =API_CONSTANTS.MPGS.BASE_URL + "form/version/" + API_CONSTANTS.MPGS.SANDBOX_MODULE_VERSION_80 + "/merchant/" + merchantId + "/session.js?debug=true"
      return MPGSScriptURL;
    }

  }
})();
