/**
* @Author: Ajay
* @Date:   06-Mar-2017 15:47 +05:30
* @Project: Ezyprocure
* @Filename: supplier.process-order.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2018-07-06T13:52:54+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierProcessOrderController', SupplierProcessOrderController);

  SupplierProcessOrderController.$inject = ['$scope', '$state', 'toastr', '$mdDialog', 'pagingParams', 'SupplierProcessOrderService', 'paginationConstants', 'SupplierPickingListService', 'NotificationService', '$timeout', 'UTCDateService', 'GLOBAL_CONSTANTS'];

  function SupplierProcessOrderController($scope, $state, toastr, $mdDialog, pagingParams, SupplierProcessOrderService, paginationConstants, SupplierPickingListService, NotificationService, $timeout, UTCDateService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.loadAll = loadAll;
    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.cancel = cancel;
    vm.getAllPickingList = getAllPickingList;
    vm.selectAllPO = selectAllPO;
    vm.showConfirmTemplate = showConfirmTemplate;
    vm.printPickingList = printPickingList;
    vm.showDownloadsTemplate = showDownloadsTemplate;
    vm.todaysDate = new Date();
    vm.filter = {};
    vm.getMarkedProcessOrder = getMarkedProcessOrder;
    vm.generateMassProcessOrder = generateMassProcessOrder;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    loadAll();
    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function loadAll() {
      var toDateVar = vm.todaysDate;
      SupplierProcessOrderService.getAllProcessOrdersByStatus({
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort(),
        status: 'PENDING',
        fromDate: !vm.filter.fromDate ? '' : UTCDateService.utcFromDate(vm.filter.fromDate),
        toDate: !vm.filter.toDate ? '' : UTCDateService.utcToDate(vm.filter.toDate),
        pickingListPrinted : vm.filter.pickingListPrinted ? vm.filter.pickingListPrinted : '',
        query: vm.filter.query ? vm.filter.query : ''
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.processOrders = response.data;
      }, function(error) {
      });
    }

    /**
    * [cancel closes modal dialog]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
    * [selectAllPO select all Pending Process Orders]
    * @return {[type]} [description]
    */
    function selectAllPO() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.processOrders, function(poItem) {
        poItem.selected = vm.selectAll;
      });
    }

    // get selected process orders
    function getSelectedItems(poList){
      var poIds = [];
      //Fetch all ids of getSelectedItems in vm.processOrders where selectedPOItem.selected = true
      var selectedPOItems = _.filter(poList, {
        'selected': true
      });
      poIds = _.map(selectedPOItems,  function(processOrderItem){
        return {
          'deliveryDate': processOrderItem.deliveryDate,
          'requirementNumber': processOrderItem.requirementNumber
        };
      });
      return poIds;
    }

    /**
    * [showConfirmTemplate shows modal to confirm Picking List Template]
    */
    function showConfirmTemplate(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/process-order/templates/supplier.process-order.picking-list-template-confirm.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [getAllPickingList get all picking list template ids]
    * @return {[type]} [description]
    */
    function getAllPickingList(event){
      vm.processOrderPickingList= getSelectedItems(vm.processOrders);
      if(vm.processOrderPickingList.length>0){
        SupplierPickingListService.getAllPickingList({
          detailedView : false
        }).then(function(response) {
          vm.templateList = response.data;
          vm.showConfirmTemplate(event);
        }, function(error) {
          NotificationService.error({
            title: 'global.menu.entities.pickingList',
            error: error
          });
        });
      }
      else {
        toastr.error('Please select atleast one Process Order', 'Print Picking List!');
      }
    }

    /**
    * [printPickingList print picking list PDFs]
    * @param  {[type]} templateId [description]
    * @return {[type]}            [description]
    */
    function printPickingList(templateId){
      SupplierPickingListService.printPickingList(vm.processOrderPickingList,{
        pickingListId: templateId
      }).then(function(response) {
        NotificationService.success({
          title: 'global.menu.entities.pickingList',
          message: 'alertMessage.PRINTED_SUCCESSFULLY'
        });
        vm.filter = {}; // to empty toDate and fromDate
        vm.loadAll();   //load pending process order list so as to update print picking list status
        vm.downloadsList= response.urls;
        vm.showDownloadsTemplate();
      }, function(error) {
        NotificationService.error({
          title: 'global.menu.entities.pickingList',
          error: error
        });
      });
    }


    /**
    * [showDownloadsTemplate shows modal listing PDF links]
    */
    function showDownloadsTemplate(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/process-order/templates/supplier.process-order.downloads.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    // get selected process order's requirementNumber
    function getMarkedProcessOrder(poList){
     var poIds = [];
      //Fetch all requirementNumber of getSelectedItems in vm.processOrders where selectedPOItem.selected = true
      var selectedPOItems = _.filter(poList, {
        'selected': true
      });
      poIds = _.map(selectedPOItems, _.property('requirementNumber')

      );
      return poIds;
    }

    /**
    * [generateMassProcessOrder creates bulk processOrder ]
    */
    function generateMassProcessOrder(){
      vm.selectedItems=getMarkedProcessOrder(vm.processOrders);
        if(vm.selectedItems.length>0){
          SupplierProcessOrderService.generateMassSupplierProcessOrder(
            vm.selectedItems
          ).then(function(response) {
            NotificationService.success({
              title: 'global.buttons.massProcessOrder',
              message: 'alertMessage.CREATED_SUCCESSFULLY'
            });
            vm.filter.fromDate = '';
            vm.filter.toDate = '';
            vm.loadAll();
          }, function(error) {
            NotificationService.error({
              title: 'global.buttons.massProcessOrder',
              error: error
            });
          });
        }
        else{
         NotificationService.simpleErrorToast({
           title: 'error.validation',
           message: 'global.pleaseSelectedOneFromTheList',
        });
        return false;
      }
    }
  }
})();
