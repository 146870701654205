/**
* @Author: Ajay
* @Date:   09-Mar-2017 18:51 +05:30
* @Project: Ezyprocure
* @Filename: buyer.cart-checkout.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-10-24T10:50:07+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerCartCheckoutController', BuyerCartCheckoutController);

  BuyerCartCheckoutController.$inject = ['$scope', '$rootScope', '$q', '$timeout', '$interval', '$state', '$mdDialog', '$filter', 'BuyerBuyNowService','BuyerBusinessService', 'pagingParams', 'paginationConstants', 'BuyerDataService', 'CartService', 'UTCDateService', 'NotificationService', 'TIME_CONSTANTS', 'UtilService','$stateParams', 'PDFExportService', '$mdMedia', 'Auth', 'BuyerWaitingSupplierReplyService','toastr'];

  function BuyerCartCheckoutController($scope, $rootScope, $q, $timeout, $interval, $state, $mdDialog, $filter, BuyerBuyNowService, BuyerBusinessService, pagingParams, paginationConstants, BuyerDataService, CartService, UTCDateService, NotificationService, TIME_CONSTANTS, UtilService,$stateParams, PDFExportService, $mdMedia, Auth, BuyerWaitingSupplierReplyService,toastr) {

    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.init = init;
    vm.purchaseMethod = $stateParams.purchaseMethod;
    vm.templateId = $stateParams.templateId;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    vm.loadAll = loadAll;
    vm.getPromotionTicker = getPromotionTicker;
    vm.cartDetails = {};
    vm.updateCart = updateCart;
    vm.invalidCartItems = [];
    vm.removeFromCart = removeFromCart;
    vm.checkoutCartItems = checkoutCartItems;
    vm.confirmDuplicatePRSubmission = confirmDuplicatePRSubmission;
    vm.checkMaximumAllocation = checkMaximumAllocation;
    vm.checkFreeDeliveryEligibility = checkFreeDeliveryEligibility;
    vm.getMatchedDeliveryCondition = getMatchedDeliveryCondition;
    vm.getItemTotal = getItemTotal;
    vm.getCartTotal = getCartTotal;
    vm.checkCartItems = checkCartItems;
    vm.getTimeAbbr12 = getTimeAbbr12;
    vm.removeFromCartConfirmationModal = removeFromCartConfirmationModal;
    vm.cartId = null;
    vm.cancel = cancel;
    vm.shareByWhatsapp = shareByWhatsapp;
    vm.showShareByWhatsappConfirm = showShareByWhatsappConfirm;
    vm.showWhatsappConfirmList = showWhatsappConfirmList;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.isMobile = $mdMedia('portrait') || ($mdMedia('landscape') && !$mdMedia('gt-md'));
    vm.disableCheckout =  false;  // disable checkout button on first click so that checkout api is not called more than once so
    vm.haveAccessForWhatsappPo = Auth.hasPermission('accessForWhatsappPo');

    //getting AdhocType
    vm.adhocType = sessionStorage.getItem('adhocType');
    vm.redirectAfterShare = redirectAfterShare;
    vm.validateMOQ = validateMOQ;
    vm.removeQuantity = removeQuantity;
    vm.autoAddToCart = autoAddToCart;
    vm.autoUpdateOnDeliveryChange = autoUpdateOnDeliveryChange;
    vm.addQuantity = addQuantity;
    vm.orderConfirmPopup = orderConfirmPopup;
    vm.startIncrement = startIncrement;
    vm.startDecrement = startDecrement;
    vm.addToCartAfterTimout = addToCartAfterTimout;
    vm.clearIncrementTimers = clearIncrementTimers;
    vm.clearDecrementTimers = clearDecrementTimers;
    vm.uploadAttachment = uploadAttachment;
    vm.deleteAttachment = deleteAttachment;
    vm.uploadModal = uploadModal;
    vm.descriptionPopup = descriptionPopup;
    // negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.selectAll = selectAll;
    vm.selectAllRows = true;
    vm.updateSelection = updateSelection;
    vm.addToCartDateCheck = addToCartDateCheck
    vm.disableDays = disableDays;
    vm.selecteddatePicker = '';
    vm.openCalendar = openCalendar;
    vm.getDeliveryConditions = getDeliveryConditions;

    function startIncrement(item, index) {
      var multiplierFactor = item.multiplierFactor ? item.multiplierFactor : item.items.multiplierFactor;
      addQuantity(multiplierFactor, item.items.uom.weightRequired, index);
      item.longPressIncrementTimeout = $timeout(function() {
        item.incrementTimer = $interval(function() {
          addQuantity(multiplierFactor, item.items.uom.weightRequired, index);
        }, 100);
      }, 500);
    }

    function startDecrement(item, index) {
      var multiplierFactor = item.multiplierFactor ? item.multiplierFactor : item.items.multiplierFactor;
      removeQuantity(multiplierFactor, item.items.uom.weightRequired, index);
      item.longPressDecrementTimeout = $timeout(function() {
        item.decrementTimer = $interval(function() {
          removeQuantity(multiplierFactor, item.items.uom.weightRequired, index);
        }, 100);
      }, 500);
    }

    function addToCartAfterTimout(item, index, action) {
      action == 'INCREMENT' ? clearIncrementTimers(item) : clearDecrementTimers(item);
      $timeout(function (preIndex, preItem) {
        if (preIndex === index && preItem.quantity === item.quantity) {
          vm.autoAddToCart(item, index);
        }
      }, 1500, true, angular.copy(index), angular.copy(item));
    }

    function clearIncrementTimers(item) {
      $timeout.cancel(item.longPressIncrementTimeout);
      $interval.cancel(item.incrementTimer);
    }

    function clearDecrementTimers(item) {
      $timeout.cancel(item.longPressDecrementTimeout);
      $interval.cancel(item.decrementTimer);
    }

    function removeQuantity(multiplierFactor, isWeightRequired, index) {
      var step = 1;
      var item = vm.cartDetails[index];
      var lowerLimit = getMoq(item.moq);
      if(multiplierFactor || isWeightRequired) {
        step = multiplierFactor || 0.001;
      }
      if ((item.quantity - step) >= lowerLimit) {
        if(!isWeightRequired){
          item.quantity = +((item.quantity - step).toFixed(2));
        }
        else {
          item.quantity = +((item.quantity - step).toFixed(3));
        }
        isNegativeBuying(item.quantity) || validateMOQ(multiplierFactor, item.quantity, index);
      }
    }

    function getMoq(moq) {
      return vm.accessForNegativeBuyingBuyerView ? Number.MIN_SAFE_INTEGER : (moq || 0);
    }

    function isNegativeBuying(quantity) {
      return vm.accessForNegativeBuyingBuyerView && (Math.sign(quantity) == -1 || Math.sign(quantity) == 0);
    }

    function addQuantity(multiplierFactor, isWeightRequired, index) {
      var step = 1;
      var item = vm.cartDetails[index];
      var lowerLimit = item.moq || 0;
      if(multiplierFactor || isWeightRequired) {
        step = multiplierFactor || 0.001;
      }
      if(!isWeightRequired){
        item.quantity = item.quantity ? +((item.quantity + step).toFixed(2)) : lowerLimit;
      }
      else {
        item.quantity = item.quantity ? +((item.quantity + step).toFixed(3)) : lowerLimit;
      }
      isNegativeBuying(item.quantity) || validateMOQ(multiplierFactor, item.quantity, index);
    }

    //Sorting of cart items
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function init() {
      vm.loadAll();
    }

    function getSupplierList(cartDetails) {
      var suppliers = [];
      suppliers = _.uniqBy(_.map(cartDetails,_.property('items.supplier')),'companyName');
      return suppliers;
    }

    // check if cart items has specific delivery conditions for the outlet, otherwise set suppler default delivery condition.
    function setBuyerSpecificDeliverConditionForSupplier() {
        _.each(vm.supplierList, function(sup, i) {
            var bDC = (_.find(vm.cartDetails, ['items.supplier.id', sup.id])).buyerDeliveryConditions;
            sup.deliveryCondition = !!bDC ? bDC.deliveryConditions : undefined;
            if (!sup.deliveryCondition) {
                sup.deliveryCondition = {
                    orderTimeForToday: sup.orderTimeForToday,
                    orderTimeForFriday: sup.orderTimeForFriday,
                    orderTimeForSaturday: sup.orderTimeForSaturday,
                    noDeliveryOnHolidays: sup.noDeliveryOnHolidays,
                    noDeliveryOnSaturday: sup.noDeliveryOnSaturday,
                    noDeliveryOnSunday: sup.noDeliveryOnSunday,
                    noDeliveryOnMonday: sup.noDeliveryOnMonday,
                    minimumOrderForFreeDelivery: sup.minimumOrderForFreeDelivery,
                    deliveryCharge: sup.deliveryCharge,
                    belowMinimumPurchaseCondition: sup.belowMinimumPurchaseCondition,
                    noSameDayDelivery: sup.noSameDayDelivery,
                    leadTime: sup.leadTime,
                    noDeliveryOnTuesday: sup.noDeliveryOnTuesday,
                    noDeliveryOnWednesday: sup.noDeliveryOnWednesday,
                    noDeliveryOnThursday: sup.noDeliveryOnThursday,
                    noDeliveryOnFriday: sup.noDeliveryOnFriday
                }
                bDC = sup.deliveryCondition;
            }
        })
    };

    //Get all cart details
    function loadAll() {
      CartService.getCartDetails({
        page: 0,
        size: paginationConstants.maxLimit,
        sort: sort(),
      }).then(function(response){
        vm.totalItems = response.cartCount;
        _.each(response.cartItems, function(element, index) {
          var deliveryCondition = getDeliveryConditions(element);
          _.extend(element,
            {
              minDate: deliveryCondition.noSameDayDelivery ? (angular.copy(new Date(new Date().getTime() + ((deliveryCondition.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(new Date()))
            });
        });
          vm.cartDetails = response.cartItems;
          _.map(vm.cartDetails, function(item){
            item['selected'] = true;
          });
          vm.supplierList = getSupplierList(vm.cartDetails);
          setBuyerSpecificDeliverConditionForSupplier();
        },function(error){
          NotificationService.error({
            title: 'global.menu.entities.cart',
            error: error
          });
        });
      }

    function getDeliveryConditions(item) {
      var deliveryConditions = {};
      if (item.buyerDeliveryConditions) {
        deliveryConditions = item.buyerDeliveryConditions.deliveryConditions;
      }
      else {
        deliveryConditions = {
          orderTimeForToday: item.items.supplier.orderTimeForToday,
          orderTimeForFriday: item.items.supplier.orderTimeForFriday,
          orderTimeForSaturday: item.items.supplier.orderTimeForSaturday,
          noDeliveryOnHolidays: item.items.supplier.noDeliveryOnHolidays,
          noDeliveryOnSaturday: item.items.supplier.noDeliveryOnSaturday,
          noDeliveryOnSunday: item.items.supplier.noDeliveryOnSunday,
          noDeliveryOnMonday: item.items.supplier.noDeliveryOnMonday,
          minimumOrderForFreeDelivery: item.items.supplier.minimumOrderForFreeDelivery,
          deliveryCharge: item.items.supplier.deliveryCharge,
          belowMinimumPurchaseCondition: item.items.supplier.belowMinimumPurchaseCondition,
          noSameDayDelivery: item.items.supplier.noSameDayDelivery,
          leadTime: item.items.supplier.leadTime,
          noDeliveryOnTuesday: item.items.supplier.noDeliveryOnTuesday,
          noDeliveryOnWednesday: item.items.supplier.noDeliveryOnWednesday,
          noDeliveryOnThursday: item.items.supplier.noDeliveryOnThursday,
          noDeliveryOnFriday: item.items.supplier.noDeliveryOnFriday
          
        }
        item.items.supplier.deliveryCondition = deliveryConditions;
      }
      return deliveryConditions;
    }

    function getPromotionTicker(promotionItem, itemIndex) {
      var promotionTicker = '';
      if(promotionItem){
        promotionTicker = 'Get '
        + promotionItem.items.itemName +
        ' (@' + $filter('currency')(promotionItem.items.price, '') + ' '
        + promotionItem.items.currency + ')'
        + ' for free ('
        + promotionItem.quantity + ' '
        + promotionItem.items.uom.name + ').';
      }
      return promotionTicker;
    }


    //update an item in cart
    function updateCart(cartItem, cartItemIndex, oldDeliveryDate) {
      if (vm.checkoutForm.$invalid) {
        return false;
      }
      if(cartItem.items.supplier.noDeliveryOnHolidays){
        if(getSupplierHolidays(cartItem.items.supplier.id))
        {
          cartItem.items.supplier.holidaylist = vm.holidaysList;
        }
      }
      vm.setPreviousDeliveryDate = true;
      cartItem.moq = cartItem.moq ? cartItem.moq : cartItem.items.moq;
      if (CartService.checkLeadTime(cartItem) && CartService.checkOrderedTime(cartItem)){
        vm.setPreviousDeliveryDate = false;
        cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(cartItem.deliveryDate);
        CartService.updateCart(cartItem).then(function(responseData) {
          //vm.loadAll();
          //$rootScope.$broadcast('refresh-cart');
          if (vm.invalidCartItems.indexOf(cartItem.id) >= 0) {
              vm.invalidCartItems.splice(vm.invalidCartItems.indexOf(cartItem.id), 1);
          }
          NotificationService.success({
            title: 'global.menu.entities.cart',
            message: 'alertMessage.UPDATED_SUCCESSFULLY'
          });
          var index = _.findIndex(responseData.cartItems, function(element) {
            // double check, since undefined === undefined
            return element['id'] !== "undefined" && element['id'] === cartItem['id'];
          });
          //if item exists, update it with new value
          if (index > -1) {
            // update service is not returning buyer specific delivery condition, since we already have it, assign it back
            var itemDC = cartItem.buyerDeliveryConditions ? cartItem.buyerDeliveryConditions.deliveryConditions : cartItem.items.supplier;
            _.extend(responseData.cartItems[index],
              {minDate: (itemDC.noSameDayDelivery)? (angular.copy(new Date(new Date().getTime() + ((itemDC.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
              })
            var tmp = cartItem.buyerDeliveryConditions;
            vm.cartDetails[cartItemIndex] = responseData.cartItems[index];
            vm.cartDetails[cartItemIndex].buyerDeliveryConditions = tmp;
            vm.cartDetails[cartItemIndex].selected = true;
          }
          getCartTotal();
        }, function(error) {
          if (vm.invalidCartItems.indexOf(cartItem.id) < 0) {
              vm.invalidCartItems.push(cartItem.id);
          }
          NotificationService.error({
            title: 'global.cartCheckout',
            error: error
          });
        });
      }
      else{
        //var delDate = oldDeliveryDate.replace(/['"]+/g, '');
        cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(oldDeliveryDate);
      }
    }

    function autoAddToCart(cartItem, $index) {
      if(!cartItem.selected) return;
      isNegativeBuying(cartItem.quantity) || vm.validateMOQ(cartItem.multiplierFactor? cartItem.multiplierFactor: cartItem.items.multiplierFactor, cartItem.quantity, $index);
      if (!(vm.checkoutForm['quantity_' + $index].$valid && vm.checkoutForm['deliveryDate_' + $index].$valid)) {
        // todo to show proper ui error
        return;
      }
      vm.checkMaximumAllocation(cartItem, $index, cartItem.deliveryDate);
    }

    function autoUpdateOnDeliveryChange(cartItem, $index) {
      if (!(vm.checkoutForm['quantity_' + $index].$valid)) {
        // todo to show proper ui error
        return;
      }
      vm.updateCart(cartItem, $index, cartItem.deliveryDate);
    }

    //check the maximum allocation of an item
    function checkMaximumAllocation(itemToBuy, cartItemIndex, deliverydate) {
      if (vm.checkoutForm.$invalid) {
        return false;
      }
      BuyerBuyNowService.getMaximumAllocation({itemId: itemToBuy.items.id,businessOutletId : itemToBuy.buyerBusiness.id})
      .then(function(response){
        vm.maxAllocation = response.data;
        if(vm.maxAllocation === 0 || (vm.maxAllocation !== 0 && itemToBuy.quantity <= vm.maxAllocation)) {
          //if item quantity is less than max allocation update cart
          updateCart(itemToBuy, cartItemIndex, deliverydate);
          return true;
        }
        else {
          NotificationService.simpleErrorToast({
            title: 'error.validation',
            message: 'alertMessage.MAXIMUM_ALLOCATION_VALIDATION',
            params: {value: vm.maxAllocation}
          });
          itemToBuy.quantity = angular.copy(vm.maxAllocation);
          return false;
        }
      },function(error){
        itemToBuy.quantity = angular.copy(vm.maxAllocation);
        return false;
      });
    }

    //Remove an item from cart
    function removeFromCart() {
      CartService.removeFromCart(vm.cartId).
      then(function(responseData) {
        if (vm.invalidCartItems.indexOf(vm.cartId) >= 0) {
            vm.invalidCartItems.splice(vm.invalidCartItems.indexOf(vm.cartId), 1);
        }
        NotificationService.success({
          title: 'global.buttons.removeFromcart',
          message: 'alertMessage.COMPLETED_SUCCESSFULLY'
        });
        vm.translatedError = undefined;
        vm.loadAll();
        $rootScope.$broadcast('refresh-cart');
        vm.cancel();
      }, function(error) {
        NotificationService.error({
          title: 'global.buttons.removeFromcart',
          error: error
        });
      });
    }


    function orderConfirmPopup(){
        vm.disableCheckout = true;
        if (vm.checkoutForm.$invalid) {
          vm.disableCheckout =  false;
          return false;
        }
        //  Show order Confirm pop up
        var orderTitle          = $filter('translate')('ezyprocureApp.cart.confirmOrder.title');
        var orderConfirmMessage = $filter('translate')('ezyprocureApp.cart.confirmOrder.message');
        showConfirmationForOrder(orderTitle, orderConfirmMessage);
    }

    // check for similar PR created with same supplier, outlet, items and delivery date
    function confirmDuplicatePRSubmission() {
      vm.disableCheckout = true;
      if (vm.checkoutForm.$invalid) {
        vm.disableCheckout =  false;
        return false;
      }
      vm.cancel();
      var selectedItems = getSelectedItems(vm.cartDetails);
      if(selectedItems.length == 0){
        NotificationService.simpleErrorToast({
          title: 'global.cartCheckout',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
        return;
      }
      var cartIds = _.map(selectedItems, _.property('id'));
      var params = getUrlStrings();
      params.cartIds = cartIds ? cartIds : null;
      // validating checkout for possible duplicated PRs
      // on success return (means no duplications and cart has been checked out) onSuccessCartCheckout will execute
      CartService.validateCheckout(params, {orderDescription: vm.orderDescription}).then(onSuccessCartCheckout, function(error) {
        // if the error message contains ALREADY_EXISTS, mean swe have found duplicates
        if (error.data.message === 'alertMessage.ALREADY_EXISTS') {
          var title = $filter('translate')('ezyprocureApp.cart.duplicatePR.title'); // title for popup
          var message = $filter('translate')('ezyprocureApp.cart.duplicatePR.message'); // base message for popup
          // if duplications found in pending PO, collect supplier names from duplicates and append to popup message
          var duplicatePRList = error.data.data.pendingPOs;
          if (duplicatePRList.length > 0) {
            message = message + '<li>' + $filter('translate')('ezyprocureApp.cart.duplicatePR.inPendingPO', {no: duplicatePRList.toString()}) + '</li>';
          }
          // if duplications found in pending approvals, collect supplier names from duplicates and append to popup message
          var duplicateApprovals = error.data.data.pendingApprovals;
          if (duplicateApprovals.length > 0) {
            message = message + '<li>' + $filter('translate')('ezyprocureApp.cart.duplicatePR.inApproval', {no: duplicateApprovals.toString()}) + '</li>';
          }
          showConfirmationForDuplicatePRs(title, message); // show confirmation popup if have duplicate PRs
        }
        else if(error.data.message === 'alertMessage.PURCHASE_ORDER_NOT_ACCEPTED' && error.data.responseList[0].reason === 'alertMessage.SUPPLIER_ORDER_TIME_EXPIRED'){
            vm.disableCheckout =  false;
            vm.supplierOrderTimeError = error.data.responseList[0].reason;
            vm.translatedError = error.data;
            vm.translatedError.message = $filter('translate')('global.cartCheckout');
            toastr.error($filter('translate')(vm.supplierOrderTimeError), $filter('translate')('global.cartCheckout'));
        }
        else {
          onFailureCartCheckout(error); // show cart checkout error
        }
      });
    }

    // showing confirmation popup for duplicate PRs. On confirm YES will checkout the cart. On confirm NO will cancel the popup
    function showConfirmationForDuplicatePRs(title, message) {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/confirm-dialog.tmpl.html',
        controller: function() {
          return {
            confirmTitle: title,
            cancel: onConfirmNoForDuplicatePRs,
            confirmBody: message,
            confirmYes: vm.checkoutCartItems,
            confirmNo: onConfirmNoForDuplicatePRs
          };
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    // event to trigger on confirmation popup cancel/NO buttons
    function onConfirmNoForDuplicatePRs() {
      vm.cancel();
      vm.disableCheckout =  false;
    }

    //checkout cart
    function checkoutCartItems() {
      vm.disableCheckout =  true;
      if (vm.checkoutForm.$invalid) {
        vm.disableCheckout =  false;
        return false;
      }
      var selectedItems = getSelectedItems(vm.cartDetails);
      if(selectedItems.length == 0){
        NotificationService.simpleErrorToast({
          title: 'global.cartCheckout',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
        });
        return;
      }
      var cartIds = _.map(selectedItems, _.property('id'));
      var params = getUrlStrings();
      params.cartIds = cartIds ? cartIds : null;
      CartService.checkout(params, {orderDescription: vm.orderDescription}).then(onSuccessCartCheckout, onFailureCartCheckout);
      cancel();
    }

   function checkSupplierTypeInCartDetails(visibilityType){
        //find vm cart details if
       var checkItemsVisibilityType =   _.find(vm.cartDetails,function(cartItem){
          return cartItem.items.supplier.visibilityType == visibilityType;
        });

      return checkItemsVisibilityType;
    }


    // function to execute on success return on checkout cart
    var onSuccessCartCheckout = function(responseData) {
      vm.disableCheckout =  true;
      _.every(responseData.purchaseOrderList.data, { 'visibilityType': 'ADHOC'});
      vm.purchaseOrderList = responseData.purchaseOrderList.data;
      if(checkSupplierTypeInCartDetails('ADHOC') != undefined){
        // vm.showShareByWhatsappConfirm();
        vm.adhocSupplierList = _.uniqBy(_.map(vm.purchaseOrderList, function(adhocPOItems){
          return {
            'supplier': adhocPOItems.processOrder.items.supplier.companyName,
            'poNumber': adhocPOItems.purchaseOrderNumber
          }
        }), 'supplier');
        if(vm.haveAccessForWhatsappPo && vm.purchaseOrderList) {
             vm.showWhatsappConfirmList();
        }
        else{
          $state.go('buyer.dashboard');
        }
      }
     else if(checkSupplierTypeInCartDetails('ALLOCATED')!=undefined){
        $state.go('buyer.dashboard');
      }
      vm.invalidCartItems = [];
      if(responseData.purchaseOrderList.status=='SUCCESS'){
        var hasAdhoc = _.result(_.find(responseData.purchaseOrderList.responseList, function(r){
          return r.index === 0;
        }),'reason');
        var hasAllocated = _.result(_.find(responseData.purchaseOrderList.responseList, function(r){
          return r.index === 1;
        }),'reason')
        if(hasAdhoc){
          NotificationService.success({
            title: 'global.cartCheckoutADHOC',
            message: _.result(_.find(responseData.purchaseOrderList.responseList, function(r){
              return r.index === 0;
            }),'reason')
          });
        }
        if(hasAllocated) {
          NotificationService.success({
            title: 'global.cartCheckoutALLOCATED',
            message:  _.result(_.find(responseData.purchaseOrderList.responseList, function(r){
              return r.index === 1;
            }),'reason')
          });
        }
        $state.go('buyer.dashboard');
      }
      else{
        NotificationService.error({
            title: 'global.cartCheckout',
            message: 'global.alertMessage.failed'
          });
      }


      $rootScope.$broadcast('refresh-cart');
    }

    // function to execute on error return on cart checkout
    var onFailureCartCheckout = function(error) {
      vm.disableCheckout =  false;
      vm.translatedError = error.data;
      if(error.data.message === 'alertMessage.PURCHASE_ORDER_NOT_ACCEPTED' && error.data.responseList[0].reason === 'alertMessage.SUPPLIER_ORDER_TIME_EXPIRED'){
        vm.disableCheckout =  false;
        vm.supplierOrderTimeError = error.data.responseList[0].reason;
        vm.translatedError = error.data;
        vm.translatedError.message = $filter('translate')('global.cartCheckout');
        toastr.error($filter('translate')(vm.supplierOrderTimeError), $filter('translate')('global.cartCheckout'));
    }
    else{
      NotificationService.error({
        title: 'global.cartCheckout',
        error: error
      });
    }
    }

    function checkCartItems(itemList){
      var isCheckoutValid = true;
      _.forEach(itemList,function(value){
        if(isCheckoutValid){
          isCheckoutValid = CartService.checkOrderedTime(value);
        }
      });
    }

    //check checkFreeDeliveryEligibility
    function checkFreeDeliveryEligibility(itemToCheck) {
      var cartItems = angular.copy(vm.cartDetails);
      var totalForSupplier = 0;
      _.forEach(cartItems, function(cartItem) {
        if (cartItem.items.supplier.id === itemToCheck.items.supplier.id && moment(itemToCheck.deliveryDate).format("DD/MM/YYYY") === moment(cartItem.deliveryDate).format("DD/MM/YYYY")) {
          totalForSupplier = totalForSupplier + (cartItem.price * cartItem.quantity);
        }
      });
      var min = itemToCheck.buyerDeliveryConditions ? itemToCheck.buyerDeliveryConditions.deliveryConditions.minimumOrderForFreeDelivery : itemToCheck.items.supplier.minimumOrderForFreeDelivery;
      return (totalForSupplier < min);
    }

    // get item delivery condition to validate free delivery in html
    function getMatchedDeliveryCondition(item) {
      return item.buyerDeliveryConditions ? item.buyerDeliveryConditions.deliveryConditions : item.items.supplier;
    }

    /**
     * [getItemTotal Calculate total price for an cart item]
     * @param  {[type]} cartItem [cartItem]
     */
    function getItemTotal(cartItem) {
      var currency = $filter('translate')('ezyprocureApp.Currency.'+cartItem.items.currency);
      return cartItem.quantity ? ($filter('currency')(CartService.getCartItemTotal(cartItem,false), '', 2) + ' ' + currency) : '-';
    }

    /**
     * [getCartTotal Calculate total price of cart items]
     * @return {[type]} [description]
     */
    function getCartTotal() {
      return CartService.getCartTotal(vm.cartDetails,false);
    }

    function getTimeAbbr12(value) {
      return UtilService.getTimeAbbr12(value);
    }

    function removeFromCartConfirmationModal(cartId, event) {
      // update card Id
      vm.cartId = cartId;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/buy-now/buy-now-main/templates/common/buyer.buy-now.remove-item-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    // showing confirmation popup for confirm Order. On confirm YES will check if duplicate PRs. On confirm NO will cancel the popup
    function showConfirmationForOrder(title, message) {
        $mdDialog.show({
          templateUrl: 'app/core/common-templates/confirm-dialog.tmpl.html',
          controller: function() {
            return {
              confirmTitle: title,
              cancel: onConfirmNoForOrder,
              confirmBody: message,
              confirmYes: vm.confirmDuplicatePRSubmission,
              confirmNo: onConfirmNoForOrder
            };
          },
          controllerAs: 'vm',
          targetEvent: event,
          clickOutsideToClose: false
        });
      }

      // event to trigger on confirmation popup cancel/NO buttons
      function onConfirmNoForOrder() {
        vm.cancel();
        vm.disableCheckout =  false;
      }

    /**
    * [redirectAfterShare redirect to dashboard page after sharing PO via whatsapp]
    * @return {[type]} [description]
    */
    function redirectAfterShare(){
      $mdDialog.cancel();
      $state.go('buyer.dashboard');
    }

    //share PO details via whatsapp
    function shareByWhatsapp(pdfLink, poNumber){
      var itemNames1 = _.map(vm.purchaseOrderList, function(adhocPOItems){
        if(adhocPOItems.purchaseOrderNumber === poNumber){
          vm.deliveryDate =  moment(adhocPOItems.processOrder.deliveryDate).format("DD-MM-YYYY");
          return adhocPOItems.buyerAcceptedQuantity+' X ('+adhocPOItems.processOrder.items.uom.name+') '+ encodeURIComponent(adhocPOItems.processOrder.items.itemName) +'%0D%0ADelivery Date: '+vm.deliveryDate+'%0D%0A';
        }
      });
      var items =_.join(itemNames1,'%0D%0A');
      vm.purchaseDate =  moment(vm.todaysDate).format("DD-MM-YYYY");
      if(vm.isMobile){
        var whatsappShareUrlMobile= "whatsapp://send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
        (vm.cartDetails[0].buyerBusiness.businessOutletName ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.businessOutletName) : "NA") +
        "%0D%0ADelivery Address: " +
        "%0D%0A" + (vm.cartDetails[0].buyerBusiness.businessOutletName ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.businessOutletName) : "NA") +
        (vm.cartDetails[0].buyerBusiness.shippingAddress ? (", " + encodeURIComponent(vm.cartDetails[0].buyerBusiness.shippingAddress)) : "") +
        "%0D%0A" + (vm.cartDetails[0].buyerBusiness.city ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.city) : "") +
        (vm.cartDetails[0].buyerBusiness.postalCode ? (", " + vm.cartDetails[0].buyerBusiness.postalCode): "")+
        "%0D%0APO Date: "+ vm.purchaseDate +
        "%0D%0APO Number: "+ poNumber +
        "%0D%0A%0D%0AProducts:%0D%0A"+items+'%0D%0A'+pdfLink;
        var mobileLink = document.createElement("a");
        document.body.appendChild(mobileLink);
        mobileLink.download = "name";
        mobileLink.href = whatsappShareUrlMobile;
        mobileLink.click();
      }
      else{
        var webUrl = "https://web.whatsapp.com/send?text=*PURCHASE ORDER*%0D%0A%0D%0ABranch Name: "+
          (vm.cartDetails[0].buyerBusiness.businessOutletName ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.businessOutletName) : "NA") +
          "%0D%0ADelivery Address: " +
          "%0D%0A"+(vm.cartDetails[0].buyerBusiness.businessOutletName ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.businessOutletName) : "NA") +
          (vm.cartDetails[0].buyerBusiness.shippingAddress ? (", " + encodeURIComponent(vm.cartDetails[0].buyerBusiness.shippingAddress)) : "") +
          "%0D%0A" + (vm.cartDetails[0].buyerBusiness.city ? encodeURIComponent(vm.cartDetails[0].buyerBusiness.city) : "") +
          (vm.cartDetails[0].buyerBusiness.postalCode ? (", " + vm.cartDetails[0].buyerBusiness.postalCode) : "") +
          "%0D%0APO Date: "+ vm.purchaseDate +
          "%0D%0APO Number: "+ poNumber +
          "%0D%0A%0D%0AProducts:%0D%0A"+items+'%0D%0A'+pdfLink;
        window.open(webUrl);
      }
    }


    //shows confirmation modal for share via whatsapp
    function showShareByWhatsappConfirm(ev) {
      var confirm = $mdDialog.confirm()
      .title('Do you want to share PO via whatsapp?')
      .targetEvent(ev)
      .ok($filter('translate')('global.buttons.yes'))
      .cancel($filter('translate')('global.buttons.no'));
      $mdDialog.show(confirm).then(function() {
        vm.shareByWhatsapp();
      }, function() {});
    }

    /**
    * [showWhatsappConfirmList modal for supplier to whom PO details is to be shared]
    * @param  {[type]} event [description]
    * @return {[type]}       [description]
    */
    function showWhatsappConfirmList(event) {
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/whatsapp-pdf-share.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }

    //get the pdf for individual purchase order details
    function getIndividualPurchaseOrderPDF(poNumber) {
      PDFExportService.getPurchaseOrderPDF(poNumber).then(function(response){
        vm.shareByWhatsapp(response.data.message, poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Purchase Order PDF Export',
          error: error
        });
      });
    }

    /**
     * [validateMOQ Validates the MOQ with Multiplier]
     */
    function validateMOQ(multiplierFactor, quantity, index){
      var item = vm.cartDetails[index];
      if (multiplierFactor || item.items.uom.weightRequired) {
        multiplierFactor = multiplierFactor || 0.001;
      }
      else {
        multiplierFactor = 1;
      }
      item.multiplierFactor = multiplierFactor;
      item.moq = item.moq || 0;
      if(!item.items.uom.weightRequired){
        var splitQty = item.quantity.toString().split(".")[1];
        if(splitQty){
          var len = splitQty.length || 0;
          if(len >= 3){
             vm.checkoutForm['quantity_' + index].$setTouched();
             vm.checkoutForm['quantity_' + index].$setValidity("max-precision", false);
            return ;
          }
          else{
            vm.checkoutForm['quantity_' + index].$setValidity("max-precision", true);
          }
        }
      }
      if(item.items.uom.weightRequired){
        var splitQty = item.quantity.toString().split(".")[1];
        if(splitQty){
          var len = splitQty.length || 0;
          if(len  > 3){
            vm.checkoutForm['quantity_' + index].$setTouched();
            vm.checkoutForm['quantity_' + index].$setValidity("max-precision", false);
            return ;
          }
        }

      }
      if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
        vm.checkoutForm['quantity_' + index].$setTouched();
        vm.checkoutForm['quantity_' + index].$setValidity("moq", false);
      } else {
        vm.checkoutForm['quantity_' + index].$setValidity("moq", true);
      }
    }

    /*
    *[uploadModal ---- file upload popup]
    */
    function uploadModal(){
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/cart-checkout/buyer.cart-upload-attachment.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /*
    *[uploadAttachment --- uploaded selected files to s3 and get the link]
    */
    function uploadAttachment(){
      var base64Array = [];
      var files = vm.files;
      $mdDialog.cancel();
      files.forEach( function(each){
        var deferred = $q.defer();
        CartService.getBase64(each.lfFile, function(base64file) {
          base64Array.push ( {
            extension :  each.lfFile.name.split('.').pop(),
            base64String :  base64file,
            fileName : each.lfFile.name.split('.').shift()
          });
          deferred.resolve(base64file);
        });
        files.push(deferred.promise);
      });
      $q.all(files).then(function(){
        CartService.fileUpload(base64Array).then(function(response){
          NotificationService.success({
            title: 'UPLOAD ATTACHMENT',
            message: 'alertMessage.UPLOADED_SUCCESSFULLY'
          });
          if(!vm.urls) {
              vm.urls = [];
          }
          angular.forEach(response.data.urls, function(url) {
            vm.urls.push(url);
        });
        });
      });
    }

    function deleteAttachment(index){
        vm.urls.splice(index,1);
    }

    /*
    *[getUrlStrings --- set array of links to a single comma seperated link]
    */
    function getUrlStrings(){
      var params = {
        attachmentLink: '',
      }
      if(!vm.urls)
        return params;
      var urlString = ''
      urlString = vm.urls.join();
       params = {
        attachmentLink:  urlString
      }
      return params;
    }

    function descriptionPopup(){
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/po-order-description.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function getSelectedItems(items){
      return  _.filter(items, {'selected': true});
    }

    function selectAll(){
      vm.selectAllRows ? vm.selectAllRows = true : vm.selectAllRows = false;
      angular.forEach(vm.cartDetails, function(cart) {
        cart.selected = vm.selectAllRows;
      });
      var checkAllFalse = _.every(vm.cartDetails, { 'selected': false });
        if(checkAllFalse){
            vm.disableCheckout = true;
        }
        else{
            vm.disableCheckout = false;
        }
    }

    function updateSelection(cartItem, index){
      var selected = cartItem.selected
      vm.cartDetails[index].selected = selected;
      //vm.autoAddToCart(cartItem, index);
      getCartTotal();
      var checkAllFalse = _.every(vm.cartDetails, { 'selected': false });
        if(checkAllFalse){
            vm.disableCheckout = true;
        }
        else{
            vm.disableCheckout = false;
        }
    }

    // Delivery Date Check, if invalid date will set the previous date
    function addToCartDateCheck(items,oldDeliveryDate){
        vm.deliveryDateBeforeChange = new Date(oldDeliveryDate);
        if(vm.setPreviousDeliveryDate){
            var index = vm.cartDetails.findIndex(function(el) {
                return el.id == items.id;
            });
            vm.cartDetails[index].deliveryDate = vm.deliveryDateBeforeChange;
        }
        else{
            var index =vm.cartDetails.findIndex(function(el) {
                return el.id == items.id;
            });
            vm.cartDetails[index].deliveryDate = new Date(items.deliveryDate);
        }
    }

    function getSupplierHolidays(supplierId){
      CartService.getAllSupplierCalendarsHoliday(supplierId).then(function(response){
        vm.holidaysList = response.data;
      })
      return vm.holidaysList;
    }
   
    /*
    *disable days in calendar based on default/specific delivery condition
    */
    function disableDays(date) {
      var item = vm.selecteddatePicker, noDeliveryDays = [], enabledDaysFlag = true;
      const day = date.getDay();
      
      var selectedSupplier = item.items? item.items.supplier : item.supplier;
      var deliveryCond = item.buyerDeliveryConditions ? item.buyerDeliveryConditions.deliveryConditions : selectedSupplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default conditions
      
      if(deliveryCond != undefined){
        if (deliveryCond.noDeliveryOnSaturday) noDeliveryDays.push(6);
        if (deliveryCond.noDeliveryOnSunday) noDeliveryDays.push(0);
        if (deliveryCond.noDeliveryOnMonday) noDeliveryDays.push(1);
        if (deliveryCond.noDeliveryOnTuesday) noDeliveryDays.push(2);
        if (deliveryCond.noDeliveryOnWednesday) noDeliveryDays.push(3);
        if (deliveryCond.noDeliveryOnThursday) noDeliveryDays.push(4);
        if (deliveryCond.noDeliveryOnFriday) noDeliveryDays.push(5);
        return !_.includes(noDeliveryDays,date.getDay());
      }else{
        return true;
      }
    };

     /*
    *set the selected datepicker
    */
    function openCalendar(itemToBuy){  
      vm.selecteddatePicker = itemToBuy;
    }

  }
})();
