/**
* @Author: Ajay
* @Date:   21-Mar-2017 19:15 +05:30
* @Project: Ezyprocure
* @Filename: manage-suppliers.list.controller.js
 * @Last modified by:   Jayalekshmi R
 * @Last modified time: 2018-06-20T16:41:58+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('ManageSupplierListController', ManageSupplierListController);

  ManageSupplierListController.$inject = ['$scope', '$rootScope', '$state', 'NotificationService', '$mdDialog', 'ManageSupplierService', 'pagingParams', 'Auth', 'paginationConstants',  '$stateParams', 'PdfUploadService', 'toastr', 'BuyerSupplierMappingService', 'BuyerDataService', 'AdhocItemPriceUpdateService'];

  function ManageSupplierListController($scope, $rootScope, $state, NotificationService, $mdDialog, ManageSupplierService, pagingParams, Auth, paginationConstants, $stateParams, PdfUploadService, toastr, BuyerSupplierMappingService, BuyerDataService, AdhocItemPriceUpdateService) {
    var vm = this;

    vm.predicate = pagingParams.predicate;
    vm.reverse = pagingParams.ascending;
    vm.searchText = '';
    vm.loadAll = loadAll;
    vm.showConfirm = showConfirm;
    vm.showVisibilityEditModal = showVisibilityEditModal;
    vm.saveVisibilityType = saveVisibilityType;
    vm.userRole = Auth.getUserRole();
    vm.cancel = cancel;
    vm.visibilityType = $stateParams.visibilityType ? $stateParams.visibilityType : 'ALLOCATED';
    // To check supplier management state
    vm.mst = vm.visibilityType === 'ADHOC' ? 'adhoc-tools.adhoc-suppliers' : 'suppliers';
    vm.showKycUpload = showKycUpload;
    vm.getPdfFileBase64 = getPdfFileBase64;
    vm.supplierKycUpload = supplierKycUpload;
    vm.setStatusKyc = setStatusKyc;
    vm.getPdfUrl = getPdfUrl;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.getSuppliersList = getSuppliersList;
    vm.getAdhocSuppliersList = getAdhocSuppliersList;
    

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    /**
     * [getAdhocSuppliersList gets all adhoc suppliers mapped to the buyer if the visibility type is ADHOC]
     */
    function getAdhocSuppliersList(){
      vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
      AdhocItemPriceUpdateService.getAllBuyerMappedAdhocSuppliers(vm.buyerId,{
          page: vm.query.page - 1,
          size: vm.query.limit,
          query: vm.searchText,
          sort: sort()
        }).then(function(response) {
          vm.totalItems = response.headers('X-Total-Count');
          vm.suppliers = response.data;
        }, function(error) {
          NotificationService.error({
            title: 'alertMessage.SUPPLIERS_LIST',
            error: error
          });
        });
    }

    /**
     * [getSuppliersList gets all supplier list]
     */
    function getSuppliersList(){
      ManageSupplierService.getAllAdminSuppliersWithUserDetails({
        page: vm.query.page - 1,
        size: vm.query.limit,
        search: vm.searchText,
        visibilityType: vm.visibilityType,
        sort: sort(),
        isAdminDashboard:vm.userRole.ugly==='ROLE_ADMIN' && $rootScope.redirectionApiFromDashboard==true?true:false
      }).then(function(response) {
        vm.totalItems = response.headers('X-Total-Count');
        vm.suppliers = response.data;
      }, function(error) {
        NotificationService.error({
          title: 'alertMessage.SUPPLIERS_LIST',
          error: error
        });
      });
    }

    function loadAll(search) {
      if (!search) {
        vm.searchText = '';
      }
      if(vm.visibilityType == 'ADHOC'){
        getAdhocSuppliersList();
      }else{
        getSuppliersList();
      }
    }

    vm.loadAll();

    function deleteAdminSupplier(supplierId) {
      ManageSupplierService.deleteAdminSupplier(supplierId).then(function(response) {
        NotificationService.success({
          title: 'Admin Supplier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Admin Supplier Deletion',
          error: error
        });
      });
    }

    function showConfirm(ev, supplier) {
      var confirm = $mdDialog.confirm()
        .title('Are you sure you want to delete Admin Supplier?')
        .textContent('This action could not be reverted back.')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        deleteAdminSupplier(supplier.id);
      }, function() {});
    }

    /**
    * [showVisibilityEditModal opens modal to choose visibility type]
    * @param  {[type]} event [description]
    */
    function showVisibilityEditModal(event, supplier) {
      vm.selectedSupplier = angular.copy(supplier);
      $mdDialog.show({
        templateUrl: 'app/common-modules/manage-suppliers/manage-suppliers.visibility-edit-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
     * [showKycUpload opens modal to upload KYC file]
     * @param {Object} event
     */
    function showKycUpload(event, supplier){
      vm.selectedSupplier = angular.copy(supplier);
      //vm.getPdfUrl(vm.selectedSupplier);
    $mdDialog.show({
      templateUrl:'app/common-modules/manage-suppliers/manage-suppliers.kyc-upload.html',
      controller:function(){
        return vm;
      },
      controllerAs: 'vm',
      targetEvent: event,
      clickOutsideToClose: true
    }); 
    }

    /**
     * [getPdfFileBase64 converts the uploaded file into base64]
     * @param {Integer} supplierId
     */
    function getPdfFileBase64(supplierId) {
      var file = vm.files[0];
      if (!file) {
        toastr.error('Please upload a file!', 'File Required');
        return false;
      }
      PdfUploadService.getPdfFileBase64(file.lfFile, function(base64PDF) {
        vm.fileUploadData = {
          kycReport: base64PDF
        };
        vm.supplierKycUpload(vm.fileUploadData,supplierId);
      });
    }

    /**
     * [supplierKycUpload updates the KYC status]
     * @param {Object} data
     * @param {Object} params
     */
    function supplierKycUpload(data,supplierId){
      vm.cancel();
      var params = {supplierId: supplierId}
      ManageSupplierService.supplierKycUpload(data, params).then(function(response){
        vm.files = '';
        NotificationService.success({
          title: 'Supplier KYC update',
          message: 'KYC Updated successfully'
        });
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Supplier KYC update',
          error: error
        });
      }
      );
    }

    /**
    * [cancel to close the modal]
    */
    function cancel() {
      $mdDialog.cancel();
   }

    function saveVisibilityType(selectedSupplier) {
      ManageSupplierService.updateASVisibilityType(selectedSupplier).then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Supplier Visibility type',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Supplier Visibility type Updation',
          error: error
        });
      });
    }

    /**
     * [setStatusKyc updates the KYC status (modal)]
     */
    function setStatusKyc(status){
      var data = {
        supplierId : vm.selectedSupplier.id,
        kycStatus : status
      }
      ManageSupplierService.updateKycStatus(data).then(function(response){
        NotificationService.success({
          title: 'KYC Status',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        }); 
        vm.loadAll();
      },function(error){
        NotificationService.error({
          title: 'Supplier KYC update',
          error: error
        });
      })
    }

    /**
     * [getPdfUrl returns Url for the Pdf preview]
     */
    function getPdfUrl(supplier){
      ManageSupplierService.getPdfUrl({supplierId : supplier.id}).then(function(response){
        vm.url = response.data.message;
        window.open(vm.url);
      });
    }
  }
})();
