/**
 * @Author: Elsa
 * @Date:   10-Mar-2017 12:55 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.good-receipt-note.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-25T09:54:54+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierGoodReceiptNoteDetailsController', SupplierGoodReceiptNoteDetailsController);

  SupplierGoodReceiptNoteDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', 'pagingParams', '$stateParams', 'toastr', '$mdDialog', 'PurchaseOrderService', 'NotificationService', 'UTCDateService', 'paginationConstants', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'Auth', 'GLOBAL_CONSTANTS'];

  function SupplierGoodReceiptNoteDetailsController($scope, $rootScope, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, PurchaseOrderService, paginationConstants, NotificationService, UTCDateService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.poNumber = pagingParams.poNumber;
    // vm.deliveryDate = $stateParams.deliveryDate;
    // vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.deliveryDate = $stateParams.deliveryDate ? $stateParams.deliveryDate : '';
    vm.getGoodsReceiptNoteCSVData = getGoodsReceiptNoteCSVData;
    vm.getGoodReceiptNotePDF = getGoodReceiptNotePDF;
    vm.formatPrice = formatPrice;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "GOOD-RECEIPT-NOTE";
    vm.role = 'ROLE_SUPPLIER';
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.roundOfPrice = roundOfPrice;

    vm.predicate = $stateParams.predicate || pagingParams.predicate;
    vm.reverse = $stateParams.reverse || pagingParams.reverse;
    if (pagingParams.predicate) {
      vm.reverse = pagingParams.reverse === 'true';
      vm.predicate = pagingParams.predicate;
    }else{
      vm.reverse = pagingParams.reverse;
    }

    vm.loadAll = loadAll;
    vm.query = {
      limit: 20,
      page: 1
    };


    /**
     * [init loads initially]
     */
    function init(){
      if(vm.accessForPriceDisplay)
        vm.goodsReceiptNoteHeader = EXPORT_CONSTANTS.CSV.goodsReceiptNoteHeaderSupplier;
      else {
        vm.goodsReceiptNoteHeader = _.without(EXPORT_CONSTANTS.CSV.goodsReceiptNoteHeaderSupplier, 'unitPrice' , 'amountPO', 'amountAccepted');
      }
      loadAll();
    }
    function sort() {
      var rev = 'asc';
      if (!vm.reverse) {
        rev = 'desc';
      };
     var result = [vm.predicate + ',' + rev];
      return result;
    }
    /**
     * [loadAll Gets GRN Details by PO number]
     */
     function loadAll() {

       PurchaseOrderService.getGRNByPONumber(vm.poNumber, {
          deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          page: vm.query.page - 1,
          size: vm.query.limit,
          query: '',
          sort: sort()
       }).then(function(response){
         vm.grnDetails = response.data;
         vm.grnDetails.subTotal = vm.grnDetails.acceptTotalAmount;
         vm.purchaseOrderNumber = vm.grnDetails.purchaseOrderNumber;
         vm.requirementNumber = vm.grnDetails.requirementNumber;
         vm.invoiceStatus = vm.grnDetails.items[0].invoiceStatus;
         vm.selectedDeliveryDate = vm.grnDetails.items[0].deliveryDate;
       },function(error){
         NotificationService.error({
           title: 'Good Receipt Note Information',
           error: error
         });
       });
     }

    function getGoodsReceiptNoteCSVData() {
      return CSVExportService.getGoodsReceiptNoteCSV(vm.grnDetails,'supplier');
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

  //To handle large decimal values round of to 4 and then to 2
  function roundOfPrice(amount){
    var totalAmount;
    totalAmount = $filter('totalRoundOfDecimal')(amount);
    return totalAmount;
}
    function getGoodReceiptNotePDF() {
      PDFExportService.getGoodReceiptNotePDF(vm.poNumber, {
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: '',
        sort: sort(),
      }).then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Good Receipt Note PDF Export',
          error: error
        });
      });
    }


      //round off price to two decimal places
          function formatPrice(amount){
            return (Math.round((amount)*1000)/1000);
          }

  }
})();
