/**
 * @Author: Mufsir
 * @Date:   28-Jun-2017 11:12 +05:30
 * @Project: Ezyprocure
 * @Filename: buyer.price-update-request.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 10-Nov-2017 11:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerPriceUpdateRequestController', BuyerPriceUpdateRequestController);

  BuyerPriceUpdateRequestController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'NotificationService', '$mdDialog', 'pagingParams', 'PriceUpdateRequestService', 'paginationConstants', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'CSVExportService', '$q'];

  function BuyerPriceUpdateRequestController($scope, $rootScope, $filter, $state, $stateParams, NotificationService, $mdDialog, pagingParams, PriceUpdateRequestService, paginationConstants, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, CSVExportService, $q) {
    var vm = this;
    vm.init = init;
    vm.predicate = 'items.supplier.companyName';
    vm.reverse = 'asc';
    vm.loadAll = loadAll;
    vm.showPriceUpdateRejectModal = showPriceUpdateRejectModal;
    vm.cancel = cancel;
    vm.updatePriceUpdateRequestStatus = updatePriceUpdateRequestStatus;
    vm.checkAll = checkAll;
    vm.checkAllSuppliyer = checkAllSuppliyer;
    vm.getSelectedItemsIds = getSelectedItemsIds;
    vm.updatePriceForAllSelectedItems = updatePriceForAllSelectedItems;
    vm.showWarning = false;
    vm.update = update;
    vm.getCSVData = getCSVData;
    vm.csvHeader = EXPORT_CONSTANTS.CSV.priceUpdateList;
    vm.showWarningForRejectAll = showWarningForRejectAll;

    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.limitOptions = paginationConstants.limitOptions;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;

    function init() {
      loadAll();
    }
    /**
     * [sort to sort by PO date]
     */
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    //Get all Price update requests
    function loadAll(search) {
      if (!search) {
        vm.searchItem = '';
        vm.searchSupplier = '';
      }
      PriceUpdateRequestService.getAllPriceUpdates({
        isApprovalRequired: true,
        query: '',
        itemName : vm.searchItem,
        supplierName : vm.searchSupplier,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: sort()
      }).then(function(response){
        vm.priceUpdateItems = response.data;
        vm.priceUpdateItems.forEach(function (item,index) {
            if(index > 0) {
            vm.priceUpdateItems[index].showLabel = vm.priceUpdateItems[index].items.supplier.companyName !== vm.priceUpdateItems[index-1].items.supplier.companyName;
            } else {
                vm.priceUpdateItems[index].showLabel = true;
            }
        });
        vm.totalItems = response.headers('X-Total-Count');
      },function(error){
        NotificationService.error({
          title: 'Price Updates Listing',
          error: error
        });
      });
    }

    /**
     *
     * @param {*} event
     */
    function showWarningForRejectAll(event, status){
      if(getSelectedItemsIds().length<1){
        NotificationService.simpleErrorToast({
          title: 'global.error',
          message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          })
        return;
      }
      if(status === 'REJECTED'){
        var suppliersOfSelctedItems = _.map(_.filter(vm.priceUpdateItems, {'selected': true}), _.property('items.supplier.companyName'));
        _.uniq(suppliersOfSelctedItems).length == 1 ? vm.showWarning = false: vm.showWarning = true;
      }
        if(vm.showWarning){
        var confirm = $mdDialog.confirm()
        .title('Approve / Reject price for all selected items')
        .textContent('Selected items are from different Suppliers..Please click ok to continue..')
        .targetEvent(event)
        .ok('Ok')
        .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          vm.showWarning = false;
          showPriceUpdateRejectModal(event);
        }, function () {
          vm.showWarning = false;
        });
        }else{
          showPriceUpdateRejectModal(event);
        }
    }

    //Show price update reject modal
    function showPriceUpdateRejectModal(event, priceUpdateItem) {
      vm.showRejectAll = priceUpdateItem ? false : true;
      vm.selectedPriceUpdateItem = priceUpdateItem;
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/price-update-request/buyer.price-update-request.reject.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function updatePriceUpdateRequestStatus(status, priceUpdateItem) {
      PriceUpdateRequestService.updatePriceUpdateRequestStatus(status, priceUpdateItem)
      .then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Price Update Request',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Price Update Request Process',
          error: error
        });
      });
    }

    function cancel() {
      $mdDialog.cancel();
    }

    //Getting CSV DATA
    function getCSVData(){
        var deferred = $q.defer();
        if (!vm.priceUpdateItems) {
          NotificationService.simpleErrorToast({
            title: 'global.table.noRecordsFound',
            message: 'alertMessage.SEARCH_AND_SUBMIT'
          });
          return deferred.promise;
        }
        else{
              PriceUpdateRequestService.getAllPriceUpdates({
                isApprovalRequired: true,
                query: '',
                itemName : vm.searchItem,
                supplierName : vm.searchSupplier,
                page: vm.query.page - 1,
                size: vm.totalItems,
                sort: sort()
              }).then(function(response){
                deferred.resolve(CSVExportService.getPriceUpdateCSV(response.data));
              },function(error){
                NotificationService.error({
                  title: 'Price Updates Listing',
                  error: error
                });
                deferred.reject(error);
              });
              return deferred.promise;
        }
      }

    /**
    * [checkAll select all Pending Process Orders]
    */
    function checkAll() {
      vm.selectAll ? vm.selectAll = true : vm.selectAll = false;
      _.map(vm.priceUpdateItems, function(poItem) {
        poItem.selected = vm.selectAll;
      });
      _.map(vm.selectAllSuppliyer, function(key) {
        vm.selectAllSuppliyer[key] = vm.selectAll;
      });
    }

    /**
    * [checkAll select all suppliyer Pending Process Orders]
    */
     function checkAllSuppliyer(suppliyer) {
        vm.selectAllSuppliyer[suppliyer] ? vm.selectAllSuppliyer[suppliyer] = true : vm.selectAllSuppliyer[suppliyer] = false;
        _.map(vm.priceUpdateItems, function(poItem) {
            if(poItem.items.supplier.companyName === suppliyer) {
                poItem.selected = vm.selectAllSuppliyer[suppliyer];
            }
        });
      }

    /**
     * [getSelectedItemsIds Returns selected objects]
     */
    function getSelectedItemsIds(){
      return _.map(_.filter(vm.priceUpdateItems, {'selected': true}), _.property('id'));
    }

    /**
     * [updatePriceForSelectedItems Approve All]
     */
    function updatePriceForAllSelectedItems(ev, status, buyerRemark){
      if(getSelectedItemsIds().length<1){
        NotificationService.simpleErrorToast({
            title: 'global.error',
            message: 'alertMessage.SELECT_ITEMS_TO_CONTINUE'
          })
          return;
      }
      if(status === 'ACCEPTED'){
        var suppliersOfSelctedItems = _.map(_.filter(vm.priceUpdateItems, {'selected': true}), _.property('items.supplier.companyName'));
        _.uniq(suppliersOfSelctedItems).length == 1 ? vm.showWarning = false: vm.showWarning = true;
      }
      if(vm.showWarning){
        var confirm = $mdDialog.confirm()
        .title('Approve / Reject price for all selected items')
        .textContent('Selected items are from different Suppliers..Please click ok to continue..')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

        $mdDialog.show(confirm).then(function () {
          vm.update(status, buyerRemark);
          vm.showWarning = false;
        }, function () {
          vm.showWarning = false;
        });
        }else{
          vm.update(status, buyerRemark)
        }
    }

    function update(status, buyerRemark){
      var priceUpdateItem = {};
      priceUpdateItem.priceUpdateIdList = vm.getSelectedItemsIds();
      priceUpdateItem.buyerRemark = buyerRemark;
      PriceUpdateRequestService.updatePriceUpdateRequestStatusForAll(status, priceUpdateItem)
      .then(function(response) {
        vm.cancel();
        NotificationService.success({
          title: 'Price Update Request',
          message: 'alertMessage.PROCESSED_SUCCESSFULLY'
        });
        vm.loadAll();
      }, function(error) {
        NotificationService.error({
          title: 'Price Update Request Process',
          error: error
        });
      });
    }
  }
})();
