/**
 * @Author: Ajay
 * @Date:   23-Feb-2018 16:35 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.purchase-order.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T15:44:40+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPurchaseOrderIndividualDetailsController', SupplierPurchaseOrderIndividualDetailsController);

  SupplierPurchaseOrderIndividualDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', 'SupplierDataService', 'PurchaseOrderService', 'NotificationService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'Auth', 'GLOBAL_CONSTANTS'];

  function SupplierPurchaseOrderIndividualDetailsController($scope, $rootScope, $filter, $state, $stateParams, pagingParams, toastr, SupplierDataService, PurchaseOrderService, NotificationService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.predicate = $stateParams.predicate || pagingParams.predicate;
    vm.reverse = $stateParams.reverse || pagingParams.reverse;
    if ($stateParams.reverse) {
      // vm.predicate = $stateParams.predicate;
      vm.reverse = $stateParams.reverse === 'asc';
    }else{
      // vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.reverse;
    }
    
    vm.query = {
      limit: 20,
      page: 1
    };
    vm.filter = {};
    // vm.limitOptions = paginationConstants.limitOptions;
    vm.poNumber = $stateParams.poNumber;
    vm.previewType = $stateParams.type;
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.deliveryDate = $stateParams.deliveryDate ? $stateParams.deliveryDate : '';
    vm.groupPODetailsByDate = groupPODetailsByDate;
    vm.formatPrice = formatPrice;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "PURCHASE-ORDER";
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.userProfile = SupplierDataService.getSupplierProfile();
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getItemSupplierRemark = getItemSupplierRemark;
    vm.isSupplierRemarkNotEmpty = isSupplierRemarkNotEmpty;
    vm.getIndividualDeliveryOrderPDF = getIndividualDeliveryOrderPDF;
    vm.purchaseOrderPreview = purchaseOrderPreview;
    vm.specialRequestList = specialRequestList;
    vm.roundOfPrice = roundOfPrice;
    vm.loadAll = loadAll;

    /**
     * [init loads initilally]
     */
     function sort() {
       var rev = 'asc';
       if (!vm.reverse) {
         rev = 'desc';
       };
      var result = [vm.predicate + ',' + rev];
      return result;
    }
    function init(){
      if(vm.accessForPriceDisplay)
      vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
      else {
        vm.purchaseOrderHeader = _.without(EXPORT_CONSTANTS.CSV.purchaseOrderHeader, 'unit_price' , 'amount_required', 'amount_supplier');
      }
      loadAll();
    }
    
    /**
     * [loadAll Gets Purchase Order Details by PO number]
     */

     function loadAll() {
       if(vm.deliveryDate){
         vm.deliveryDate = vm.deliveryDate.split(',');
       }
       PurchaseOrderService.getPurchaseOrderByPurchaseOrderNumber(vm.poNumber, {
          deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
          page: vm.query.page - 1,
          size: vm.query.limit,
          query: '',
        sort: sort(),
       }).then(function(response){
         vm.poDetails = response.data;
         vm.purchaseOrderNumber = vm.poDetails.purchaseOrderNumber;
         vm.requirementNumber = vm.poDetails.items[0].buyerRequirement.requirementNumber;
         vm.invoiceStatus = vm.poDetails.items[0].invoiceStatus;
         vm.selectedDeliveryDate = vm.poDetails.items[0].buyerRequirement.deliveryDate;
         vm.poDetailGroup = vm.groupPODetailsByDate(vm.poDetails.items);
         vm.specialRequest = vm.specialRequestList(vm.poDetails.items);

       },function(error){
         NotificationService.error({
           title: 'Purchase Order',
           error: error
         });
       });
     }

     function purchaseOrderPreview(poNumber,type,poDetailItems){
        var deliveryDate = _.map(poDetailItems, function(each){
          if(each.supplierResponses){
            return each.supplierResponses.deliveryDate;
          }
          return each.buyerRequirement.deliveryDate;
        });
      deliveryDate = _.uniq(deliveryDate);
      var rev = vm.reverse ? 'asc' : 'desc' ;
      
        $state.go('supplier.reports.purchase-order.preview',{
            poNumber: poNumber, type: type, deliveryDate: deliveryDate,predicate:vm.predicate, reverse:rev
        });
     }

    /**
     * [groupPODetailsByDate description]
     * @param  {[objectArray]} itemsData [PODetails to be grouped according to delivery date]
     * @return {[objectArray]}           [description]
     */
     function groupPODetailsByDate(itemsData){
       var poDetailsGroup= _.groupBy(itemsData, function(purchaseOrder){
         if(purchaseOrder.supplierResponses)
         var deliveryDate = moment(purchaseOrder.supplierResponses.deliveryDate).format('DD-MM-YYYY');
         else
         var deliveryDate = moment(purchaseOrder.buyerRequirement.deliveryDate).format('DD-MM-YYYY');
         return deliveryDate;
       });
       var orderDetailsGroup = _.map(poDetailsGroup, function(purchaseOrder, key){
         purchaseOrder['sumRequired'] = vm.sumRequired(purchaseOrder);
         purchaseOrder['sumSupplier'] = vm.sumSupplier(purchaseOrder);
         purchaseOrder['deliveryDate'] = purchaseOrder[0].supplierResponses? purchaseOrder[0].supplierResponses.deliveryDate : purchaseOrder[0].buyerRequirement.deliveryDate;
         return purchaseOrder;
       });
       var purchaseOrders = _.values(orderDetailsGroup);
       return purchaseOrders;
     }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
         
            totalAmount += item.amount
         
        }
      });
      return _.round(totalAmount,2);
    }

    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.weightRequired === false) {
            if(item.supplierResponses === null) {
                totalAmount += item.amount
            }
            else {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += _.round((item.supplierResponses.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price,2);
              }
              else
              {
                //To handle large decimal values use format price fn 
                totalAmount += _.round(vm.formatPrice(item.supplierResponses.quantity * item.buyerRequirement.price),2);
              }
            }
          }
          else {
            if(item.supplierResponses === null) {
           
                totalAmount += item.amount
            }
            else {
              //totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += _.round((item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price,2);
              }
              else
              {
                totalAmount += _.round(item.supplierResponses.weight * item.buyerRequirement.price,2);
              }
            }
          }
        }
      });
      return _.round(totalAmount,2);
    }

    function getPurchaseOrderCSVData(poDetailItems) {
     
      var csvFilter = {
        page: vm.query.page - 1,
          size: vm.query.limit,
          query: '',
        sort: sort()
      };
     
      return CSVExportService.getPurchaseOrderCSV(vm.poDetails, poDetailItems, csvFilter);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    // get Pdf for DO.
    function getIndividualDeliveryOrderPDF(poDetailItems) {
      if(poDetailItems){
        var deliveryDate = _.map(poDetailItems, function(each){
          if(each.supplierResponses){
            return each.supplierResponses.deliveryDate;
          }
          return each.buyerRequirement.deliveryDate;
        });
        deliveryDate = _.uniq(deliveryDate);
        vm.selectedDeliveryDate = deliveryDate;
      }
      var doPdfSort = {
        page: vm.query.page - 1,
          size: vm.query.limit,
          query: '',
        sort: sort()
      }
      PDFExportService.printMassDOPDF([{
        'deliveryDateList': vm.deliveryDate ? (Array.isArray(vm.deliveryDate) ? vm.deliveryDate : [vm.deliveryDate]): (Array.isArray(vm.selectedDeliveryDate) ? vm.selectedDeliveryDate : [vm.selectedDeliveryDate]),
        'purchaseOrderNumber': vm.poNumber,
        // 'page': vm.query.page - 1,
        //   'size': vm.query.limit,
        //   'query': '',
        // 'sort': sort()
      }],doPdfSort)
        .then(function(response){
        downloadURI(response.data.message, "DOPDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Individual DO PDF Export',
          error: error
        });
      });
    }

    function getIndividualPurchaseOrderPDF(deliveryDate) {
      PDFExportService.getPurchaseOrderPDF(vm.poNumber, {
        deliveryDate: deliveryDate,
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: '',
        sort: sort()
      })
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Individual PO PDF Export',
          error: error
        });
      });
    }

    // get supplier remarks from supplier responses if not found get from buyer requirement supplier response
    function getItemSupplierRemark(poDetail) {
      return poDetail.supplierResponses ? poDetail.supplierResponses.supplierRemark : poDetail.buyerRequirement.supplierResponse
    }

    function isSupplierRemarkNotEmpty(poDetail) {
      var remark = vm.getItemSupplierRemark(poDetail);
      return remark && remark.length > 0;
    }

    //round off price to two decimal places
    function formatPrice(amount){
      return (Math.round((amount)*1000)/1000);
    }
    function specialRequestList(poDetailItems){
        vm.specialrequestArray = [];
        poDetailItems.forEach(function (item) {
            if(item.buyerRequirement.specialRequest){
                    vm.specialrequestArray.push({
                        "itemCode": item.buyerRequirement.items.itemCode,
                        "itemName": item.buyerRequirement.items.itemName,
                        "specialRequest" : item.buyerRequirement.specialRequest
                    })
            }
        })
    }

    //To handle large decimal values round of to 4 and then to 2
    function roundOfPrice(amount){
      var totalAmount;
      totalAmount = $filter('totalRoundOfDecimal')(amount);
      return totalAmount;
    }
  }
})();
