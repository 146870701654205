/**
* @Author: Allan
* @Date:   07-June-2018 16:15 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.buyer.due-payment-summary.details.controller.js
* @Last modified by:   Allan
* @Last modified time: 17-October-2018 14:45 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzypaymentDuePaymentSummaryDetailsController', EzypaymentDuePaymentSummaryDetailsController);

  EzypaymentDuePaymentSummaryDetailsController.$inject = ['paginationConstants','$state', '$q', '$timeout', 'DuePaymentInvoicesService', 'NotificationService', 'CardsService', 'Auth', '$mdDialog', '$filter', 'GLOBAL_CONSTANTS', 'toastr', 'CustomPaymentsService'];

  function EzypaymentDuePaymentSummaryDetailsController(paginationConstants,$state, $q, $timeout, DuePaymentInvoicesService, NotificationService, CardsService, Auth, $mdDialog, $filter, GLOBAL_CONSTANTS, toastr, CustomPaymentsService) {

    var vm = this;
    var popup = this;
    popup.message = '';
    vm.init = init;
    vm.getBrandIds = getBrandIds;
    var selectedPOIds = DuePaymentInvoicesService.getSelectedPOIds();
    vm.brandIdsC = DuePaymentInvoicesService.getBrandIds();
    vm.getDuePaymentSummary = getDuePaymentSummary;
    vm.cardTokenByBrands = cardTokenByBrands;
    vm.getPurposes = getPurposes;
    vm.brandIds = [];
    vm.tokenCards = [];
    vm.duePaymentSummaryDetails = [];
    popup.cancel = cancel;
    vm.purposeCode = DuePaymentInvoicesService.getPurpose();
    vm.purposeCodeObj = DuePaymentInvoicesService.getPurposeObj();
    vm.loadTransactionReport = loadTransactionReport;
    vm.confirmAndPay = confirmAndPay;
    vm.paymentResponse = [];
    vm.accessForOcr = Auth.hasPermission("accessForOcr");
    vm.invoiceType = DuePaymentInvoicesService.getInvoiceType();
    vm.isCustom = DuePaymentInvoicesService.getIsCustom();
    vm.getExternalDuePaymentSummary = getExternalDuePaymentSummary;
    vm.showDuePaymentMessage = showDuePaymentMessage;
    vm.getCardType = getCardType;
    vm.calculateSum=calculateSum;
    vm.purposeChange=purposeChange;
    // 2FA changes
    vm.otp = undefined;
    vm.tempToken = {};
    popup.okpop = okpop;
    vm.isOtpValid = true;
    vm.counterMilliSeconds = undefined;
    vm.counterSeconds = undefined;
    vm.counterMinutes = undefined;
    vm.validateOTP = validateOTP;
    vm.resendOTP = resendOTP;
    vm.refreshData = refreshData;
    vm.brandChangeId = null;
    vm.issuer = [];
    vm.preTokenId = vm.brandTokens;
    vm.selectedPOs = DuePaymentInvoicesService.getSelectedPO();
    vm.isTTCharge = DuePaymentInvoicesService.getIsTTCharge();

    function init() {
        if(vm.isCustom){
        vm.getPurposes();
        vm.cardTokenByBrands();
        } else {
            if(vm.invoiceType === 'INTERNAL'){
              vm.getDuePaymentSummary();
            } else {
              vm.getExternalDuePaymentSummary();
            }
        }
    }
    function purposeChange() {
        vm.purposeCodeObj = _.filter(vm.purposes, function(purpose) {
            return purpose.id == vm.purposeCode;
        })[0];
        DuePaymentInvoicesService.setPurpose(vm.purposeCodeObj.id);
        DuePaymentInvoicesService.setPurposeObj(vm.purposeCodeObj);
        if(vm.invoiceType === 'INTERNAL'){
           vm.getDuePaymentSummary();
        } else {
           vm.getExternalDuePaymentSummary();
        }
    }
    function getPurposes() {
        CustomPaymentsService.getPurposes().then(function (res) {
            vm.purposes = res.data;
          }, function (error) {
            NotificationService.error({
              title: 'Purposes',
              error: error
            });
          });
    }
    function okpop() {
        _.filter(vm.tempToken.list, function(token) {
            if(token.id == vm.tempToken.id){
                vm.issuer[vm.brandChangeId] = token.issuer;
            }
          });
        vm.brandChangeId = vm.tempToken.brandId;
        vm.brandTokens[vm.tempToken.brandId] = vm.tempToken.id;
        vm.preTokenId[vm.tempToken.brandId] = vm.brandTokens[vm.tempToken.brandId];
        vm.issuer[vm.tempToken.brandId] = vm.tempToken.issuer;
        $mdDialog.cancel();
        if(vm.isCustom){
            if(vm.invoiceType === 'INTERNAL'){
              vm.getDuePaymentSummary();
            } else {
              vm.getExternalDuePaymentSummary();
            }
        }
    }
    function refreshData(token) {
        _.filter(token.list, function(tkon) {
            if(tkon.id == vm.brandTokens[token.brandId]){
                token.issuer = tkon.issuer;
            }
          });
        if(!vm.issuer[token.brandId] || vm.issuer[token.brandId] !== token.issuer){
            vm.tempToken = token;
            vm.tempToken.id = vm.brandTokens[token.brandId];
        var tokenId;
        const entries = Object.entries(vm.brandTokens);
        entries.forEach(function (elem) {
            if(vm.brandChangeId !== null) {
                if(elem[0] === String(vm.tempToken.brandId)) {
                    tokenId = elem[1];
                }
            } else {
                tokenId = elem[1];
            }
        })
        var supplierId;
        if(vm.invoiceType === 'INTERNAL'){
        _.filter(vm.selectedPOs, function(poList) {
            if(poList.purchaseOrder.processOrder.buyerBusiness.brandId == vm.tempToken.brandId){
                supplierId = poList.supplier.id;
            }
          });
          } else {
            _.filter(vm.selectedPOs, function(poList) {
                if(poList.brand.id == vm.tempToken.brandId){
                    supplierId = poList.supplier.id;
                }
              });
          }
        var dataObj = {
            brandId : vm.tempToken.brandId,
            supplierId : supplierId,
            purposeId : vm.purposeCode ? vm.purposeCode : '',
            tokenId : tokenId
        }
        DuePaymentInvoicesService.bsfPopup(dataObj).then(function(response){
            popup.message = response.data.message;
        $mdDialog.show({
            templateUrl: 'app/ezypayment-modules/buyer-modules/common/card-change.tmpl.html',
            controller: function() {
              return popup;
            },
            controllerAs: 'popup',
            targetEvent: event,
            clickOutsideToClose: true
        });
        });
    }
    }
    // timer for otp resend
    function counter() {
      vm.stopped = $timeout(function () {
        vm.counterMilliSeconds -= 1000;
        if (vm.counterMilliSeconds) {
          var seconds = vm.counterMilliSeconds / 1000;
          vm.counterMinutes = Math.floor(seconds / 60);
          if(vm.counterMinutes > 0) {
            vm.counterSeconds = seconds - (vm.counterMinutes * 60);
          }
          else {
            vm.counterSeconds = seconds;
          }
          counter();
        }
        else {
          vm.counterSeconds = 0;
          vm.counterMinutes = 0;
          stopCounter();
        }
      }, 1000);
    };
    /**
     * cancel allows close the pop-up
     */
     function cancel() {
        _.filter(vm.tempToken.list, function(token) {
            if(token.id == vm.preTokenId[vm.tempToken.brandId]){
                vm.issuer[vm.brandChangeId] = token.issuer;
            }
          });
        vm.brandTokens[vm.tempToken.brandId] = vm.preTokenId[vm.tempToken.brandId];
        $mdDialog.cancel();
      }
    function stopCounter(){
      vm.isOtpValid = false;
      $timeout.cancel(vm.stopped);
    }

    function showOtpPrompt() {
      // clear otp
      vm.otp = undefined;
      vm.isOtpValid = true;
      vm.counterSeconds = 0;
      vm.counterMinutes = 0;
      vm.counterMilliSeconds = GLOBAL_CONSTANTS.OTP_EXPIRY_MINUTES * 60 * 1000;
      counter();
      // show prompt
      $mdDialog.show({
        templateUrl: 'app/core/common-templates/otp-templates/otp-capture.tmpl.html',
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function validateOTP() {
      if (!vm.otp) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }

      if (vm.otp && (vm.otp.length < 5 || vm.otp.length > 8)) {
        toastr.error('Invalid OTP!', 'Please check the OTP entered');
        return;
      }
      confirmAndPay(vm.otp);
    }

    function resendOTP() {
      confirmAndPay();
    }

    function getBrandIds(params) {
      _.filter(params, function(poList) {
        if(vm.brandIds.indexOf(poList.brandId) === -1){
          vm.brandIds.push(poList.brandId);
        }
      });
    }

    function cardTokenByBrands() {
        CardsService.getAllCards({
            brandIds: vm.isCustom ? vm.brandIdsC : vm.brandIds,
            page: 0,
            size: paginationConstants.maxLimit,
            isCustom: vm.isCustom
          }).then(function(response){
            vm.tokenCards = response.data;
            if(vm.isCustom){
                _.filter(vm.tokenCards, function(token) {
                    vm.issuer[token.brandId] = token.list[0].issuer;
                  });
                if(vm.invoiceType === 'INTERNAL'){
                  vm.getDuePaymentSummary();
                } else {
                  vm.getExternalDuePaymentSummary();
                }
            }
          },function(error) {
            NotificationService.error({
              title: 'Fetching Token By Cards',
              error: error
            });
          });
    }

    function getDuePaymentSummary(){
        var tokenData = [];
        var invList = [];
        if(vm.brandTokens === undefined){
            vm.tokenCards.forEach(function (elem){
                tokenData.push(elem.brandId + '=' + elem.list[0].id)
            })
            tokenData = String(tokenData);
        } else {
            if(vm.brandChangeId !== null){
                _.filter(vm.selectedPOs, function(poList) {
                    if(poList.purchaseOrder.processOrder.buyerBusiness.brandId == vm.brandChangeId){
                        invList.push(poList.id);
                    }
                  });
            }
            const entries = Object.entries(vm.brandTokens);
            entries.forEach(function (elem) {
                if(vm.brandChangeId !== null) {
                    if(elem[0] === String(vm.brandChangeId)) {
                        tokenData.push(elem[0]+ '=' + elem[1]);
                    }
                } else {
                    tokenData.push(elem[0]+ '=' + elem[1]);
                }
            })
            tokenData = String(tokenData);
        }
        vm.isTTCharge = false;
      DuePaymentInvoicesService.getDuePaymentSummary({
        purchaseOrderIdList: vm.isCustom && vm.brandChangeId !== null ? invList: selectedPOIds,
        hasPaymentApproval: false,
        approvalSeqNo: '',
        hasOcrAccess: !vm.accessForOcr ? false : true,
        isCustom: vm.isCustom,
        purposeId: vm.isCustom ? vm.purposeCode : null,
        token: vm.isCustom ? tokenData : null
      }).then(function(response){
        if(vm.brandChangeId !== null) {
            var dataDuePay = [];
            vm.duePaymentSummaryDetails.forEach(function (row) {
                if(row.brandId === response.data[0].brandId) {
                    dataDuePay.push(response.data[0]);
                } else {
                    dataDuePay.push(row);
                }
            });
            vm.duePaymentSummaryDetails = dataDuePay
          } else {
            vm.duePaymentSummaryDetails = response.data;
          }
          var listData = [];
          vm.duePaymentSummaryDetails.forEach(function (elem) {
              var listInv = [];
              if(elem.currencyId === 'USD') {
                  vm.isTTCharge = true;
              }
              elem.paidInvoicesList.forEach(function (inv) {
                  if(inv.ttCharge === null) {
                      inv.ttCharge = 0;
                  }
                  listInv.push(inv);
              });
              elem.paidInvoicesList = listInv;
              listData.push(elem);
          })
          vm.duePaymentSummaryDetails = listData;
        if(vm.duePaymentSummaryDetails[0].message !== null){
          showDuePaymentMessage(vm.duePaymentSummaryDetails[0].message);
        }
        vm.getBrandIds(vm.duePaymentSummaryDetails);
        if(!vm.isCustom) {
            vm.cardTokenByBrands();
        }
      },function(error) {
        NotificationService.error({
          title: 'Fetching Due Payment Summary',
          error: error
        });
      });
    }

    /**
     * Get all card types
     */
    function getCardType() {
      CardsService.getCardType({
        page: 0,
        size: paginationConstants.maxLimit,
        query: ''
       }).then(function(response){
          vm.cardType = response.data;
      });
     }

   /**
    * [calculateSum shows the sum of key in list]
    */
    function calculateSum(duePaymentSummaryDetailsList, key){
      var sum = 0;
      if(duePaymentSummaryDetailsList && duePaymentSummaryDetailsList.length > 0) {
        duePaymentSummaryDetailsList.forEach( function (invoice) {
            if(key === 'buyerBsfRate') {
                sum += Math.round(Number(invoice[key]) * 1000) / 1000;
            } else {
                sum += Math.round(Number(invoice[key]) * 100) / 100;
            }
        });
      }
      return sum;
    }

    function confirmAndPay(otp){
        var tokenData = [];
        if(vm.brandTokens === undefined){
            vm.tokenCards.forEach(function (elem){
                tokenData.push(elem.brandId + '=' + elem.list[0].id)
            })
            tokenData = String(tokenData);
        } else {
            const entries = Object.entries(vm.brandTokens);
            entries.forEach(function (elem) {
                tokenData.push(elem[0]+ '=' + elem[1]);
            })
            tokenData = String(tokenData);
        }
      _.each(vm.duePaymentSummaryDetails, function(detail, index){
        vm.duePaymentSummaryDetails[index].tokenId = vm.brandTokens[detail.brandId];
      });
      vm.isTTCharge = false;
      DuePaymentInvoicesService.confirmPayment(
        {
          hasPaymentApproval: false,
          approvalSeqNo: '',
          paymentType: vm.invoiceType,
          isCustom: vm.isCustom,
          purposeId: vm.isCustom ? vm.purposeCode : null,
          token: vm.isCustom ? tokenData : null
        },
        vm.duePaymentSummaryDetails, otp).then(function(response){
          if(response.headers('authorization-otp') == 'OTP_VERIFICATION_REQUIRED') {
            showOtpPrompt();
          }
          else if(GLOBAL_CONSTANTS.OTP_EXCEPTIONS.includes(response.headers('authorization-otp'))) {
            toastr.error('Invalid OTP', 'AUTHENTICATION FAILED');
          }
          else {
            NotificationService.success({
              title: 'Processed',
              message: 'Payment For Invoices'
            });
            vm.paymentResponse = response;
            var listData = [];
            console.log(vm.paymentResponse);
            vm.paymentResponse.data.forEach(function (elem) {
                var listInv = [];
                if(elem.currencyId === 'USD') {
                    vm.isTTCharge = true;
                }
                elem.paidInvoicesList.forEach(function (inv) {
                    if(inv.ttCharge === null) {
                        inv.ttCharge = 0;
                    }
                    listInv.push(inv);
                });
                elem.paidInvoicesList = listInv;
                listData.push(elem);
            })
            vm.paymentResponse.data = listData;
            DuePaymentInvoicesService.setInvoiceType(vm.invoiceType);
            DuePaymentInvoicesService.setPaymentResponse(vm.paymentResponse);
            DuePaymentInvoicesService.setIsTTCharge(vm.isTTCharge);
            $mdDialog.hide();
            $state.go('ezypayment.buyer.due-payment-summary.details.transaction-report');
          }
        },function(error) {
          if (error.status === 524) {
            error.data = {message: 'Your payments are processing. Please wait for few minutes and check.'};
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          } else {
            NotificationService.error({
              title: 'Payment For Invoices',
              error: error
            });
          }
          return error;
        });
      }

      function loadTransactionReport(){
        vm.paymentResponse = DuePaymentInvoicesService.getPaymentResponse();
        vm.duePaymentSummaryDetails = vm.paymentResponse.data;
        // vm.paymentResponse.then(function(response) {
        //   vm.duePaymentSummaryDetails = response.data;
        // });
      }

      function getExternalDuePaymentSummary(){
        var tokenData = [];
        var invList = [];
        if(vm.brandTokens === undefined){
            vm.tokenCards.forEach(function (elem){
                tokenData.push(elem.brandId + '=' + elem.list[0].id)
            })
            tokenData = String(tokenData);
        } else {
            if(vm.brandChangeId !== null){
                _.filter(vm.selectedPOs, function(poList) {
                    if(poList.brand.id == vm.brandChangeId){
                        invList.push(poList.id);
                    }
                  });
            }
            const entries = Object.entries(vm.brandTokens);
            entries.forEach(function (elem) {
                if(vm.brandChangeId !== null) {
                    if(elem[0] === String(vm.brandChangeId)) {
                        tokenData.push(elem[0]+ '=' + elem[1]);
                    }
                } else {
                    tokenData.push(elem[0]+ '=' + elem[1]);
                }
            })
            tokenData = String(tokenData);
        }
        vm.isTTCharge = false;
        DuePaymentInvoicesService.getExternalDuePaymentSummary({
          invoiceIdList: vm.isCustom && vm.brandChangeId !== null ? String(invList): String(selectedPOIds),
          hasPaymentApproval: false,
          isCustom: vm.isCustom,
          purposeId: vm.isCustom ? vm.purposeCode : null,
          token: vm.isCustom ? tokenData : null
        }).then(function(response){
          if(vm.brandChangeId !== null) {
            var dataDuePay = [];
            vm.duePaymentSummaryDetails.forEach(function (row) {
                if(row.brandId === response.data[0].brandId) {
                    dataDuePay.push(response.data[0]);
                } else {
                    dataDuePay.push(row);
                }
            });
            vm.duePaymentSummaryDetails = dataDuePay
          } else {
            vm.duePaymentSummaryDetails = response.data;
          }
          vm.getBrandIds(vm.duePaymentSummaryDetails);
          if(!vm.isCustom) {
            vm.cardTokenByBrands();
        }
        var listData = [];
        vm.duePaymentSummaryDetails.forEach(function (elem) {
            var listInv = [];
            if(elem.currencyId === 'USD') {
                vm.isTTCharge = true;
            }
            elem.paidInvoicesList.forEach(function (inv) {
                if(inv.ttCharge === null) {
                    inv.ttCharge = 0;
                }
                listInv.push(inv);
            });
            elem.paidInvoicesList = listInv;
            listData.push(elem);
        })
        vm.duePaymentSummaryDetails = listData;
        },function(error) {
          NotificationService.error({
            title: 'Fetching External Due Payment Summary',
            error: error
          });
        });
      }

      function showDuePaymentMessage(message){
        var confirm = $mdDialog.confirm()
        .title($filter('translate')('global.duePaymentInvoices'))
        .textContent(message)
        .ok($filter('translate')('global.buttons.proceedToPay'))
        .cancel($filter('translate')('global.buttons.cancel'));

        $mdDialog.show(confirm).then(function() {
          // close  dialog to proceed
        }, function() {
          $state.go("ezypayment.buyer.due-payment-summary");
        });
      }



    }
  })();
