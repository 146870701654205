/**
* @Author: elsa
* @Date:   21-Apr-2017 11:37 +05:30
* @Project: Ezyprocure
* @Filename: buyer.invoice-report.consolidated-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T14:08:21+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerInvoiceReportConsolidatedDetailsController', BuyerInvoiceReportConsolidatedDetailsController);

  BuyerInvoiceReportConsolidatedDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$q', '$state', '$stateParams', 'toastr', '$mdDialog', 'InvoiceService', 'UTCDateService', 'NotificationService', 'PDFExportService', 'CSVExportService', 'EXPORT_CONSTANTS', 'paginationConstants', 'GLOBAL_CONSTANTS', 'Auth'];

  function BuyerInvoiceReportConsolidatedDetailsController($scope, $rootScope, $filter, $q, $state, $stateParams, toastr, $mdDialog, InvoiceService, UTCDateService, NotificationService, PDFExportService, CSVExportService, EXPORT_CONSTANTS, paginationConstants, GLOBAL_CONSTANTS, Auth) {
    var vm = this;
    vm.init = init;
    vm.getConsolidatedInvoicePDF = getConsolidatedInvoicePDF;
    vm.getConsolidatedInvoiceCSV = getConsolidatedInvoiceCSV;
    vm.pageDetails = "";
    vm.hasMore = false;
    vm.query = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.showMore = showMore;
    vm.loadAll = loadAll;
    vm.consolidatedInvoiceDetails = [];
    vm.loadMetaData = loadMetaData;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    /**
    * [init loads initilally]
    */
    function init(){
      if(vm.accessForPriceDisplay)
      vm.consolidatedInvoiceReportHeader = EXPORT_CONSTANTS.CSV.buyerConsolidatedInvoiceReport;
      else {
        vm.consolidatedInvoiceReportHeader = _.without(EXPORT_CONSTANTS.CSV.buyerConsolidatedInvoiceReport, 'Total_Delivery_Charge','Sub-total', 'Discount_Percentage', 'Discount_Amount', 'GST', 'Grand Total');
      }
      vm.filter = InvoiceService.getConsolidatedInvoiceFilters();
      if(vm.filter === null) {
        $state.go('buyer.reports.invoice-report');
      }
      else {
        vm.loadAll();
        vm.loadMetaData();
      }
    }

    function showMore() {
      vm.query.page += 1;
      vm.loadAll();
    }

    /**
    * [loadMetaData Gets Total Amount details for consildated invoice reports]
    */
    function loadMetaData() {
      InvoiceService.getConsolidatedInvoiceMetaData({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessOutletId ? []: [vm.filter.businessOutletId],
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        query: '',
        reportType: "INVOICE"
      }).then(function(response){
        vm.consolidatedCurrency = response.data;
      },function(error){
        NotificationService.error({
          title: 'Fetching Consolidated Invoice Metadata',
          error: error
        });
      });
    }


    /**
    * [loadAll Gets Purchase Order Details]
    */
    function loadAll() {
      InvoiceService.getConsolidatedInvoiceDetails({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessOutletId ? []: [vm.filter.businessOutletId],
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        query: '',
        reportType: "INVOICE",
        page: vm.query.page - 1,
        size: vm.query.limit
      }).then(function(response){
        vm.totalItems = response.headers('X-Total-Count');
        vm.hasMore = _.gt(parseInt(vm.totalItems), (vm.query.page  * vm.query.limit));
        vm.consolidatedInvoiceDetails = vm.consolidatedInvoiceDetails.concat(response.data);
        vm.pageDetails = "Showing " + (vm.consolidatedInvoiceDetails.length === 0 ? "0" : "1") + " to " + $filter('number')(vm.consolidatedInvoiceDetails.length) + " of " + $filter('number')(vm.totalItems) + " entries";
      },function(error){
        NotificationService.error({
          title: 'Invoice Details',
          error: error
        });
      });
    }

    function getConsolidatedInvoicePDF() {
      PDFExportService.getConsolidatedInvoicePDF({
        poNumber: !vm.filter.poNumber ? '' : vm.filter.poNumber,
        purchaseOrderStatus: '',
        invoiceStatus: 'PROCESSED',
        invoiceNumber: !vm.filter.invoiceNumber ? '' : vm.filter.invoiceNumber,
        businessOutletId: !vm.filter.businessOutletId ? '': vm.filter.businessOutletId,
        businessOutletName: !vm.filter.businessOutletName ? '' : vm.filter.businessOutletName,
        //If dates are selected, they are converted to UTC format
        poFromDate: !vm.filter.poFromDate ? '' : UTCDateService.utcFromDate(vm.filter.poFromDate),
        poToDate: !vm.filter.poToDate ? '' : UTCDateService.utcToDate(vm.filter.poToDate),
        deliveryFromDate: !vm.filter.deliveryFromDate ? '' : UTCDateService.utcFromDate(vm.filter.deliveryFromDate),
        deliveryToDate: !vm.filter.deliveryToDate ? '' : UTCDateService.utcToDate(vm.filter.deliveryToDate),
        invoiceFromDate: !vm.filter.invoiceFromDate ? '' : UTCDateService.utcFromDate(vm.filter.invoiceFromDate),
        invoiceToDate: !vm.filter.invoiceToDate ? '' : UTCDateService.utcToDate(vm.filter.invoiceToDate),
        grnFromDate : !vm.filter.grnFromDate ? '' : UTCDateService.utcFromDate(vm.filter.grnFromDate),
        grnToDate : !vm.filter.grnToDate ? '' : UTCDateService.utcToDate(vm.filter.grnToDate),
        supplierId: !vm.filter.supplierId ? '': vm.filter.supplierId,
        itemGroupId: !vm.filter.itemGroupId ? '' :vm.filter.itemGroupId,
        debitNoteStatus: '',
        grnNumber: '',
        buyerName: '',
        creditNoteStatus: '',
        query: '',
        reportType: "INVOICE"
      })
      .then(function(response){
        NotificationService.success({
          title: 'PDF Export',
          message: 'The invoice report has been sent to your email.'
        });
      },function(error) {
        NotificationService.error({
          title: 'PDF Export',
          error: error
        });
      });
    }

    //Getting BUYER CSV DATA
    function getConsolidatedInvoiceCSV(){
      var deferred = $q.defer();
      if(!vm.consolidatedInvoiceDetails) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.SEARCH_AND_SUBMIT'
        });
        return deferred.promise;
      }
      return CSVExportService.getConsolidatedInvoiceCSV(vm.consolidatedInvoiceDetails, 'Buyer');
    }

  }
})();
