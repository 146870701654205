/**
 * @Author: hareesh
 * @Date:   09-Aug-2017 04:07 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.purchase-request.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-06-24T14:45:14+05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierPurchaseRequestIndividualDetailsController', SupplierPurchaseRequestIndividualDetailsController);

  SupplierPurchaseRequestIndividualDetailsController.$inject = ['$scope', '$rootScope', '$filter', '$state', '$stateParams', 'pagingParams', 'toastr', '$mdDialog', 'SupplierProcessOrderService', 'paginationConstants', 'EXPORT_CONSTANTS', 'NotificationService', 'CSVExportService', 'PDFExportService', 'BuyerWaitingSupplierReplyService', 'GLOBAL_CONFIG_CONSTANTS', 'Auth', 'GLOBAL_CONSTANTS'];

  function SupplierPurchaseRequestIndividualDetailsController($scope, $rootScope, $filter, $state, $stateParams, pagingParams, toastr, $mdDialog, SupplierProcessOrderService, EXPORT_CONSTANTS, NotificationService, paginationConstants, CSVExportService, PDFExportService, BuyerWaitingSupplierReplyService, GLOBAL_CONFIG_CONSTANTS, Auth, GLOBAL_CONSTANTS) {
    var vm = this;
    vm.init = init;
    vm.loadAll = loadAll;
    
    vm.getPurchaseOrdersByRequirementNumber = getPurchaseOrdersByRequirementNumber;
    vm.poNumber = $stateParams.poNumber;
    vm.previewType = $stateParams.type;
    vm.userRole = Auth.getUserRole();
    vm.sumRequired = sumRequired;
    vm.sumSupplier = sumSupplier;
    // vm.purchaseOrderHeader = EXPORT_CONSTANTS.CSV.purchaseOrderHeader;
    vm.getPurchaseOrderCSVData = getPurchaseOrderCSVData;
    vm.getIndividualPurchaseOrderPDF = getIndividualPurchaseOrderPDF;
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.formatPrice = formatPrice;
    vm.invStatusClass = GLOBAL_CONFIG_CONSTANTS.INVOICE_STATUS.LABEL_CLASS;
    vm.poStatusClass = GLOBAL_CONFIG_CONSTANTS.PURCHASE_ORDER_STATUS.LABEL_CLASS;
    vm.prStatusClass = GLOBAL_CONFIG_CONSTANTS.PROCESS_ORDER_STATUS.LABEL_CLASS;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.printPurchaseRequest = printPurchaseRequest;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');

    vm.predicate = $stateParams.predicate || pagingParams.predicate;
    vm.reverse = $stateParams.reverse || pagingParams.reverse;
    if ($stateParams.reverse) {
      vm.reverse = $stateParams.reverse === 'true';
    }else{
      vm.reverse = pagingParams.reverse;
    }
    vm.query = {
      limit: 20,
      page: 1
    };

    /**
     * [printPurchaseRequest Prints the purchase request details]
     */
    function printPurchaseRequest(id) {
      PDFExportService.printPDF(id);
   }
   function sort() {
     var rev = 'asc';
       if (!vm.reverse) {
         rev = 'desc';
       };
      var result = [vm.predicate + ',' + rev];
    return result;
  }
    /**
     * [init loads initilally]
     */
    function init(){
      
      vm.loadAll();
      vm.getPurchaseOrdersByRequirementNumber();
    }

    /**
     * [loadAll Gets Purchase Order Details by PO number]
     */
    function loadAll() {
      var sendSort= {
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: '',
        sort: sort(),
      }
      BuyerWaitingSupplierReplyService.getPurchaseRequestByRequirementNumber( 
        vm.requirementNumber, sendSort).then(function(response) {
        vm.poDetails = response;
      }, function(error) {
        NotificationService.error({
          title: 'Fetching Individual PR Details',
          error: error
        });
      });
    }

    /**
     * [sumRequired Grand total amount of buyer Requirement]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumRequired(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        if(item.buyerRequirement.items.uom.weightRequired === false && !item.buyerRequirement.freeItem) {
          //totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
          if(item.buyerRequirement.items.uom.moqPrice === true)
          {
            totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
          }
          else
          {
            totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
          }
        }
      });
      return totalAmount;
    }

    /**
     * [sumSupplier Grand total amount of supplier response]
     * @param  {[object]} poItems [PO Details]
     * @return {[number]}         [total amount]
     */
    function sumSupplier(poItems) {
      var totalAmount = 0;
      _.forEach(poItems, function(item) {
        //skip caluclations for free item
        if (!item.buyerRequirement.freeItem) {
          if(item.buyerRequirement.items.uom.weightRequired === false) {
            if(item.supplierResponses === null) {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.buyerRequirement.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
              }
              else
              {
                totalAmount += item.buyerRequirement.quantity * item.buyerRequirement.price;
              }
            }
            else {
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.supplierResponses.quantity/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
              }
              else
              {
                totalAmount += item.supplierResponses.quantity * item.buyerRequirement.price;
              }
            }
          }
          else {
            if(item.supplierResponses === null) {
              //totalAmount += item.buyerRequirement.weight * item.buyerRequirement.price;
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.buyerRequirement.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
              }
              else
              {
                totalAmount += item.buyerRequirement.weight * item.buyerRequirement.price;
              }
            }
            else {
              //totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
              if(item.buyerRequirement.items.uom.moqPrice === true)
              {
                totalAmount += (item.supplierResponses.weight/item.buyerRequirement.items.moq) * item.buyerRequirement.price;
              }
              else
              {
                totalAmount += item.supplierResponses.weight * item.buyerRequirement.price;
              }
            }
          }
        }
      });
      return totalAmount;
    }

    function getPurchaseOrderCSVData() {
      return CSVExportService.getPurchaseOrderCSV(vm.poDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualPurchaseOrderPDF() {
      PDFExportService.getPurchaseOrderPDF(vm.poNumber,{
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: '',
        sort: sort(),
      })
      .then(function(response){
        downloadURI(response.data.message, "PDF_" + vm.poNumber);
      },function(error) {
        NotificationService.error({
          title: 'Individual PO PDF Export',
          error: error
        });
      });
    }

    //round off price to two decimal places
    function formatPrice(amount){
      return (Math.round((amount)*1000)/1000);
    }

    function getPurchaseOrdersByRequirementNumber() {
      SupplierProcessOrderService.getPurchaseOrdersByRequirementNumber(vm.requirementNumber).then(function(response) {
        vm.purchaseOrders = response.data;
      }, function(error) {
      });
    }

  }
})();
