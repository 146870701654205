/**
* @Author: Ajay
* @Date:   08-Mar-2017 13:17 +05:30
* @Project: Ezyprocure
* @Filename: supplier.process-order.details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-12-05T09:24:52+05:30
*/


(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierProcessOrderDetailsController', SupplierProcessOrderDetailsController);

  SupplierProcessOrderDetailsController.$inject = ['$filter', 'SupplierDataService', '$mdDialog', '$state', '$q', '$stateParams', 'toastr', 'Auth', 'PurchaseOrderService', 'SupplierProcessOrderService', 'UTCDateService', 'NotificationService', 'SupplierItemService', 'SupplierPickingListService', 'GLOBAL_CONSTANTS'];

  function SupplierProcessOrderDetailsController($filter, SupplierDataService, $mdDialog, $state, $q, $stateParams, toastr, Auth, PurchaseOrderService, SupplierProcessOrderService, UTCDateService, NotificationService, SupplierItemService, SupplierPickingListService, GLOBAL_CONSTANTS) {

    var vm = this;
    vm.requirementNumber = $stateParams.requirementNumber;
    vm.getProcessOrder = getProcessOrder;
    vm.checkProcessOrderStatus = checkProcessOrderStatus;
    vm.checkQuantityExceededRequirement = checkQuantityExceededRequirement;
    vm.checkDeliveryDateAlreadyChoosen = checkDeliveryDateAlreadyChoosen;
    vm.addNewSupplierResponse = addNewSupplierResponse;
    vm.deleteSupplierResponse = deleteSupplierResponse;
    vm.confirmYes = confirmYes;
    vm.totalAmount = totalAmount;
    vm.grandTotal = grandTotal;
    vm.calculateDeliveryCharge = calculateDeliveryCharge;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    vm.deliveryCharge = 0;
    vm.deliveryDateArray = [];
    vm.rejectProcessOrder= rejectProcessOrder;
    vm.searchForItems = searchForItems;
    vm.itemChanged = itemChanged;
    vm.addItemDeviaton = addItemDeviaton;
    vm.itemSearchQuery = {};
    vm.cancelItemDeviation = cancelItemDeviation;
    vm.addItemDeviationPO = addItemDeviationPO;
    vm.printPickingList = printPickingList;
    vm.showConfirmTemplate = showConfirmTemplate;
    vm.getAllPickingList = getAllPickingList;
    vm.showConfirm = showConfirm;
    vm.showDownloadsTemplate = showDownloadsTemplate;
    vm.cancel = cancel;
    vm.deleteItemDeviation = deleteItemDeviation;
    vm.changeViewType = changeViewType;
    vm.showRejectPRConfirmationModal = showRejectPRConfirmationModal;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.accessDTO = SupplierDataService.getSupplierProfile().accessDTO;
    vm.disableBtn = false;
    vm.editPrice = editPrice;
    vm.checkForDuplicatePOs = checkForDuplicatePOs;
    vm.confirmNo = confirmNo;
    //ShowPurchaseOrder
    vm.showPurchaseOrder = null;
    // inventory changes
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.displayItemDetails = true;
    vm.accessForEzyinventory = Auth.hasPermission('accessForEzyinventory');
    vm.isAllBatchDetailSelected = false;
    vm.selectedPoIndex = 0;
    vm.locations = [];
    vm.showBatchDialog = showBatchDialog;
    vm.closeDialog = closeDialog;
    vm.submitBatchDetails = submitBatchDetails;
    vm.toggleAll = toggleAll;
    vm.updateTotalIssueQuantity = updateTotalIssueQuantity;
    vm.getAllLocations = getAllLocations;
    vm.validateSupplierResponseWeight = validateSupplierResponseWeight;
    // negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.isNegativeBuying = isNegativeBuying;
    vm.setModifiedPrice = setModifiedPrice;
    vm.priceEdited = false;
    init();

    function init() {
      vm.getProcessOrder();
      vm.getAllLocations();
    }

    function isNegativeBuying(quantity) {
      return vm.accessForNegativeBuyingBuyerView && (Math.sign(quantity) == -1 || Math.sign(quantity) == 0);
    }

    function getSupplierResponseSum(purchaseOrder) {
      var supplierResponseList = purchaseOrder.supplierResponses;
      // get total supplier quantity
      var sum = supplierResponseList.reduce(function (sum, supplierResponse) {
        if (purchaseOrder.items.uom.weightRequired) {
          return sum + (isFinite(supplierResponse.weight) ? supplierResponse.weight : 0);
        }
        else {
          return sum + (isFinite(supplierResponse.quantity) ? supplierResponse.quantity : 0);
        }
      }, 0);
      return sum;
    }

    function getAllLocations() {
      PurchaseOrderService.getAllLocations().
        then(function (response) {
          vm.locations = response.data;
        }, function (error) {

        });
    }

    function createStockUpdateData() {
      var stockDetails = [];
      if (vm.accessForEzyinventory) {
        vm.pendingProcessOrders.processOrders.forEach(function(po) {
          var poDetails = {
            ezyprocureItemId: po.items.id,
            businessOutletName: vm.businessOutletName || vm.pendingProcessOrders.processOrderInventoryDTO.businessOutletName,
            poQuantity: po.quantity,
            totalPrice: totalAmount(po),
            ezyprocurePOId: po.id,
            ezyprocurePONumber: po.requirementNumber,
            processOrderBatchDetails: [],
            itemCode: po.items.itemCode,
            uom: po.items.uom.name,
            rate: po.items.price,
            buyerId: po.buyerUser.id
          };
          if(po.inventoryCombinedData.isBatchItem) {
            po.inventoryCombinedData.batchDetailList.forEach(function(batch) {
              if (batch.issuedQuantity) {
                var batchDetail = {
                  batchId: batch.batchId,
                  batchNumber: batch.batchNumber,
                  batchQuantity: batch.issuedQuantity,
                  productionDate: batch.productionDate,
                  expiryDate: batch.expiryDate,
                }
                poDetails.processOrderBatchDetails.push(batchDetail);
              }
            });
          }
          stockDetails.push(poDetails);
        });
      }
      return stockDetails;
    }

    function validateInventoryData() {
      var isValid = true;
      if (vm.accessForEzyinventory) {
        vm.pendingProcessOrders.processOrders.forEach(function (po) {
          po.inventoryErrorList = [];
          var sum = parseFloat(getSupplierResponseSum(po).toFixed(3));
          if (sum > po.inventoryCombinedData.quantity) {
            isValid = false;
            if(!po.errorList.length) {
              po.errorList.push('Insufficient stock available');
            }
          }

          if (po.inventoryCombinedData.isBatchItem && parseFloat(getSupplierResponseSum(po).toFixed(3)) != po.inventoryCombinedData.issuedQuantity) {
            po.inventoryErrorList.push('Issued Quantity and PO Quantity mismatch');
            isValid = false;
          }
          else if (po.errorList.length) {
            isValid = false;
          }
        });
      }
      return isValid;
    }

    function updateTotalIssueQuantity() {
      vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.issuedQuantity = calculateIssuedQuantity();
    }

    function showBatchDialog(ev, index) {
      vm.selectedPoIndex = index;
      vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.issuedQuantity = calculateIssuedQuantity();
      $mdDialog.show({
        controller: function () {
          return vm;
        },
        controllerAs: 'vm',
        templateUrl: 'app/supplier-modules/process-order/details/supplier.process-order.batch-details.tmpl.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: false,
      }).then(function (answer) {

      }, function () {

      });
    }

    function closeDialog() {
      $mdDialog.hide();
    }

    function submitBatchDetails() {
      vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryErrorList = [];
      updateTotalIssueQuantity();
      var totalIssuedQuantity = vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.issuedQuantity;
      // match total issued quantity with supplier response quantity
      if (totalIssuedQuantity == vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].supplierQuantitySum) {
        closeDialog();
      }
      else {
        toastr.error('PO Quantity and Issued Quantity should match', 'Quantity mismatch');
      }
    }

    function toggleAll(isItemLevel, index) {
      var isAllSelected = true;
      vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.batchDetailList.forEach(function (batchDetail, arrayIndex) {
        if (!isItemLevel) {
          batchDetail.isSelected = vm.isAllBatchDetailSelected;
          batchDetail.issuedQuantity = batchDetail.issuedQuantity ? batchDetail.issuedQuantity : batchDetail.batchQuantity;
          vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.issuedQuantity = calculateIssuedQuantity();
        }
        else {
          isAllSelected = isAllSelected ? batchDetail.isSelected : isAllSelected;
          if(index == arrayIndex) {
            batchDetail.issuedQuantity = batchDetail.issuedQuantity ? batchDetail.issuedQuantity : batchDetail.batchQuantity;
            vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.issuedQuantity = calculateIssuedQuantity();
          }
        }
      });
      vm.isAllBatchDetailSelected = isItemLevel ? isAllSelected : vm.isAllBatchDetailSelected;
    }

    function calculateIssuedQuantity() {
      var totalIssuedQuantiy = 0;
      vm.pendingProcessOrders.processOrders[vm.selectedPoIndex].inventoryCombinedData.batchDetailList.forEach(function (batch) {
        if(batch.issuedQuantity && batch.isSelected) {
          totalIssuedQuantiy += batch.issuedQuantity
        }
      });
      return parseFloat(totalIssuedQuantiy.toFixed(3));
    }

    function processInventoryDetails() {
      vm.pendingProcessOrders.processOrders = vm.pendingProcessOrders.processOrders.map(function (order) {

        order.errorList = [];

        /* combine inventory details */
        var inventoryItemDetail = undefined;
        if(vm.pendingProcessOrders.processOrderInventoryDTO && vm.pendingProcessOrders.processOrderInventoryDTO.processOrderDetails) {
          inventoryItemDetail = vm.pendingProcessOrders.processOrderInventoryDTO.processOrderDetails.find(function (inventoryItem) {
            return inventoryItem.itemCode.toLowerCase() == order.items.itemCode.toLowerCase();
          });
        }
        var inventoryCombinedData = {
          inventoryDetailDisplayFlag: !!inventoryItemDetail
        };
        if (inventoryCombinedData.inventoryDetailDisplayFlag) {
          inventoryCombinedData.buyerId = inventoryItemDetail.buyerId;
          inventoryCombinedData.isBatchItem = inventoryItemDetail.isBatchItem;
          inventoryCombinedData.isItemPresent = inventoryItemDetail.isItemPresent;
          inventoryCombinedData.isUomPresent = inventoryItemDetail.isUomPresent;
          inventoryCombinedData.itemCode = inventoryItemDetail.itemCode;
          inventoryCombinedData.quantity = inventoryItemDetail.quantity ? inventoryItemDetail.quantity.toFixed(2) : 0;
          inventoryCombinedData.uom = inventoryItemDetail.uom;
          inventoryCombinedData.batchDetailList = inventoryItemDetail.batchDetailsDTOS;
          inventoryCombinedData.isStandAloneSupplier = !vm.pendingProcessOrders.processOrderInventoryDTO.isMappingAvailable;
          updateErrorList();
        }
        else {
          order.errorList.push('No item in this item code');
        };
        order.inventoryCombinedData = inventoryCombinedData;

        function updateErrorList() {
          if(order.quantity > inventoryItemDetail.quantity) {
            order.errorList.push('Insufficient stock available');
          }
        }

        return order;
      });
    }

    //supplier edit price on each item
    function editPrice(arr) {
        SupplierProcessOrderService.editPriceOnPR(arr).then(function(response){
          NotificationService.success({
            title: 'global.menu.entities.processOrder',
            message: 'alertMessage.PRICE_UPDATED_SUCCESSFULLY'
          });
          //vm.getProcessOrder();          
          vm.checkForDuplicatePOs();
        },
        function(error){
          NotificationService.error({
            title: 'global.menu.entities.processOrder',
            error: error
          });
        });
    }

    function showConfirm(ev) {
      var arr = {
        'approvalRequiredFromBuyer': false,
        'buyerIdList':[vm.pendingProcessOrders.processOrders[0].buyerUser.buyer.id],
        'businessIdList': [vm.pendingProcessOrders.processOrders[0].buyerBusiness.id],
        'items':[]
      }
      _.forEach(vm.pendingProcessOrders.processOrders, function(processOrder){
        arr.items.push({
        'itemId': processOrder.items.id,
        'modifiedPrice': processOrder.modifiedPrice
        })
      });
      var confirm = $mdDialog.confirm()
      .title('Are you sure you want to update price? ')
      .targetEvent(ev)
      .ok('Yes')
      .cancel('No');

      $mdDialog.show(confirm).then(function() {
        vm.editPrice(arr);
      }, function() {
        $state.reload();
      });
    }

    // Redirect back to process order listing page if status is not PENDING*
    function checkProcessOrderStatus(processOrder) {
      vm.processOrderStatus = processOrder.processOrders[0].processOrderStatus;
      if (vm.processOrderStatus !== 'PENDING') {
        $state.go('supplier.process-order', {}, { reload: false});
      }
    }

    //change the view type - table/list view
    function changeViewType(type){
      vm.viewType = type;
    }

    //Check Quantity Exceeded Requirement
    function checkQuantityExceededRequirement(p_index, s_index) {
      if(vm.pendingProcessOrders.processOrders[p_index].items.uom.weightRequired)
        vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].weight = parseFloat(vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].quantity.toFixed(3));
      var purchaseOrder = vm.pendingProcessOrders.processOrders[p_index];
      var sum = getSupplierResponseSum(purchaseOrder);
      purchaseOrder.supplierQuantitySum = parseFloat(sum.toFixed(3));
      if (sum > purchaseOrder.quantity) {
        toastr.error('The input exceeds on the total quantity of the item.', 'Please check your input');
      }
      if (sum <= purchaseOrder.inventoryCombinedData.quantity && !purchaseOrder.errorList.includes('No item in this item code')) {
        purchaseOrder.errorList = [];
      }
    }

    function validateSupplierResponseWeight(p_index,s_index) {
      /*if (vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].quantity > vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].weight + ) {
        vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].quantity = parseFloat(vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].weight.toFixed(3));
      }*/
      /*var purchaseOrder = vm.pendingProcessOrders.processOrders[p_index];
      var sum = getSupplierResponseSum(purchaseOrder);
      purchaseOrder.supplierQuantitySum = parseFloat(sum.toFixed(3));
      if (sum > purchaseOrder.weight) {
        toastr.error('The input exceeds on the total weight of the item.', 'Please check your input');
      }
      if (sum <= purchaseOrder.inventoryCombinedData.quantity && !purchaseOrder.errorList.includes('No item in this item code')) {
        purchaseOrder.errorList = [];
      }*/
    }

    //Check Delivery Date Already Choosen
    function checkDeliveryDateAlreadyChoosen(p_index, s_index) {
      var requirement = angular.copy(vm.pendingProcessOrders.processOrders[p_index]);
      var allResponses = requirement.supplierResponses;
      var currentResponse = requirement.supplierResponses[s_index];
      allResponses.splice(s_index, 1);// Remove 1 item at 2-index position - Removes currentResponse from allResponses
      var deliveryDateAlreadyChoosen = _.some(allResponses, function(response) {
        return moment(response.deliveryDate).isSame(currentResponse.deliveryDate, 'day');
      });
      if (deliveryDateAlreadyChoosen) {
        vm.pendingProcessOrders.processOrders[p_index].supplierResponses[s_index].deliveryDate = moment(vm.pendingProcessOrders.processOrders[p_index].deliveryDate).add(1, 'days');
        toastr.error('Same delivery date is not accepted for orders with deviation.', 'Delivery date already choosen');
      }
    }
    //Function to set/insert default supplier response
    function setDefaultSupplierResponse() {
      var newArr = _.map(vm.pendingProcessOrders.processOrders, function(element) {
        element.supplierQuantitySum = element.quantity;
        return _.extend({}, element, {
          supplierResponses: [{
            processOrderId: element.id,
            itemCode: element.items.itemCode,
            deliveryDate: element.deliveryDate,
            quantity: element.quantity,
            weight:element.quantity,
            supplierRemark: ''
          }]
        });
      });
      vm.pendingProcessOrders.processOrders = newArr;
    }

    function getProcessOrder() {
      SupplierProcessOrderService.getProcessOrderByRequirementNumber(vm.requirementNumber, vm.businessOutletName).
      then(function(response) {
        vm.checkProcessOrderStatus(response);
        vm.pendingProcessOrders = response;
        vm.pendingProcessOrders.processOrders = _.forEach(vm.pendingProcessOrders.processOrders, function(processOrder){         
          processOrder.modifiedPrice = processOrder.price;
          processOrder.isEdit = true;
        });
        if(vm.accessForEzyinventory) {
          processInventoryDetails();
          checkOutletAvailable();
        }
        setDefaultSupplierResponse();
        _.forEach(vm.pendingProcessOrders.processOrders, function(processOrder){
          vm.deliveryDateArray.push(new Date(processOrder.deliveryDate));
          vm.viewType = 'List';
        });
      }, function(error) {
        vm.pendingProcessOrders = [];
      });
    }

    function checkOutletAvailable() {
      vm.displayItemDetails = true;
      if (vm.pendingProcessOrders.processOrderInventoryDTO
        && (!vm.pendingProcessOrders.processOrderInventoryDTO.isMappingAvailable && !vm.businessOutletName)) {
          vm.displayItemDetails = false;
        }
    }

    //If the ProcessOrder-Cancel is Clicked
    function rejectProcessOrder(){
      SupplierProcessOrderService.getReject_PR(vm.requirementNumber).then(function(response) {
        vm.disableBtn = true;
        NotificationService.success({
          title: 'global.menu.entities.processOrder',
          message: 'alertMessage.REJECTED_SUCCESSFULLY'
        });
        $state.go('supplier.process-order', {
          reload: false
        });
      }, function(error) {
        vm.disableBtn = false;
        NotificationService.error({
          title: 'global.menu.entities.processOrder',
          error: error
        });
      });
    }

    //Add Supplier Response
    function addNewSupplierResponse(p_index) {
      var supplierResponse = {
        processOrderId: vm.pendingProcessOrders.processOrders[p_index].id,
        itemCode: vm.pendingProcessOrders.processOrders[p_index].items.itemCode,
        deliveryDate: moment(vm.pendingProcessOrders.processOrders[p_index].deliveryDate).add(1, 'days'),
        quantity: null,
        weight: null,
        supplierRemark: ''
      };
      vm.pendingProcessOrders.processOrders[p_index].supplierResponses = vm.pendingProcessOrders.processOrders[p_index].supplierResponses ? vm.pendingProcessOrders.processOrders[p_index].supplierResponses : [];
      vm.pendingProcessOrders.processOrders[p_index].supplierResponses.push(supplierResponse);
    }

    //Delete Supplier Response
    function deleteSupplierResponse(p_index, s_index) {
      vm.pendingProcessOrders.processOrders[p_index].supplierResponses.splice(s_index, 1);
    }

    function getSupplierResponses(pendingProcessOrders) {
      //Pick all supplierResponses array property from list of pendingProcessOrders
      var result1 = _.map(pendingProcessOrders, _.property('supplierResponses'));
      //Creates an array excluding all given values
      var result2 = _.without(result1, undefined, null);
      //Flattens array a single level deep.
      var supplierResponses = _.flatten(result2);
      return supplierResponses;
    }

    function totalAmount(pendingProcessOrder) {
      var totalAmount = 0;
      var totalWeight = 0;
      var moq = 0;

      moq = pendingProcessOrder.items.moq;

      //if free item, skip calculations
      if (pendingProcessOrder.freeItem) {
        return totalAmount;
      }
      else{
        if(pendingProcessOrder.items.uom.weightRequired === false) {
          _.forEach(pendingProcessOrder.supplierResponses, function(response){
            if(pendingProcessOrder.items.uom.moqPrice === true)
            {
              totalAmount += ((response.quantity / moq) * pendingProcessOrder.modifiedPrice);
            }
            else
            {
              totalAmount += (Math.round((response.quantity * pendingProcessOrder.modifiedPrice)*1000)/1000);
            }
          });
        }
        else {
          _.forEach(pendingProcessOrder.supplierResponses, function(response){
            if(response.weight) {
              totalWeight += response.weight;
            }
            else {
              totalWeight = 0;
            }
          });

          if(pendingProcessOrder.items.uom.moqPrice === true)
          {
            totalAmount += ((totalWeight / moq) * pendingProcessOrder.modifiedPrice);
          }
          else
          {
            //totalAmount +=  (Math.round((totalWeight *  pendingProcessOrder.price).toFixed(4) * 1000 /10) / 100).toFixed(2);
            if(vm.accessForNegativeBuyingBuyerView){
                totalAmount += parseFloat((Math.round((totalWeight *  pendingProcessOrder.modifiedPrice).toFixed(4) * 1000 /10) / 100).toFixed(2));
             }
             else{
                 totalAmount +=  (Math.round((totalWeight *  pendingProcessOrder.modifiedPrice).toFixed(4) * 1000 /10) / 100).toFixed(2);
             }
          }
        }
      }

      return _.round(totalAmount,2);
    }

    function grandTotal() {
      var grandTotal = 0;
      _.forEach(vm.pendingProcessOrders.processOrders, function(order) {
        grandTotal += totalAmount(order);
      });
      return _.round(grandTotal,2);
    }

    function calculateDeliveryCharge() {
      var deliveryCharge = 0;
      var dateArray = [];
      _.forEach(vm.pendingProcessOrders.processOrders, function(processOrder) {
        _.forEach(processOrder.supplierResponses, function(supplierResponse) {
          dateArray.push({deliveryDate:moment(supplierResponse.deliveryDate).format("DD/MM/YYYY")});
        });
      });

      var result = _.map(_.uniqBy(dateArray, 'deliveryDate'), function(item) {
        return {
          deliveryDate: item.deliveryDate
        };
      });
      _.forEach(result, function(date) {
        var amount = 0;
        var deliveryDate = date.deliveryDate;
        _.forEach(vm.pendingProcessOrders.processOrders, function(processOrder) {
          _.forEach(processOrder.supplierResponses, function(supplierResponse) {
            if (deliveryDate === moment(supplierResponse.deliveryDate).format("DD/MM/YYYY")) {
              if (!processOrder.freeItem) {
                if(processOrder.items.uom.weightRequired === true) {
                  if(processOrder.items.uom.moqPrice === true)
                  {
                    amount += ((supplierResponse.weight / processOrder.items.moq) * processOrder.price);
                  }
                  else
                  {
                    amount += processOrder.price * supplierResponse.weight;
                  }
                }
                else {
                  if(processOrder.items.uom.moqPrice === true)
                  {
                    amount += ((supplierResponse.quantity / processOrder.items.moq) * processOrder.price);
                  }
                  else
                  {
                    amount += supplierResponse.quantity * processOrder.price;
                  }
                }
              }
            }
          });
        });
        if(amount > 0) {
        if(vm.pendingProcessOrders.buyerDeliveryConditions!=null){
          if (vm.pendingProcessOrders.buyerDeliveryConditions.deliveryConditions.minimumOrderForFreeDelivery > amount) {
            deliveryCharge += vm.pendingProcessOrders.deliveryCharge;
          }
        }
        else{
        if (vm.pendingProcessOrders.processOrders[0].items.supplier.minimumOrderForFreeDelivery > amount) {
                    deliveryCharge += vm.pendingProcessOrders.deliveryCharge;
                  }
        }
        }
      });
      return deliveryCharge;
    }

    // check for duplication PO
    function checkForDuplicatePOs(){      
      if(!validateInventoryData()) {
        toastr.error('Invalid stock information', 'Validation Error');
        return;
      }
      vm.disableBtn = true;
      var acceptOrderDTO = {
        requirementNumber: vm.requirementNumber,
        supplierResponse: getSupplierResponses(vm.pendingProcessOrders.processOrders)
      };
      SupplierProcessOrderService.checkForDuplicatePOs(acceptOrderDTO).then(function(response){
        vm.confirmYes();
      },
      function(error){
        vm.duplicate = error.data.responseList[0];
        if(error.data.responseList[0]){
          $mdDialog.show({
            templateUrl: 'app/core/common-templates/confirm-dialog.tmpl.html',
            controller: function() {
              return vm;
            },
            controllerAs: 'vm',
            targetEvent: event,
            clickOutsideToClose: false
          });
        } else {
          vm.confirmYes();
        }
      });
    }

    function confirmYes() {
      if(vm.duplicate)
        $mdDialog.cancel();
      vm.disableBtn = true;
      var acceptOrderDTO = {
        requirementNumber: vm.requirementNumber,
        supplierResponse: getSupplierResponses(vm.pendingProcessOrders.processOrders),
        processOrderDetails: createStockUpdateData()
      };
      SupplierProcessOrderService.supplierAcceptOrder(acceptOrderDTO).then(function(response) {
        vm.disableBtn = true;
        vm.showPurchaseOrder = response.data;
        NotificationService.success({
          title: 'global.menu.entities.processOrder',
          message: 'alertMessage.ACCEPTED_SUCCESSFULLY'
        });
        if(vm.showPurchaseOrder.length > 0){
          $state.go('supplier.reports.purchase-order.individual-details',{'poNumber':vm.showPurchaseOrder[0].purchaseOrderNumber}, {
            reload: false
          });
        }else if(vm.showPurchaseOrder.length <= 0){
          $state.go('supplier.process-order', {
            reload: false
          });
        }else{
          $state.go('supplier.process-order', {
            reload: false
          });
        }
      }, function(error) {
        vm.disableBtn = false;
        NotificationService.error({
          title: 'global.menu.entities.processOrder',
          error: error
        });
      });
    }

    function confirmNo(){
      $mdDialog.cancel();
    }

    /**
    * [addItemDeviaton add item deviation]
    */
    function addItemDeviaton() {
      if(vm.supplierAddedPOItem)
      toastr.error('Please submit current item deviation', 'Item Deviation');
      vm.supplierAddedPOItem = {
        deliveryDate: moment(vm.todaysDate),
      };
      vm.selectedItem = {};
    }


    /**
    * [itemChanged change item on search]
    * @param  {[type]} supplierItem [description]
    * @param  {[type]} index    [description]
    * @return {[type]}          [description]
    */
    function itemChanged(supplierItem, index) {
      var temp = angular.copy(supplierItem);
      supplierItem.items = temp.selectedItem;
      supplierItem.itemName = temp.selectedItem.itemName;
      supplierItem.itemCode = temp.selectedItem.itemCode;
      supplierItem.uom = temp.selectedItem.uom;
      supplierItem.price = temp.selectedItem.price;
      supplierItem.moq = temp.selectedItem.moq;
      supplierItem.itemId = temp.selectedItem.id;
    }

    /**
    * [searchForItems search item]
    * @param  {[type]} queryType [description]
    * @param  {[type]} queryText [description]
    * @return {[type]}           [description]
    */
    function searchForItems(queryType, queryText) {
      var deferred = $q.defer();
      if(!queryText) {
        return;
      }
      else {
        vm.itemSearchQuery.queryField = queryType;
        vm.itemSearchQuery.queryText = queryText;
        vm.itemSearchQuery.itemType = "BUYER";
        vm.itemSearchQuery.businessIdList = [];
        vm.itemSearchQuery.buyerIdList = [];
        SupplierItemService.searchAllItems(vm.itemSearchQuery)
        .then(function(response) {
          deferred.resolve($filter('orderBy')(response.data, 'itemName'));
        }, function(error) {
          deferred.reject(error);
        });
      }
      return deferred.promise;
    }

    /**
    * [cancelItemDeviation cancel item deviation and deletes Item deviation object]
    * @return {[type]} [description]
    */
    function cancelItemDeviation(){
      delete vm.supplierAddedPOItem;
      delete vm.selectedItem;
    }

    /**
    * [addItemDeviationPO adds item deviation to pending process order]
    */
    function addItemDeviationPO(){
      vm.supplierAddedPOItem.requirementNumber = vm.requirementNumber;
      vm.supplierAddedPOItem.items = angular.copy(vm.selectedItem.items);
      vm.supplierAddedPOItem.freeItem = vm.supplierAddedPOItem.freeItem ? vm.supplierAddedPOItem.freeItem :false;
      SupplierProcessOrderService.supplierPOAddItemDeviation(vm.supplierAddedPOItem).then(function(response) {
        NotificationService.success({
          title: 'global.itemDeviation',
          message: 'alertMessage.ADDED_SUCCESSFULLY'
        });
        delete vm.supplierAddedPOItem;
        delete vm.selectedItem;
        vm.getProcessOrder();
      }, function(error) {
        NotificationService.error({
          title: 'global.itemDeviation',
          error: error
        });
      });
    }

    /**
    * [deleteItemDeviation delete item deviation added by supplier]
    * @param  {[type]} poId [description]
    * @return {[type]}      [description]
    */
    function deleteItemDeviation(poId){
      SupplierProcessOrderService.deleteItemDeviation(poId).then(function(response) {
        NotificationService.success({
          title: 'global.itemDeviation',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
        vm.getProcessOrder();
      }, function(error) {
        NotificationService.error({
          title: 'global.itemDeviation',
          error: error
        });
      });
    }

    /**
    * [printPickingList print picking list PDFs]
    * @param  {[type]} templateId [description]
    * @return {[type]}            [description]
    */
    function printPickingList(templateId){
      var processOrderPickingList= _.uniqBy(_.map(vm.pendingProcessOrders.processOrders, function(processOrderItem){
        return{
          'deliveryDate': processOrderItem.deliveryDate,
          'requirementNumber': processOrderItem. requirementNumber
        }
      }),'requirementNumber');
      SupplierPickingListService.printPickingList(processOrderPickingList,{
        pickingListId: templateId
      }).then(function(response) {
        NotificationService.success({
          title: 'global.menu.entities.pickingList',
          message: 'alertMessage.PRINTED_SUCCESSFULLY'
        });
        vm.cancel();
        vm.downloadsList= response.urls;
        vm.showDownloadsTemplate();
      }, function(error) {
        NotificationService.error({
          title: 'global.menu.entities.pickingList',
          error: error
        });
      });
    }


    /**
    * [showConfirmTemplate shows modal to confirm Picking List Template]
    */
    function showConfirmTemplate(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/process-order/templates/supplier.process-order.picking-list-template-confirm.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    /**
    * [getAllPickingList get all picking list template ids]
    * @return {[type]} [description]
    */
    function getAllPickingList(){
      SupplierPickingListService.getAllPickingList({
        detailedView : false
      }).then(function(response) {
        vm.templateList = response.data;
        vm.showConfirmTemplate(event);
      }, function(error) {
        NotificationService.error({
          title: 'global.menu.entities.pickingList',
          error: error
        });
      });
    }

    /**
    * [showDownloadsTemplate shows modal listing PDF links]
    */
    function showDownloadsTemplate(event) {
      $mdDialog.show({
        templateUrl: 'app/supplier-modules/process-order/templates/supplier.process-order.downloads.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: false
      });
    }


    /**
    * [cancel closes modal dialog]
    */
    function cancel() {
      $mdDialog.cancel();
    }

    /**
     * showRejectOrderConfirm shows the confirmation for reject Order button
     */
    function showRejectPRConfirmationModal(event) {
      vm.disableBtn = true;
      var confirm = $mdDialog.confirm()
      .title($filter('translate')('alertMessage.REJECT_PO_CONFIRMATION_MESSAGE'))
      .targetEvent(event)
      .ok($filter('translate')('global.buttons.proceed'))
      .cancel($filter('translate')('global.buttons.cancel'));
      $mdDialog.show(confirm).then(function() {
        vm.rejectProcessOrder();
      }, function() {
        vm.disableBtn = false;
      });
    }

    /**
     * store modified price to scope
     */
    function setModifiedPrice(price,index){
      if(price == 'check'){
        vm.priceEdited = true;
        vm.pendingProcessOrders.processOrders[index].isEdit = true
      } else if(price == 'edit'){
        vm.pendingProcessOrders.processOrders[index].isEdit = false;
      }
    }
  }
})();
