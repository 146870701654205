/**
* @Author: Allan
* @Date:   24-Feb-2017 12:40 +05:30
* @Project: Ezyprocure
* @Filename: ezypayment.supplier.navbar.controller.js
 * @Last modified by:   allan
 * @Last modified time: 27-August-2018 15:30 +05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('EzyPaymentSupplierNavbarController', EzypaymentSupplierNavbarController);

  EzypaymentSupplierNavbarController.$inject = ['$rootScope', '$window', '$stateParams', '$timeout', '$state', 'Auth', 'SupplierAccountService', 'SupplierDataService','UserRedirectService','AuthServerProvider','API_CONSTANTS','toastr'];

  function EzypaymentSupplierNavbarController ($rootScope, $window, $stateParams, $timeout, $state, Auth, SupplierAccountService, SupplierDataService,UserRedirectService,AuthServerProvider,API_CONSTANTS,toastr) {
    var vm = this;

    vm.getSupplierProfile = getSupplierProfile;
    $rootScope.back = back;

    function getSupplierProfile () {
      SupplierAccountService.getSupplierProfile().
      then(function(response) {
        if (response) {
          vm.supplierProfile = response;
          SupplierDataService.setSupplierProfile(vm.supplierProfile);
        }
      }, function(error) {
      });
    }

    vm.getSupplierProfile();

    function back() {
      $window.history.back();
    }

    vm.redirectToCbPayPlatform = function () {
      Auth.checkCbHealth().then(function() {
        UserRedirectService.sync().then(function () {
            var token = AuthServerProvider.getCbToken();
            var domToken = AuthServerProvider.getToken();
            if (token) {
              $window.location.href = API_CONSTANTS.cbBase + '#/autologin/save/' + token + '/' + domToken + '?destinationUrl=ezypayment/supplier/dashboard';
            } else {
              $window.location.href = API_CONSTANTS.cbBase;
            }
        }).catch(function (data) {
          toastr.error('Something went wrong');
        })
      }).catch(function() {
        toastr.error('Something went wrong');
      });
    }

    vm.logout = function() {
      Auth.logout();
    };
  }
})();
