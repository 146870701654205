/**
 * @Author: Ajay
 * @Date:   03-Mar-2017 15:12 +05:30
 * @Project: Ezyprocure
 * @Filename: supplier.dashboard.controller.js
 * @Last modified by:   Ajay
 * @Last modified time: 09-Nov-2017 15:04 +05:30
 */



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierDashboardController', SupplierDashboardController);

  SupplierDashboardController.$inject = ['$scope', '$state', '$stateParams', '$filter', '$mdDialog', 'SupplierDashboardService', 'GLOBAL_CONSTANTS', 'NotificationService', 'SupplierDataService', 'SatsManagementService','BuyerDashboardService'];

  function SupplierDashboardController ($scope, $state, $stateParams, $filter, $mdDialog, SupplierDashboardService, GLOBAL_CONSTANTS, NotificationService, SupplierDataService, SatsManagementService,BuyerDashboardService) {

    var vm = this;
    vm.getSupplierDashboardCount = getSupplierDashboardCount;
    vm.tabIndex = $stateParams.tabIndex ? $stateParams.tabIndex : 0;
    vm.refreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.uobBizPlusUrl = GLOBAL_CONSTANTS.UOB_BIZPLUS_URL;
    vm.showEzysourceBanner = GLOBAL_CONSTANTS.SHOW_EZYSOURCE_BANNER;
    vm.showUobBanner = GLOBAL_CONSTANTS.SHOW_UOB_BANNER;
    vm.accessDTO = SupplierDataService.getSupplierProfile().accessDTO;
    vm.getSupplierDashboardPendingJobsCount = getSupplierDashboardPendingJobsCount;
    //Dashboard counter refresh timeout
    vm.incrementInterval = incrementInterval;
    vm.initializeIntervalCount = initializeIntervalCount;
    vm.refreshDashboardCounts = refreshDashboardCounts;
    vm.refreshTimeout = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_TIMEOUT;
    vm.initialRefreshInterval = GLOBAL_CONSTANTS.DASHBOARD_REFRESH_INTERVAL;
    vm.refreshIntervalIncrement = GLOBAL_CONSTANTS.DASHBOARD_INTERVAL_INCREMENT;
    vm.pauseRefreshFlag = false;
    vm.accessForEzyinventory = SupplierDataService.getSupplierProfile().accessDTO.accessForEzyinventory;
    vm.initialiseCarousal = initialiseCarousal;

    /**
     * [getSupplierDashboardPendingJobsCount gets the count of Released jobs count]
     */
    function getSupplierDashboardPendingJobsCount(){
      SatsManagementService.getPendingJobsDashboardCount()
      .then(function(response){
        vm.supplierPendingJobsCount = response.data.count;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
    }
    
    function initialiseCarousal(){
      var myCarousel = document.querySelector('#myCarousel');
      var carousel = $(myCarousel).carousel();
    }

    /** force refresh counts */
    function refreshDashboardCounts() {
      getSupplierDashboardCount();
      initializeIntervalCount();
      initialiseCarousal();
    }

    /** increment refresh interval on successfull refresh */
    function incrementInterval() {
      if(vm.refreshInterval == vm.initialRefreshInterval) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if(vm.refreshInterval < vm.refreshTimeout) {
        vm.refreshInterval += vm.refreshIntervalIncrement;
      }
      else if (vm.refreshInterval >= vm.refreshTimeout) {
        vm.pauseRefreshFlag = true;
      }
    }

    function initializeIntervalCount() {
      vm.refreshInterval = vm.initialRefreshInterval;
      vm.pauseRefreshFlag = false;
    }

    function getSupplierDashboardCount() {
      SupplierDashboardService.getSupplierDashboardCount().then(function(response){
        vm.supplierDashboardCount = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
      getCarousalImg();
    }

    function getCarousalImg(){
      BuyerDashboardService.getCarousalDetails()
      .then(function(response){
        vm.carousalImgList = response.data;
      },function(error){
        NotificationService.error({
          title: 'Supplier Dashboard',
          error: error
        });
      });
    }
    getSupplierDashboardCount();

    initializeIntervalCount();
    initialiseCarousal();

  }
})();
