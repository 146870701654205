/**
* @Author: Ajay
* @Date:   27-Feb-2017 16:21 +05:30
* @Project: Ezyprocure
* @Filename: buyer.supplier-onboarding.controller.js
* @Last modified by:   Liya
 * @Last modified time: 12-Feb-2020 18:38
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerSupplierMappingTableController', BuyerSupplierMappingTableController);

  BuyerSupplierMappingTableController.$inject = ['$scope', '$state', '$translate','toastr', '$mdDialog','BuyerSupplierMappingTableService', 'BuyerDataService','SupplierDataService','AuthServerProvider', 'Auth', 'paginationConstants'];

  function BuyerSupplierMappingTableController ($scope, $state, $translate,toastr, $mdDialog,BuyerSupplierMappingTableService, BuyerDataService,SupplierDataService,AuthServerProvider, Auth, paginationConstants) {

    var vm = this;
    $scope.state = $state;
    vm.suppliersBuyers = [];
    vm.InvitedsuppliersBuyers = [];
    vm.notAllocated = [];
    vm.visibilityType = '';
    vm.type = '';
    vm.user = '';
    vm.onInit = onInit;
    vm.showConfirm = showConfirm;
    vm.showBuyerConfirm = showBuyerConfirm;
    vm.resendEmail = resendEmail;
    vm.totalItems;
    vm.invitedItems;
    vm.unmappedBuyers = unmappedBuyers;
    vm.unmappedSuppliers = unmappedSuppliers;
    vm.mappedItemSearch = '';
    vm.fetchInvitedUsers = fetchInvitedUsers;
    vm.allocatedBuyerOrSuppliers = allocatedBuyerOrSuppliers;
    vm.getContryCode =  getContryCode;
    vm.filter = {
      brandIdList: []
    };
    vm.role = Auth.getUserRole().ugly;
    // Buyer Supplier table
    vm.queryUsers = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicateUsers = 'company_name';
    vm.limitOptionsUsers = paginationConstants.limitOptions;
    vm.reverseUsers = true;

    // Invited users table
    vm.queryInvited = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicateInvited = 'companyName';
    vm.limitOptionsInvited = paginationConstants.limitOptions;
    vm.reverseInvited = true;

    // Existing users table
    vm.queryExisting = {
      limit: paginationConstants.itemsPerPage,
      page: 1
    };
    vm.predicateExisting = 'company_name';
    vm.limitOptionsExisting = paginationConstants.limitOptions;
    vm.reverseExisting = true;

    vm.getBuyerOrSuppliers = getBuyerOrSuppliers;
    vm.supplierType = "All";
    vm.loadAll = function() {
      if(vm.role == 'ROLE_BUYER'){
        vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
     
      }
      else if(vm.role == 'ROLE_SUPPLIER'){
        vm.buyerId =  SupplierDataService.getSupplierProfile().supplier.id;
      
      }
      // getBuyerOrSuppliers(vm.query.page, vm.mappedItemSearch);
    }

     function getContryCode(){
       BuyerSupplierMappingTableService.getCountry().then(function(response){
         vm.countryCode = response;
       });
     }

    vm.loadInvited = function(){
      fetchInvitedUsers(vm.queryInvited.page-1,vm.mappedItemSearch)
    }  
    vm.loadAllocated = function(){
      allocatedBuyerOrSuppliers(vm.queryUsers.page,vm.mappedItemSearch)
    }
    vm.loadUnAllocated = function(){
      unmappedBuyers(vm.queryExisting.page,vm.mappedItemSearch)
    }

    function onInit(){
      if(vm.role == 'ROLE_BUYER'){
        vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
     
      }
      else if(vm.role == 'ROLE_SUPPLIER'){
        vm.buyerId =  SupplierDataService.getSupplierProfile().supplier.id;
      
      }
      allocatedBuyerOrSuppliers(vm.queryUsers.page,vm.mappedItemSearch);
      vm.getContryCode();
    }

    function fetchInvitedUsers(page,search) {
      vm.selected = '2';
      vm.user = 'Invited';
      var request = {
        "buyer": vm.role === 'ROLE_BUYER'? true: false,
        "includePagination": true,
        "order": vm.reverseInvited ? 'ASC' : 'DESC',
        "orderBy": vm.predicateInvited,
        "pageNumber": page,
        "searchKey": search,
        "size": vm.queryInvited.limit,
        "userId": vm.buyerId
      };
      BuyerSupplierMappingTableService.fetchInvitedUsers(request)
      .then(function(response) {
        vm.InvitedsuppliersBuyers = response.invite.content
        vm.totalItems = response.invite.totalElements
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }
    function allocatedBuyerOrSuppliers(page,search) {     
      vm.selected = '1';
      vm.user = '';
      var request = {
        "buyer": vm.role === 'ROLE_BUYER'? true: false,
        "includePagination": true,
        "pageNumber": page,
        "searchKey": search,
        "order": vm.reverseUsers ? 'ASC' : 'DESC',
        "orderBy": vm.predicateUsers,
        "size": vm.queryUsers.limit,
        "userId": vm.buyerId,
        "supplierType": vm.role === 'ROLE_BUYER'? vm.supplierType : "Allocated"
      };
      BuyerSupplierMappingTableService.getExistingUsersList(request)
      .then(function(response) {
        if(vm.role === 'ROLE_BUYER'){
          vm.suppliersBuyers = response.suppliers.content;
          vm.totalItems = response.totalRecords;
         }
         else if(vm.role === 'ROLE_SUPPLIER'){
          vm.suppliersBuyers = response.buyers.content
          vm.totalItems = response.totalRecords
         }
        
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    
    function showConfirm(ev,id) {
      var confirm = $mdDialog.confirm()
        .title('Do  you want to map the Supplier?')
        .textContent('  ')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        mapSupplier(id);
      }, function() {});
    }

    function showBuyerConfirm(ev,id,index) {
      var confirm = $mdDialog.confirm()
        .title('Do  you want to map the Buyer?')
        .textContent('  ')
        .targetEvent(ev)
        .ok('Ok')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function() {
        mapBuyer(id,index);
      }, function() {});
    }

    function mapSupplier(supplierId) {
      BuyerSupplierMappingTableService.mapSupplier(vm.buyerId,supplierId, 'buyer').then(function(response) {
        toastr.success('Mapped Successfully');
        unmappedBuyers(vm.queryExisting.page, vm.mappedItemSearch);
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }

    function mapBuyer(buyerId,index) {
      BuyerSupplierMappingTableService.mapSupplier(buyerId,vm.buyerId,'supplier', vm.filter.brandIdList[index]).then(function(response) {
        toastr.success('Mapped Successfully');
        vm.filter.brandIdList = '';
        unmappedBuyers(vm.queryExisting.page, vm.mappedItemSearch);
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }


    function resendEmail(emailId) {
      BuyerSupplierMappingTableService.resendEmail(emailId).then(function(response) {
        toastr.success($translate.instant('ezyprocureApp.buyer.onBoarding.resendEmail'))

      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }

    function unmappedBuyers(page,search) {
      vm.user = 'mapp';
      vm.selected = '3';
      var request = {
        "buyer": vm.role === 'ROLE_BUYER'? true: false,
        "includePagination": true,
        "pageNumber": page,
        "plan_type": "CROSSBORDER",
        "searchKey": search,
        "order": vm.reverseExisting ? 'ASC' : 'DESC',
        "orderBy": vm.predicateExisting,
        "size": vm.queryExisting.limit,
        "userId": vm.buyerId,
        "supplierType": vm.role === 'ROLE_BUYER'? vm.supplierType : "All"
      };
      BuyerSupplierMappingTableService.unmappedUsers(request).then(function(response) {
       if(vm.role === 'ROLE_BUYER'){
        vm.notAllocated = response.suppliers.content;
        vm.totalItems = response.totalRecords;
       }
       else if(vm.role === 'ROLE_SUPPLIER'){
        vm.notAllocated = response.buyers.content;
        vm.totalItems = response.totalRecords;
       }
        vm.type = 'map supplier';
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }

    function unmappedSuppliers() {
      vm.user = 'mapp';
      vm.selected = '3';
      BuyerSupplierMappingTableService.unmappedSuppliers(vm.buyerId).then(function(response) {
        vm.notAllocated = response.suppliers.content;
        vm.totalItems = response.suppliers.totalElements
        vm.type = 'map supplier';
      }).catch(function () {
        toastr.error('Something went wrong', 'Server Error');
      });
    }
    function showmappedSupplier(supplierId) {
      BuyerSupplierMappingTableService.viewMappedUsers(vm.buyerId,supplierId).then(function(response) {
        
      }).catch(function () { 
        toastr.error('Something went wrong', 'Server Error');
      });
    }
    function getBuyerOrSuppliers(page, search) {
      vm.user = ''
      vm.selected = '1'
      var request = {
        "buyer": vm.role === 'ROLE_BUYER'? false: true,
        "includePagination": true,
        "pageNumber": page,
        "searchKey": search,
        "size": vm.queryUsers.limit
      };
      BuyerSupplierMappingTableService.getExistingUsersList(vm.buyerId )
        .then(function(response) {
          if(vm.role == 'ROLE_SUPPLIER'){
            vm.suppliersBuyers = response;
          }
          else if(vm.role == 'ROLE_BUYER'){
            vm.suppliersBuyers = response;
          }
          vm.totalItems =response.totalRecords;
        }).catch(function () {
          toastr.error('Something went wrong', 'Server Error');
        });
    }
  
  }
  
})();
