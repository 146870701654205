/**
* @Author: Ajay
* @Date:   16-Jun-2017 18:10 +05:30
* @Project: Ezyprocure
* @Filename: buyer.buy-now.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-05-20T14:47:43+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('BuyerBuyNowController', BuyerBuyNowController);

  BuyerBuyNowController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$q', '$mdDialog', '$filter', '$timeout', '$interval', 'BuyerBuyNowService', 'pagingParams', 'paginationConstants', 'BuyerDataService', 'CartService', 'UTCDateService', 'BuyerBusinessUserMapService', 'Auth', 'PurchaseOrderTypeService', 'NotificationService', 'PromotionService', 'BuyerPurchaseApprovalRequestService', 'BuyerOrderTemplateService', 'GLOBAL_CONSTANTS', 'EXPORT_CONSTANTS', 'CSVExportService','BlanketOrderService','toastr'];

  function BuyerBuyNowController($scope, $rootScope, $state, $stateParams, $q, $mdDialog, $filter, $timeout, $interval, BuyerBuyNowService, pagingParams, paginationConstants, BuyerDataService, CartService, UTCDateService, BuyerBusinessUserMapService, Auth, PurchaseOrderTypeService, NotificationService, PromotionService, BuyerPurchaseApprovalRequestService, BuyerOrderTemplateService, GLOBAL_CONSTANTS, EXPORT_CONSTANTS, CSVExportService, BlanketOrderService,toastr) {

    var vm = this;
    vm.init = init;
    vm.approvalRequestNumber = $stateParams.approvalRequestNumber;
    vm.businessOutletId = +($stateParams.businessOutletId);
    vm.supplierVisibilityType = $stateParams.visibilityType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated';
    vm.supplierId = +($stateParams.supplierId);
    vm.itemListQuery = {};
    vm.itemListQuery.supplierId = vm.supplierId || undefined;
    vm.fromApproval = $stateParams.fromApproval;
    vm.loadViewType = loadViewType;
    vm.setBusinessOutletAndSupplier = setBusinessOutletAndSupplier;
    vm.loadPurchaseOrderTypes = loadPurchaseOrderTypes;
    vm.getAllBuyerBusinessOutlets = getAllBuyerBusinessOutlets;
    vm.supplierVisibilityTypeChanged = supplierVisibilityTypeChanged;
    vm.setDefaultPurchaseOrderType = setDefaultPurchaseOrderType;
    vm.getAllPublicSuppliers = getAllPublicSuppliers;
    vm.getAllAllocatedSuppliersForBusiness1 = getAllAllocatedSuppliersForBusiness1;
    vm.changeViewType = changeViewType;
    vm.getPromotionTicker = getPromotionTicker;
    vm.addToCart = addToCart;
    vm.autoAddToCart = autoAddToCart;
    vm.autoAddToCartGridView = autoAddToCartGridView;
    vm.openAddToCartModal = openAddToCartModal;
    vm.removeFromCartConfirmationModal = removeFromCartConfirmationModal;
    vm.removeFromCart = removeFromCart;
    vm.addToFavorites = addToFavorites;
    vm.checkItemInCart = checkItemInCart;
    vm.removeFromFavorites = removeFromFavorites;
    vm.generateRequirementNo = generateRequirementNo;
    vm.checkBusinessOutletSelection = checkBusinessOutletSelection;
    vm.todaysDate = new Date();
    vm.tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    vm.requirementNo = generateRequirementNo();
    vm.businessOutletChanged = businessOutletChanged;
    vm.itemListQueryChanged = itemListQueryChanged;
    vm.setDefaultView = setDefaultView;
    vm.loadAll = loadAll;
    vm.cancel = cancel;
    vm.toggleItemLoad = toggleItemLoad;
    vm.itemListLoaded = false;
    vm.predicate = 'items.itemName';
    vm.reverse = pagingParams.ascending;
    vm.transition = transition;
    vm.searchText = '';
    vm.resetPaginationQuery = resetPaginationQuery;
    vm.getBuyerPromotions = getBuyerPromotions;
    vm.showPromotionTicker = false;
    vm.hidePromotions = hidePromotions;
    vm.haveAccessForGLModule = Auth.hasPermission('accessForGlModule');
    vm.addToItemApprovalRequest = addToItemApprovalRequest;
    vm.saveAdhoc = saveAdhoc;
    vm.getAllAllocatedSuppliersForBusiness = getAllAllocatedSuppliersForBusiness;
    vm.limitOptions = paginationConstants.limitOptions;
    vm.cartId = null;
    vm.changeDeliveryDate = changeDeliveryDate;
    vm.createCartByTemplate = createCartByTemplate;
    vm.checkout = checkout;
    vm.loadOrderTemplateItems =  loadOrderTemplateItems;
    vm.getOrderTemplates = getOrderTemplates;
    vm.getAllOrderTemplateByBusinessIds = getAllOrderTemplateByBusinessIds;
    vm.changePurchaseMethod = changePurchaseMethod;
    vm.showDeleteCartConfirmationModal = showDeleteCartConfirmationModal;
    vm.purchaseMethod = $stateParams.purchaseMethod || "Purchase By Item";
    vm.itemListQuery.selectedTemplate = $stateParams.templateId;
    vm.itemListQuery.businessId = $stateParams.businessOutletId;
    vm.clearCart = clearCart;
    vm.disableCommonDate =  true;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.getCartDetails = getCartDetails;
    vm.buyerDeliveryConditions = [];

    vm.orderTemplateItemsHeaders = EXPORT_CONSTANTS.CSV.OrderTemplateItemsCSVHeaders
    vm.getOrderTemplateCSVData = getOrderTemplateCSVData;
    vm.validateMOQ = validateMOQ;
    vm.barcodeSearch = barcodeSearch;
    vm.accessForBarcodeSearch = BuyerDataService.getBuyerProfile().accessDTO.accessForBarcodeSearch;
    vm.removeQuantity = removeQuantity;
    vm.addQuantity = addQuantity;
    vm.gridItemAddQuantity = gridItemAddQuantity;
    vm.gridItemRemoveQuantity = gridItemRemoveQuantity;
    vm.validateMOQGridItem = validateMOQGridItem;
    vm.navigateBackToApprovalRequest = navigateBackToApprovalRequest;
    vm.startIncrement = startIncrement;
    vm.startDecrement = startDecrement;
    vm.startGridItemIncrement = startGridItemIncrement;
    vm.startGridItemDecrement = startGridItemDecrement;
    vm.addToCartAfterTimout = addToCartAfterTimout;
    vm.gridAddToCartAfterTimout = gridAddToCartAfterTimout;
    vm.clearIncrementTimers = clearIncrementTimers;
    vm.clearDecrementTimers = clearDecrementTimers;
    vm.clearGridIncrementTimers = clearGridIncrementTimers;
    vm.clearGridDecrementTimers = clearGridDecrementTimers;
    vm.maximumAllowedItemsPerOrder = GLOBAL_CONSTANTS.MAXIMUM_ALLOWED_ITEMS_PER_ORDER;
    vm.checkCartCount = checkCartCount;
    vm.searchTemplateText = '';
    vm.beforeQuickSearchArray = [];
    vm.accessForBlanketOrderBuying = BuyerDataService.getBuyerProfile().accessDTO.accessForBlanketOrderBuying;
    vm.buyerId = BuyerDataService.getBuyerProfile().buyerUser.buyer.id;
    vm.getAllBlanketOrdersBuyNow = getAllBlanketOrdersBuyNow;
    vm.loadBlanketOrders = loadBlanketOrders;
    vm.supplierCountAdhoc = 0;
    loadViewType();
    // negative buying
    vm.accessForNegativeBuyingBuyerView = Auth.hasPermission('accessForNegativeBuyingBuyerView');
    vm.isNegativeBuying = isNegativeBuying;
    vm.filterFields = ['itemName','itemCode'];
    vm.getAllBuyerItems = getAllBuyerItems;
    vm.buyerItemBuyingsortData = sort();
    vm.setDefaultBuyingMode = setDefaultBuyingMode;
    vm.getDefaultBuyingMode = getDefaultBuyingMode;
    getDefaultBuyingMode();
    vm.setPrice = setPrice;
    vm.disableDays = disableDays;
    vm.selecteddatePicker = '';
    vm.openCalendar = openCalendar;
    function init() {
      if(BuyerDataService.getBuyerProfile().buyerUser.userRoles.name != 'ADMIN_BUYER' && Auth.hasPermission('accessForTemplateBuyingOnly'))
       vm.purchaseMethod = 'Purchase By Template';
      vm.getCartDetails();
      vm.getAllBuyerBusinessOutlets();
      vm.loadPurchaseOrderTypes();
      getBuyerPromotions();

      if($stateParams.templateId && vm.itemListQuery.businessId ){
        vm.businessOutletChanged(vm.itemListQuery.businessId)
        vm.loadOrderTemplateItems(vm.itemListQuery.selectedTemplate);
      }

    }

    function setPrice(item) {
      if (item.itemBusinessMapping && item.itemBusinessMapping.price && item.itemBusinessMapping.price != '') {
        return item.itemBusinessMapping.price;
      } else if (item.itemBuyerMapping && item.itemBuyerMapping.price && item.itemBuyerMapping.price != '') {
        return item.itemBuyerMapping.price;
      } else {
        return item.price
      }
    }

    function startIncrement(item, index) {
      addQuantity(item.multiplierFactor, item.weightRequired, item, index);
      item.longPressIncrementTimeout = $timeout(function() {
        item.incrementTimer = $interval(function() {
          addQuantity(item.multiplierFactor, item.weightRequired, item, index);
        }, 100);
      }, 500);
    }

    function startDecrement(item, index) {
      removeQuantity(item.multiplierFactor, item.weightRequired, item, index);
      item.longPressDecrementTimeout = $timeout(function() {
        item.decrementTimer = $interval(function() {
          removeQuantity(item.multiplierFactor, item.weightRequired, item, index);
        }, 100);
      }, 500);
    }

    function startGridItemIncrement() {
      gridItemAddQuantity();
      vm.selectedItem.gridLongPressIncrementTimeout = $timeout(function() {
        vm.selectedItem.incrementTimer = $interval(function() {
          gridItemAddQuantity();
        }, 100);
      }, 500);
    }

    function startGridItemDecrement() {
      gridItemRemoveQuantity();
      vm.selectedItem.gridLongPressDecrementTimeout = $timeout(function() {
        vm.selectedItem.decrementTimer = $interval(function() {
          gridItemRemoveQuantity();
        }, 100);
      }, 500);
    }

    function clearIncrementTimers(item) {
      $timeout.cancel(item.longPressIncrementTimeout);
      $interval.cancel(item.incrementTimer);
    }

    function clearDecrementTimers(item) {
      $timeout.cancel(item.longPressDecrementTimeout);
      $interval.cancel(item.decrementTimer);
    }

    function clearGridIncrementTimers() {
      $timeout.cancel(vm.selectedItem.gridLongPressIncrementTimeout);
      $interval.cancel(vm.selectedItem.incrementTimer);
    }

    function clearGridDecrementTimers() {
      $timeout.cancel(vm.selectedItem.gridLongPressDecrementTimeout);
      $interval.cancel(vm.selectedItem.decrementTimer);
    }

    /**
     * populate business outlet if routed from approval page
     */
    function setBusinessOutletAndSupplier() {
      if(vm.approvalRequestNumber) {
        if(vm.businessOutletId) {
          vm.itemListQuery.businessId = vm.businessOutletId;
          businessOutletChanged();
        }
        if(vm.supplierId) {
          vm.itemListQuery.supplierId = vm.supplierId;
          itemListQueryChanged();
        }
      }
    }

    function navigateBackToApprovalRequest() {
      $state.go('buyer.purchase-approval-request.details', {
        approvalRequestNumber: vm.approvalRequestNumber
      });
    }

    function gridItemAddQuantity() {
      var step = 1;
      var item = vm.selectedItem;
      var lowerLimit = item.moq || 0;
      if(item.multiplierFactor || item.weightRequired) {
        step = item.multiplierFactor || 0.001;
      }
      if(!item.weightRequired){
        item.quantity =  item.quantity ? +((item.quantity + step).toFixed(2)) : lowerLimit;
      }
      else {
        item.quantity =  item.quantity ? +((item.quantity + step).toFixed(3)) : lowerLimit;
      }
      isNegativeBuying(item.quantity) || validateMOQGridItem(item.multiplierFactor, item.quantity);
    }

    function gridItemRemoveQuantity() {
      var step = 1;
      var item = vm.selectedItem;
      var lowerLimit = getMoq(item.moq);
      if(item.multiplierFactor || item.weightRequired) {
        step = item.multiplierFactor || 0.001;
      }
      if ((item.quantity - step) >= lowerLimit) {
        if(!item.weightRequired){
          item.quantity = +((item.quantity - step).toFixed(2));
        }
        else {
          item.quantity = +((item.quantity - step).toFixed(3));
        }
        isNegativeBuying(item.quantity) || validateMOQGridItem(item.multiplierFactor, item.quantity);
      }
    }

    function removeQuantity(multiplierFactor, isWeightRequired, item, index) {
      var step = 1;
      var lowerLimit = getMoq(item.moq);
      if(multiplierFactor || isWeightRequired) {
        step = multiplierFactor || 0.001;
      }
      if ((item.quantity - step) >= lowerLimit) {
        if(!isWeightRequired){
          item.quantity = +((item.quantity - step).toFixed(2));
        }
        else {
          item.quantity = +((item.quantity - step).toFixed(3));
        }
        isNegativeBuying(item.quantity) || validateMOQ(multiplierFactor, item.quantity, item, index);
      }
    }

    function isNegativeBuying(quantity) {
      return vm.accessForNegativeBuyingBuyerView && (Math.sign(quantity) == -1 || Math.sign(quantity) == 0);
    }

    function getMoq(moq) {
      return vm.accessForNegativeBuyingBuyerView ? Number.MIN_SAFE_INTEGER : (moq || 0);
    }

    function addQuantity(multiplierFactor, isWeightRequired, item, index) {
      var step = 1;
      var lowerLimit = item.moq || 0;
      if(multiplierFactor || isWeightRequired) {
        step = multiplierFactor || 0.001;
      }
      if(!isWeightRequired){
        item.quantity =  item.quantity ? +((item.quantity + step).toFixed(2)) : lowerLimit;
      }
      else {
        item.quantity =  item.quantity ? +((item.quantity + step).toFixed(3)) : lowerLimit;
      }
      isNegativeBuying(item.quantity) || validateMOQ(multiplierFactor, item.quantity, item, index);
    }

    function addToCartAfterTimout(item, index, action) {
      action == 'INCREMENT' ? clearIncrementTimers(item) : clearDecrementTimers(item);
      $timeout(function (preIndex, preItem) {
        if (preIndex === index && preItem.quantity === item.quantity) {
          vm.autoAddToCart(item, index);
        }
      }, 1500, true, angular.copy(index), angular.copy(item));
    }

    function gridAddToCartAfterTimout(action) {
      action == 'INCREMENT' ? clearGridIncrementTimers(vm.selectedItem) : clearGridDecrementTimers(vm.selectedItem);
      $timeout(function (preItem) {
        if (preItem.quantity === vm.selectedItem.quantity) { vm.autoAddToCartGridView(vm.selectedItem); }
      }, 1500, true, angular.copy(vm.selectedItem));
    }


    function resetPaginationQuery() {
      vm.query = {
        limit: 20,
        page: 1
      };
    }
    vm.resetPaginationQuery();
    //Test
    function saveAdhoc(){
      sessionStorage.setItem("adhocType", vm.supplierVisibilityType);
    }

    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }

    function transition() {
      vm.loadAll();
    }


    /**
     * set buyer favourite view type
     */
    function loadViewType() {
      vm.buyerProfile = BuyerDataService.getBuyerProfile();
      vm.itemListType = 'all';
      vm.isAdminBuyer = BuyerDataService.isAdminBuyer();
      if (vm.buyerProfile) {
        vm.buyerViewType = vm.buyerProfile.buyerUser.favoriteView ? vm.buyerProfile.buyerUser.favoriteView : 'List';
        if(vm.buyerViewType === 'FAV_List') {
          vm.isFavoritesView = true;
          vm.viewType = 'List';
          vm.itemListType = 'favorites';
        }
        else if(vm.buyerViewType === 'FAV_Grid'){
          vm.isFavoritesView = true;
          vm.viewType = 'Grid';
          vm.itemListType = 'favorites';
        }
        else {
          vm.viewType = vm.buyerViewType;
        }
      }
      else {
        vm.viewType = 'List';
      }
    }

/**
 * [getOrderTemplateCSVData get order template CSV data for buy now page]
 * @return {[type]} [description]
 */
    function getOrderTemplateCSVData(){
      var deferred = $q.defer();
      if (!vm.orderTemplateDetails) {
        NotificationService.simpleErrorToast({
          title: 'global.table.noRecordsFound',
          message: 'alertMessage.PLEASE_SELECT_ANOTHER_TEMPLATE'
        });
        return deferred.promise;
      }
      return CSVExportService.getBuyNowOrderTemplateCSVData(vm.orderTemplateDetails, vm.items);
    }

    function barcodeSearch() {
      vm.barcode = "";
      $mdDialog.show({
        templateUrl: 'app/buyer-modules/buy-now/buy-now-main/templates/common/buyer.buy-now.barcode-search-modal.tmpl.html',
        controller: function() {
          return vm;
        },
        controllerAs: 'vm',
        targetEvent: event,
        clickOutsideToClose: true
      });
    }

    function setDeliveryDateValidity(isValid) {
      if (vm.viewType == 'List') {
        vm.items.forEach(function (item, index) {
          if (vm.purchaseMethod == 'Buyer Item Buying') {
            vm.buyNowListFormBuyerItem['deliveryDate_' + index].$setValidity("mindate", isValid);
          }
          else{
            vm.buyNowListForm['deliveryDate_' + index].$setValidity("mindate", isValid);
          }
        });
      }
    }

    //Get all items
    function loadAll(barcode) {
      vm.cancel();
      vm.selecteddatePicker = '';
      if(vm.purchaseMethod === 'Purchase By Item'){
        BuyerBuyNowService.getAllItemBuyerMappingsByBuyer(vm.itemListQuery,{
          supplierId: vm.itemListQuery.supplierId ? vm.itemListQuery.supplierId : '',
          businessId: vm.itemListQuery.businessId ? vm.itemListQuery.businessId : '',
          adhocPoType: vm.supplierVisibilityType ? vm.supplierVisibilityType : '',
          view: vm.itemListType,
          barcode:  barcode ? vm.barcode : '',
          query: vm.searchText,
          buyerQuery: vm.buyerQuery ? vm.buyerQuery: '',
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort()
        }).then(function(response){
          vm.disableCommonDate =  true;
          vm.totalItems = response.headers('X-Total-Count');
          vm.recordError = [];
          _.each(response.data, function(element, index) {
            setItemDeliveryCondition(element);
            var deliveryCon = element.buyerDeliveryConditions ? element.buyerDeliveryConditions.deliveryConditions : element.supplier.deliveryCondition; // get specific delivery condition, otherwise supplier default condition
            if(vm.setselectchangedDate){
                var deliverydateset = vm.setselectchangedDate;
            }
            else {
              var deliverydateset = (deliveryCon.noSameDayDelivery && deliveryCon.leadTime >= 0) ? (angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(vm.tomorrow));
              var days = [0, 1, 2, 3, 4, 5, 6], noDeliveryDays = [], defaultdeliveryDay, diff;
              if (deliveryCon.noDeliveryOnSaturday) noDeliveryDays.push(6);
              if (deliveryCon.noDeliveryOnSunday) noDeliveryDays.push(0);
              if (deliveryCon.noDeliveryOnMonday) noDeliveryDays.push(1);
              if (deliveryCon.noDeliveryOnTuesday) noDeliveryDays.push(2);
              if (deliveryCon.noDeliveryOnWednesday) noDeliveryDays.push(3);
              if (deliveryCon.noDeliveryOnThursday) noDeliveryDays.push(4);
              if (deliveryCon.noDeliveryOnFriday) noDeliveryDays.push(5);
              for (var i = 0; i <= noDeliveryDays.length; i++) {
                for (var j = 0; j <= days.length; j++) {
                  if (noDeliveryDays[i] == days[j]) {
                    days.splice(j, 1);
                  }
                }
              }
              if (_.includes(noDeliveryDays, deliverydateset.getDay())) {
                for (var i = 0; i <= days.length; i++) {
                  if (days[i] > (deliverydateset.getDay())) {
                    defaultdeliveryDay = days[i];
                    diff = defaultdeliveryDay - deliverydateset.getDay()
                    deliverydateset = angular.copy(new Date(deliverydateset.getTime() + ((diff) * 24) * 60 * 60 * 1000));
                    break;
                  }
                }
                if (defaultdeliveryDay == undefined || defaultdeliveryDay == null) {
                  defaultdeliveryDay = days[0];
                  diff = defaultdeliveryDay + 1;
                  deliverydateset = angular.copy(new Date(deliverydateset.getTime() + ((diff) * 24) * 60 * 60 * 1000));
                }
              }
            }
            _.extend(element,
            {
              deliveryDate: deliverydateset,
              quantity: angular.copy(element.inCartQuantity),
              minDate: (deliveryCon.noSameDayDelivery)?(angular.copy(new Date(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000).setHours(0, 0, 0, 0)))): (angular.copy(new Date(new Date().getTime())))
            })
          });
          vm.items = [];
          vm.items = response.data;
          if (vm.items && vm.items.length) {
            $timeout(function () {
              setDeliveryDateValidity(true);
            }, 1000);
          }
          else {
            NotificationService.simpleErrorToast(
              {
                "title": "global.buttons.buyNow",
                "message": "global.table.noRecordsFound"
              }
            );
          }
          vm.itemListLoaded = true;
          if(vm.itemListQuery.supplierId && vm.items.length > 0){
            vm.disableCommonDate =  false;
            var firstItemDeliveryCond = vm.items[0].buyerDeliveryConditions ? vm.items[0].buyerDeliveryConditions.deliveryConditions : vm.items[0].supplier.deliveryCondition; // get specific delivery condition, otherwise supplier default condition
           if(vm.setselectchangedDate){
            vm.selectedDate = {
                deliveryDate: vm.setselectchangedDate,
                minDate : (firstItemDeliveryCond.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
              }
           }
           else{
            vm.selectedDate = {
                deliveryDate: (firstItemDeliveryCond.noSameDayDelivery && firstItemDeliveryCond.leadTime >= 0)?(angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                minDate : (firstItemDeliveryCond.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
              }
           }

          }
          vm.saveAdhoc();
        },function(error){
          NotificationService.error({
            title: 'Buy Now - Listing Items',
            error: error
          });
        });
      }
      if(vm.purchaseMethod === 'Buyer Item Buying'){
        // vm.buyerViewType = vm.buyerProfile.buyerUser.favoriteView ? vm.buyerProfile.buyerUser.favoriteView : 'Compact';
        // vm.viewType = vm.buyerViewType;
        vm.buyerItemBuyingsortData = sort()
        getAllBuyerItems();
      }
    }

        function loadPurchaseOrderTypes() {
          vm.purchaseOrderTypes = [];
          PurchaseOrderTypeService.getAllPurchaseOrderTypes({
            page: 0,
            size: paginationConstants.maxLimit,
            sort: ''
          }).then(function(response) {
            vm.purchaseOrderTypes = response.data;
          }, function(error) {
          });
        }

    /**
      * [changeDeliveryDate change delivery date of each item on change of common delivery date]
      * @return {[type]} [description]
    */
    function changeDeliveryDate(date) {
      if (date) {
        vm.setselectchangedDate = date;
      }
      var tempItemDetails = _.each(vm.items, function (element) {
        if (!checkItemInCart(element)) {
          element['deliveryDate'] = vm.setselectchangedDate;
        }
      });
      vm.items = angular.copy(tempItemDetails);
    }

        //Get all business outlets of buyer
        function getAllBuyerBusinessOutlets() {
          vm.businessOutlets = null;
          BuyerBusinessUserMapService.getMappedBusinessOutletsOfLoggedInBuyerUser().
          then(function(response){
            vm.businessOutlets = response;
            //if only one business outlet set it as default
            if(vm.businessOutlets.length === 1) {
              vm.itemListQuery.businessId = vm.businessOutlets[0].id;
              vm.setDefaultPurchaseOrderType();
              vm.businessOutletChanged(vm.businessOutlets[0].id);
            }
            setBusinessOutletAndSupplier();
            itemListQueryChanged();
            vm.getAllOrderTemplateByBusinessIds();
            vm.getAllBlanketOrdersBuyNow();
          },function(error){
          });
        }

        function setDefaultPurchaseOrderType() {
          if(vm.businessOutlets){
            var selectedOutlet = _.find(vm.businessOutlets, ['id', vm.itemListQuery.businessId]);
            vm.itemListQuery.purchaseOrderTypeId = selectedOutlet.defaultPurchaseOrderType ? selectedOutlet.defaultPurchaseOrderType.id : undefined;
          }
        }

        //change the business outlet
        function businessOutletChanged(businessOutletId) {
           _.forEach(vm.businessOutlets, function(businessOutlet){
            if(businessOutlet.id == businessOutletId){
              vm.supplierVisibilityType = businessOutlet.defaultSupplierType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated'
            }
          });
          getBuyerOutletDeliveryConditions(vm.itemListQuery.businessId);
          vm.setDefaultPurchaseOrderType();
          supplierVisibilityTypeChanged();
          itemListQueryChanged();
          getBuyerPromotions();
        }

        // get delivery conditions for the selected outlet
        function getBuyerOutletDeliveryConditions(outletId) {
          BuyerBuyNowService.getDeliveryConditionsForOutlet(outletId).then(
            function(response) {
              vm.buyerDeliveryConditions = response.data;
            }, function(error) {
            });
        }

        // setting buyer outlet specific delivery conditions to the item by filtering from the item supplier
        function setItemDeliveryCondition(item) {
          var matched = vm.buyerDeliveryConditions.find(function(bdc) {return bdc.supplier.id === item.supplier.id});
          if (matched) {
            item.buyerDeliveryConditions = matched;
          }
          // if no specific delivery condition, set supplier default condition as delivery condition
          item.supplier.deliveryCondition = {
            orderTimeForToday: item.supplier.orderTimeForToday,
            orderTimeForFriday: item.supplier.orderTimeForFriday,
            orderTimeForSaturday: item.supplier.orderTimeForSaturday,
            noDeliveryOnHolidays: item.supplier.noDeliveryOnHolidays,
            noDeliveryOnSaturday: item.supplier.noDeliveryOnSaturday,
            noDeliveryOnSunday: item.supplier.noDeliveryOnSunday,
            noDeliveryOnMonday: item.supplier.noDeliveryOnMonday,
            minimumOrderForFreeDelivery: item.supplier.minimumOrderForFreeDelivery,
            deliveryCharge: item.supplier.deliveryCharge,
            belowMinimumPurchaseCondition: item.supplier.belowMinimumPurchaseCondition,
            noSameDayDelivery: item.supplier.noSameDayDelivery,
            leadTime: item.supplier.leadTime,
            noDeliveryOnTuesday: item.supplier.noDeliveryOnTuesday,
            noDeliveryOnWednesday: item.supplier.noDeliveryOnWednesday,
            noDeliveryOnThursday: item.supplier.noDeliveryOnThursday,
            noDeliveryOnFriday: item.supplier.noDeliveryOnFriday
          }
        }

        //change the supplier type
        function supplierVisibilityTypeChanged() {
          //clear supplierId from itemListQuery used for filter
          vm.itemListQuery.supplierId = vm.supplierId || undefined;
          //clear existing supplier list
          vm.supplierList = null;
          var visibilityType = angular.copy(vm.supplierVisibilityType);
          if(vm.itemListQuery && vm.itemListQuery.businessId) {
            if(visibilityType === 'PUBLIC') {
              vm.getAllPublicSuppliers();
            }
            else {
              vm.getAllAllocatedSuppliersForBusiness1(vm.itemListQuery.businessId,vm.supplierVisibilityType);
            }
          }

          vm.loadAll();
        }

        //Get all allocated suppliers of buyer business
        function getAllAllocatedSuppliersForBusiness(businessId) {
          var params = {'businessId':businessId};
          BuyerBuyNowService.getAllAllocatedSuppliersForBusiness(params).then(function(response){
            vm.supplierList = $filter('orderBy')(response.data, 'companyName');
            //if only one allocated supplier set it as default
            if(vm.supplierList.length === 1) {
              vm.itemListQuery.supplierId = vm.supplierList[0].id;
              itemListQueryChanged();
            }
          },function(error){
          });
        }

        function getAllAllocatedSuppliersForBusiness1(businessId,supplierVisibilityType) {
          var params = {'businessId':businessId,'supplierVisibilityType':supplierVisibilityType};
          BuyerBuyNowService.getAllAllocatedSuppliersForBusiness1(params).then(function(response){
            vm.supplierList = $filter('orderBy')(response.data, 'companyName');
            //if only one allocated supplier set it as default
            if(vm.supplierList.length === 1) {
              vm.itemListQuery.supplierId = vm.supplierList[0].id;
              itemListQueryChanged();
            }
          },function(error){
          });
        }

        //Get all public suppliers
        function getAllPublicSuppliers() {
          BuyerBuyNowService.getAllPublicSuppliers().then(function(response){
            vm.supplierList = $filter('orderBy')(response.data, 'companyName');
          },function(error){
          });
        }

        //Change the item load view type
        function toggleItemLoad() {
          vm.isFavoritesView = !vm.isFavoritesView;
          vm.itemListType = vm.itemListType === 'all' ? 'favorites' : 'all';
          vm.resetPaginationQuery();
          vm.loadAll();
        }

        //change the view type
        function changeViewType(type) {
          vm.viewType = type;
        }

        function generateRequirementNo() {
          return Math.floor(Math.random()*899999999999+100000000000);
        }

        function itemListQueryChanged() {
          vm.resetPaginationQuery();
          if(vm.itemListQuery.businessId && !vm.itemListQuery.supplierId) {
            supplierVisibilityTypeChanged();
          }
          if (vm.itemListQuery.businessId || !vm.itemListQuery.businessId) {
            vm.loadAll();
          }
          if(vm.itemListQuery.supplierId) {
            vm.selectedSupplier = _.find(vm.supplierList, function(o) {
              return o.id === vm.itemListQuery.supplierId;
            });
            getSupplierHolidays(vm.itemListQuery.supplierId);
          }
          getBuyerOutletDeliveryConditions(vm.itemListQuery.businessId);
        }

        function autoAddToCart(item, $index) {
          isNegativeBuying(item.quantity) || vm.validateMOQ(item.multiplierFactor, item.quantity, item, $index);
          if(vm.purchaseMethod === 'Purchase By Template' &&  vm.viewType == 'Grid'){
            if (!(!vm.fromApproval && vm.buyNowGridForm['quantity_' + $index].$valid && vm.buyNowGridForm['deliveryDate_' + $index].$valid)) {
              // todo to show proper ui error
              return;
            }
          }
          else {
            if (vm.purchaseMethod == 'Buyer Item Buying') {
              if (!(!vm.fromApproval && vm.buyNowListFormBuyerItem['quantity_' + $index].$valid && vm.buyNowListFormBuyerItem['deliveryDate_' + $index].$valid)) {
                // todo to show proper ui error
                return;
              }
            }
            else{
              if (!(!vm.fromApproval && vm.buyNowListForm['quantity_' + $index].$valid && vm.buyNowListForm['deliveryDate_' + $index].$valid)) {
                // todo to show proper ui error
                return;
              }
            }
          }
          if(parseInt(vm.cartCount) >= vm.maximumAllowedItemsPerOrder){
            if(!_.includes(vm.cartItemsIds, item.itemId)){
              NotificationService.simpleErrorToast({
                title: 'global.buttons.addToCart',
                message: 'alertMessage.MAXIMUM_ALLOWED_ITEMS_PER_ORDER_IS_200'
              });
              return false;
            }
          }
          vm.addToCart(item);
        }

        function autoAddToCartGridView(item) {
          isNegativeBuying(item.quantity) || validateMOQGridItem(item.multiplierFactor, item.quantity);
          if (!(!vm.fromApproval && vm.buyNowGridDetailsForm.quantity.$valid && vm.buyNowGridDetailsForm.deliveryDate.$valid)) {
            // todo to show proper ui error
            return;
          }
          vm.addToCart(item);
        }

        /**
         * double checks quantity before adding to cart,
         * especially for grid view
         */
        function validateQuantityBeforeCartAdd(multiplierFactor, quantity) {
          return (((quantity * 1000).toFixed(3) % (multiplierFactor * 1000).toFixed(3)) == 0);
        }

        //add an item to cart
        function addToCart(itemToBuy) {
          getSupplierHolidays(vm.itemListQuery.supplierId);
          if(vm.holidaysList){
            itemToBuy.supplier.holidaylist = vm.holidaysList;
            // itemToBuy.buyerDeliveryConditions.deliveryConditions.holidaylist = vm.holidaysList;
          }
          setItemDeliveryCondition(itemToBuy);  
          // if quantity is not valid, exit function
          if(!isNegativeBuying(itemToBuy.quantity) && !validateQuantityBeforeCartAdd(itemToBuy.multiplierFactor, itemToBuy.quantity)) {
            if(vm.viewType == 'Grid') {
              vm.buyNowGridDetailsForm['quantity'].$setTouched();
              vm.buyNowGridDetailsForm['quantity'].$setValidity("moq", false);
            }
            return;
          }
          var cartItem = {};
          var setOriginPoint = "Buynow";
          vm.setPreviousDeliveryDate = true;
          if (CartService.checkLeadTime(itemToBuy)){
            if (CartService.checkOrderedTime(itemToBuy, vm.itemListQuery) && (isNegativeBuying(itemToBuy.quantity) || CartService.checkDeliveryQuantity(itemToBuy)) && vm.checkBusinessOutletSelection()) {
              vm.setPreviousDeliveryDate = false;
              cartItem.supplierType = itemToBuy.supplier.visibilityType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated' ;
              cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(itemToBuy.deliveryDate);
              cartItem.quantity = itemToBuy.quantity;
              cartItem.specialRequest = itemToBuy.specialRequest ? itemToBuy.specialRequest : null;
              cartItem.supplierId = vm.itemListQuery.supplierId ? vm.itemListQuery.supplierId : itemToBuy.supplier.id;
              cartItem.items = {};
              cartItem.buyerBusiness = {};
              cartItem.buyerBusiness.id = vm.itemListQuery.businessId;
              if(Auth.hasPermission('accessForGlModule')){
                cartItem.glCodeMappings = {};
                cartItem.glCodeMappings.purchaseOrderTypes = {};
                cartItem.glCodeMappings.purchaseOrderTypes.id = vm.itemListQuery.purchaseOrderTypeId;
              }
              cartItem.items.id = itemToBuy.itemId;
              if(vm.purchaseMethod === 'Purchase By Template') {
                var params = {
                  templateBuying : true
                }
                setOriginPoint = "Template Buying";
              }
              if(vm.purchaseMethod === 'Buyer Item Buying'){
                var params = {
                  buyerItemBuying: true
                }
                setOriginPoint = "Buynow";
              }
              if(vm.purchaseMethod === 'Blanket Order Purchasing'){
                cartItem.blanketOrderItemsMapping = {};
                cartItem.blanketOrderItemsMapping = {id:itemToBuy.blanketOrderItemsMappingID};
                setOriginPoint = "Blanket Order";
              }

              cartItem.originPoint = setOriginPoint;
              CartService.addToCart(cartItem, params).
              then(function(responseData) {
                NotificationService.success({
                  title: 'global.buttons.addToCart',
                  message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                });
                vm.cancel();
                $rootScope.$broadcast('refresh-cart');
                vm.cartCount = responseData.cartCount;
              }, function(error) {
                NotificationService.error({
                  title: 'global.buttons.addToCart',
                  error: error
                });
              });
            }
          }
        }

        //checkout to cart
        function checkout() {
          if (vm.purchaseMethod == 'Purchase By Template') {
            if(vm.buyNowListForm.$valid){
              vm.createCartByTemplate();
            }
          }
          if (vm.purchaseMethod == 'Purchase By Item' || vm.purchaseMethod == 'Blanket Order Purchasing' ||  vm.purchaseMethod == 'Buyer Item Buying') {
            $state.go('buyer.cart-checkout',{
              reload: false
            });
          }
        }

        //add items to cart using template
        function createCartByTemplate(){
          var cart = _.map(vm.items, function (item) {
            if((item.quantity!=null)){
              return {
                "buyerBusiness": { "id": vm.itemListQuery.businessId },
                "glCodeMappings": {"purchaseOrderTypes": {"id": vm.itemListQuery.purchaseOrderTypeId}},
                "supplierId": item.supplier.id,
                "supplierType": vm.supplierVisibilityType,
                "items": {'id': item.itemId},
                'quantity': item.quantity,
                'deliveryDate': item.deliveryDate
              }
            }
          });
          var cartItems=_.compact(cart);
          if(cartItems.length>0){
            CartService.createCartByTemplate(cartItems).then(function (responseData) {
              if(responseData.data.responseList.length>0){
                vm.recordError=responseData.data;
                NotificationService.simpleErrorToast({
                  title: 'global.buttons.addToCart',
                  message: 'alertMessage.SCROLL_DOWN_TO_SEE_THE_ERROR'
                });
              }
              else{
                // NotificationService.success({
                //   title: 'global.buttons.addToCart',
                //   message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                // });
                $rootScope.$broadcast('refresh-cart');
                $state.go('buyer.cart-checkout', {
                  'purchaseMethod': vm.purchaseMethod,
                  'templateId': vm.itemListQuery.selectedTemplate
                },{
                  reload: false
                });}
              }, function (error) {
                NotificationService.error({
                  title: 'global.buttons.addToCart',
                  error: error
                });
              });
            }
          }

          function checkCartCount(itemToBuy){
            BuyerPurchaseApprovalRequestService.getPurchaseApprovalRequest(vm.approvalRequestNumber, {
              page: vm.query.page - 1,
              size: 10000000,
            }).then(function(response) {
              vm.cartCount = _.uniq(_.map(response.data, _.property('items.id'))).length;
              if(parseInt(vm.cartCount) >= vm.maximumAllowedItemsPerOrder){
                NotificationService.simpleErrorToast({
                  title: 'global.buttons.addToCart',
                  message: 'alertMessage.MAXIMUM_ALLOWED_ITEMS_PER_ORDER_IS_200'
                });
                return false;
            }
            addToItemApprovalRequest(itemToBuy);
            }
            ),function(error){}
          }

          //add an item to item approval request
          function addToItemApprovalRequest(itemToBuy) {
          getSupplierHolidays(vm.itemListQuery.supplierId);
          if(vm.holidaysList){
            itemToBuy.supplier.holidaylist = vm.holidaysList;
            // itemToBuy.buyerDeliveryConditions.deliveryConditions.holidaylist = vm.holidaysList;
          }
            var cartItem = {};
            setItemDeliveryCondition(itemToBuy);
            if (CartService.checkOrderedTime(itemToBuy, vm.itemListQuery) && (isNegativeBuying(itemToBuy.quantity) || CartService.checkDeliveryQuantity(itemToBuy)) && vm.checkBusinessOutletSelection()) {
              cartItem.supplierType = itemToBuy.supplier.visibilityType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated';
              cartItem.deliveryDate = UTCDateService.setCurrentTimeToDate(itemToBuy.deliveryDate);
              cartItem.quantity = itemToBuy.quantity;
              cartItem.price = itemToBuy.price;
              cartItem.specialRequest = itemToBuy.specialRequest ? itemToBuy.specialRequest : null;
              cartItem.supplierId = vm.itemListQuery.supplierId;
              cartItem.items = {};
              cartItem.buyerBusiness = {};
              cartItem.buyerBusiness.id = vm.itemListQuery.businessId;
              if(Auth.hasPermission('accessForGlModule')){
                cartItem.glCodeMappings = {};
                cartItem.glCodeMappings.purchaseOrderTypes = {};
                cartItem.glCodeMappings.purchaseOrderTypes.id = vm.itemListQuery.purchaseOrderTypeId;
              }
              cartItem.items.id = itemToBuy.itemId;
              BuyerPurchaseApprovalRequestService.addToPurchaseApprovalRequest(cartItem, vm.approvalRequestNumber).
              then(function(responseData) {
                NotificationService.success({
                  title: 'global.buttons.addToItemApproval',
                  message: 'alertMessage.COMPLETED_SUCCESSFULLY'
                });
                vm.cancel();
                $rootScope.$broadcast('refresh-cart');
              }, function(error) {
                NotificationService.error({
                  title: 'global.buttons.addToItemApproval',
                  error: error
                });
              });
            }
          }

          //Open the add to cart modal (Grid view)
          function openAddToCartModal(event, index, itemToBuy) {
            vm.selectedItem = itemToBuy;
            vm.gridSelectedItemIndex = index;
            $mdDialog.show({
              templateUrl: 'app/buyer-modules/buy-now/buy-now-main/templates/grid/buyer.buy-now-grid-view.details.tmpl.html',
              controller: function() {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
              clickOutsideToClose: true,
              multiple: true
            });
          }

          //Remove an Item from cart. Cart id as param.
          function removeFromCartConfirmationModal(cartId, event, index) {
            // update card Id
            vm.cartId = cartId;
            $mdDialog.show({
              templateUrl: 'app/buyer-modules/buy-now/buy-now-main/templates/common/buyer.buy-now.remove-item-modal.tmpl.html',
              controller: function() {
                return vm;
              },
              controllerAs: 'vm',
              targetEvent: event,
              clickOutsideToClose: false,
              multiple: true
            }).then(function() {
              vm.items[index].quantity = undefined;
            });
          }

          //Check item is in cart
          function checkItemInCart(item) {
            var index =  CartService.checkItemInCart(item);
            if(index !== false) {
              item.inCartId = index;
            }
            return index;
          }

          //Add an item to fav
          function addToFavorites(itemToFav) {
            var favItem = {};
            favItem.items = {'id': itemToFav.itemId};
            BuyerBuyNowService.addToFavorites(favItem).
            then(function(responseData) {
              itemToFav.favoriteItem = true;
              NotificationService.success({
                title: 'global.buttons.addToFavourites',
                message: 'alertMessage.COMPLETED_SUCCESSFULLY'
              });
            }, function(error) {
              NotificationService.error({
                title: 'global.buttons.addToFavourites',
                error: error
              });
            });
          }

          //Remove an item from fav
          function removeFromFavorites(itemToRemove) {
            BuyerBuyNowService.removeFromFavorites(itemToRemove.itemId).
            then(function(responseData) {
              itemToRemove.favoriteItem = false;
              NotificationService.success({
                title: 'global.buttons.removeFromFavourites',
                message: 'alertMessage.COMPLETED_SUCCESSFULLY'
              });
              vm.loadAll();
            }, function(error) {
              NotificationService.error({
                title: 'global.buttons.removeFromFavourites',
                error: error
              });
            });
          }

          //Save the default view of buyer
          function setDefaultView() {
            var defaultView = angular.copy(vm.viewType);
            if(vm.isFavoritesView) {
              defaultView = 'FAV_' + defaultView;
            }
            BuyerBuyNowService.setDefaultView({
              view: defaultView
            }).then(function(response){
              vm.buyerProfile.buyerUser.favoriteView = defaultView;
              BuyerDataService.setBuyerProfile(vm.buyerProfile);
              NotificationService.success({
                title: 'alertMessage.SET_AS_DEFAULT_VIEW',
                message: 'alertMessage.COMPLETED_SUCCESSFULLY'
              });
            },function(error){
              NotificationService.error({
                title: 'alertMessage.SET_AS_DEFAULT_VIEW',
                error: error
              });
            });
          }

          //Check if business outlet is selected or not
          function checkBusinessOutletSelection() {
            if(vm.itemListQuery.businessId) {
              return true;
            }
            else {
              NotificationService.simpleErrorToast({
                title: 'alertMessage.ERROR',
                message:'alertMessage.SELECT_BUSINESS_OUTLET'
              });
              return false;
            }
          }

          //Get all promotions for the buyer starting from today
          function getBuyerPromotions() {
            PromotionService.getAllPromotionItemsForBuyer({
              page: 0,
              size: 10,
              sort: 'effectiveStartDate,asc',
              searchTerm: '',
              startDate: new Date(),
              endDate: '',
              flag:"BUY_NOW",
              buyerBusinessId: vm.itemListQuery.businessId
            }).then(function(response) {
              vm.showPromotionTicker = true;
              vm.promotions = response.data;
            }, function(error) {
              vm.promotions = null;
            });
          }

          function hidePromotions() {
            vm.showPromotionTicker = !vm.showPromotionTicker;
          }

          function cancel() {
            $mdDialog.cancel();
          }

          function getPromotionTicker(promotionItem, itemIndex) {
            var promotionTicker = '';
            if(promotionItem){
              promotionTicker = 'Buy '
              + promotionItem.applicablePromotionQuantity + ' '
              + promotionItem.promotionItem.itemName + ' and get '
              + promotionItem.freeQuantity + ' ' + promotionItem.freeItem.itemName
              + ' free! | \n (Promo Duration) '
              + $filter('date')(promotionItem.effectiveStartDate, "dd/MM/yyyy") + ' to '
              + $filter('date')(promotionItem.effectiveEndDate, "dd/MM/yyyy")
              + ' ( ' + promotionItem.promotionItem.supplier.companyName + ' ) ';
            }
            return promotionTicker;
          }

          function removeFromCart(cartId) {
            vm.cartId = cartId ? cartId : vm.cartId;
            CartService.removeFromCart(vm.cartId).
            then(function (responseData) {
              NotificationService.success({
                title: 'global.buttons.removeFromcart',
                message: 'alertMessage.COMPLETED_SUCCESSFULLY'
              });
              $rootScope.$broadcast('refresh-cart');
              vm.cartCount = responseData.data;
              $mdDialog.hide();
            }, function (error) {
              NotificationService.error({
                title: 'global.buttons.removeFromcart',
                error: error
              });
            });
          }

          //load all items in the template
          function loadOrderTemplateItems(templateId) {
            vm.recordError=[];
            vm.items = undefined;
            if(templateId){
              BuyerBuyNowService.getAllItemBuyerMappingsByTemplateId(templateId, {
                view: vm.itemListType,
                outletId: vm.itemListQuery.businessId
              }).then(function (response) {
              vm.disableCommonDate =  true;
              _.each(response.data.items, function(element, index) {
                setItemDeliveryCondition(element);
                var deliveryCon = element.buyerDeliveryConditions ? element.buyerDeliveryConditions.deliveryConditions : element.supplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default delivery condition
                _.extend(element,
                  {
                    deliveryDate: (deliveryCon.noSameDayDelivery && deliveryCon.leadTime >= 0)? (angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                    quantity: angular.copy(element.inCartQuantity),
                    minDate: (deliveryCon.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                  })
              });
              vm.orderTemplateData = response.data;
              vm.items = response.data.items;
              if(response.data.buyerMappingList.length != 0){
                _.map(response.data.buyerMappingList, function(item, index){
                  vm.items[index]['buyerItemName'] = item.buyerItemName;
                  vm.items[index]['buyerItemCode'] = item.buyerItemCode;
                });
              }
              vm.beforeQuickSearchArray = vm.items;
              vm.orderTemplateDetails = response.data.orderTemplate;
              vm.itemListLoaded = true;
              vm.itemListQuery.purchaseOrderTypeId = vm.orderTemplateDetails.purchaseOrderType ? vm.orderTemplateDetails.purchaseOrderType.id : null;
              vm.itemListQuery.businessName =_.result(_.find(vm.businessOutlets, { 'id':vm.itemListQuery.businessId }), 'businessOutletName');
              vm.itemListQuery.supplierList = BuyerOrderTemplateService.getSelectedSuppliersNames(vm.items);
              vm.supplierCountAdhoc = BuyerOrderTemplateService.checkSupplierLength(vm.items);
              vm.supplierVisibilityType = BuyerOrderTemplateService.getSelectedSupplierVisibilityType(vm.items) == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated';

              if(vm.items.length > 0 && BuyerOrderTemplateService.checkSupplierLength(vm.items) >= 1) {
                vm.disableCommonDate =  false;
                vm.selectedDate = {
                  deliveryDate : new Date(),
                  minDate : new Date()
                }
              }
              if(BuyerOrderTemplateService.checkSupplierLength(vm.items)===1 && vm.items.length > 0){
                vm.disableCommonDate =  false;
                var deliveryCon = vm.items[0].buyerDeliveryConditions ? vm.items[0].buyerDeliveryConditions.deliveryConditions : vm.items[0].supplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default delivery condition
                if(vm.setselectchangedDate){
                    vm.selectedDate = {
                        deliveryDate: vm.setselectchangedDate,
                        minDate : (firstItemDeliveryCond.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                      }
                }
                else{
                    vm.selectedDate = {
                    deliveryDate: (deliveryCon.noSameDayDelivery && deliveryCon.leadTime >= 0)?(angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                    minDate : (deliveryCon.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((deliveryCon .leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                    }
                }
              }
              }, function (error) {
                NotificationService.error({
                  title: 'Order Templates',
                  error: error
                });
              });
            }
          }

          //change purchase method
          function changePurchaseMethod() {
            vm.itemListQuery={};
            vm.getAllBuyerBusinessOutlets();
            if(vm.purchaseMethod==='Purchase By Template'){
            vm.items=undefined;
            vm.supplierVisibilityType = 'allocated';
            vm.orderTemplateList = [];
            CartService.getCartDetails({
              page: 0,
              size: 100000
            }).then(function (response) {
              var count = response.cartCount ? response.cartCount : 0;
              if (count > 0) {
                vm.showDeleteCartConfirmationModal();
              }
            }, function (error) {
              NotificationService.error({
                title: 'Buyer Cart',
                error: error
              });
            });
            vm.init();
          }
          else if(vm.purchaseMethod==='Purchase By Item'){
            vm.supplierList = [];
            vm.init();
          }
          else if(vm.purchaseMethod==='Blanket Order Purchasing'){
             // vm.getAllBlanketOrdersBuyNow();
             vm.itemListLoaded = false;
             vm.items = [];
             vm.init();
          }
          else if(vm.purchaseMethod==='Buyer Item Buying'){
            vm.itemListLoaded = false;
            vm.items = [];
            vm.init();
         }
          else {
            vm.supplierList = [];
            vm.items = [];
            vm.init();
          }
          }


          //show confirmation modal for clearing cart
          function showDeleteCartConfirmationModal(ev, supplierItem) {
            var confirm = $mdDialog.confirm()
            .title($filter('translate')('alertMessage.CONFIRM_CLEAR_CART'))
            .textContent($filter('translate')('alertMessage.COULD_NOT_BE_REVERTED_BACK'))
            .targetEvent(ev)
            .ok($filter('translate')('global.buttons.clear'))
            .cancel($filter('translate')('global.buttons.cancel'));

            $mdDialog.show(confirm).then(function () {
              vm.clearCart();
            }, function () {
              //vm.purchaseMethod='Purchase By Item';
              vm.loadAll();
            });
          }

          //clearCart - resets cart if purchase by template
          function clearCart() {
            BuyerBuyNowService.resetCart().then(function (response) {
              vm.cartCount = 0;
              clearAllItemQuantity();
              NotificationService.success({
                title: 'global.menu.entities.cart',
                message: 'alertMessage.DELETED_SUCCESSFULLY'
              });
              $rootScope.$broadcast('refresh-cart');
            }, function (error) {
              NotificationService.error({
                title: 'Order Templates',
                error: error
              });
            });
          }

          function clearAllItemQuantity() {
            vm.items.forEach(function(item) {
              item.quantity = undefined;
            });
          }

          //get all templates available
           function getOrderTemplates() {
            BuyerOrderTemplateService.getAllOrderTemplates({isForPurchase:true}).then(function (response) {
              if (response.data.length === 0) {
                NotificationService.simpleErrorToast({
                  title: 'global.purchaseMethod',
                  message: 'alertMessage.ORDER_TEMPLATE_NOT_MAPPED'
                });
                vm.isRequired = false;
                vm.purchaseMethod="Purchase By Item";
                vm.loadAll();
              }
              else {
                vm.isRequired = true;
              }
            }, function (error) {
              NotificationService.error({
                title: 'Order Templates',
                error: error
              });
            });
          }

          /**
           * [getAllOrderTemplateByBusinessIds Gets all templates by business Ids]
           */
          function getAllOrderTemplateByBusinessIds() {
            if(vm.purchaseMethod=="Purchase By Template" && vm.itemListQuery.businessId){
              BuyerOrderTemplateService.getAllOrderTemplateByOutlets({'buyerBusinessId' : vm.itemListQuery.businessId}).then(function (response) {
                if (response.data.length === 0) {
                  NotificationService.simpleErrorToast({
                    title: 'global.purchaseMethod',
                    message: 'alertMessage.ORDER_TEMPLATE_NOT_MAPPED'
                  });
                  vm.orderTemplateList = [];
                  vm.itemListQuery.supplierList = [];
                }
                else {
                  vm.orderTemplateList = response.data;
                }
              }, function (error) {
                NotificationService.error({
                  title: 'Order Templates',
                  error: error
                });
              });
            }

          }

        /**
         * [getCartDetails get all details of the cart]
         */
        function getCartDetails(){
          var params = {
            page: 0,
            size: 100000
          };
          CartService.getCartDetails(params).then(function (response) {
            if(response.cartCount > 0){
              vm.cartCount = response.cartCount;
              vm.cartItemsIds = _.map(response.cartItems, _.property('items.id'));
              vm.itemListQuery.businessId = response.cartItems[0].buyerBusiness.id;
              vm.supplierVisibilityType = response.cartItems[0].items.supplier.visibilityType == 'ADHOC' ? 'ADHOC_PO_OFF_BOARD' : 'allocated';
              vm.supplierVisibilityTypeChanged();
            }
          });
        }

      /**
        * [validateMOQ Validates the MOQ with Multiplier]
        */
      function validateMOQ(multiplierFactor, quantity, item, index){
        // calculate multiplier factor
        multiplierFactor = (multiplierFactor || item.weightRequired) ? multiplierFactor || 0.001 : 1;
        item.multiplierFactor = multiplierFactor;

        if(!item.weightRequired){
          var splitQty = item.quantity.toString().split(".")[1];
          if(splitQty){
            var len = splitQty.length || 0;
            if(len >= 3){
              if(vm.purchaseMethod === 'Purchase By Template' &&  vm.viewType == 'Grid') { /* template buying grid view*/
                vm.buyNowGridForm['quantity_' + index].$setTouched();
                vm.buyNowGridForm['quantity_' + index].$setValidity("max-precision", false);
                return;
              }
              if (vm.purchaseMethod == 'Buyer Item Buying') {
                vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
                vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setValidity("max-precision", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", false);
              }
              else{
                vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
                vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setValidity("max-precision", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", false);
              }
              return ;
            }
            else{
              if(vm.purchaseMethod === 'Purchase By Template' &&  vm.viewType == 'Grid') { /* template buying grid view*/
                vm.buyNowGridForm['quantity_' + index].$setValidity("max-precision", true);
              } else {
                if(vm.purchaseMethod == 'Buyer Item Buying'){
                  vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setValidity("max-precision", true) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", true);
                }
                else{
                  vm.viewType == 'List'? vm.buyNowListForm['quantity_' + index].$setValidity("max-precision", true) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", true);
                }
              }
            }
          }
        }
        if(item.weightRequired){
          var splitQty = item.quantity.toString().split(".")[1];
          if(splitQty){
            var len = splitQty.length || 0;
            if(len  > 3){
              if(vm.purchaseMethod === 'Purchase By Template' &&  vm.viewType == 'Grid') { /* template buying grid view*/
                vm.buyNowGridForm['quantity_' + index].$setTouched();
                vm.buyNowGridForm['quantity_' + index].$setValidity("max-precision", false);
                return;
              }
              if (vm.purchaseMethod == 'Buyer Item Buying') {
              vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
              vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setValidity("max-precision", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", false);
              }
              else{
                vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
                vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setValidity("max-precision", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("max-precision", false);
              }
              return ;
            }
          }
        }

        // validate multiplier factor
        item.moq = item.moq || 0;
        if(vm.purchaseMethod == 'Purchase By Template' &&  vm.viewType == 'Grid' ) {
          if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
            vm.buyNowGridForm['quantity_' + index].$setTouched();
            vm.buyNowGridForm['quantity_' + index].$setValidity("moq", false);
          }
          else {
            vm.buyNowGridForm['quantity_' + index].$setValidity("moq", true);
          }
        }
       else if(vm.purchaseMethod == 'Buyer Item Buying' &&  vm.viewType == 'Compact' ) {
            if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
              vm.buyNowCompactListForm['quantity_' + index].$setTouched();
              vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", false);
            }
            else {
              vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", true);
            }
          }
        else {
          if(vm.purchaseMethod == 'Buyer Item Buying'){
            if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
              vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setValidity("moq", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", false);
              vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
            } else {
              vm.viewType == 'List' ? vm.buyNowListFormBuyerItem['quantity_' + index].$setValidity("moq", true) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", true);
            }
          }
          else{
            if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
              vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setValidity("moq", false) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", false);
              vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setTouched() : vm.buyNowCompactListForm['quantity_' + index].$setTouched();
            } else {
              vm.viewType == 'List' ? vm.buyNowListForm['quantity_' + index].$setValidity("moq", true) : vm.buyNowCompactListForm['quantity_' + index].$setValidity("moq", true);
            }
          }
        }
      }


      function validateMOQGridItem(multiplierFactor, quantity){
        // calculate multiplier factor
        multiplierFactor = (multiplierFactor || vm.selectedItem.weightRequired) ? (multiplierFactor || 0.001) : 1;
        vm.selectedItem.multiplierFactor = multiplierFactor;
        vm.selectedItem.moq = vm.selectedItem.moq || 0;

        // validate decimal places
        var decimals = vm.selectedItem.quantity.toString().split(".")[1];
        if (!vm.selectedItem.weightRequired && decimals) {
          var len = decimals.length || 0;
          if (len >= 3) {
            vm.buyNowGridDetailsForm['quantity'].$setTouched();
            vm.buyNowGridDetailsForm['quantity'].$setValidity("max-precision", false);
            return;
          }
          else {
            vm.buyNowGridDetailsForm['quantity'].$setValidity("max-precision", true);
          }
        }
        if (vm.selectedItem.weightRequired && decimals) {
          var len = decimals.length || 0;
          if (len > 3) {
            vm.buyNowGridDetailsForm['quantity'].$setTouched();
            vm.buyNowGridDetailsForm['quantity'].$setValidity("max-precision", false);
            return;
          }
        }
        if (((quantity * 1000).toFixed(2) % (multiplierFactor * 1000).toFixed(2)) != 0) {
          vm.buyNowGridDetailsForm['quantity'].$setTouched();
          vm.buyNowGridDetailsForm['quantity'].$setValidity("moq", false);
        } else {
          vm.buyNowGridDetailsForm['quantity'].$setValidity("moq", true);
        }
      }

      function getAllBlanketOrdersBuyNow(){
        if(vm.purchaseMethod=="Blanket Order Purchasing" && vm.itemListQuery.businessId){
            vm.buyerID = {
                "buyerId":vm.buyerId,
            }
            BlanketOrderService.getAllBlanketOrderBuyNow(vm.buyerId).then(function (response) {
              if (response.length === 0) {
                NotificationService.simpleErrorToast({
                  title: 'global.purchaseMethod',
                  message: 'alertMessage.ORDER_TEMPLATE_NOT_MAPPED'
                });
                vm.blanketOrderList = [];
                vm.itemListQuery.supplierList = [];
              }
              else {
                vm.blanketOrderList = response;
              }
            }, function (error) {
              NotificationService.error({
                title: 'Blanket Order',
                error: error
              });
            });
          }
      }

    //load all items in the template
    function loadBlanketOrders(blanketOrderId) {
      vm.recordError = [];
      vm.items = undefined;
      vm.blanketOrderId = vm.itemListQuery.selectedBlanketOrder;
      if (vm.blanketOrderId) {
        _.find(vm.blanketOrderList, function (stype) {
          if (stype.blanketOrderId === blanketOrderId) {
            vm.supplierVisibilityType = stype.supplierType;
            vm.itemListQuery.supplierList = stype.supplierName;
          };
        });
        BlanketOrderService.getBlanketOrderById(vm.blanketOrderId, {
          outletId: vm.itemListQuery.businessId,
          page: vm.query.page - 1,
          size: vm.query.limit,
          sort: sort(),
        }).then(function (response) {
          vm.itemListLoaded = true;
          vm.items = []
          vm.totalItems = response.data.blanketOrderItemsMappingPage.totalElements;
          _.each(response.data.blanketOrderItemsMappingPage.content, function (element, index) {
            setItemDeliveryCondition(element.items);
            var deliveryCon = element.items.buyerDeliveryConditions ? element.items.buyerDeliveryConditions.deliveryConditions : element.items.supplier.deliveryCondition; // get specific delivery condition, otherwise supplier default condition
            if (vm.setselectchangedDate) {
              var deliverydateset = vm.setselectchangedDate;
            }
            else {
              var deliverydateset = (deliveryCon.noSameDayDelivery && deliveryCon.leadTime >= 0) ? (angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(vm.tomorrow));
            }
            _.extend(element.items,
              {
                deliveryDate: deliverydateset,
                // quantity: angular.copy(element.inCartQuantity ? element.inCartQuantity : null),
                minDate: (deliveryCon.noSameDayDelivery) ? (angular.copy(new Date(new Date(new Date().getTime() + ((deliveryCon.leadTime) * 24) * 60 * 60 * 1000).setHours(0, 0, 0, 0)))) : (angular.copy(new Date(new Date().getTime())))
              })
            vm.items.push(element.items);
            vm.items[index].itemId = element.items.id ? element.items.id : '';
            vm.items[index].price = element.price ? element.price : '';
            //vm.items[index].quantity = element.quantity ? element.quantity : '';
            vm.items[index].uomName = element.items.uom.name ? element.items.uom.name : '';
            vm.items[index].itemGroupName = element.items.itemGroup.name ? element.items.itemGroup.name : '';
            vm.items[index].weightRequired = element.items.uom.weightRequired;
            vm.items[index].maxAllocation = null;
            vm.items[index].blanketOrderItemsMappingID = element.id ? element.id : null;
            vm.items[index].availableQuantity = element.quantity - element.prQuantity;
            if (element.promotionItem && element.promotionItem != null) {
              vm.items[index].promotionItem = element.promotionItem
            }
          });
          if (response.data.buyerMappingList.length != 0) {
            _.map(response.data.buyerMappingList, function (each, index) {
              vm.items[index]['buyerItemCode'] = each.buyerItemCode;
              vm.items[index]['buyerItemName'] = each.buyerItemName;
            });
          }
          if (vm.items && vm.items.length) {
            $timeout(function () {
              setDeliveryDateValidity(true);
            }, 1000);
          }
          else {
            NotificationService.simpleErrorToast(
              {
                "title": "global.buttons.buyNow",
                "message": "global.table.noRecordsFound"
              }
            );
          }
          vm.itemListLoaded = true;
          if (vm.items.length > 0) {
            vm.disableCommonDate = false;
            var firstItemDeliveryCond = vm.items[0].buyerDeliveryConditions ? vm.items[0].buyerDeliveryConditions.deliveryConditions : vm.items[0].supplier.deliveryCondition; // get specific delivery condition, otherwise supplier default condition
            if (vm.setselectchangedDate) {
              vm.selectedDate = {
                deliveryDate: vm.setselectchangedDate,
                minDate: (firstItemDeliveryCond.noSameDayDelivery) ? (angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(new Date(new Date().getTime())))
              }
            }
            else {
              vm.selectedDate = {
                deliveryDate: (firstItemDeliveryCond.noSameDayDelivery && firstItemDeliveryCond.leadTime >= 0) ? (angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(vm.tomorrow)),
                minDate: (firstItemDeliveryCond.noSameDayDelivery) ? (angular.copy(new Date(new Date().getTime() + ((firstItemDeliveryCond.leadTime) * 24) * 60 * 60 * 1000))) : (angular.copy(new Date(new Date().getTime())))
              }
            }

          }
        }, function (error) {
          NotificationService.error({
            title: 'Blanket Order',
            error: error
          });
        });
      }
    }

    function getAllBuyerItems(){
      var params = {
        businessId: vm.itemListQuery.businessId,
        searchText: vm.buyerQuery ? vm.buyerQuery: '',
        view: vm.itemListType,
        page: vm.query.page - 1,
        size: vm.query.limit,
        sort: vm.buyerItemBuyingsortData
      }
    vm.disableCommonDate = false;
    BuyerBuyNowService.getAllBuyerItems(params).then(function(response){
        vm.itemListLoaded = true;
        vm.items = []
        vm.totalItems = response.data.buyerItemBuyingItemsDTOS.totalElements;
        _.each(response.data.buyerItemBuyingItemsDTOS.content, function (element, index) {
            _.extend(element.items,
              {
                deliveryDate: vm.setselectchangedDate ? vm.setselectchangedDate : ''
               // quantity: angular.copy(element.inCartQuantity ? element.inCartQuantity : null),
               // minDate: (deliveryCon.noSameDayDelivery) ? (angular.copy(new Date(new Date(new Date().getTime() + ((deliveryCon.leadTime) * 24) * 60 * 60 * 1000).setHours(0, 0, 0, 0)))) : (angular.copy(new Date(new Date().getTime())))
              })
          vm.items.push(element.items);
          vm.items[index].itemId = element.items.id ? element.items.id : '';
          vm.items[index].buyerItemCode = element.itemBuyerMapping.buyerItemCode ? element.itemBuyerMapping.buyerItemCode : '';
          vm.items[index].buyerItemName = element.buyerItemName ? element.buyerItemName : '';
          vm.items[index].price = element.price ? element.price : '';
          vm.items[index].uomName = element.items.uom.name ? element.items.uom.name : '';
          vm.items[index].itemGroupName = element.items.itemGroup.name ? element.items.itemGroup.name : '';
          vm.items[index].weightRequired = element.items.uom.weightRequired;
          vm.items[index].maxAllocation = null;
          vm.items[index].availableQuantity = element.items.availableQuantity;
          vm.items[index].quantity = element.items.quantity ? element.items.quantity : '' ;
          vm.items[index].itemBusinessMapping = element.itemBusinessMapping ? element.itemBusinessMapping : null ;
          vm.items[index].itemBuyerMapping = element.itemBuyerMapping ? element.itemBuyerMapping : null ;
          vm.items[index].favoriteItem = element.favoriteItem ? element.favoriteItem : false;
        });
        // Date fix buyer item buying
        if(vm.buyerDeliveryConditions.length>0){
            var deliveryCon = vm.buyerDeliveryConditions[0].deliveryConditions ? vm.buyerDeliveryConditions[0].deliveryConditions : vm.buyerDeliveryConditions[0].supplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default delivery condition
        }
        if(vm.items.length > 0){
            vm.disableCommonDate =  false;
            if(vm.setselectchangedDate){
                vm.selectedDate = {
                    deliveryDate: vm.setselectchangedDate,
                    minDate : new Date()
                  }
            }
            else{
                if(deliveryCon){
                vm.selectedDate = {
                    deliveryDate: (deliveryCon.noSameDayDelivery && deliveryCon.leadTime >= 0)?(angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(vm.tomorrow)),
                    minDate : (deliveryCon.noSameDayDelivery)?(angular.copy(new Date(new Date().getTime() + ((deliveryCon.leadTime)*24) * 60 * 60 * 1000))): (angular.copy(new Date(new Date().getTime())))
                  }
                }
                else{
                    vm.selectedDate = {
                        deliveryDate: (angular.copy(vm.tomorrow)),
                        minDate : new Date()
                    }
                }
            }
          }
          var tempItemDetails = _.each(vm.items, function (element) {
              element['deliveryDate'] = vm.selectedDate.deliveryDate;
          });
          vm.items = angular.copy(tempItemDetails);
          // Date fix buyer item buying
      }, function (error) {
        NotificationService.error({
          title: 'Buyer item buying',
          error: error
        });
      });


    }

    function setDefaultBuyingMode(buyingMode){
        localStorage.setItem("defaultBuyingMode",buyingMode);
        toastr.success('', 'Default Buying mode set as '+buyingMode);
    }
    function getDefaultBuyingMode(){
        vm.purchaseMethod = localStorage.getItem("defaultBuyingMode") ? localStorage.getItem("defaultBuyingMode") : 'Purchase By Item'
    }

    function getSupplierHolidays(supplierId){
      CartService.getAllSupplierCalendarsHoliday(supplierId).then(function(response){
        vm.holidaysList = response.data;            
      })
    }

    /*
    *disable days in calendar based on default/specific delivery condition
    */
    function disableDays(date) {
      var item = vm.selecteddatePicker, noDeliveryDays = [], enabledDaysFlag = true;
      const day = date.getDay();
      
      var selectedSupplier = item.items? item.items.supplier : item.supplier;
      var deliveryCond = item.buyerDeliveryConditions ? item.buyerDeliveryConditions.deliveryConditions : selectedSupplier.deliveryCondition; // get specific delivery condition, otherwise get supplier default conditions
      
      if(deliveryCond != undefined){
      if (deliveryCond.noDeliveryOnSaturday) noDeliveryDays.push(6);
      if (deliveryCond.noDeliveryOnSunday) noDeliveryDays.push(0);
      if (deliveryCond.noDeliveryOnMonday) noDeliveryDays.push(1);
      if (deliveryCond.noDeliveryOnTuesday) noDeliveryDays.push(2);
      if (deliveryCond.noDeliveryOnWednesday) noDeliveryDays.push(3);
      if (deliveryCond.noDeliveryOnThursday) noDeliveryDays.push(4);
      if (deliveryCond.noDeliveryOnFriday) noDeliveryDays.push(5);
      return !_.includes(noDeliveryDays,date.getDay());
      }else{
        return true;
      }
    };

    /*
    *set the selected datepicker
    */
    function openCalendar(itemToBuy){      
      vm.selecteddatePicker = itemToBuy;
    }
    }
})();
