/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.waiting-supplier-reply.service.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-01-11T14:44:33+05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerWaitingSupplierReplyService', BuyerWaitingSupplierReplyService);

  BuyerWaitingSupplierReplyService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function BuyerWaitingSupplierReplyService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var buyerWaitingSupplierReplyService = {
      getWaitingSupplierReply: getWaitingSupplierReply,
      getPendingRequestByRequirementNumber: getPendingRequestByRequirementNumber,
      getSupplierReplyReq_PO: getSupplierReplyReq_PO,
      getCancel_PO: getCancel_PO,
      getPurchaseRequestByRequirementNumber: getPurchaseRequestByRequirementNumber
    };
    return buyerWaitingSupplierReplyService;

    function getWaitingSupplierReply(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'waiting-supplier-reply/PENDING',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getSupplierReplyReq_PO(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'waiting-supplier-reply/REQUIREMENT_PENDING_PO',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get Purchase Request by requirementNumber - To be removed as it is in ProcessOrders service
    function getPurchaseRequestByRequirementNumber(requirementNumber, sendSort) {
      var deferred = $q.defer();
      var params = sendSort;
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/report',
        method: "GET",
        params: params,
      }).then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
      // $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/report').
    }

    // Cancel_PO processOrderNumber input
    function getCancel_PO(requirementNumber) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer-cancel-process-order/'+requirementNumber,
        method: "PUT"
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Get PendingRequest by requirementNumber - To be removed as it is in ProcessOrders service
    function getPendingRequestByRequirementNumber(requirementNumber) {
      var deferred = $q.defer();
      $http.get(API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + RESOURCE_URL_CONSTANTS.PROCESS_ORDER_RESOURCE + requirementNumber + '/details').then(function(data) {
        deferred.resolve(data.data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


  }
})();
