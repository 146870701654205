/**
* @Author: Ajay
* @Date:   03-Mar-2017 15:12 +05:30
* @Project: Ezyprocure
* @Filename: buyer.dashboard.service.js
 * @Last modified by:   Ajay
 * @Last modified time: 25-Sep-2017 10:26 +05:30
*/



(function() {
  'use strict';

  angular.module('ezyprocureApp').factory('BuyerDashboardService', BuyerDashboardService);

  BuyerDashboardService.$inject = ['$http', '$q', 'API_CONSTANTS'];
  function BuyerDashboardService($http, $q, API_CONSTANTS) {

    var buyerDashboardService = {
      getBuyerDashboardCount: getBuyerDashboardCount,
      getBuyerDashboardApprovals: getBuyerDashboardApprovals,
      getCarousalDetails:getCarousalDetails
    };

    return buyerDashboardService;

    /* --  Buyer Dashboard APIs  -- */

    //Get Buyer Dashboard Count
    function getBuyerDashboardCount() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/dashboard/',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

        //Get Carousel Details
        function getCarousalDetails() {
          var deferred = $q.defer();
          $http({
            url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'utility/carousal',
            method: "GET",
          }).then(function(data) {
            deferred.resolve(data);
          }).catch(function(message) {
            deferred.reject(message);
          });
          return deferred.promise;
        }
    //Get Buyer Dashboard Count
    function getBuyerDashboardApprovals() {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyers/dashboard/approvals',
        method: "GET",
      }).then(function(data) {
        deferred.resolve(data);
      }).catch(function(message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

  }
})();
