/**
 * @Author: Ajay
 * @Date:   05-Apr-2017 14:41 +05:30
 * @Filename: s3-url.constants.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-11T11:13:12+05:30
 */



/**
* Please add in aplhabetic order of keys
*/

"use strict";
angular.module('ezyprocureApp')
.constant('S3_URL_CONSTANTS', {
  "ADHOC_PRICE_UPDATE":"https://ezyprocure-sample-csv.s3-ap-southeast-1.amazonaws.com/Adhoc-price-update.csv",
  "ADMIN_BUYERS_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/Buyers-upload.csv",
  "ADMIN_CALENDAR_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/Admin-Calendars-Upload.csv",
  "ADMIN_SUPPLIERS_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/suppliers-upload.csv",
  "ADD_PAYEE_UPLOAD_EXCEL": "https://ezyprocure-sample-csv.s3-ap-southeast-1.amazonaws.com/add-payee-upload.xlsx",
  "BUSINESS_SUPPLIER_MAPPING_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/BusinessOutlet-Supplier-mapping.csv",
  "BUYER_ITEM_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/adhoc-items-upload.csv",
  "BUYER_MAP_SUPPLIERS_LIST_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/Buyer-Map-Suppliers-List.csv",
  "EZYAP_INVOICE_UPLOAD_CSV":"https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/PeppolExternalInvoice.csv",
  "PAYMENT_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/PaymentUploadFileSample_MY.csv",
  "SUPPLIER_EXTERNAL_PAYMENT_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/SupplierExternalPaymentFileSample.csv",
  "SUPPLIER_EXTERNAL_PAYMENT_WITH_CREDIT_NOTE_UPLOAD_CSV": "https://sgebiz-artifacts.s3.ap-southeast-1.amazonaws.com/SupplierExternalPaymentFileSampleWithCreditNote.csv",
  "PURCHASE_REQUEST_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/purchase-request-upload.csv",
  "SALES_ORDER_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/sales_order.csv",
  "SUPPLIER_ITEM_MAPPING_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/item-mapping.bulk-upload.csv",
  "SUPPLIER_ITEM_UPLOAD_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/Supplier-Items-upload.csv",
  "SUPPLIER_MAP_BUYERS_LIST_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/Supplier-Map-Buyers-List.csv",
  "SUPPLIER_PRICE_UPDATE_CSV": "https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/supplier-price-update.csv",
  "ADHOC_PO_FORM_ITEMS_CSV":"https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/adhoc_po_form_items.csv",
  "ORDER_TEMPLATE_CSV":"https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/order_template.csv",
  'PO_JOB_CSV': 'https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/job_po.csv',
  "SIP_INVOICE_UPLOAD_CSV":"https://s3-ap-southeast-1.amazonaws.com/ezyprocure-sample-csv/sip-invoice-upload.csv",
});
