/*
 * @Author: Lovin Chacko
 * @Date: 2020-08-10 12:39:34
 * @Last Modified by: Lovin Chacko
 * @Last Modified time: 2021-03-24 11:48:13
 */



(function () {
  'use strict';

  angular.module('ezyprocureApp').factory('AdhocItemPriceUpdateService', AdhocItemPriceUpdateService);

  AdhocItemPriceUpdateService.$inject = ['$http', '$q', 'API_CONSTANTS', 'RESOURCE_URL_CONSTANTS'];

  function AdhocItemPriceUpdateService($http, $q, API_CONSTANTS, RESOURCE_URL_CONSTANTS) {

    var AdhocItemPriceUpdateService = {
      getAllAdhocItemPrices: getAllAdhocItemPrices,
      setPriceObject: setPriceObject,
      getPriceObject: getPriceObject,
      searchAllItems: searchAllItems,
      deletePrice: deletePrice,
      newPriceUpdateItems: newPriceUpdateItems,
      setEditPriceObject: setEditPriceObject,
      getEditablePriceObj: getEditablePriceObj,
      editPriceUpdate: editPriceUpdate,
      deletePriceUpdate: deletePriceUpdate,
      getAllBuyerMappedAdhocSuppliers: getAllBuyerMappedAdhocSuppliers,
      priceUpdateCSV: priceUpdateCSV,
      loadAllListIteams: loadAllListIteams,
      deletePriceUpdateBulk: deletePriceUpdateBulk
    };

    var priceUpdateFilter;
    var vm = this;

    return AdhocItemPriceUpdateService;

    //price update using csv
    function priceUpdateCSV(csvUploadData) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'adhoc/price-updates/upload',
        method: "POST",
        data: csvUploadData,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function getAllBuyerMappedAdhocSuppliers(buyerId, params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'map/buyer/' + buyerId + '/supplier',
        method: "GET",
        params: params,
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    function loadAllListIteams(body, params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/buyers/items/detail',
          method: "POST",
          params: params,
          data: body,
        }).then(function(response) {
          deferred.resolve(response);
        }).catch(function(message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    // Get all adhoc items price details
    function getAllAdhocItemPrices(urlParams) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-updates/adhoc',
        method: "GET",
        params: urlParams
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Delete price update
    function deletePriceUpdate(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-updates/adhoc/' + id,
        method: "DELETE"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //Delete price update in bulk
    function deletePriceUpdateBulk(params) {
        var deferred = $q.defer();
        $http({
          url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-updates/adhoc/all',
          method: "DELETE",
          params: params
        }).then(function (data) {
          deferred.resolve(data);
        }).catch(function (message) {
          deferred.reject(message);
        });
        return deferred.promise;
      }

    //update edited price
    function editPriceUpdate(params) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/adhoc/price-updates/update',
        method: "PUT",
        params: params
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // Get  item by query
    function searchAllItems(itemSearchQuery) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + '/buyers/items/adhoc',
        method: "PUT",
        data: itemSearchQuery
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    // delete price update
    function deletePrice(id) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'price-updates/adhoc/' + id,
        method: "DELETE"
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }

    //
    function newPriceUpdateItems(priceUpdateDTO) {
      var deferred = $q.defer();
      $http({
        url: API_CONSTANTS.serviceBase + 'api/' + API_CONSTANTS.baseVersion + 'buyer/adhoc/price-updates',
        method: "POST",
        data: priceUpdateDTO
      }).then(function (data) {
        deferred.resolve(data);
      }).catch(function (message) {
        deferred.reject(message);
      });
      return deferred.promise;
    }


    // set price object
    function setPriceObject(obj) {
      vm.priceObj = obj;
    }

    //Get price object
    function getPriceObject() {
      return vm.priceObj;
    }

    function setEditPriceObject(obj) {
      vm.editObj = obj;
    }

    function getEditablePriceObj() {
      return vm.editObj;
    }



  }
})();
