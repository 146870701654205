/**
* @Author: Jayalekshmi R
* @Date:   2018-06-11T15:32:51+05:30
* @Project: Ezyprocure
* @Filename: buyer.brands.edit.controller.js
 * @Last modified by: Jayalekshmi R
 * @Last modified time: 2018-06-14T15:45:47+05:30
*/



(function() {
  'use strict';
  angular
  .module('ezyprocureApp')
  .controller('BrandEditController', BrandEditController);

  BrandEditController.$inject = ['BuyerDataService', '$state', '$stateParams', 'toastr', 'BrandManagementService', 'GLOBAL_CONSTANTS', 'NotificationService'];

  function BrandEditController(BuyerDataService, $state, $stateParams, toastr, BrandManagementService, GLOBAL_CONSTANTS, NotificationService) {
    var vm = this;

    vm.brandId = $stateParams.brandId;
    vm.brands = {};
    vm.save = save;
    vm.formPatterns = GLOBAL_CONSTANTS.formPatterns;
    vm.getBrand = getBrand;
    vm.ezyCompanyDetails = {};
    vm.getBrandIsoCodeAndIdentifier = getBrandIsoCodeAndIdentifier;
    vm.changeIsoCodeAndIdentifier = changeIsoCodeAndIdentifier;
    vm.deleteIsocodeAndIdentifier = deleteIsocodeAndIdentifier;
    vm.createBrandIsoCodeAndIdentifier = createBrandIsoCodeAndIdentifier;
    vm.peppolAccess = BuyerDataService.getBuyerProfile().accessDTO.accessForPEPPOL;
    vm.customDutyTypes = GLOBAL_CONSTANTS.CUSTOM_DUTY_TYPE;

    /**
    * [save - Updates Brand]
    */
    function save(){
        if(vm.brands.poPrefix == ''){
            vm.brands.poPrefix = null;
        }
      BrandManagementService.updateBrand(vm.brands).then(function(response){
        NotificationService.success({
          title: 'Brand',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
        if((vm.ezyCompanyDetails.isoCode == '' || vm.ezyCompanyDetails.identifier == '') && vm.peppolAccess){
            vm.deleteIsocodeAndIdentifier();
        }
        else if(vm.isIsoCodeEmpty && vm.peppolAccess){
          vm.createBrandIsoCodeAndIdentifier();
        }
        else {
        vm.changeIsoCodeAndIdentifier();
        }
        $state.go('buyer.tools.brands', {reload: false});
      },function(error) {
        NotificationService.error({
          title: 'Brand Updation',
          error: error
        });
      });
    }

    /**
    * [getBrand - Fetches the brand using brand id]
    */
    function getBrand() {
      BrandManagementService.getBrandById(vm.brandId).
        then(function(response) {
          if(response){
            vm.brands = response;
            vm.brands['orderTermsAndConditions'] = vm.brands.orderTermsConditions;
            getBrandIsoCodeAndIdentifier();
          }
        }, function(error) {
        });
    }

    // get brands  iso code and identifier
    function getBrandIsoCodeAndIdentifier(){
      var params = {
        'ezyBrandId': vm.brands.id
      }
      BrandManagementService.getBrandIsoCodeAndIdentifier(params).then(function(response){
        vm.ezyCompanyDetails = response.data;
        vm.isoCode = response.data.isoCode;
        vm.identifier = response.data.identifier;
      },
      function(error){
        vm.isIsoCodeEmpty = true;
        toastr.info('No ISO Code and Identifier for this Brand!', 'ISO Code and Identifier');
      });
    }

    // edit iso code and identifier
    function changeIsoCodeAndIdentifier(){
      if(!vm.peppolAccess)
          return false;
      if(vm.isoCode == vm.ezyCompanyDetails.isoCode && vm.identifier == vm.ezyCompanyDetails.identifier)
          return false;
      var detailsDTO = {
        'ezpBrandId': vm.brands.id,
        'identifier': vm.ezyCompanyDetails.identifier,
        'isoCode':vm.ezyCompanyDetails.isoCode,
        ezyCompanyDetails:{
          'ezpCompanyId':BuyerDataService.getBuyerProfile().buyerUser.buyer.id
        }
      }
      BrandManagementService.editBrandIsoCodeAndIdentifier(detailsDTO).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.UPDATED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'global.IsoCodeAndIdentifier',
          error: error
        });
      });
    }

    //delete iso code and identifier
    function deleteIsocodeAndIdentifier(){
      BrandManagementService.deleteBrandIsoCodeAndIdentifier(vm.ezyCompanyDetails.id).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.DELETED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'global.IsoCodeAndIdentifier',
          error: error
        });
      });
    }

    //create iso code and identifier
    function createBrandIsoCodeAndIdentifier(){
      var detailsDTO = {
        'ezpBrandId': vm.brands.id,
        'identifier': vm.ezyCompanyDetails.identifier,
        'isoCode':vm.ezyCompanyDetails.isoCode,
        ezyCompanyDetails:{
          'ezpCompanyId':BuyerDataService.getBuyerProfile().buyerUser.buyer.id
        }
      }
      BrandManagementService.createBrandIsoCodeAndIdentifier(detailsDTO).then(function(response){
        NotificationService.success({
          title: 'global.IsoCodeAndIdentifier',
          message: 'alertMessage.CREATED_SUCCESSFULLY'
        });
      },
      function(error){
        NotificationService.error({
          title: 'global.IsoCodeAndIdentifier',
          error: error
        });
      });
    }

    getBrand();

  }
})();
