/**
* @Author: Ajay
* @Date:   20-Apr-2017 16:40 +05:30
* @Project: Ezyprocure
* @Filename: supplier.invoice-report.individual-details.controller.js
 * @Last modified by:   sneha
 * @Last modified time: 2019-11-29T09:13:11+05:30
*/



(function() {
  'use strict';

  angular
  .module('ezyprocureApp')
  .controller('SupplierInvoiceReportIndividualDetailsController', SupplierInvoiceReportIndividualDetailsController);

  SupplierInvoiceReportIndividualDetailsController.$inject = ['$scope', '$state', '$stateParams', 'pagingParams', 'toastr', 'BuyerReceivingOrderService', 'NotificationService', 'EXPORT_CONSTANTS', 'CSVExportService', 'PDFExportService', 'UTCDateService', 'Auth', 'GLOBAL_CONSTANTS','EzyapInvoiceService', 'SupplierDataService', 'BuyerAdhocPOFormService'];

  function SupplierInvoiceReportIndividualDetailsController($scope, $state, $stateParams, pagingParams, toastr, BuyerReceivingOrderService, NotificationService, EXPORT_CONSTANTS, CSVExportService, PDFExportService, UTCDateService, Auth, GLOBAL_CONSTANTS, EzyapInvoiceService,SupplierDataService, BuyerAdhocPOFormService) {
    var vm = this;
    vm.init = init;
    vm.purchaseOrderNumber = $stateParams.purchaseOrderNumber;
    vm.deliveryDate = new Date($stateParams.deliveryDate);
    vm.xeroInvoiceHeader = EXPORT_CONSTANTS.CSV.xeroInvoiceHeader;
    vm.getXEROInvoiceCSVData = getXEROInvoiceCSVData;
    vm.getIndividualInvoicePDF = getIndividualInvoicePDF;
    vm.userRole = Auth.getUserRole();
    vm.reportType = "INVOICE";
    vm.role = Auth.getUserRole().ugly;
    vm.defaultDateFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_FORMAT;
    vm.defaultDateTimeFormat = GLOBAL_CONSTANTS.DEFAULT_DATE_TIME_FORMAT;
    vm.accessForPriceDisplay = Auth.hasPermission('accessForPriceDisplay');
    vm.getInvoiceDetails = getInvoiceDetails;
    vm.downloadURI = downloadURI;
    vm.printInvoiceReport = printInvoiceReport;
    vm.userDetails =  SupplierDataService.getSupplierProfile().supplier;

    vm.predicate = $stateParams.predicate || pagingParams.predicate;
    vm.reverse = $stateParams.reverse || pagingParams.reverse;
    if ($stateParams.reverse) {
      // vm.predicate = $stateParams.predicate;
      vm.reverse = $stateParams.reverse === 'true';
    }else{
      // vm.predicate = pagingParams.predicate;
      vm.reverse = pagingParams.reverse;
    }
    vm.loadAll = loadAll;
    vm.query = {
      limit: 20,
      page: 1
    };

    /**
     * [init loads the function initially]
     */
    function init(){
      BuyerAdhocPOFormService.gstConfigDetails().then(function (res) {
        localStorage.setItem("gst-config", JSON.stringify(res[0].configValue));
      });
      loadAll();
    }
    function sort() {
      var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
      return result;
    }
    /**
     * [loadAll Gets GRN item Details by PO number]
     */
     function loadAll() {
       BuyerReceivingOrderService.getGRNItemDetails(vm.purchaseOrderNumber, {
         deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
         page: vm.query.page - 1,
         size: vm.query.limit,
         query: '',
          sort: sort(),
       }).then(function(response){
         vm.purchaseOrderDetails = response.data;
       //  vm.purchaseOrderDetails.invoice.deliveryCharge = vm.purchaseOrderDetails.deliveryCharges;
         vm.requirementNumber = vm.purchaseOrderDetails.items[0].requirementNumber;
         vm.selectedDeliveryDate = vm.purchaseOrderDetails.items[0].deliveryDate;
         vm.purchaseOrderNumber = vm.purchaseOrderDetails.purchaseOrderNumber;
         vm.invoiceType = vm.purchaseOrderDetails.invoice.type;
         if (vm.purchaseOrderDetails.invoice.otherCharges){
            vm.purchaseOrderDetails.invoice.otherCharges = JSON.parse(vm.purchaseOrderDetails.invoice.otherCharges);
            vm.keys = Object.keys(vm.purchaseOrderDetails.invoice.otherCharges)
            vm.gstPercentage = (vm.purchaseOrderDetails.invoice.addedGst * 100) / vm.purchaseOrderDetails.invoice.invoiceTotal;
          }
         vm.getInvoiceDetails();
       },function(error){
         NotificationService.error({
           title: 'Invoice Report Details',
           error: error
         });
       });
     }

    function getXEROInvoiceCSVData() {
      return CSVExportService.getXEROInvoiceCSV(vm.purchaseOrderDetails);
    }

    function downloadURI(uri, name)
    {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = name;
      link.href = uri;
      link.click();
    }

    function getIndividualInvoicePDF() {
      PDFExportService.getInvoicePDF(vm.purchaseOrderNumber,{
        deliveryDate: !vm.deliveryDate ? '' : vm.deliveryDate,
        page: vm.query.page - 1,
        size: vm.query.limit,
        query: '',
         sort: sort(),
      }).then(function(response){
        vm.downloadURI(response.data.message, "PDF_" + vm.purchaseOrderNumber);
      },function(error) {
        NotificationService.error({
          title: 'Individual PDF Export',
          error: error
        });
      });
    }

    //get Invoice Details to export xml
    function getInvoiceDetails() {
      EzyapInvoiceService.getInvoiceDetails(vm.purchaseOrderDetails.invoice.id).then(function(response){
        vm.invoiceDetails = response.data;
      },function(error){
        // NotificationService.error({
        //   title: 'Purchase Order Details',
        //   error: error
        // });
      });
    }

    /**
     * [printInvoiceReport Prints the purchase request details]
     */
    function printInvoiceReport(id) {
      PDFExportService.getPDF(id)
    }

  }
})();
